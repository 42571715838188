import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { 
    Divider, Tabs, Tab, Card, CardContent, CardHeader, LinearProgress, Table, TableRow,
    TableCell, TableBody, TableContainer, TableHead, Paper, Breadcrumbs, Typography,
    TablePagination
} from "@material-ui/core";
import TabPanel from "../../../components/TabPanel";
import moment from "moment";
import { Routes, Route, Link } from 'react-router-dom';
import InvoiceDetails from './InvoiceDetails';
import OrderDetails from './OrderDetails';
import { Home, AccountBox } from "@material-ui/icons";
import InfiniteScroll from 'react-infinite-scroller';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        jobDetailCardRow: {
            display: "flex",
            justifyContent: "space-between"
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        textField: {
            marginRight: 8
        },
        sheetSummaryCard: {
            marginTop: 16, 
            backgroundColor: "#1E88E5", 
            padding: 8,
            display: "flex"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        },
        table: {
            minWidth: 650,
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        }
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface ParamTypes {
    customerId: string;
}

interface CustomerInfo {
    id: number;
    name: string;
    address1: string;
    address2: string;
    address3: string;
    phone: string;
    email: string;
}

interface TransactionData {
    transactions: Array<TransactionInfo>;
    transactionCount: number;
}

interface TransactionInfo {
    id: number;
    invoiceNo: string;
    transactionTypeText: string;
    transactionDate: string;
    amount: number;
}

interface OrderInfo {
    salesOrderId: number;
    customerOrderNo: string;
    statusText: string;
    orderDate: string;
    dueDate: string;
    orderTotal: number;
}

interface JobInfo {
    jobNo: number;
    accountName: string;
    totalTags: number;
    totalDispatched: number;
    totalMade: number;
    totalMaking: number;
    totalInZone: number;
    totalInvoiced: number;
}

export default function CustomerDetails() {
    const classes = useStyles()
    const { customerId } = useParams()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo | undefined>(undefined)
    const [transactions, setTransactions] = useState<Array<TransactionInfo>>([])
    const [hasMoreTransactions, setMoreTransactions] = useState<boolean>(true)
    const [orders, setOrders] = useState<Array<OrderInfo> | undefined>(undefined)
    const [jobs, setJobs] = useState<Array<JobInfo> | undefined>(undefined)

    const [orderPage, setOrderPage] = useState(0);
    const [rowsPerOrderPage, setRowsPerOrderPage] = useState<number>(20)
  
    const handleChangeOrderPage = (event: unknown, newPage: number) => {
      setOrderPage(newPage);
    };

    const handleChangeRowsPerOrderPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerOrderPage(+event.target.value);
        setOrderPage(0);
      };
  
    // let { path, url } = useRouteMatch()
    const location = useLocation()

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        //2nd tab uses infinite scroller
        if(newValue !== 1) {
            setLoading(true)
        }
        setSelectedTab(newValue);
    };

    useEffect(() => {
        if(selectedTab === 0) {
            const getCustomerInfo = () => {
                setLoading(true)
                fetch(`/api/encore/customers/${customerId}`)
                .then(res => res.json())
                .then((result: CustomerInfo) => {
                    if(result) {
                        setCustomerInfo(result)
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching customer detail", error)
                    setLoading(false)
                })
            }
            getCustomerInfo()
            
        } else if(selectedTab === 1) {
            setTransactions([])
            setMoreTransactions(true)
            
        } else if (selectedTab === 2) {
            const getOrders = () => {
                setLoading(true)
                fetch(`/api/encore/customers/${customerId}/orders`)
                .then(res => res.json())
                .then((result: Array<OrderInfo>) => {
                    if(result) {
                        setOrders(result)
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching customer orders", error)
                    setLoading(false)
                })
            }
            getOrders()

        } else if (selectedTab === 3) {
            const getJobs = () => {
                setLoading(true)
                fetch(`/api/encore/customers/${customerId}/jobs`)
                .then(res => res.json())
                .then((result: Array<JobInfo>) => {
                    if(result) {
                        setJobs(result)
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching customer jobs", error)
                    setLoading(false)
                })
            }
            getJobs()
        }
    }, [selectedTab, customerId])

    //Get tab indicator to show on inital load
    useEffect(() => {
        window.dispatchEvent(new CustomEvent("resize"));
    }, []);

    function formatDate(date: string) : moment.Moment | string {
        if (moment.parseZone(date).get('year') < 1900){
            return "";
        }
        return date ? moment.parseZone(date).format('DD MMM YYYY'): "";
    }

    async function loadMoreTransactions(page: number) {
        
        const response = await fetch(`/api/encore/customers/${customerId}/transactions/offset/${(page -1) * 50}/rows/50`)

        if(!response.ok) {
            console.log("ERROR: fetching customer transactions", response.status, response.statusText)
           return; 
        }

        const data: TransactionData = await response.json()

        if(data.transactions !== null) {
            let newTransactions = [...transactions, ...data.transactions]
            setTransactions(newTransactions)

            if(newTransactions.length === data.transactionCount) {
                setMoreTransactions(false);
            }
        } else {
            setMoreTransactions(false);
        }
    }

    return (
        <>
                    <Breadcrumbs>
                        <Link to="/encore" className={classes.link}>
                            <Home className={classes.icon} />
                            Home
                        </Link>
                        <Link to="/encore/customers" className={classes.link}>
                            <AccountBox className={classes.icon} />
                            Customers
                        </Link>
                        <Typography color="textPrimary">{customerId}</Typography>
                    </Breadcrumbs>
                    <div className={classes.header}>
                        <h2 style={{flexGrow: 1}}>
                            Customer Details
                        </h2>
                    </div>
                    <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="General" />
                        <Tab label="Transactions" />
                        <Tab label="Orders" />
                        <Tab label="Jobs" />

                    </Tabs>
                    <Divider />
                    {loading && 
                        <LinearProgress 
                            className={classes.progressBackground} 
                            classes={{barColorPrimary: classes.progress}}/>
                    }
                    <TabPanel value={selectedTab} index={0}>
                        {(!loading && customerInfo !== undefined) &&
                            <div style={{marginTop: 8}}>
                                <Card>
                                    <CardHeader title={`${customerInfo.id} - ${customerInfo.name}`}>
                                    </CardHeader>
                                    <CardContent>
                                        <div>
                                            <span>Phone: {customerInfo.phone}</span>
                                        </div>
                                        <div>
                                            <span>Email: {customerInfo.email}</span>
                                        </div>
                                        <div>
                                            <span>Address: {customerInfo.address1} {customerInfo.address2} {customerInfo.address3}</span>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                            {(hasMoreTransactions || transactions.length !== 0) &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={loadMoreTransactions}
                                    hasMore={hasMoreTransactions}
                                    loader={
                                        <LinearProgress 
                                        className={classes.progressBackground} 
                                        classes={{barColorPrimary: classes.progress}} key={0}/>
                                    }>
                                    <TableContainer component={Paper} style={{marginTop: 16}}>
                                        <Table className={classes.table} size="small" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Invoice No</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Transaction Date</StyledTableCell>
                                                <StyledTableCell>Amount</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {transactions.length !== 0 && transactions.map((transaction: TransactionInfo) => (
                                                <StyledTableRow key={transaction.id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        <Link to={`invoices/${transaction.id}`}>
                                                            {transaction.invoiceNo}
                                                        </Link>
                                                    </StyledTableCell>
                                                    <StyledTableCell>{transaction.transactionTypeText}</StyledTableCell>
                                                    <StyledTableCell>{formatDate(transaction.transactionDate)}</StyledTableCell>
                                                    <StyledTableCell>{transaction.amount.toFixed(2)}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </InfiniteScroll>
                            }
                            {(!hasMoreTransactions && transactions.length === 0) &&
                                <p>No transactions found</p>
                            }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        {(!loading && orders !== undefined) &&
                            <div>
                                <TableContainer component={Paper} style={{marginTop: 16}}>
                                    <Table className={classes.table} size="small" aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Order No</StyledTableCell>
                                            <StyledTableCell>Customer Order No</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Order Date</StyledTableCell>
                                            <StyledTableCell>Due Date</StyledTableCell>
                                            <StyledTableCell>Order Total</StyledTableCell>

                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {orders.length !== 0 && orders.slice(orderPage * rowsPerOrderPage, orderPage * rowsPerOrderPage + rowsPerOrderPage).map((order: OrderInfo) => (
                                            <StyledTableRow key={order.salesOrderId}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Link to={`orders/${order.salesOrderId}`}>
                                                        {order.salesOrderId}
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell>{order.customerOrderNo}</StyledTableCell>
                                                <StyledTableCell>{order.statusText}</StyledTableCell>
                                                <StyledTableCell>{formatDate(order.orderDate)}</StyledTableCell>
                                                <StyledTableCell>{formatDate(order.orderDate)}</StyledTableCell>
                                                <StyledTableCell>{order.orderTotal.toFixed(2)}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 30]}
                                    component="div"
                                    count={orders.length}
                                    rowsPerPage={rowsPerOrderPage}
                                    page={orderPage}
                                    onChangePage={handleChangeOrderPage}
                                    onChangeRowsPerPage={handleChangeRowsPerOrderPage}
                                />
                            </div>
                        }
                        {(!loading && orders === undefined) &&
                            <p>No orders found</p>
                        }
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        {(!loading && jobs !== undefined) &&
                            <TableContainer component={Paper} style={{marginTop: 16}}>
                                <Table className={classes.table} size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Job No</StyledTableCell>
                                        <StyledTableCell>Account Name</StyledTableCell>
                                        <StyledTableCell>Total Tags</StyledTableCell>
                                        <StyledTableCell>Total Dispatched</StyledTableCell>
                                        <StyledTableCell>Total Made</StyledTableCell>
                                        <StyledTableCell>Total Making</StyledTableCell>
                                        <StyledTableCell>Total In Zone</StyledTableCell>
                                        <StyledTableCell>Total Invoiced</StyledTableCell>

                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {jobs.length !== 0 && jobs.map((job: JobInfo) => (
                                        <StyledTableRow key={job.jobNo}>
                                            <StyledTableCell component="th" scope="row">
                                                <Link to={`/encore/jobs/${job.jobNo}`}>
                                                    {job.jobNo}
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell>{job.accountName}</StyledTableCell>
                                            <StyledTableCell>{job.totalTags}</StyledTableCell>
                                            <StyledTableCell>{job.totalDispatched}</StyledTableCell>
                                            <StyledTableCell>{job.totalMade}</StyledTableCell>
                                            <StyledTableCell>{job.totalMaking}</StyledTableCell>
                                            <StyledTableCell>{job.totalInZone}</StyledTableCell>
                                            <StyledTableCell>{job.totalInvoiced}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        {(!loading && jobs === undefined) &&
                            <p>No jobs found</p>
                        }
                    </TabPanel>
           
                </>
                
        
        
    )
}
