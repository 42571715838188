import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid, Paper, Radio, Table, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { API } from 'aws-amplify';
import { OpportunityCustomerDetail, OpportunityCustomerQuoteDetail, OpportunityMeasureFileInfo } from '../../../types/OpportunityTypes';
import useFetch from '../../../hooks/api/useFetch';
import { StyledTableCell, StyledTableRow } from '../../../../components/StyledTableComponents';
import { formatDateTime } from '../../../../utils/utils';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import { useStyles } from './JobTagDetailTable';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            '& > *': {
              margin: theme.spacing(1),
            },
          },
        table: {
            minWidth: 650,
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 97,
            paddingBottom: 97,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        },
        dollarAmountCell: {
            textAlign: "right"
        },
        grandTotal:{
            textAlign: "right",
            fontWeight: "bold",
            color: "black"
        },
        footer: {
            "& > td > div": {
              height: 60,
              minHeight: 60
            },
            backgroundColor: "#F5F5F5",
            height: 60
          }
    })
);


export interface CreateCustomerMeasureQuoteDialogProps {
    open: boolean;
    user: any | null
    // quoteDrawing?: string;
    // quoteNotes?: string;
    opportunityNo?: string;
    selectedCustomer?: OpportunityCustomerDetail;
    // customerSeqNo?: number;
    // measureNo?: number;
    // customerQuote?: OpportunityCustomerQuoteDetail
    
    onClose: () => void;
}
export function CreateCustomerMeasureQuoteDialog(props: CreateCustomerMeasureQuoteDialogProps) {
    const { onClose, open, opportunityNo, selectedCustomer, user } = props;
    const [measureFiles, setMeasureFiles] = useState<Array<OpportunityMeasureFileInfo>>([])
    const [selectedMeasureFileIdx, setSelectedMeasureFileIdx] = useState<number>(-1)
    const classes = useStyles();
    // const [drawing, setDrawing] = useState('');

    // const [notes, setNotes] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { data, loading, error:fetchError, reFetch } = useFetch<Array<OpportunityMeasureFileInfo>>(`/encore/opportunities/measure/${opportunityNo}`)



    const handleClose = () => {
        onClose();
    };


    function handleCloseDialog(): void {
        onClose();
    }

    const handleSubmit = async () => {

        setErrorMessage('')
        try {
            let params = {
                body: {
                    oppNo: parseInt(opportunityNo!, 10),
                    customerId: selectedCustomer?.seqNo,
                    customerName: selectedCustomer?.customerName,
                    username:user.attributes.name,
                    measureId: data![selectedMeasureFileIdx].id
                }
            }
            await API.post("", `/encore/opportunities/addCustomer/${opportunityNo}/customers/${selectedCustomer?.seqNo}/quotes/new`, params)
            onClose();
            
        } catch (error: any) {
            console.log(error) 
            setErrorMessage(error.response.data.message)
        }
        
    }

    if (loading) return <div>Loading...</div>

    if (fetchError) console.log(fetchError)

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth='md'>
            <DialogTitle id="simple-dialog-title">Select Measure</DialogTitle>
            
                <DialogContent>

                {data?.length !== 0 &&
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Filename</StyledTableCell>
                                        <StyledTableCell>Uploaded By</StyledTableCell>
                                        <StyledTableCell>Uploaded At</StyledTableCell>
                                        <StyledTableCell>Total</StyledTableCell>
                                        <StyledTableCell>Version No.</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {data?.map((file: OpportunityMeasureFileInfo, i: number) => (
                                        <StyledTableRow key={file.id}>
                                            <StyledTableCell component="th" scope="row">
                                                <Radio 
                                                    checked={i === selectedMeasureFileIdx}
                                                    onChange={() => setSelectedMeasureFileIdx(i)}/>
                                            </StyledTableCell>
                                            <StyledTableCell>{file.filename}</StyledTableCell>
                                            <StyledTableCell>{file.uploadedBy}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(file.uploadedAt)}</StyledTableCell>
                                            <StyledTableCell>{`$${file.measureTotal.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell>{data.length - i}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                {/* <Grid container direction='column' spacing={1}>
                    <Grid item  >

                    <TextField
                        placeholder="Enter Drawing Detail"
                        fullWidth
                        variant="filled"
                        required
                        name="drawing"
                        value={drawing}
                        multiline={false}
                        rows={1}
                        onChange={(e) => setDrawing(e.target.value)} />
                </Grid>
                <Grid item  >
                    <TextField
                        placeholder="Enter Notes"
                        fullWidth
                        variant="filled"
                        required
                        name="notes"
                        value={notes}
                        multiline={true}
                        rows={10}
                        onChange={(e) => setNotes(e.target.value)} />
                </Grid>
                </Grid> */}

                </DialogContent>

                <DialogActions>
                    {errorMessage &&
                    <span style={{color: "red"}}>{errorMessage}</span>
                }
                    <Button color="secondary" onClick={() => handleCloseDialog()}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={selectedMeasureFileIdx === -1}
                    >
                        Add Measure Quote
                    </Button>
                </DialogActions>
        </Dialog>
    );
}
