import React, { useState } from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, Button
} from "@material-ui/core"; 
import { Add } from "@material-ui/icons";
import FileUpload from './FileUpload';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        addButton: {
            backgroundColor: "#00e676"
        }
    })
);

interface Props {
    user: any | null
}

function EurolabHome({ user }: Props) {
    const classes = useStyles()

    const [isAdd, setAdd] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    
    const handleAdd = () => {
        setLoading(false)
        setAdd(true)
    }

    const hideUpload = () => {
        setAdd(false)
    }

    const handleLoading = (value: boolean) => {
        setLoading(value)
    }

    const getLoading = () => {
        return isLoading
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Home
                </h2>
                <div className={classes.margin}>
                    <Button color="inherit" className={classes.addButton} onClick={handleAdd}>
                        <Add />
                        Add
                    </Button>
                </div>
            </div>
            <Divider style={{marginBottom: 16}} />
            
            {isAdd && 
                <FileUpload description="Upload file below." addDisabled={hideUpload} setLoading={handleLoading} getLoading={getLoading} isSecondary={false} user={user}/>
            }
        </div>
        
                

    )
}

export default EurolabHome