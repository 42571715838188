import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, FormControl, Dialog, DialogContent, 
    DialogActions, TextField, FormLabel, DialogTitle
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import {
    AddQuoteForm
} from '../../../types/OpportunityTypes';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface QuoteDialogProps {
    openDialog: boolean;
    handleQuoteDialog: (open: boolean) => void;
}

export default function AddQuoteDialog({ openDialog, handleQuoteDialog }: QuoteDialogProps) {
    const classes = useStyles()

    const { handleSubmit, control, errors } = useForm<AddQuoteForm>();

    function onSubmit(data: AddQuoteForm) {
        console.log(data)
        handleQuoteDialog(false)
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleQuoteDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                Add Quote
            </DialogTitle>
            <DialogContent>
                <FormControl
                    error={Boolean(errors.serviceType)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Service Type</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter service type"
                                fullWidth
                                variant="filled"
                                error={errors.serviceType ? true : false}
                                helperText={errors.serviceType?.message}/>
                        }
                        name="serviceType"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.supplyTonnes)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Supply Tonnes</FormLabel>

                    <Controller
                        as={
                            <NumberFormat
                                customInput={TextField}
                                error={errors.supplyTonnes ? true : false}
                                helperText={errors.supplyTonnes?.message} />
                        }
                        name="supplyTonnes"
                        control={control}
                        defaultValue=""
                        variant="filled"
                        placeholder="Enter supply tonnes"
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.placeTonnes)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Place Tonnes</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter place tonnes"
                                fullWidth
                                variant="filled"
                                error={errors.placeTonnes ? true : false}
                                helperText={errors.placeTonnes?.message}/>
                        }
                        name="placeTonnes"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.supplyAmount)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Supply Amount ($)</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter supply amount"
                                fullWidth
                                variant="filled"
                                error={errors.supplyAmount ? true : false}
                                helperText={errors.supplyAmount?.message}/>
                        }
                        name="supplyAmount"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.placeAmount)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Place Amount ($)</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter place amount"
                                fullWidth
                                variant="filled"
                                error={errors.placeAmount ? true : false}
                                helperText={errors.placeAmount?.message}/>
                        }
                        name="placeAmount"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.meshAmount)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Mesh Amount ($)</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter mesh amount"
                                fullWidth
                                variant="filled"
                                error={errors.meshAmount ? true : false}
                                helperText={errors.meshAmount?.message}/>
                        }
                        name="meshAmount"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.otherAmount)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Other Amount ($)</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter other amount"
                                fullWidth
                                variant="filled"
                                error={errors.otherAmount ? true : false}
                                helperText={errors.otherAmount?.message}/>
                        }
                        name="otherAmount"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.supplyRate)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Supply Rate</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter supply rate"
                                fullWidth
                                variant="filled"
                                error={errors.supplyRate ? true : false}
                                helperText={errors.supplyRate?.message}/>
                        }
                        name="supplyRate"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.placeRate)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Place Rate</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter place rate"
                                fullWidth
                                variant="filled"
                                error={errors.placeRate ? true : false}
                                helperText={errors.placeRate?.message}/>
                        }
                        name="placeRate"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.total)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Total ($)</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter total"
                                fullWidth
                                variant="filled"
                                error={errors.total ? true : false}
                                helperText={errors.total?.message}/>
                        }
                        name="total"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => handleQuoteDialog(false)}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}