import React, { useState } from 'react'
import { 
    Button, DialogTitle, Dialog, DialogContent, CircularProgress,
    DialogActions
} from "@material-ui/core";
import {
    OpportunityCustomerDetail
} from '../../../types/OpportunityTypes';
import { API } from "aws-amplify";

interface ConvertToJobProps {
    openDialog: boolean;
    handleCompleteOppDialog: (open: boolean) => void;
    user?: any | null;
    oppNo?: string;
    jobNo?: string;
    selectedCustomer: OpportunityCustomerDetail | undefined;
    selectedQuoteIdx: number | undefined
    getOpportunityDetail: () => void;
}

export default function ConvertToJobDialog({ openDialog, handleCompleteOppDialog, user, jobNo, oppNo, selectedCustomer, selectedQuoteIdx, getOpportunityDetail }: ConvertToJobProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const onSubmit = () => {
        if(user && oppNo && selectedCustomer && selectedQuoteIdx !== undefined) {
            setLoading(true)
            let params = {
                body: {
                    oppNo: parseInt(oppNo, 10),
                    customerId: selectedCustomer.seqNo,
                    measureId: selectedCustomer.quotes[selectedQuoteIdx].measureId,
                    username:user.attributes.name,
                    jobNo: jobNo
                }
            }
            API.post("", `/encore/opportunities/convertToJob`, params)
            .then(() => {
                setLoading(false)
                handleCompleteOppDialog(false)
                getOpportunityDetail()
            })
            .catch((error: any) => {
                console.log("Error: marking opportunity as lost", error)
                setError("Error: processing request")
                setLoading(false)
            })
        } else {
            setError("Error: processing request")
        }
    }

    const getVersionNumber = (): number => {
        if(selectedCustomer && selectedQuoteIdx !== undefined) {
            return selectedCustomer?.quotes.length - selectedQuoteIdx
        }
        return 1
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleCompleteOppDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                Convert to Job
            </DialogTitle>
            <DialogContent>
                Are you sure you want to convert this opportunity to a job. Selecting {selectedCustomer?.customerName} and quote version {getVersionNumber()} as the opportunity winner?
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleCompleteOppDialog(false)}  color="secondary">
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="secondary">
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}