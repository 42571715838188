import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, Button, Paper, TableContainer, Table, TableHead, TableRow,
    TableBody, TableCell, Tabs, Tab, Card, CardContent, CardHeader,
    Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText,
    Breadcrumbs, Typography, LinearProgress, FormControl, FormLabel, TextField,
    CircularProgress, IconButton, Grid
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
    Check, Close, ArrowDownward, Warning, Home, Description, CloudUpload,
    Add, Info, PictureAsPdf, ArrowBack, InsertDriveFileOutlined, GetApp, Delete,
    Assessment,
    DeleteOutline
} from "@material-ui/icons"
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
import { API, Storage } from "aws-amplify"

import TabPanel from "../../components/TabPanel";
import FileUpload from './FileUpload';
import LTQAnalysis from './LTQAnalysis';
import { 
    StressTestFileInfo, StressTestHistory, StressTestResults, StressWeldResults, StressTestUploadedFile 
} from '../types/StressTestTypes';
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot,
    TimelineContent, Autocomplete
} from "@material-ui/lab";
import { useForm, Controller } from "react-hook-form";
import Dropzone from 'react-dropzone'
import { FileIcon, defaultStyles, DefaultExtensionType } from "react-file-icon";
import { isThisSecond } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        addButton: {
            backgroundColor: "#00e676"
        },
        cardRow: {
            display: "flex"
        },
        cardItem: {
            flexGrow: 1
        },
        cardValue: {
            fontWeight: "bold"
        },
        toolbar: {
            marginTop: 16
        },
        cardSummary: {
            marginBottom: 8,
            // backgroundColor: "#F5F5F5",
            paddingBottom: 8
        },
        cardSummaryRow: {
            display: "flex",
            justifyContent: "space-between"
        },
        summaryValue: {
            display: "flex",
            marginLeft: 4
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        paper: {
            padding: '6px 16px',
        },
        button: {
            marginRight: 8, 
            marginBottom: 8
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 97,
            paddingBottom: 97,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        },
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

  interface ParamTypes {
    id: string;     
}

interface Props {
    user: any | null
}

interface StateProps {
    tab: number;
}

export default function StressTestFileDetails({ user }: Props) {
    const classes = useStyles()

    const [isLoaded, setLoading] = useState<boolean>(true)
    const [fileInfo, setFileInfo] = useState<StressTestFileInfo>()
    const [selectedTab, setSelectedTab] = useState(0);
    const [openQuarantineDialog, setOpenQuarantineDialog] = useState<boolean>(false);
    const [openRemoveQuarantineDialog, setOpenRemoveQuarantineDialog] = useState<boolean>(false);
    const [openDowngradeDialog, setOpenDowngradeDialog] = useState<boolean>(false);
    const [openPassDialog, setOpenPassDialog] = useState<boolean>(false);
    const [openFailDialog, setOpenFailDialog] = useState<boolean>(false);
    const [uploadSecondaryTest, setUploadSecondaryTest] = useState<boolean>(false)
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    const [previousFileInfo, setPreviousFileInfo] = useState<StressTestFileInfo>()
    const [fileHistory, setFileHistory] = useState<Array<StressTestHistory>>()
    const [analysisActive, setAnalysisActive] = useState<boolean>(false)
    const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState<Array<File>>([])
    const [numUploadedFiles, setNumUploadedFiles] = useState<number>(0)
    const [filesList, setFilesList] = useState<Array<StressTestUploadedFile>>([])
    const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState<boolean>(false)
    const [selectedFile, setSelectedFile] = useState<StressTestUploadedFile>()
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<String>('');
    

    const location = useLocation()
    const state = location.state as StateProps;
    const { tab } = state;
    const { id } = useParams()
    const username = user !== null ? user.attributes.name : ""
    const navigate = useNavigate()

    function getFileInfo() {
        setLoading(true)
        setUploadSecondaryTest(false)
        fetch(`/api/el/stressfiles/${id}`)
        .then(res => res.json())
        .then((result: StressTestFileInfo) => {
            setFileInfo(result) 
            setLoading(false)
        })
    }

    function updateStatus(status: string) {

        let data = {

            body: {
                seqNo: Number(id),
                username: username,
                status: status,
            }
        }

        API.post("", `/el/stressfiles/status/updateFile`, data)
        .then(() => {
           getFileInfo()
        })
        .catch((error: any) => {
            console.log("Error: updating file", error)
            getFileInfo()
        });
    }

    function updateQuarantine(isQuarantine: boolean) {

        let data = {
            body: {
                seqNo: Number(id),
                username: username,
                isQuarantine: isQuarantine,
            }
        }

        API.post("", `/el/stressfiles/quarantine/updateFile`, data)
        .then(() => {
           getFileInfo()
        })
        .catch((error: any) => {
            console.log("Error: updating file", error)
            getFileInfo()
        });
    }

    useEffect(() => {

        if(selectedTab === 0) {
            const getFileInfo = () => {
                setUploadSecondaryTest(false)
                setLoading(true)
                fetch(`/api/el/stressfiles/${id}`)
                .then(res => res.json())
                .then((result: StressTestFileInfo) => {
                    setFileInfo(result)
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching file info", error)
                    setLoading(false)
                })
            }
            getFileInfo()

        } else if(selectedTab === 1) {
            const getFileHistory = () => {
                setUploadSecondaryTest(false)
                setLoading(true)
                fetch(`/api/el/stressfiles/${id}/history`)
                .then(res => res.json())
                .then((result: Array<StressTestHistory>) => {
                    setFileHistory(result)
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching file info", error)
                    setLoading(false)
                })
            }
            getFileHistory()
    
        } else if (selectedTab === 2) {
            setAnalysisActive(true)

        } else if (selectedTab === 3) {
            setShowUploadFiles(false)
            const getFiles = () => {
                setLoading(true)
                API.get("", `/el/stressfiles/${id}/files`, {})
                .then((response: Array<StressTestUploadedFile>) => {
                    if(response) {
                        setFilesList(response)
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching files", error.response)
                    setLoading(false)
                })  
            }
            getFiles()

        } else if (selectedTab === 4) {
            if(fileInfo) {
                const getPreviousFileInfo = () => {
                    setUploadSecondaryTest(false)
                    setLoading(true)
                    fetch(`/api/el/stressfiles/${fileInfo.origTestSeqNo}`)
                    .then(res => res.json())
                    .then((result: StressTestFileInfo) => {

                        setPreviousFileInfo(result)
                        setLoading(false)
                    })
                    .catch((error: any) => {
                        console.log("Error: fetching file info", error)
                        setLoading(false)
                    })
                }
                getPreviousFileInfo()
            }
        }

    }, [id, selectedTab]);

    useEffect(() => {
        if(location.state) {
            if(tab !== undefined) {
                setSelectedTab(tab)
            }
        }
    }, [location.state, tab])

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    function formatDate(date: string) : moment.Moment | string {
        if (date === "0000-00-00"){
            return "";
        }
        return date ? moment.parseZone(date).format('DD/MM/YY'): "";
    }

    function formatDateTime(date: string) : moment.Moment | string {
        if (date === "0000-00-00"){
            return "";
        }
        return date ? moment.parseZone(date).format('DD/MM/YY HH:mm'): "";
    }

    const handleQuarantineDialog = () => {
        !fileInfo?.isQuarantine ? setOpenQuarantineDialog(true) : setOpenRemoveQuarantineDialog(true);
    }

    const handleCloseQuarantineDialog = () => {
        !fileInfo?.isQuarantine ? setOpenQuarantineDialog(false) : setOpenRemoveQuarantineDialog(false)
    };
    
    const handleSetQuarantine = () => {
        if(fileInfo !== undefined) {
            let isQuarantine: boolean;
            if(!fileInfo?.isQuarantine) {
                setOpenQuarantineDialog(false);
                isQuarantine = true;
            } else {
                setOpenRemoveQuarantineDialog(false);
                isQuarantine = false;
            }
            updateQuarantine(isQuarantine)
        }
    }

    const handleConfirmDeleteAllRecords = async () => {
        let data = {
            body: {
                seqNo: Number(id),
                username: username
            }
        }

        setLoading(true)
        try {
            await API.del("", `/el/stressfiles/${id}`, data)
        } catch (error) {
            console.log("Error: Deleting records for Lab Test", error)
            setLoading(false)
        }finally {
            setLoading(false)
            navigate("/eurolab/stressfiles")
        }
            
    }


    const handleDowngradeDialog = () => {
        setOpenDowngradeDialog(true)
    }

    const handleCloseDowngradeDialog = () => {
        setOpenDowngradeDialog(false)
    };
    
    const handlePassDialog = () => {
        setOpenPassDialog(true);
    }

    const handleFailDialog = () => {
        setOpenFailDialog(true);
    }

    const handleClosePassDialog = () => {
        setOpenPassDialog(false);
    }

    const handleCloseFailDialog = () => {
        setOpenFailDialog(false);
    }

    const handleSetPassStatus = () => {
        if(fileInfo !== undefined) {
            setOpenPassDialog(false);
            updateStatus("Pass")
        }
    }

    const handleSetFailStatus = () => {
        if(fileInfo !== undefined) {
            setOpenFailDialog(false);
            updateStatus("Fail")
        }
    }

    const handleSecondaryTest = () => {
        setUploadSecondaryTest(true)
    }

    const hideUpload = () => {
        setUploadSecondaryTest(false)
        getFileInfo()
    }

    const handleLoading = (value: boolean) => {
        setFileLoading(value)
    }

    const getLoading = () => {
        return fileLoading
    }

    function disableSecondaryTest(): boolean {
        if(fileInfo !== undefined) {
            if(fileInfo.status !== "Fail") {
                return true;
            } else if (fileInfo.origTestSeqNo !== 0) {
                return true
            } else if (fileInfo.newTestSeqNo !== 0) {
                return true;
            } else if (fileInfo.isRetestLoaded) {
                return true;
            } else {
                return false;
            }
        }
        return true
    }

    function disableUpdateStatusStressFile() : boolean {
        let disabled = false;
        if(fileInfo !== undefined) {
            if(fileInfo.isRetestLoaded) {
                disabled = true;
            } else {
                disabled = false;
            }
            if(user !== null) {
                if(fileInfo.loadedBy === user.attributes.name) {
                    disabled = true
                }
            }
        }
        return disabled;
    }

    function disableUpdateStressFile() : boolean {
        let disabled = false;
        if(fileInfo !== undefined) {
            if(fileInfo.isRetestLoaded) {
                disabled = true;
            } else {
                disabled = false;
            }
        }
        return disabled;
    }

    function disableDowngradeFile() : boolean {
        let disabled = false;
        if(fileInfo !== undefined) {
            if(fileInfo.isRetestLoaded) {
                disabled = true;
            } else if(fileInfo.isDowngrade) {
                disabled = true;
            } else {
                disabled = false;
            }
        }
        return disabled;
    }

    function getHistoryItemIcon(status: string) : JSX.Element {
        let icon;
        switch(status) {
            case "FILE LOADED":
                icon = <Add />
                break;
            case "RETEST":
                icon = <CloudUpload />
                break;
            case "STATUS":
                icon = <Info />
                break;
            case "QUARANTINE":
                icon = <Warning />
                break;
            case "DOWNGRADE":
                icon = <ArrowDownward />
                break;
            case "LTQ":
                icon = <Assessment />
                break;
            default:
                icon = <Home />
                break;  
        }
        return icon;
    }

    function getHistoryItemColor(status: string) : string {
        let color;
        switch(status) {
            case "FILE LOADED":
                color = "#9ccc65"
                break;
            case "RETEST":
                color = "#ec407a"
                break;
            case "STATUS":
                color = "#42a5f5"
                break;
            case "QUARANTINE":
                color = "#ffa726"
                break;
            case "DOWNGRADE":
                color = "#78909c"
                break;
            case "LTQ":
                color = "#009688"
                break;
            default:
                color = "#ef5350"
                break;  
        }
        return color;
    }

    // function handleCertPdf() {
    //     fetch(`/api/el/stressfiles/${id}/pdf`, {
    //         credentials: 'include'
    //     })
    //     .then(resp => resp.json())
    //     .then(url => {
    //         console.log(url)
    //         window.open(url, "_blank")
    //     })
    //     .catch((error: any) => {
    //         console.log("Error: fetching cert pdf", error)
    //     });
    // }

    function getFilename(filename: string) : string {
        return fileInfo?.seqNo + "/" + filename;
    }

    function incrementFileUploads() {
        setNumUploadedFiles(numUploadedFiles => numUploadedFiles + 1)
    }

    function onUploadFilesBack() {
        setShowUploadFiles(false)
        setUploadFiles([])
        setNumUploadedFiles(0)
        getFiles()
    }

    const getFiles = () => {
        setLoading(true)
        API.get("", `/el/stressfiles/${id}/files`, {})
        .then((response: Array<StressTestUploadedFile>) => {
            if(response) {
                console.log(response)
                setFilesList(response)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching files", error.response)
            setLoading(false)
        })  
    }

    function getFileExtension(filename: string) : DefaultExtensionType {
        let strSplit = filename.split("/")
        if(strSplit[1] === "pdf") return "pdf"
        if(strSplit[1] === "docx") return "docx"
        if(strSplit[1] === "doc") return "doc"
        if(strSplit[1] === "jpg") return "jpg"
        if(strSplit[1] === "jpeg") return "jpeg"
        if(strSplit[1] === "png") return "png"
        if(strSplit[1] === "csv") return "csv"
        if(strSplit[1] === "xlsx") return "xlsx"
        if(strSplit[1] === "xls") return "xls"
        return "txt"
    }

    const handleDeleteFile = (file: StressTestUploadedFile | undefined) => {
        if(file) {
            setLoading(true)
            var params = {
                body: {
                    filename: file.filename
                }
            }
    
            API.post("", "/el/stressfiles/deleteFile", params)
            .then((response: any) => {
                    setOpenDeleteFileDialog(false)
                    getFiles()
            })
            .catch((error: any) => {
                console.log("Error: deleting file", error)
                setLoading(false)
            })
        }
    }

    return (
        <div>
            <Breadcrumbs>
                <Link to="/eurolab" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Link to="/eurolab/stressfiles" className={classes.link}>
                    <Description className={classes.icon} />
                    Stress Files
                </Link>
                <Typography color="textPrimary">{id}</Typography>
            </Breadcrumbs>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Stress Test File Details
                </h2>
            </div>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="General" />
                <Tab label="History" />
                <Tab label="Analysis" />
                <Tab label="Files" />
                {(fileInfo !== undefined && fileInfo.origTestSeqNo !== 0) &&
                    <Tab label="Previous Test" />
                }
            </Tabs>

            {isLoaded &&  <LinearProgress  className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
            <Divider style={{marginBottom: 16}}/>
            

            <TabPanel value={selectedTab} index={0}>
                {uploadSecondaryTest ? 
                    <FileUpload description="Upload file below." addDisabled={hideUpload} setLoading={handleLoading} getLoading={getLoading} isSecondary={true} originalTestId={fileInfo?.seqNo} user={user}/>
                :
                    <div>
                        {fileInfo &&
                            <div>
                                <div style={{display: "flex", marginBottom: 16}}>
                                    <div style={{flexGrow: 1}}></div>
                                    <div>
                                        {fileInfo?.status === "Pending" &&
                                            <Button variant="contained"  color="primary" className={classes.button} onClick={()=> setOpenDeleteDialog(true)} endIcon={<DeleteOutline/>} >
                                                Delete
                                            </Button>
                                        }
                                        {fileInfo?.status === "Fail" &&
                                            <Button variant="contained" color="secondary" className={classes.button} onClick={handlePassDialog} endIcon={<Check/>} disabled={disableUpdateStatusStressFile()}>
                                                Pass
                                            </Button>
                                        }
                                        {fileInfo?.status === "Pass" &&
                                            <Button variant="contained" color="primary" className={classes.button} onClick={handleFailDialog} endIcon={<Close/>} disabled={disableUpdateStatusStressFile()}>
                                                Fail
                                            </Button>
                                        }

                                        {fileInfo?.status === "Pending" &&
                                            <Button variant="contained" color="secondary" className={classes.button} onClick={handlePassDialog} endIcon={<Check/>} disabled={disableUpdateStatusStressFile()}>
                                                Pass
                                            </Button>
                                        }
                                        {fileInfo?.status === "Pending" &&
                                            <Button variant="contained" color="primary" className={classes.button} onClick={handleFailDialog} endIcon={<Close/>} disabled={disableUpdateStatusStressFile()}>
                                                Fail
                                            </Button>
                                        }

                                        <Dialog open={openPassDialog} onClose={handleClosePassDialog} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Pass Stress Test</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                Are you sure you want to pass stress test?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClosePassDialog}  color="secondary">
                                                    No
                                                </Button>
                                                <Button onClick={handleSetPassStatus} variant="contained" color="secondary">
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog open={openFailDialog} onClose={handleCloseFailDialog} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Fail Stress Test</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                Are you sure you want to fail stress test?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseFailDialog}  color="primary">
                                                    No
                                                </Button>
                                                <Button onClick={handleSetFailStatus} variant="contained" color="primary">
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        {!fileInfo?.isQuarantine ? 
                                            <Button variant="contained" color="secondary" className={classes.button} onClick={handleQuarantineDialog} endIcon={<Warning/>} disabled={disableUpdateStressFile()}>
                                                Quarantine
                                            </Button>
                                            :
                                            <Button variant="contained" color="primary" className={classes.button} onClick={handleQuarantineDialog} endIcon={<Close/>} disabled={disableUpdateStressFile()}>
                                                Remove Quarantine
                                            </Button>
                                        }

                                        <Dialog open={openQuarantineDialog} onClose={handleCloseQuarantineDialog} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Quarantine Stress Test</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                Are you sure you want to quarantine stress test?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseQuarantineDialog}  color="secondary">
                                                    No
                                                </Button>
                                                <Button onClick={handleSetQuarantine} variant="contained" color="secondary">
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog open={openRemoveQuarantineDialog} onClose={handleCloseQuarantineDialog} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Remove Quarantine Stress Test</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                Are you sure you want to remove quarantine on stress test?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleCloseQuarantineDialog}  color="primary">
                                                    No
                                                </Button>
                                                <Button onClick={handleSetQuarantine} variant="contained" color="primary">
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Dialog open={openDeleteDialog} onClose={()=>setOpenDeleteDialog(false)} aria-labelledby="form-dialog-title">
                                            <DialogTitle id="form-dialog-title">Confirm Delete</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                Are you sure you want to delete all the records for this Lab data?
                                                </DialogContentText>
                                                <DialogContentText>
                                                This action is irreversible.
                                                </DialogContentText>
                                                <DialogContentText>
                                                <TextField  id="id-delete" 
                                                label="Confirm"
                                                variant="outlined" 
                                                fullWidth={true}
                                                onChange={(e)=>setConfirmDelete(e.target.value)}
                                                value={confirmDelete}>
                                                    
                                                </TextField>
                                                </DialogContentText>
                                            </DialogContent>
                                            
                                            <DialogActions>
                                                <Button onClick={()=>setOpenDeleteDialog(false)}  color="secondary">
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleConfirmDeleteAllRecords} variant="contained" color="secondary" disabled={confirmDelete !== "delete"}>
                                                    Delete
                                                </Button>
                                            </DialogActions>
                                        </Dialog>

                                        <Button variant="contained" color="secondary" className={classes.button} onClick={handleDowngradeDialog} endIcon={<ArrowDownward />} disabled={disableDowngradeFile()}>
                                            Downgrade
                                        </Button>
                                        
                                        <Button variant="contained" color="secondary" className={classes.button} onClick={handleSecondaryTest} endIcon={<CloudUpload/>} disabled={disableSecondaryTest()}>
                                            Secondary Test
                                        </Button>

                                        <Button variant="contained" color="secondary" className={classes.button}  endIcon={<PictureAsPdf/>} disabled={fileInfo.isLtqIncluded === false} href={`/#/report/viewer/119?SEQNO=${fileInfo?.seqNo}`} target="_blank" >
                                            PDF Cert
                                        </Button>

                                        <DowngradeDialog openDialog={openDowngradeDialog} handleCloseDialog={handleCloseDowngradeDialog} user={user} fileId={fileInfo?.seqNo} getFileInfo={getFileInfo} origProductName={fileInfo?.productName} />
                                    </div>
                                </div>
                                <Card>
                                    <CardContent>
                                        <div className={classes.cardSummary}>
                                            <div className={classes.cardSummaryRow}>
                                                {fileInfo.status === "Pass" &&
                                                    <Alert severity="success">
                                                        <span>Status: <span style={{fontWeight: "bold"}}>Pass</span></span>
                                                    </Alert>
                                                }

                                                {fileInfo.status === "Fail" &&
                                                    <Alert severity="error">
                                                        <span>Status: <span style={{fontWeight: "bold"}}>Fail</span></span>
                                                    </Alert>
                                                }

                                                {fileInfo.status === "Pending" &&
                                                    <Alert severity="info">
                                                        <span>Status: <span style={{fontWeight: "bold"}}>Pending</span></span>
                                                    </Alert>
                                                }

                                                {fileInfo.isQuarantine ?
                                                    <Alert severity="warning">
                                                        <span>Quarantine</span>
                                                    </Alert>
                                                    :
                                                    <Alert severity="info">
                                                    <span>Not In Quarantine</span>
                                                </Alert>
                                                }
                                                {fileInfo.isDowngrade ?
                                                    <Alert severity="warning" iconMapping={{warning: <ArrowDownward/>}}>
                                                        <span>Downgrade</span>
                                                    </Alert>
                                                    :
                                                    <Alert severity="info">
                                                        <span>Not Downgraded</span>
                                                    </Alert>
                                                }
                                            </div>
                                            <div className={classes.cardSummaryRow} style={{marginTop: 4}}>
                                                <span>Last Modified By: <span className={classes.cardValue}>{fileInfo.lastModifiedBy}</span></span>
                                                <span>Last Modfified At: <span className={classes.cardValue}>{formatDateTime(fileInfo.lastModifiedAt)}</span></span>
                                            </div>
                                            <div className={classes.cardSummaryRow}>
                                                <span>Loaded By: <span className={classes.cardValue}>{fileInfo.loadedBy}</span></span>
                                                <span>Loaded At: <span className={classes.cardValue}>{formatDateTime(fileInfo.loadedAt)}</span></span>
                                            </div>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Name: <span className={classes.cardValue}>{fileInfo.testName}</span></span>
                                            <span>Seq No: <span className={classes.cardValue}>{fileInfo.seqNo}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Mode: <span className={classes.cardValue}>{fileInfo.testMode}</span></span>
                                            <span>Type: <span className={classes.cardValue}>{fileInfo.testType}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Title: <span className={classes.cardValue}>{fileInfo.title1}</span></span>
                                            <span>Comment: <span className={classes.cardValue}>{fileInfo.comment1}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Supplier: <span className={classes.cardValue}>{fileInfo.supplier}</span></span>
                                            <span>Manufacturer: <span className={classes.cardValue}>{fileInfo.manufacturer}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Product Name: <span className={classes.cardValue}>{fileInfo.productName}</span></span>
                                            <span>CastHeatNumber: <span className={classes.cardValue}>{fileInfo.castHeatNumber}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Batch No: <span className={classes.cardValue}>{fileInfo.batchNumber}</span></span>
                                            <span>Test No: <span className={classes.cardValue}>{fileInfo.testNumber}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Report Date: <span className={classes.cardValue}>{formatDate(fileInfo.reportDate)}</span></span>
                                            <span>Manufacturing Date: <span className={classes.cardValue}>{formatDate(fileInfo.manufacturingDate)}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Operator: <span className={classes.cardValue}>{fileInfo.operator}</span></span>
                                            <span>Temp: <span className={classes.cardValue}>{fileInfo.temp}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Batch Qty: <span className={classes.cardValue}>{fileInfo.batchQty}</span></span>
                                            <span>Speed: <span className={classes.cardValue}>{fileInfo.speed}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Shape: <span className={classes.cardValue}>{fileInfo.shape}</span></span>
                                            <span>Num Batches: <span className={classes.cardValue}>{fileInfo.numberBatches}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Qty Batch: <span className={classes.cardValue}>{fileInfo.qtyBatch}</span></span>
                                            <span>Diameter (mm): <span className={classes.cardValue}>{fileInfo.diameterMM}</span></span>
                                        </div>
                                        <div className={classes.cardRow}>
                                            <span className={classes.cardItem}>Gauge Length (mm): <span className={classes.cardValue}>{fileInfo.gaugeLengthMM}</span></span>
                                        </div>
                                    </CardContent>
                                </Card>
                                
                                {fileInfo.testResults && 
                                    <Card style={{marginTop: 16}}>
                                        <CardHeader title="Test Results"/>
                                        <CardContent>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="simple table">
                                                    <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Size Unit</StyledTableCell>
                                                        <StyledTableCell>Max Force (kN)</StyledTableCell>
                                                        <StyledTableCell>YS1 Stress (Mpa)</StyledTableCell>
                                                        <StyledTableCell>Max Stress (Mpa)</StyledTableCell>
                                                        <StyledTableCell>Rm/Re</StyledTableCell>
                                                        <StyledTableCell>Agt 02</StyledTableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {fileInfo.testResults.length !== 0 && fileInfo.testResults.map((test: StressTestResults) => (
                                                        <StyledTableRow key={test.seqNo}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {test.sizeUnit}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{test.maxForce}</StyledTableCell>
                                                            <StyledTableCell>{test.ys1Stress}</StyledTableCell>
                                                            <StyledTableCell>{test.maxStress}</StyledTableCell>
                                                            <StyledTableCell>{test.rmRe}</StyledTableCell>
                                                            <StyledTableCell>{test.agt02}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )) 
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                }
                                

                                {fileInfo.weldResults && 
                                    <Card style={{marginTop: 16}}>
                                        <CardHeader title="Weld Results"/>
                                        <CardContent>
                                            <TableContainer component={Paper}>
                                                <Table className={classes.table} size="small" aria-label="simple table">
                                                    <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>Size Unit</StyledTableCell>
                                                        <StyledTableCell>Max Force (kN)</StyledTableCell>
                                                        <StyledTableCell>Max Stress (Mpa)</StyledTableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {fileInfo.weldResults.length !== 0 && fileInfo.weldResults.map((weld: StressWeldResults) => (
                                                        <StyledTableRow key={weld.seqNo}>
                                                            <StyledTableCell component="th" scope="row">
                                                                {weld.sizeUnit}
                                                            </StyledTableCell>
                                                            <StyledTableCell>{weld.maxForce}</StyledTableCell>
                                                            <StyledTableCell>{weld.maxStress}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )) 
                                                    }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                }
                                
                            </div>
                        }
                    </div>
                }
            </TabPanel >

            <TabPanel value={selectedTab} index={1}>
                {fileHistory && 
                    <Card style={{marginTop: 16}}>
                        <CardHeader title="History Timeline"/>
                        <CardContent>
                            <Timeline align="alternate">
                                {fileHistory.length !== 0 && fileHistory.map((history: StressTestHistory, index: number) => (
                                    <TimelineItem key={history.id}>
                                        <TimelineSeparator>
                                        <TimelineDot style={{backgroundColor: getHistoryItemColor(history.historyType)}}>
                                            {getHistoryItemIcon(history.historyType)}
                                        </TimelineDot>
                                        {index !== fileHistory.length - 1 && 
                                            <TimelineConnector />
                                        }
                                        </TimelineSeparator>
                                        <TimelineContent>
                                        <Paper elevation={3} className={classes.paper}>
                                            <Typography variant="h6" component="h1">
                                                {history.historyType}
                                            </Typography>
                                            <Typography variant="body1" component="h1">
                                                {history.description}
                                            </Typography>
                                            <Typography variant="caption">
                                                {history.recordedBy} - {formatDateTime(history.recordedAt)}
                                            </Typography>
                                        </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </CardContent>
                    </Card>
                }
                
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                <LTQAnalysis active={analysisActive} productName={fileInfo?.productName} setLoading={setLoading} fileInfo={fileInfo} user={user} />

            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                <div style={{display: "flex", marginBottom: 16}}>
                    <div style={{flexGrow: 1}}>
                        {showUploadFiles &&
                            <IconButton onClick={() => onUploadFilesBack()} disabled={numUploadedFiles !== uploadFiles.length}>
                                <ArrowBack />
                            </IconButton>
                        }
                    </div>
                    <div>
                        {!showUploadFiles &&
                            <Button variant="contained" color="secondary" onClick={() => setShowUploadFiles(true)}>
                                <Add />
                                Upload Files
                            </Button>
                        }
                    </div>
                </div>

                {showUploadFiles ?
                    <div>
                        <Dropzone 
                            onDrop={(acceptedFiles) => setUploadFiles(acceptedFiles)} 
                            multiple={true}
                            disabled={numUploadedFiles !== uploadFiles.length}>
                            {({ getRootProps, getInputProps }) => (
                                <Paper
                                variant="outlined"
                                {...getRootProps({className: classes.dropzone})}
                                >
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop files here, or click to select files</p>
                                </Paper>
                            )}
                        </Dropzone>

                        <div>
                            {uploadFiles.length !== 0 && uploadFiles.map((file: File) => (
                                <FileToUpload 
                                    file={file} 
                                    filename={getFilename(file.name)} 
                                    incrementFileUpload={incrementFileUploads}
                                    files={uploadFiles.length}
                                    numUploadedFiles={numUploadedFiles}
                                    key={getFilename(file.name)}
                                    user={user}
                                    seqNo={fileInfo?.seqNo}/>
                            ))}
                        </div>
                    </div>
                :
                    <div>
                        {filesList.length !== 0 &&
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell width={60}>
                                            <InsertDriveFileOutlined />
                                        </StyledTableCell>
                                        <StyledTableCell>Filename</StyledTableCell>
                                        <StyledTableCell>Uploaded By</StyledTableCell>
                                        <StyledTableCell>Uploaded At</StyledTableCell>
                                        <StyledTableCell width={130}></StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {filesList.length !== 0 && filesList.map((file: StressTestUploadedFile) => (
                                        <StyledTableRow key={file.filename}>
                                            <StyledTableCell component="th" scope="row">
                                                <FileIcon extension={"" + getFileExtension(file.contentType)} {...defaultStyles[getFileExtension(file.contentType)]} />
                                            </StyledTableCell>
                                            <StyledTableCell>{file.filename.split("/")[1]}</StyledTableCell>
                                            <StyledTableCell>{file.uploadedBy}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(file.uploadedAt)}</StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton>
                                                    <GetApp />
                                                </IconButton>
                                                <IconButton onClick={() => {
                                                    setSelectedFile(file)
                                                    setOpenDeleteFileDialog(true)
                                                }}>
                                                    <Delete />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </div>
                }

                <Dialog open={openDeleteFileDialog} onClose={() => setOpenDeleteFileDialog(false)} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete File</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Are you sure you want to delete {selectedFile?.filename}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteFileDialog(false)}  color="primary">
                            No
                        </Button>
                        <Button onClick={() => handleDeleteFile(selectedFile)} variant="contained" color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </TabPanel>

            <TabPanel value={selectedTab} index={4}>
                {previousFileInfo &&
                    <div>
                        <Card>
                            <CardContent>
                                <div className={classes.cardSummary}>
                                    <div className={classes.cardSummaryRow}>
                                        {previousFileInfo.status === "Pass" &&
                                            <Alert severity="success">
                                                <span>Status: <span style={{fontWeight: "bold"}}>Pass</span></span>
                                            </Alert>
                                        }

                                        {previousFileInfo.status === "Fail" &&
                                            <Alert severity="error">
                                                <span>Status: <span style={{fontWeight: "bold"}}>Fail</span></span>
                                            </Alert>
                                        }

                                        {previousFileInfo.status === "Pending" &&
                                            <Alert severity="info">
                                                <span>Status: <span style={{fontWeight: "bold"}}>Pending</span></span>
                                            </Alert>
                                        }
                                        {previousFileInfo.isQuarantine ?
                                            <Alert severity="warning">
                                                <span>Quarantine</span>
                                            </Alert>
                                            :
                                            <Alert severity="info">
                                            <span>Not In Quarantine</span>
                                        </Alert>
                                        }
                                        {previousFileInfo.isDowngrade ?
                                            <Alert severity="warning" iconMapping={{warning: <ArrowDownward/>}}>
                                                <span>Downgrade</span>
                                            </Alert>
                                            :
                                            <Alert severity="info">
                                                <span>Not Downgraded</span>
                                            </Alert>
                                        }
                                    </div>
                                    <div className={classes.cardSummaryRow} style={{marginTop: 4}}>
                                        <span>Last Modified By: <span className={classes.cardValue}>{previousFileInfo.lastModifiedBy}</span></span>
                                        <span>Last Modfified At: <span className={classes.cardValue}>{formatDateTime(previousFileInfo.lastModifiedAt)}</span></span>
                                    </div>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Name: <span className={classes.cardValue}>{previousFileInfo.testName}</span></span>
                                    <span>Seq No: <span className={classes.cardValue}>{previousFileInfo.seqNo}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Mode: <span className={classes.cardValue}>{previousFileInfo.testMode}</span></span>
                                    <span>Type: <span className={classes.cardValue}>{previousFileInfo.testType}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Title: <span className={classes.cardValue}>{previousFileInfo.title1}</span></span>
                                    <span>Comment: <span className={classes.cardValue}>{previousFileInfo.comment1}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Supplier: <span className={classes.cardValue}>{previousFileInfo.supplier}</span></span>
                                    <span>Manufacturer: <span className={classes.cardValue}>{previousFileInfo.manufacturer}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Product Name: <span className={classes.cardValue}>{previousFileInfo.productName}</span></span>
                                    <span>CastHeatNumber: <span className={classes.cardValue}>{previousFileInfo.castHeatNumber}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Batch No: <span className={classes.cardValue}>{previousFileInfo.batchNumber}</span></span>
                                    <span>Test No: <span className={classes.cardValue}>{previousFileInfo.testNumber}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Report Date: <span className={classes.cardValue}>{formatDate(previousFileInfo.reportDate)}</span></span>
                                    <span>Manufacturing Date: <span className={classes.cardValue}>{formatDate(previousFileInfo.manufacturingDate)}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Operator: <span className={classes.cardValue}>{previousFileInfo.operator}</span></span>
                                    <span>Temp: <span className={classes.cardValue}>{previousFileInfo.temp}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Batch Qty: <span className={classes.cardValue}>{previousFileInfo.batchQty}</span></span>
                                    <span>Speed: <span className={classes.cardValue}>{previousFileInfo.speed}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Shape: <span className={classes.cardValue}>{previousFileInfo.shape}</span></span>
                                    <span>Num Batches: <span className={classes.cardValue}>{previousFileInfo.numberBatches}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Qty Batch: <span className={classes.cardValue}>{previousFileInfo.qtyBatch}</span></span>
                                    <span>Diameter (mm): <span className={classes.cardValue}>{previousFileInfo.diameterMM}</span></span>
                                </div>
                                <div className={classes.cardRow}>
                                    <span className={classes.cardItem}>Gauge Length (mm): <span className={classes.cardValue}>{previousFileInfo.gaugeLengthMM}</span></span>
                                </div>
                            </CardContent>
                        </Card>
                        
                        {previousFileInfo.testResults && 
                            <Card style={{marginTop: 16}}>
                                <CardHeader title="Test Results"/>
                                <CardContent>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Size Unit</StyledTableCell>
                                                <StyledTableCell>Max Force (kN)</StyledTableCell>
                                                <StyledTableCell>YS1 Stress (Mpa)</StyledTableCell>
                                                <StyledTableCell>Max Stress (Mpa)</StyledTableCell>
                                                <StyledTableCell>Rm/Re</StyledTableCell>
                                                <StyledTableCell>Agt 02</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {previousFileInfo.testResults.length !== 0 && previousFileInfo.testResults.map((test: StressTestResults) => (
                                                <StyledTableRow key={test.seqNo}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {test.sizeUnit}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{test.maxForce}</StyledTableCell>
                                                    <StyledTableCell>{test.ys1Stress}</StyledTableCell>
                                                    <StyledTableCell>{test.maxStress}</StyledTableCell>
                                                    <StyledTableCell>{test.rmRe}</StyledTableCell>
                                                    <StyledTableCell>{test.agt02}</StyledTableCell>
                                                </StyledTableRow>
                                            )) 
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }
                        

                        {previousFileInfo.weldResults && 
                            <Card style={{marginTop: 16}}>
                                <CardHeader title="Weld Results"/>
                                <CardContent>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Size Unit</StyledTableCell>
                                                <StyledTableCell>Max Force (kN)</StyledTableCell>
                                                <StyledTableCell>Max Stress (Mpa)</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {previousFileInfo.weldResults.length !== 0 && previousFileInfo.weldResults.map((weld: StressWeldResults) => (
                                                <StyledTableRow key={weld.seqNo}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {weld.sizeUnit}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{weld.maxForce}</StyledTableCell>
                                                    <StyledTableCell>{weld.maxStress}</StyledTableCell>
                                                </StyledTableRow>
                                            )) 
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }
                        
                    </div>
                }
            </TabPanel>

        </div>
    )
}


interface DowngradeProps {
    openDialog: boolean
    handleCloseDialog: () => void
    user?: any | null
    fileId: number
    getFileInfo: () => void
    origProductName: string
}

interface DowngradeForm {
    productName: string;
}

function DowngradeDialog({ openDialog, handleCloseDialog, user, fileId, getFileInfo, origProductName }: DowngradeProps) {

    const { handleSubmit, control, errors } = useForm<DowngradeForm>();
    const [products, setProducts] = useState<Array<string>>([])
    const [productsLoading, setProductsLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const username = user !== null ? user.username : ""

    useEffect(() => {
        if(openDialog) {
            setProductsLoading(true)
            API.get("", "/el/products", {})
            .then((response: Array<string>) => {
                setProducts(response.filter((x) => x !== origProductName))
                setProductsLoading(false)
            })
            .catch((error: any) => {
                console.log("Error fetching products", error)
                setProductsLoading(false)
            })
        }
    }, [openDialog])

    const onSubmit = (data: DowngradeForm) => {
        setLoading(true)
        

        let params = {
            body: {
                seqNo: fileId,
                username: username,
                newProductName: data.productName,
                origProductName: origProductName,
                isDowngrade: true,
            }
        }

        API.post("", `/el/stressfiles/downgrade/updateFile`, params)
        .then(() => {
            setLoading(false)
            handleCloseDialog()
            getFileInfo()
        })
        .catch((error: any) => {
            console.log("Error: updating file", error)
            setLoading(false)
        });        
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Downgrade Stress Test</DialogTitle>
            <DialogContent>
                <span>Change the product name to downgrade the file. Downgrading this file will remove it from the LTQ.</span>
                <FormControl 
                    style={{width: "100%", marginTop: 16}}
                    error={Boolean(errors.productName)}>

                    <FormLabel style={{marginBottom: 6}}>Change Product Name</FormLabel>
                    <Controller 
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                options={products}
                                loading={productsLoading}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        placeholder="Select Product Name"
                                        error={errors.productName ? true : false}
                                        helperText={errors.productName?.message} />
                                )}
                                getOptionLabel={(option) => option}
                                onChange={(_, data) => props.onChange(data)}
                            />
                        )}
                        name="productName"
                        control={control}
                        defaultValue={null}
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}  color="secondary">
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Ok
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

interface FileUploadProps {
    file: File
    filename: string
    incrementFileUpload: () => void
    numUploadedFiles: number
    files: number
    seqNo?: number,
    user?: any | null
}

function FileToUpload({ filename, file, incrementFileUpload, numUploadedFiles, files, seqNo, user } : FileUploadProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [retryCount, setRetryCount] = useState<number>(0)
    const [success, setSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(numUploadedFiles !== files) {
            if(retryCount < 3) {
                setLoading(true)
                console.log("FILE UPLOAD", filename)
        
                Storage.put(filename, file)
                .then(() => {
                    Storage.get(filename, { level: 'public', download:false })
                    .then ( (result: any) => { 
                        let url = result;
                        
                        const params = {
                            body: {
                                stressFileSeqNo: seqNo,
                                fileUrl: url,
                                filename: filename,
                                contentType: file.type,
                                username: user?.attributes.name
                            },
                        };
                        API.post("", "/el/stressfiles/upload",params)
                            .then((response: any) => {
                                setSuccess(true)
                                setLoading(false)
                                incrementFileUpload()
                            })
                            .catch(error => {
                                console.log(error.response);
                                setLoading(false)
                                setRetryCount(retryCount => retryCount + 1)
                            })
                    })
                    .catch( (err) => { 
                        console.log(err);
                        setLoading(false)
                        setRetryCount(retryCount => retryCount + 1)
                    });
                    
                }).catch((error: any) => {
                    console.log("Error uploading to s3", error)
                    setRetryCount(retryCount => retryCount+1)
                    setLoading(false)
                });
           }
        }
        
    }, [retryCount]);

    function uploadFile() {
        setLoading(true)
        console.log("FILE UPLOAD", filename)

        Storage.put(filename, file)
        .then(() => {
            setSuccess(true)
            setLoading(false)
        }).catch((error: any) => {
            console.log("Error uploading to s3", error)
            setLoading(false)
        });
    }
 
    return (
        <div style={{display: "flex", marginTop: 24}}>
            <div style={{flexGrow: 1}}>
                {file.name}
            </div>
            <div>
                {loading && 
                    <CircularProgress color="secondary" size={25}/>
                }
                {(retryCount === 3 && !loading && !success) && 
                    <Button variant="contained" color="secondary" onClick={() => uploadFile()}>
                        Retry
                    </Button>
                }
                {success && 
                    <span>Successfully uploaded</span>
                }
            </div>
        </div>
    )
}