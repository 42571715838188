import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone'
import { 
    Paper, Button, IconButton, CircularProgress, DialogContent, Dialog, DialogActions, DialogTitle,
    TableContainer, Table, TableHead, TableRow, TableBody, Radio, Collapse, Box, Typography, TableCell, TableFooter
} from "@material-ui/core";
import {
    Add, ArrowBack, KeyboardArrowDown, KeyboardArrowUp, GetApp
} from "@material-ui/icons";
import { Storage, API } from "aws-amplify";
import { 
    OpportunityMeasureFileInfo, OpportunityMeasureQuoteLineInfo, OpportunityMeasureQuoteLineGroup, 
    OpportunityMeasureFileGroup
} from '../../../types/OpportunityTypes';
import { StyledTableCell, StyledTableRow } from '../../../../components/StyledTableComponents';
import { formatDateTime } from '../../../../utils/utils';
import { OpportunityInfo } from '../../../types/OpportunityTypes';
import { downloadBlob } from '../../../../utils/s3';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            '& > *': {
              margin: theme.spacing(1),
            },
          },
        table: {
            minWidth: 650,
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 97,
            paddingBottom: 97,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        },
        dollarAmountCell: {
            textAlign: "right"
        },
        grandTotal:{
            textAlign: "right",
            fontWeight: "bold",
            color: "black"
        },
        footer: {
            "& > td > div": {
              height: 60,
              minHeight: 60
            },
            backgroundColor: "#F5F5F5",
            height: 60
          }
    })
);

interface OpportunityMeasureProps {
    oppNo?: string;
    user?: any | null;
    oppStatus?: string;
    setLoading: (loading: boolean) => void;
    selectedMeasureFileIdx: number;
    setSelectedMeasureFileIdx: (idx: number) => void
    setOpportunityDetail: (info: OpportunityInfo) => void
}

export default function OpportunityMeasureTab({ user, oppNo, oppStatus, setLoading, selectedMeasureFileIdx, setSelectedMeasureFileIdx, setOpportunityDetail }: OpportunityMeasureProps) {
    const classes = useStyles()

    const [showUploadFile, setShowUploadFile] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState<Array<File>>([])
    const [fileLoading, setFileLoading] = useState<boolean>(false)
    // const [openCustomerQuoteDialog, setOpenCustomerQuoteDialog] = useState<boolean>(false)
    const [uploadActive, setUploadActive] = useState<boolean>(false)
    const [genCustomerQuote, setGenCustomerQuote] = useState<boolean>(false)
    const [measureFiles, setMeasureFiles] = useState<Array<OpportunityMeasureFileInfo>>([])

    const [measureTableData, setMeasureTableData] = useState<Array<OpportunityMeasureFileGroup>>([])

    useEffect(() => {
        const getMeasureFiles = () => {
            if(oppNo) {
                setLoading(true)
                API.get("", `/encore/opportunities/measure/${oppNo}`, {})
                .then((response: Array<OpportunityMeasureFileInfo>) => {
                    if(response) {
                        setMeasureFiles(response)
                        
                        formatMeasureData(response)
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching opportunity measure files", error)
                    setLoading(false)
                })
            }
        }

        getMeasureFiles()
        
    }, [oppNo])

    const getMeasureFiles = () => {
        if(oppNo) {
            setLoading(true)
            API.get("", `/encore/opportunities/measure/${oppNo}`, {})
            .then((response: Array<OpportunityMeasureFileInfo>) => {
                if(response) {
                    setMeasureFiles(response)
                    formatMeasureData(response)
                    setSelectedMeasureFileIdx(0)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunity measure files", error)
                setLoading(false)
            })

            API.get("", `/encore/opportunities/${oppNo}`, {})
            .then((response: OpportunityInfo) => {
                if(response) {
                    setOpportunityDetail(response)
                }
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunity detail", error)
            })
        }
    }

    const formatMeasureData = (data: Array<OpportunityMeasureFileInfo>) => {
        let measureData = new Array<OpportunityMeasureFileGroup>()
        data.forEach((measure: OpportunityMeasureFileInfo) => {
            let file: any = {}
            if(measure.lines) {
                let groups = Array<OpportunityMeasureQuoteLineGroup>()
                measure.lines.forEach((line: OpportunityMeasureQuoteLineInfo) => {
                    
                    let index = groups.findIndex((i: OpportunityMeasureQuoteLineGroup) => i.area === line.area && i.schedule === line.schedule)
                    if(index >= 0) {
                        groups[index].totalWeight += line.kg
                        groups[index].placeTotal += line.placeAmount
                        groups[index].supplyTotal += line.supplyAmount
                        groups[index].total += line.totalAmount
                        groups[index].lines.push(line)
                    } else {
                        let lineGroup: OpportunityMeasureQuoteLineGroup = {
                            schedule: line.schedule,
                            area: line.area,
                            totalWeight: line.kg,
                            placeTotal: line.placeAmount,
                            supplyTotal: line.supplyAmount,
                            total: line.totalAmount,
                            lines: [line]
                        }
                        groups.push(lineGroup)
                    }
                })
                file.groups = groups
                measureData.push(file)
            }
        })
        console.log(measureData)
        setMeasureTableData(measureData)
    }
 
    function handleFileUpload(files: Array<File>) {
        // setOpenCustomerQuoteDialog(true)
        setUploadActive(true)
        setUploadFiles(files)
    } 

    const downloadFile = (fileUrl: string) => {
    
        Storage.get(fileUrl, { download: true} )
        .then((res: any) => {
            downloadBlob(res.Body, fileUrl)
        })
        .catch((error: any) => {
            console.log("Error fetching file from s3", error);
        })
    }

    return (
        <div style={{marginTop: 16}}>
            {showUploadFile ?
                <div>
                    <div>
                        <IconButton 
                            onClick={() => {
                                setShowUploadFile(false)
                                setUploadFiles([])
                            }} 
                            disabled={fileLoading}>
                            <ArrowBack />
                        </IconButton>
                    </div>

                    {!fileLoading &&
                        <Dropzone 
                            onDrop={handleFileUpload} 
                            multiple={false}
                            disabled={fileLoading}
                            accept={[
                                ".xlsx"
                            ]}>
                            {({ getRootProps, getInputProps }) => (
                                <Paper
                                variant="outlined"
                                {...getRootProps({className: classes.dropzone})}
                                >
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop files here, or click to select files</p>
                                </Paper>
                            )}
                        </Dropzone>
                    }
                    
                    {uploadFiles.length !== 0 && uploadFiles.map((file: File) => (
                        <FileToUpload 
                            key={file.name} 
                            loading={fileLoading} 
                            setLoading={setFileLoading} 
                            file={file} 
                            oppNo={oppNo} 
                            user={user} 
                            active={uploadActive} 
                            genCustomerQuote={false}
                            setShowFileUpload={setShowUploadFile}
                            getMeasureFiles={getMeasureFiles}
                            setUploadFiles={setUploadFiles} />
                    ))}

                    {/* <Dialog open={openCustomerQuoteDialog} onClose={() => setOpenCustomerQuoteDialog(false)}>
                        <DialogTitle>
                            Customer Quote
                        </DialogTitle>
                        <DialogContent>
                            Do you want to create customer quotes?
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => {                                
                                    setUploadActive(true)
                                    setOpenCustomerQuoteDialog(false)
                                }
                            }>
                                No
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => {                                
                                    setUploadActive(true)
                                    setGenCustomerQuote(true)
                                    setOpenCustomerQuoteDialog(false)
                                }
                            }>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog> */}
                </div>
            :
                <div>
                    <div style={{display: "flex"}}>
                        <div style={{flexGrow: 1 }}>
                        </div>
                        <div className={classes.root} >
                            <Button style={{paddingRight:16}} variant="contained" color="primary" onClick={() => downloadFile("MeasureforceCommercial.xltx")} >
                                <GetApp />
                                Download Template
                            </Button>
                            <Button variant="contained" color="secondary" onClick={() => setShowUploadFile(true)} disabled={oppStatus?.includes("JOB") || oppStatus === "Not Quoting"}>
                                <Add />
                                Upload File
                            </Button>
                        </div>
                    </div>

                    <div style={{marginTop: 16}}>
                        {measureFiles.length !== 0 &&
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>Filename</StyledTableCell>
                                        <StyledTableCell>Uploaded By</StyledTableCell>
                                        <StyledTableCell>Uploaded At</StyledTableCell>
                                        <StyledTableCell>Total</StyledTableCell>
                                        <StyledTableCell>Version No.</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {measureFiles.map((file: OpportunityMeasureFileInfo, i: number) => (
                                        <StyledTableRow key={file.id}>
                                            <StyledTableCell component="th" scope="row">
                                                <Radio 
                                                    checked={i === selectedMeasureFileIdx}
                                                    onChange={() => setSelectedMeasureFileIdx(i)}/>
                                            </StyledTableCell>
                                            <StyledTableCell>{file.filename}</StyledTableCell>
                                            <StyledTableCell>{file.uploadedBy}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(file.uploadedAt)}</StyledTableCell>
                                            <StyledTableCell>{`$${file.measureTotal.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell>{measureFiles.length - i}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                        {(measureTableData.length > 0 && selectedMeasureFileIdx >= 0 && selectedMeasureFileIdx < measureFiles.length) && 
                            <OpportunityMeasureTable measureData={measureTableData[selectedMeasureFileIdx].groups} />
                        }
                    </div>
                </div>
            }
        </div>
    )
}

interface OpportunityMeasureTableProps {
    measureData: Array<OpportunityMeasureQuoteLineGroup>
}

export function OpportunityMeasureTable({ measureData }: OpportunityMeasureTableProps) {

    const classes = useStyles()

    return (
        <>
            <TableContainer component={Paper} style={{marginTop: 16}}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Schedule</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Total Kg</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Supply Total</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Place Total</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Total</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {measureData.map((group: OpportunityMeasureQuoteLineGroup, idx: number) => (
                        <MeasureTableRow key={idx} group={group} />
                    ))}
                    </TableBody>
                    <TableFooter  className={classes.footer}>
                    <TableRow >
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell className={classes.grandTotal}>Grand Total</TableCell>
                        <TableCell className={classes.grandTotal}>{`$${measureData.reduce((a, b) => a + b.supplyTotal, 0).toFixed(2)}`}</TableCell>
                        <TableCell className={classes.grandTotal}>{`$${measureData.reduce((a, b) => a + b.placeTotal, 0).toFixed(2)}`}</TableCell>
                        <TableCell className={classes.grandTotal}>{`$${measureData.reduce((a, b) => a + b.total, 0).toFixed(2)}`}</TableCell>
                    </TableRow>
                    
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    )
}

interface MeasureTableRowProps {
    group: OpportunityMeasureQuoteLineGroup
}

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    dollarAmountCell: {
        textAlign: "right"
    }
  });

function MeasureTableRow({ group }: MeasureTableRowProps) {

    const classes = useRowStyles()
    const [open, setOpen] = useState<boolean>(false);
    
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{group.schedule}</strong>
                </TableCell>
                <TableCell><strong>{group.area}</strong></TableCell>
                <TableCell><strong>{Math.round(group.totalWeight)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.supplyTotal.toFixed(2)}`}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.placeTotal.toFixed(2)}`}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.total.toFixed(2)}`}</strong></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Item</StyledTableCell>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Product</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                        <StyledTableCell>UOM</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Supply Rate</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Place Rate</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Supply Total</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Place Total</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Total</StyledTableCell>
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.lines.map((line: OpportunityMeasureQuoteLineInfo) => (
                                        <StyledTableRow key={line.id}>
                                            <StyledTableCell>{line.item}</StyledTableCell>
                                            <StyledTableCell>{line.type}</StyledTableCell>
                                            <StyledTableCell>{line.product}</StyledTableCell>
                                            <StyledTableCell>{Math.round(line.kg)}</StyledTableCell>
                                            <StyledTableCell>{line.uom}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.supplyRate.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.placeRate.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.supplyAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.placeAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.totalAmount.toFixed(2)}`}</StyledTableCell>
                                           
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

interface FileUploadProps {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    file: File
    user?: any | null;
    oppNo?: string
    active: boolean
    genCustomerQuote: boolean
    setShowFileUpload: (b: boolean) => void;
    getMeasureFiles: () => void
    setUploadFiles: (a: Array<File>) => void
}

function FileToUpload({ file, user, oppNo, loading, setLoading, active, genCustomerQuote, setShowFileUpload, getMeasureFiles, setUploadFiles } : FileUploadProps) {

    const [retryCount, setRetryCount] = useState<number>(0)
    const [success, setSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(active && user && oppNo) {
            if(retryCount < 3) {
                setLoading(true)

                var date = new Date();
                var timestamp = date.getTime();
                var objKey = oppNo + "_" + timestamp + "_" + file.name;

                console.log(objKey);
        
                Storage.put(objKey, file)
                .then(() => {
                    var params = {
                        body: {
                            oppNo: parseInt(oppNo, 10),
                            s3Key: objKey,
                            filename: file.name,
                            username:user.attributes.name,
                            genCustomerQuote: genCustomerQuote
                        }
                    }

                    API.post("", `/encore/opportunities/measure/uploadFile`, params)
                    .then(() => {
                        setSuccess(true)
                        setLoading(false)
                        setUploadFiles([])
                        setShowFileUpload(false)
                        getMeasureFiles()
                    })
                    .catch((error: any) => {
                        console.log("Error posting measure to db", error)
                        setRetryCount(retryCount => retryCount+1)
                        setLoading(false)
                    })
                }).catch((error: any) => {
                    console.log("Error uploading to s3", error)
                    setRetryCount(retryCount => retryCount+1)
                    setLoading(false)
                });
            }
        }
        
    }, [active, retryCount]);

    function uploadFile() {
        if(active && user && oppNo) {
            setLoading(true)

            var date = new Date();
            var timestamp = date.getTime();
            var objKey = oppNo + "_" + timestamp + "_" + file.name;

            console.log(objKey);
    
            Storage.put(objKey, file)
            .then(() => {
                var params = {
                    body: {
                        oppNo: parseInt(oppNo, 10),
                        s3Key: objKey,
                        filename: file.name,
                        username:user.attributes.name,
                        genCustomerQuote: genCustomerQuote
                    }
                }

                API.post("", `/encore/opportunities/measure/uploadFile`, params)
                .then(() => {
                    setSuccess(true)
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error posting measure to db", error)
                    setLoading(false)
                })
            }).catch((error: any) => {
                console.log("Error uploading to s3", error)
                setLoading(false)
            });
        }
    }
 
    return active ? (
        
        <div style={{display: "flex", marginTop: 24}}>
            <div style={{flexGrow: 1}}>
                {file.name}
            </div>
            <div>
                {loading && 
                    <CircularProgress color="secondary" size={25}/>
                }
                {(retryCount === 3 && !loading && !success) && 
                    <Button variant="contained" color="secondary" onClick={() => uploadFile()}>
                        Retry
                    </Button>
                }
                {success && 
                    <span>Successfully uploaded</span>
                }
            </div>
        </div>
    ) : (<div></div>)
}