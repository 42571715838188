import React, { useState, useEffect, useRef } from 'react'
import { 
    Divider, TextField, Grid, IconButton, InputAdornment, LinearProgress,
    Card, CardContent, CardHeader, CardActions, Button, Breadcrumbs, Typography
} from "@material-ui/core"; 
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Search, ClearAll, Home } from "@material-ui/icons";
import { DataGrid, GridColDef, GridRowsProp, GridRowData, GridRowParams } from '@mui/x-data-grid';

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        jobSearchCardRow: {
            display: "flex",
            justifyContent: "space-between"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        dataGrid: {
            backgroundColor: "white"
        }
    })
);

interface Props {
    user?: any | null 
}

export interface JobsInfo {
    jobNo: string
    jobTitle: string
    accountName: string
    totalTags: number
    totalMade: number;
    totalDispatched: number;
    totalMaking: number;
    totalInZone: number;
    orderId: string;
    ctrlCode: string;
}

export default function EncoreJobs({ user }: Props) {
    const classes = useStyles()
    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [searchInput, setSearchInput] = useState<string>('')
    const [jobs, setJobs] = useState<Array<JobsInfo>>([])

    const navigate = useNavigate()
    // let { path, url } = useMatch()
    const location = useLocation()

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        setSearchInput(event.target.value)
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
        }
    }

    useEffect(() => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
          }
          sTimer = setTimeout(() => {
            if (searchInput.length > 0) {
                setIsLoaded(false)
                fetch(`/api/encore/job/search/${searchInput}`)
                .then(res => res.json())
                .then((result: Array<JobsInfo>) => {
                    if(result) {
                        setJobs(result)
                    } else {
                        setJobs([])
                    }
                    setIsLoaded(true)
                })
            }
          }, 1500);
    }, [searchInput])

    function handleJobDetail(jobId: string) {
        navigate(`${jobId}`)
    }

    return (
        <div>
                    <Breadcrumbs>
                        <Link to="/encore" className={classes.link}>
                            <Home className={classes.icon} />
                            Home
                        </Link>
                        <Typography color="textPrimary">Jobs</Typography>
                    </Breadcrumbs>
                    <div className={classes.header}>
                        <h2 style={{flexGrow: 1}}>
                            Jobs
                        </h2>
                        <div className={classes.margin}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                    id="input-with-icon-grid" 
                                    label="Search by Job Number"
                                    onChange={handleSearch}
                                    value={searchInput}
                                    InputProps={{
                                        className: classes.input,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton color="inherit" onClick={handleClearSearch}>
                                                <ClearAll />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {!isLoaded && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
                    <Divider style={{marginBottom: 16}} />
                    {isLoaded && 
                        <div>
                            <Grid container spacing={3}>
                                {jobs.length !== 0 && jobs.map((job: JobsInfo) => (
                                    <Grid item xs={12} key={job.jobNo}>
                                        <Card>
                                            <CardHeader title={`${job.jobNo} - ${job.jobTitle}`}>
                                            </CardHeader>
                                            <CardContent>
                                                <div className={classes.jobSearchCardRow}>
                                                    <span>Account: {job.accountName}</span>
                                                </div>
                                                <div className={classes.jobSearchCardRow}>
                                                    <span>Total Tags: {job.totalTags}</span>
                                                    <span>Made: {job.totalMade}</span>
                                                    <span>Dispatched: {job.totalDispatched}</span>
                                                </div>
                                                <div className={classes.jobSearchCardRow}>
                                                    <span>Total Making: {job.totalMaking}</span>
                                                    <span>Total In Zone: {job.totalInZone}</span>
                                                </div>
                                            </CardContent>
                                            <CardActions style={{backgroundColor: "#fafafa"}}>
                                                <div style={{flexGrow: 1}}></div>
                                                <Button variant="contained" color="secondary" onClick={() => handleJobDetail(job.jobNo)}>
                                                    Job Detail
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        </div>
                    }
                    {(isLoaded && jobs.length === 0) &&
                        <p>No results found.</p>
                    }
                </div>
        
    )
}

interface JobsTableProps {
    jobs: Array<JobsInfo>
}

export function JobsTable({ jobs }: JobsTableProps) {

    const classes = useStyles()

    const navigate = useNavigate()

    const gridWrapperRef = useRef<HTMLDivElement>(null);
    
    // useLayoutEffect(() => {
    //     const gridDiv = gridWrapperRef.current;
    //     if (gridDiv){
    //         const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
    //         gridEl.style.height = '';
    //     }
    // });

    const jobsColumns: GridColDef[] = [
        { field: 'jobNo', headerName: "Job No", width: 80 },
        { field: 'jobTitle', headerName: 'Job Title', width: 250 },
        { field: 'accountName', headerName: 'Account Name', width: 400 },
        { field: 'totalTags', headerName: 'Tags', width: 100 },
        { field: 'totalMade', headerName: 'Made', width: 100 },
        { field: 'totalDispatched', headerName: 'Dispatched', width: 110 },
        { field: 'totalMaking', headerName: 'Making', width: 100 },
        { field: 'totalInZone', headerName: 'In Zone', width: 100 },
    ]

    function getTableRows(): GridRowsProp {
        let rows: GridRowData[] = []
        jobs?.forEach((job: JobsInfo) => {
            let obj: any = {
                id: job.jobNo,
                ...job
            }
            rows.push(obj)
        })
        return rows
    }

    return (
        <div ref={gridWrapperRef}>
            <DataGrid  
                className={classes.dataGrid}
                autoHeight
                rows={getTableRows()} 
                columns={jobsColumns} 
                pageSize={25}
                onRowClick={(params: GridRowParams) => {
                    navigate(`/encore/jobs/${params.id}`)
                }} />
        </div>

    )
}