import React from 'react';
import { API } from "aws-amplify";
import {
    Card, CardHeader, CardContent, Paper, Typography
} from '@material-ui/core';
import { OpportunityHistoryDetail } from '../../../types/OpportunityTypes';
import { formatDateTime } from '../../../../utils/utils';
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, 
    TimelineDot, TimelineContent
} from "@material-ui/lab";
import {
    Work, Person, Home, Assessment, InsertDriveFile, Cancel, LibraryBooks,
    NoteAdd,
    Check
} from "@material-ui/icons";

interface OpportunityHistoryProps {
    history: Array<OpportunityHistoryDetail>
}

export default function OpportunityHistoryTab({ history }: OpportunityHistoryProps) {

    const getHistoryItemIcon = (status: string) : JSX.Element => {
        let icon;
        switch(status) {
            case "OPPORTUNITY CREATED":
                icon = <Work />
                break;
            case "CUSTOMER ADDED":
                icon = <Person />
                break;
            case "MEASURE UPLOADED":
                icon = <Assessment />
                break;
            case "QUOTE GENERATED":
                icon = <LibraryBooks />
                break;
            case "FILE UPLOADED":
                icon = <InsertDriveFile />
                break;
            case "FILE DELETED":
                icon = <Cancel />
                break;
            case "NOTE ADDED":
                icon = <NoteAdd />
                break;
            case "OPPORTUNITY LOST":
                icon = <Cancel />
                break;
            case "JOB STARTED":
                icon = <Check />
                break;
            default:
                icon = <Home />
                break;  
        }
        return icon;
    }

    const getHistoryItemColor = (status: string) : string => {
        let color;
        switch(status) {
            case "OPPORTUNITY CREATED":
                color = "#9ccc65"
                break;
            case "CUSTOMER ADDED":
                color = "#3f51b5"
                break;
            case "MEASURE UPLOADED":
                color = "#42a5f5"
                break;
            case "QUOTE GENERATED":
                color = "#9c27b0"
                break;
            case "FILE UPLOADED":
                color = "#009688"
                break;
            case "FILE DELETED":
                color = "#f50057"
                break;
            case "NOTE ADDED":
                color = "#ec407a" 
                break;
            case "OPPORTUNITY LOST":
                color = "#f50057"
                break;
            case "JOB STARTED":
                color = "#4caf50"
                break;
            default:
                color = "#ef5350"
                break;  
        }
        return color;
    }

    return (
        <div>
           {history.length !== 0 && 
                <Card style={{marginTop: 16}}>
                    <CardHeader title="History Timeline"/>
                    <CardContent>
                        <Timeline align="alternate">
                            {history.length !== 0 && history.map((item: OpportunityHistoryDetail, index: number) => (
                                <TimelineItem key={item.id}>
                                    <TimelineSeparator>
                                    <TimelineDot style={{backgroundColor: getHistoryItemColor(item.historyType)}}>
                                        {getHistoryItemIcon(item.historyType)}
                                    </TimelineDot>
                                    {index !== history.length - 1 && 
                                        <TimelineConnector />
                                    }
                                    </TimelineSeparator>
                                    <TimelineContent>
                                    <Paper elevation={3} style={{padding: '6px 16px'}}>
                                        <Typography variant="h6" component="h1">
                                            {item.historyType}
                                        </Typography>
                                        <Typography variant="body1" component="h1">
                                            {item.description}
                                        </Typography>
                                        <Typography variant="caption">
                                            {item.recordedBy} - {formatDateTime(item.recordedAt)}
                                        </Typography>
                                    </Paper>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </CardContent>
                </Card>
            }
        </div>
    )
}
