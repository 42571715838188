import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { 
    Table, TableContainer, TableHead, TableBody, TableRow, TableCell, CircularProgress,
    Paper, Button, IconButton, FormControl, InputLabel, Select, MenuItem, Tooltip, Grid,
    TextField, InputAdornment, Breadcrumbs, Link, Typography
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { 
    Folder, InsertDriveFileOutlined, Add, ArrowBack, Delete, GetApp, 
    ViewList, ViewModule, Search, ClearAll, Close, Launch
} from "@material-ui/icons";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

import Dropzone, { useDropzone, FileWithPath } from 'react-dropzone'
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import { OpportunityFileDetail, OpportunityFolderDetail, OpportunityInfo } from '../../../types/OpportunityTypes';
import DeleteFileDialog from '../dialogs/DeleteFileDialog';
import { downloadBlob } from '../../../../utils/s3';
import { API, Storage } from "aws-amplify";
import { formatDateTime } from '../../../../utils/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateNewFolderDialog from '../dialogs/CreateNewFolderDialog';
import {loginRequest} from '../../../../msAuthConfig'
import { useMsal } from "@azure/msal-react";
import { GraphFileBrowser } from '@microsoft/file-browser';
import { graphConfig } from "../../../../msAuthConfig";
import {createFolders} from "../../../../utils/MsGraphApi"
import ReactDOM from 'react-dom';



let sTimer: NodeJS.Timeout




const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            width: 135,
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 97,
            paddingBottom: 97,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        }
    })
);

const StyledTableCell = withStyles(() =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface OpportunityFilesTabProps {
    user: any | null
    opportunityNo: string
    opportunityDetail: OpportunityInfo | undefined
    currentTab: number;
    loading: boolean
    setLoading: (loading: boolean) => void
    accessToken: string |null
    spFolder: string
}
interface FolderPath{
    name: string
    path: string
}
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

export default function OpportunityFilesTab({ user, opportunityNo, opportunityDetail, currentTab, loading, setLoading, accessToken, spFolder }: OpportunityFilesTabProps) {
    
    const classes = useStyles()
    let query = useQuery();
    let location = useLocation();
    let navigate = useNavigate();
    
    const [folderDetailss, setFolderDetailss] = useState<Array<OpportunityFolderDetail>>([])
    const [files, setFiles] = useState<Array<OpportunityFileDetail>>([])
    const [showFolderTable, setShowFolderTable] = useState<boolean>(false)
    const [uploadFiles, setUploadFiles] = useState<Array<FileWithPath>>([])
    const [numUploadedFiles, setNumUploadedFiles] = useState<number>(0)
    const [currentFolder, setCurrentFolder] = useState<string>("/")
    const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false)
    const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState<boolean>(false)
    const [fileToDelete, setFileToDelete] = useState<OpportunityFileDetail>()
    const [filesView, setFilesView] = useState<string>('folders')
    const [searchInput, setSearchInput] = useState<string>('')
    const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState<boolean>(false)
    // const [prefix, setPrefix] = useState<string>(query.get("prefix") || '')
    const [folders, setFolders] = useState<Array<FolderPath>>([])


    

    const { instance, accounts, inProgress } = useMsal();

    const fileUrl = opportunityDetail?.fileUrl.length! > 0 ? opportunityDetail?.fileUrl : `https://eurocorporation.sharepoint.com/sites/encorelive/Shared%20Documents/${opportunityNo}`

 


// useEffect(() => {
//     if (inProgress === "none" && accounts.length > 0) {
//         // Retrieve an access token
//         instance.acquireTokenSilent({
//             account: accounts[0],
//             scopes: ['files.readwrite']
//         }).then(response => {
//             if (response.accessToken) {
                
                 
//                 createFolders(opportunityNo)
//                     .then((id)=> { 
//                         console.log(id)
//                         setSpFolder(id)
//                         setAccessToken(response.accessToken);
//                     })
//             }
//         });
//     }
// }, [inProgress, accounts, instance]);
   
    React.useEffect(() => {
        console.log('Location changed');
        // const folder = query.get("prefix") || '/'
        getFilesByFolder()
    }, [location]);

    useEffect(() => {
        if(currentTab === 3) {
            setShowFolderTable(false)
            setUploadFiles([])
            setNumUploadedFiles(0)
        }
    }, [currentTab])
    
    useEffect(() => {
        if(filesView === "files") {
            if (searchInput.length > 0) {
                getFilesBySearch()
            } else {
                getAllFiles()
            }
        } else if (filesView === "folders") {
            if(!showFolderTable) {
                // const folder = query.get("prefix") || ''
                getFilesByFolder()
            }
        }
    }, [filesView, showFolderTable])

    useEffect(() => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if (searchInput.length > 0) {
                getFilesBySearch()
            }
        }, 1500);
    }, [searchInput])

    useEffect(()=>{
        if(currentFolder === "/"){
            setShowUploadFiles(false)
        }
        const sp = currentFolder.split("/")
        const fss: Array<FolderPath> = []
        let path = "#" + location.pathname + "?prefix="
        sp.forEach((f)=>{
            if (f && f.length > 0){
                path = (path +"/" + f).replace("//","/")
                fss.push({name: f, path: path})
            }
        })

        setFolders(fss)
        console.log(fss)
    },[currentFolder])

    // const getFolders = () => {
    //     setLoading(true)
    //     API.get("", `/encore/opportunities/${opportunityNo}/files/folders`, {})
    //     .then((response: Array<OpportunityFolderDetail>) => {
    //         setFolders(response)
    //         setLoading(false)
    //     })
    //     .catch((error: any) => {
    //         setLoading(false)
    //         console.log("Error: fetching opportunity folders", error)
    //     })
    // }

    const getFilesBySearch = () => {
        setFiles(Array<OpportunityFileDetail>())
        setLoading(true)
        API.get("", `/encore/opportunities/${opportunityNo}/files/search/${searchInput}`, {})
        .then((response: Array<OpportunityFileDetail>) => {
            if(response) {
                setFiles(response)
            }
            else {
                setFiles([])
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: searching opportunities", error)
            setLoading(false)
        })
    }

    const getAllFiles = () => {
        setFiles(Array<OpportunityFileDetail>())
        setLoading(true)
        API.get("", `/encore/opportunities/${opportunityNo}/files`, {})
        .then((response: Array<OpportunityFileDetail>) => {
            if(response) {
                setFiles(response)
            }
            else {
                setFiles([])
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error fetching all files", error)
        })
    }

    const getFilesByFolder = () => {
        setFiles(Array<OpportunityFileDetail>())
        
        setLoading(true)
        const prefix = query.get("prefix") || '/'
        setCurrentFolder(prefix)
        API.get("", `/encore/opportunities/${opportunityNo}/files/end?folderPrefix=${prefix}`, {})
        .then((response: Array<OpportunityFileDetail>) => {
            if(response) {
                setFiles(response)
            }
            else {
                setFiles([])
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching files by folder", error)
            setLoading(false)
        })
    }

    const handleRefreshFiles = () => {
        if(filesView === "files") {    
            if(searchInput.length > 0) {
                getFilesBySearch()
            } else {
                getAllFiles()
            }
        } else if (fileToDelete) {
            getFilesByFolder()
        }
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
            getAllFiles()
        }
    }
    
    // function onFolderClick(folder: string) {
    //     setFiles([])
    //     const path = `${currentFolder}/${folder}`
    //     console.log(path)
    //     setShowFolderTable(true)
    //     setCurrentFolder(path)
    //     getFilesByFolder(path)
    // }

    // function onFilesBackClick() {
    //     const path = currentFolder.split('/').slice(0,-1).join('/')
    //     setCurrentFolder(path)
    //     if(showUploadFiles) {
    //         setShowUploadFiles(false)
    //         setUploadFiles([])
    //         setNumUploadedFiles(0)
           
    //         if(path) {
    //             setFiles([])
    //             getFilesByFolder(path)
    //         }
    //     } else {
    //         setShowFolderTable(false)
    //     }
    // }

    function handleFileUpload(files: Array<FileWithPath>) {
        setUploadFiles(files)
    } 

    // const changeCurrentFolder = (event: React.ChangeEvent<{ value: unknown }>) => {
    //     let folder = event.target.value as string;
    //     setCurrentFolder(folder);
    //     setUploadFiles([]);
    //     setNumUploadedFiles(0);
    //     if(!showUploadFiles) {
    //         setFiles([])
    //         getFilesByFolder(folder);
    //     }
    // };

    function getFilename(path: string | undefined) : string {
        const name =  `${opportunityNo}${currentFolder}/${path}`//currentFolder + "/" + user?.username + "_" + opportunityNo + "_" + filename;
        return name.replace("//","/")
    }

    function incrementFileUploads() {
        setNumUploadedFiles((numUploadedFiles: number) => numUploadedFiles + 1)
    }

    const filesToggleOnChange = (_event: React.MouseEvent<HTMLElement>, nextView: string | null) => {
        console.log(nextView)
        if(nextView !== null) {
            setFilesView(nextView);
        }
    }

    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }


    const onCloseFileUpload = () => {
        setShowUploadFiles(false)
        getFilesByFolder()
    }

    return (
        <>
        <div style={{marginTop: 16, marginBottom:16, display: "flex"}}>
            <Button variant="contained" color="secondary" startIcon={<Launch />} target="_blank" href={fileUrl!}>
            Open Files
            </Button>
        </div>
            {accessToken && 
            
                <GraphFileBrowser 
                    endpoint={graphConfig.siteEndpoint}
                    itemId={spFolder}    
                    getAuthenticationToken={()=>Promise.resolve(accessToken)} 

                />
           
                }
        </>
        // <>
        //         <div>
        //             <div style={{marginTop: 16, display: "flex"}}>
                        
        //                 <Breadcrumbs aria-label="breadcrumb">
        //                     <Link color="inherit" href={"#" + location.pathname} >
        //                         Files
        //                     </Link>
        //                     {folders.length !== 0 && folders.map((folder:FolderPath)=>(
        //                         folder.name.length !== 0 && (
        //                             <Link color="inherit" href={folder.path}  >
        //                                 {folder.name}
        //                             </Link> 
        //                         )

        //                     ))}
                           
                           
        //                 </Breadcrumbs>
        //             </div>
        //             <div style={{marginTop: 16, display: "flex"}}>
                    
        //                 <div style={{flexGrow: 1}}>
        //                     <ToggleButtonGroup orientation="horizontal" size="small" value={filesView} exclusive onChange={filesToggleOnChange}>
        //                         <ToggleButton value="folders" disabled={loading}>
        //                             <Tooltip title="Group by Folders">
        //                                 <ViewModule />
        //                             </Tooltip>
        //                         </ToggleButton>
        //                         <ToggleButton value="files" disabled={loading}>
        //                             <Tooltip title="Group by Files">
        //                                 <ViewList />
        //                             </Tooltip>
        //                         </ToggleButton>
        //                     </ToggleButtonGroup>
        //                 </div>
        //                 {filesView === "files" ?
        //                     <div>
        //                         <Grid container spacing={1} alignItems="flex-end">
        //                             <Grid item>
        //                                 <Search />
        //                             </Grid>
        //                             <Grid item>
        //                                 <TextField 
        //                                 id="input-with-icon-grid" 
        //                                 label="Search by Filename"
        //                                 onChange={(event: React.ChangeEvent<{value: string}>) => setSearchInput(event.target.value as string)}
        //                                 value={searchInput}
        //                                 InputProps={{
        //                                     style: { width: 300 },
        //                                     endAdornment: <InputAdornment position="end">
        //                                         <IconButton color="inherit" onClick={handleClearSearch}>
        //                                             <ClearAll />
        //                                         </IconButton>
        //                                     </InputAdornment>
        //                                 }} />
        //                             </Grid>
        //                         </Grid>
        //                     </div>
        //                     :   
                            
        //                     <Grid container spacing={1} alignContent="flex-end" direction="row-reverse">
        //                         <Grid item>
        //                             {!showUploadFiles ?
        //                                 <Button variant="contained" color="secondary" disabled={currentFolder === "/"} onClick={() => setShowUploadFiles(true)}>
        //                                     <Add />
        //                                     Upload Files
        //                                 </Button>
                                        
        //                             :
        //                                 <Button variant="contained" onClick={onCloseFileUpload}>
        //                                     <Close />
        //                                     Close Upload Files
        //                                 </Button>

        //                             }
                                    
        //                         </Grid>
        //                         <Grid item>
        //                             <Button variant="contained" color="primary" onClick={() => setOpenCreateFolderDialog(true)}>
        //                                 <CreateNewFolderIcon />
        //                                 New Folder
        //                             </Button>
        //                         </Grid>
                                
        //                     </Grid> 
        //                 }
                        
 
        //             </div>
        //             <div style={{marginTop: 16}}>
                    
        //                 {showUploadFiles ?
        //                     <div>
        //                         <Dropzone 
        //                             onDrop={handleFileUpload} 
        //                             multiple={true}
        //                             disabled={numUploadedFiles !== uploadFiles.length}>
        //                             {({ getRootProps, getInputProps }) => (
        //                                 <Paper
        //                                 variant="outlined"
        //                                 {...getRootProps({className: classes.dropzone})}
        //                                 >
        //                                 <input {...getInputProps()} />
        //                                 <p>Drag 'n' drop files here, or click to select files</p>
        //                                 </Paper>
        //                             )}
        //                         </Dropzone>

        //                         <div>
        //                             {uploadFiles.length !== 0 && uploadFiles.map((file: FileWithPath) => (
        //                                 <FileToUpload 
        //                                     file={file} 
        //                                     filename={getFilename(file.path)} 
        //                                     incrementFileUpload={incrementFileUploads}
        //                                     files={uploadFiles.length}
        //                                     numUploadedFiles={numUploadedFiles}
        //                                     key={getFilename(file.name)}
        //                                     user={user}
        //                                     folder={currentFolder}
        //                                     oppNo={opportunityNo}/>
        //                             ))}
        //                         </div>
                                
        //                     </div>
        //                 :
        //                     <div>
        //                         {accessToken && 
        //                          <GraphFileBrowser 
        //           endpoint={"https://graph.microsoft.com/v1.0/sites/humming.sharepoint.com,278266ef-ce10-4098-88f3-71278be4460b,95c10229-3397-4656-9ea7-f8ec3a9edf7b"}
        //           itemId="01GUVQDDD35PADAYN2R5DJ35FPEMVJBUBV"    
        //           getAuthenticationToken={()=>Promise.resolve(accessToken)} 
                 
        //             />}
        //                         {files.length !== 0 &&
        //                             <FilesTable 
        //                                 files={files}
        //                                 setFileToDelete={setFileToDelete}
        //                                 setOpenDeleteFileDialog={setOpenDeleteFileDialog}
        //                             />
        //                         }
        //                         {!loading && files.length === 0 &&
        //                             <p>No files uploaded.</p>
        //                         }
        //                     </div>
        //                 }
        //             </div>
        //         </div>

        //     <DeleteFileDialog openDialog={openDeleteFileDialog} handleDialog={setOpenDeleteFileDialog} fileToDelete={fileToDelete} getFiles={handleRefreshFiles} user={user} />
        //     <CreateNewFolderDialog openDialog={openCreateFolderDialog} handleDialog={setOpenCreateFolderDialog} currentFolder={currentFolder} opportunityNo={opportunityNo} getFiles={getFilesByFolder}  />
        // </>
    )
}


interface FilesTableProps {
    files: Array<OpportunityFileDetail>
    setFileToDelete: (file: OpportunityFileDetail) => void
    setOpenDeleteFileDialog: (open: boolean) => void
}

function FilesTable({ files, setFileToDelete, setOpenDeleteFileDialog}: FilesTableProps) {

    const classes = useStyles()
    let navigate = useNavigate();
  let location = useLocation();

    function getFileExtension(filename: string) : DefaultExtensionType {
        let strSplit = filename.split(".")
        let extension = strSplit[strSplit.length - 1]
        if(extension === "pdf") return "pdf"
        if(extension === "docx") return "docx"
        if(extension === "doc") return "doc"
        if(extension === "jpg") return "jpg"
        if(extension === "jpeg") return "jpeg"
        if(extension === "png") return "png"
        if(extension === "csv") return "csv"
        return "txt"
    }

    const downloadFile = (file: OpportunityFileDetail) => {
    
        Storage.get(file.fileUrl, { download: true} )
        .then((res: any) => {
            downloadBlob(res.Body, file.fileUrl)
        })
        .catch((error: any) => {
            console.log("Error fetching file from s3", error);
        })
    }

    function onFolderClick(folder: string) {
        console.log(location)
        navigate(`${location.pathname}?prefix=${folder}`)
        
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead>
                <TableRow>
                    <StyledTableCell width={60}>
                        <InsertDriveFileOutlined />
                    </StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Uploaded By</StyledTableCell>
                    <StyledTableCell>Uploaded At</StyledTableCell>
                    <StyledTableCell width={130}></StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {files.length !== 0 && files.map((file: OpportunityFileDetail) => (
                    <StyledTableRow key={file.id}>
                        {file.fileType === "FOLDER"
                        ? <TableCell component="th" scope="row">
                            <IconButton onClick={() => onFolderClick(file.fileUrl)} >
                            <Folder />
                                </IconButton>
                                
                                
                            </TableCell>
                            
                        : <StyledTableCell component="th" scope="row">
                            <FileIcon extension={"" + getFileExtension(file.filename)} {...defaultStyles[getFileExtension(file.filename)]} />
                            </StyledTableCell>

                        }
                        
                        <StyledTableCell>{file.filename}</StyledTableCell>
                        <StyledTableCell>{file.uploadedBy}</StyledTableCell>
                        <StyledTableCell>{formatDateTime(file.uploadedAt)}</StyledTableCell>
                        
                        {file.fileType === "File" ?
                            <StyledTableCell>
                                <IconButton onClick={() => downloadFile(file)}>
                                    <GetApp />
                                </IconButton>
                                <IconButton 
                                onClick={() => {
                                    setFileToDelete(file)
                                    setOpenDeleteFileDialog(true)
                                }}
                                >
                                <Delete />
                            </IconButton>
                        </StyledTableCell>
                        :
                        <StyledTableCell></StyledTableCell>
                       }
                            
                        
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


interface FileUploadProps {
    file: FileWithPath
    filename: string
    incrementFileUpload: () => void
    numUploadedFiles: number
    files: number
    user?: any | null;
    folder?: string
    oppNo?: string
}

function FileToUpload({ filename, file, incrementFileUpload, numUploadedFiles, files, user, folder, oppNo } : FileUploadProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [retryCount, setRetryCount] = useState<number>(0)
    const [success, setSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(user && folder && oppNo) {
            if(numUploadedFiles !== files) {
                if(retryCount < 3) {
                    setLoading(true)
                    console.log("FILE UPLOAD", filename)
            
                    Storage.put(filename, file)
                    .then(() => {
                        var params = {
                            body: {
                                oppSeqNo: parseInt(oppNo, 10),
                                folder: folder,
                                fileUrl: filename,
                                filename: file.name,
                                uploadedBy:user.attributes.name,
                                contentType: file.type,
                                path: file.path
                            }
                        }
    
                        API.post("", `/encore/opportunities/files/addFile`, params)
                        .then(() => {
                            setSuccess(true)
                            setLoading(false)
                            incrementFileUpload()
                        })
                        .catch((error: any) => {
                            console.log("Error posting opportunity to db", error)
                            setRetryCount(retryCount => retryCount+1)
                            setLoading(false)
                        })
                    }).catch((error: any) => {
                        console.log("Error uploading to s3", error)
                        setRetryCount(retryCount => retryCount+1)
                        setLoading(false)
                    });
                }
            }
        }
        
    }, [retryCount]);

    // function uploadFile() {
    //     if(oppNo && folder && user) {
    //         setLoading(true)
    //         console.log("FILE UPLOAD", filename)
    
    //         Storage.put(filename, file)
    //         .then(() => {
    //             var params = {
    //                 body: {
    //                     oppSeqNo: parseInt(oppNo, 10),
    //                     folder: folder,
    //                     fileUrl: filename,
    //                     filename: file.name,
    //                     uploadedBy:user.attributes.name,
    //                     contentType: file.type
    //                 }
    //             }
    
    //             API.post("", `/encore/opportunities/files/addFile`, params)
    //             .then(() => {
    //                 setSuccess(true)
    //                 setLoading(false)
    //                 incrementFileUpload()
    //             })
    //             .catch((error: any) => {
    //                 console.log("Error posting opportunity to db", error)
    //                 setRetryCount(retryCount => retryCount+1)
    //                 setLoading(false)
    //             })
    //         }).catch((error: any) => {
    //             console.log("Error uploading to s3", error)
    //             setRetryCount(retryCount => retryCount+1)
    //             setLoading(false)
    //         });
    //     }
    // }
 
    return (
        <div style={{display: "flex", marginTop: 24}}>
            <div style={{flexGrow: 1}}>
                {file.name}
            </div>
            <div>
                {loading && 
                    <CircularProgress color="secondary" size={25}/>
                }
                {/* {(retryCount === 3 && !loading && !success) && 
                    <Button variant="contained" color="secondary" onClick={() => uploadFile()}>
                        Retry
                    </Button>
                } */}
                {success && 
                    <span>Successfully uploaded</span>
                }
            </div>
        </div>
    )
}


