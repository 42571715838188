import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core";
import { useStyles } from './JobTagDetailTable';


export interface ClaimScheduleCommentDialogProps {
    open: boolean;
    initialComment: string;
    onClose: (comment: string) => void;
}
export function ClaimScheduleCommentDialog(props: ClaimScheduleCommentDialogProps) {
    const { onClose, initialComment, open } = props;
    const classes = useStyles();
    const [comment, setComment] = useState(initialComment);




    const handleClose = () => {
        onClose(initialComment);
    };


    function handleCloseDialog(): void {
        onClose(initialComment);
    }

    function handleSubmit(): void {
        onClose(comment);
        // setComment("");
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Claim Schedule Comment</DialogTitle>
            <DialogContent>

                <TextField
                    placeholder="Enter Schedule Comment"
                    fullWidth
                    variant="filled"
                    required
                    name="comment"
                    value={comment}
                    multiline={true}
                    rows={3}
                    onChange={(e) => setComment(e.target.value)} />


            </DialogContent>

            <DialogActions>
                {/* {error &&
                <span style={{color: "red"}}>{error}</span>
            } */}
                <Button color="secondary" onClick={() => handleCloseDialog()}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
