import React, { useState } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Paper, IconButton, TableContainer, Table, TableHead, TableRow, 
    TableBody, Collapse, Box, Typography, TableCell, Popover, Button
} from "@material-ui/core";
import {
    KeyboardArrowDown, KeyboardArrowUp, Add, Edit
} from "@material-ui/icons";
import { 
    JobMeasureQuoteLineInfo, JobMeasureQuoteLineGroup, JobScheduleClaims, JobTagDetailInfo 
} from '../../types/JobTypes';
import { 
    StyledTableCell, StyledTableRow 
} from '../../../components/StyledTableComponents';
import AddToScheduleDialog from './AddToScheduleDialog';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        table: {
            minWidth: 650,
        },
        red: {
            color: "red"
        },
        orange: {
            color: "orange"
        },
        green: {
            color: "green"
        },
        popover: {
            marginTop: 8,
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        dollarAmountCell: {
            textAlign: "right"
        }
    })
);

interface JobMeasureTableProps {
    measureData: Array<JobMeasureQuoteLineGroup>
    refreshData: () => void;
}

export function JobMeasureTable({ measureData, refreshData }: JobMeasureTableProps) {

    const classes = useStyles()

    return (
        <>

            <TableContainer component={Paper} style={{marginTop: 16}}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Schedule</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Total Kg</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Supply Total</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Place Total</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Total</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Claimed</TableCell>
                        <TableCell className={classes.dollarAmountCell}>Adj.</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {measureData.map((group: JobMeasureQuoteLineGroup, idx: number) => (
                        <MeasureTableRow key={idx} group={group} refreshData={refreshData} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface MeasureTableRowProps {
    group: JobMeasureQuoteLineGroup
    refreshData: () => void;
}

function MeasureTableRow({ group, refreshData}: MeasureTableRowProps) {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false);
    const [openSchedule, setOpenSchedule] = useState<boolean>(false);
    const [selectedJobMeasureQuoteInfo, setSelectedJobMeasureQuoteInfo] = useState<JobMeasureQuoteLineInfo>();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openAdjustments = Boolean(anchorEl);

    const handleAdjustmentsOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAdjustmentsClose = () => {
        setAnchorEl(null);
    };

    const getAmountTextColor = (a: number, b: number) => {
        if(a < b) {
            return classes.red
        } else if(a === b) {
            return classes.green
        } else {
            return classes.orange
        }
    }

    const getAdjTextColor = (a: number) => {
        if(a < 0) {
            return classes.red
        } else if(a > 0) {
            return classes.green
        }
    }

    const handleClickOpen = (line: JobMeasureQuoteLineInfo) => {
        setSelectedJobMeasureQuoteInfo(line)
        setOpenSchedule(true)
    }

    const handleClose = (value: boolean) => {
        setOpenSchedule(false);
        // setOpenSubScheduleDialog(false);
        refreshData();
        // setSelectedValue(value);
    };
    
    return (
        <>
       
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{group.schedule}</strong>
                </TableCell>
                <TableCell><strong>{group.area}</strong></TableCell>
                <TableCell><strong>{Math.round(group.totalWeight)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.supplyTotal.toFixed(2)}`}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.placeTotal.toFixed(2)}`}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>{`$${group.total.toFixed(2)}`}</strong></TableCell>
                <TableCell className={`${getAmountTextColor(group.claimed, group.total)} ${classes.dollarAmountCell}`}><strong>{`$${group.claimed.toFixed(2)}`}</strong></TableCell>
                <TableCell className={`${getAdjTextColor(group.adjustmentTotal)} ${classes.dollarAmountCell}`}>
                    {group.claims.length > 0 ?
                        <strong onMouseEnter={handleAdjustmentsOpen} onMouseLeave={handleAdjustmentsClose}>
                            {`$${group.adjustmentTotal.toFixed(2)}`}
                        </strong>
                    :
                        <strong>
                            {`$${group.adjustmentTotal.toFixed(2)}`}
                        </strong>
                    }
                </TableCell>

                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={openAdjustments}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handleAdjustmentsClose}
                    disableRestoreFocus
                >
                    <TableContainer component={Paper} >
                        <Table size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell className={classes.dollarAmountCell}>Actual Amount</TableCell>
                                <TableCell className={classes.dollarAmountCell}>Claim Amount</TableCell>
                                <TableCell className={classes.dollarAmountCell}>Adjustment Total</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {group.claims.map((claim: JobScheduleClaims, idx: number) => (
                                <StyledTableRow key={idx}>
                                    <StyledTableCell className={classes.dollarAmountCell}>${claim.actualAmount}</StyledTableCell>
                                    <StyledTableCell className={classes.dollarAmountCell}>${claim.claimAmount}</StyledTableCell>
                                    <StyledTableCell className={`${getAdjTextColor(claim.adjustmentTotal)} ${classes.dollarAmountCell}`}>${claim.adjustmentTotal}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Popover>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Item</StyledTableCell>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Product</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                        <StyledTableCell>Claimed Kg</StyledTableCell>
                                        <StyledTableCell>UOM</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Supply Rate</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Place Rate</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Supply Total</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Place Total</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Total</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Claimed</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.lines.map((line: JobMeasureQuoteLineInfo) => (
                                        <StyledTableRow key={line.id}>
                                            <StyledTableCell>{line.item}</StyledTableCell>
                                            <StyledTableCell>{line.type}</StyledTableCell>
                                            <StyledTableCell>{line.product}</StyledTableCell>
                                            <StyledTableCell>{Math.round(line.kg)}</StyledTableCell>
                                            <StyledTableCell className={getAmountTextColor(line.claimedKg, line.kg)}>{Math.round(line.claimedKg)}</StyledTableCell>
                                            <StyledTableCell>{line.uom}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.supplyRate.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.placeRate.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.supplyAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.placeAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={classes.dollarAmountCell}>{`$${line.totalAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell className={`${getAmountTextColor(line.claimedAmount, line.totalAmount)} ${classes.dollarAmountCell}`}>{`$${line.claimedAmount.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell>
                                                <IconButton aria-label="edit line" onClick={() => handleClickOpen(line)}>
                                                    <Edit />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <AddToScheduleDialog openDialog={openSchedule} handleCloseDialog={handleClose} jobMeasureQuoteInfo={selectedJobMeasureQuoteInfo} isNewItem={false} isUpdateMode={true} ></AddToScheduleDialog>
        </>
    )
}

