import React, { Suspense, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  HashRouter,
  Navigate
} from "react-router-dom";

import Grid from "@material-ui/core/Grid";


import SwimsApp from "./swims/SwimsApp"
import EncoreApp from "./encore/EncoreApp"
import EurolabApp from "./eurolab/EurolabApp"

// MSAL imports
import { MsalProvider, useMsal} from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { IPublicClientApplication, InteractionType } from "@azure/msal-browser";

import { Auth } from "aws-amplify"

import './App.css';
import { Button, Typography } from '@material-ui/core';
import { loginRequest } from './msAuthConfig';
import AdminApp from './admin/AdminApp';
// import ReportViewer from './components/ReportViewer'
const ReportViewer = React.lazy(() => import('./components/ReportViewer'));


type AppProps = {
  pca: IPublicClientApplication
};

function App({ pca }: AppProps) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  // const history = useHistory();
  // const navigationClient = new CustomNavigationClient(history);
  // pca.setNavigationClient(navigationClient);

  


  return (
    <MsalProvider instance={pca}>
      
      <Pages />

    </MsalProvider>
  );
}

function Pages() {

  const { instance, accounts, inProgress } = useMsal();

  const [user, setUser] = useState<any | null>(null)
  const [globalBranch, setGlobalBranch] = useState<string>('All')

//   const request = {
//     // loginHint: "name@example.com",
//     scopes: ["User.Read"]
// }
const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);

// useEffect(() => {
//   if (inProgress === "none" && accounts.length > 0) {

//       setUser(accounts[0])

//       instance.acquireTokenSilent({
//         account: accounts[0],
//         scopes: ["User.Read"]
//     }).then(response => {
//         if (response.accessToken) {
//           let fedUser:any;
//           const token = response.accessToken
//           Auth.federatedSignIn(
//             "login.windows.net",
//             {
//                 token,
//                 // "de7ea84b-bba2-4480-90f1-fd3b1795c959", // Optional
//                 expires_at: 100 * 1000 + new Date().getTime() // the expiration timestamp
//             },
//             fedUser
//         ).then(cred => {
//             // If success, you will get the AWS credentials
//             console.log(cred);
//             return Auth.currentAuthenticatedUser();
//         }).then(user => {
//             // If success, the user object you passed in Auth.federatedSignIn
//             console.log(user);
//         }).catch(e => {
//             console.log(e)
//         });
//         }
  
//     });
      
//   }
// }, [inProgress, accounts, instance]);
  useEffect(() => {
    if(user === null) {
      getUser().then(userData => {
        setUser(userData);
      });
    }

    let eurocorpBranch = localStorage.getItem("eurocorp-branch")
    if(eurocorpBranch) {
      setGlobalBranch(eurocorpBranch)
    }
  });

  async function getUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      return userData;
    } catch (e) {
      console.log('Not signed in');
      await Auth.federatedSignIn();

    }
  }

  

  // const handleLogin = (loginType: string) => {
  //   // setAnchorEl(null);

  //   if (loginType === "popup") {
  //       instance.loginPopup();
  //   } else if (loginType === "redirect") {
  //       instance.loginRedirect();
  //   }
// }

  return (
    <>
   
      <AuthenticatedTemplate>
        <HashRouter>
            <Routes>
              <Route path="/encore/*" element={<EncoreApp user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch} />}/>
              <Route path="/swims/*" element={<SwimsApp user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch} />}/>
    
              <Route path="/eurolab/*" element={<EurolabApp user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch} />}/>
                
              <Route path="/admin/*" element={<AdminApp user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch} />}/>

              <Route path="/report/viewer/:reportId" element={<Suspense fallback={<div>Loading...</div>}><ReportViewer user={user}/></Suspense>}/>

              <Route path="/" element={<Navigate to="/encore" replace/>}/>
            </Routes>
        </HashRouter>
      </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Typography variant="h3" align="center">Sign-In to ENCORE </Typography>
            {/* <Button color="primary" onClick={()=> login()}>Click here to sign in</Button>; */}
          </UnauthenticatedTemplate>

     </> 
   
  );
}

export default App;
