import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Button, TextField, Typography, Checkbox, InputAdornment,
    Dialog, DialogContent, Stepper, Step, StepLabel, CircularProgress,
    FormControl, FormLabel, Select, MenuItem, Table,
    TableContainer, TableBody, TableHead, TableCell, TableRow, Box, IconButton, Collapse,
    Paper, Popover, Toolbar, InputBase, Tooltip, Input
} from "@material-ui/core";
import {
    KeyboardArrowUp, KeyboardArrowDown, Edit, Clear, ArrowDropDown, InfoOutlined, CheckCircleOutline, RemoveCircleOutline, Search
} from "@material-ui/icons"
import { FormProvider, Controller, useForm, useFormContext } from "react-hook-form";
import {
    JobMeasureQuoteLineGroup, JobTagDetailScheduleGroup, JobTagDetailOrderGroup, JobTagDetailInfo,
    JobMeasureQuoteLineInfo, JobClaimSummary, ClaimScheduleSummary, JobLabourInfo, JobLabourTableData,
    JobClaimsData, ClaimLineSummary, JobScheduleClaims, ClaimDataDto
} from "../../types/JobTypes";
import { API } from "aws-amplify";
import { StyledTableCell, StyledTableRow } from '../../../components/StyledTableComponents';
import { formatDate, formatDateTime } from '../../../utils/utils';
import { Storage } from "aws-amplify";
import { ClaimContactForm } from '../../types/OpportunityTypes';
import { ClaimScheduleCommentDialog } from './ClaimScheduleCommentDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
            
            
        },
        stepper: {
            '&.MuiStepper-root': {
                padding: 16
            }
        },
        dialogContent: {
            '&.MuiDialogContent-root': {
                padding: "0px 24px"
            }
        },
        formInput: {
            width: "100%",
            marginBottom: 16
        },
        label: {
            marginBottom: 6
        },
        field: {
            '&.MuiInputBase-root.Mui-disabled': {
                color: "#000000"
            },
            '&.MuiInputBase-input.Mui-disabled': {
                color: "#000000"
            },
            textAlign: "right"
        },
        cell: {
            '&.MuiTableCell-root': {
                padding: 0
            }
        },
        claimInputs: {
            width: 120
        },
        adjustmentInput: {
            width: 100
        },
        totalValue: {
            fontWeight: "bold"
        },
        popover: {
            marginTop: 8,
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        dollarAmountCell: {
            textAlign: "right"
        }, search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            // backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                //   backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },
        
    })
);

interface NewClaimDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    jobId: string;
    quoteData: Array<JobMeasureQuoteLineGroup>
    claimData: Array<JobClaimsData>;
    user?: any | null
    refreshData: () => void;
    claimEditMode: boolean;
    setClaimEditMode: (editMode: boolean) => void;
    selectedClaimID: number | undefined
    setSelectedClaimID: (claim: number | undefined) => void;
    claimContact: ClaimContactForm;
}

export function NewClaimDialog({ open, handleDialog, jobId, quoteData, claimData, user, refreshData, claimEditMode, setClaimEditMode, selectedClaimID, setSelectedClaimID, claimContact }: NewClaimDialogProps) {

    const classes = useStyles()
    const [activeStep, setActiveStep] = useState<number>(0)

    const [dispatchedTags, setDispatchedTags] = useState<Array<JobTagDetailInfo>>([])
    const [variationTags, setVariationTags] = useState<Array<JobTagDetailInfo>>([])
    const [subVariationTags, setSubVariationTags] = useState<Array<JobTagDetailInfo>>([])
    const [tagsTableData, setTagsTableData] = useState<Array<JobTagDetailOrderGroup>>([])
    const [variationTagsTableData, setVariationTagsTableData] = useState<Array<JobTagDetailOrderGroup>>([])
    const [subVariationTagsTableData, setSubVariationTagsTableData] = useState<Array<JobTagDetailOrderGroup>>([])
    const [claimSummary, setClaimSummary] = useState<JobClaimSummary>()
    const [labourSummary, setLabourSummary] = useState<ClaimScheduleSummary>()
    const [unclaimedLabour, setUnclaimedLabour] = useState<Array<JobLabourInfo>>([])
    const [labourTableData, setLabourTableData] = useState<Array<JobLabourTableData>>([])
    const [previousClaim, setPreviousClaim] = useState<JobClaimsData>()
    const [jobScheduleClaims, setJobScheduleClaims] = useState<Array<JobScheduleClaims>>([])


    const steps = ["Schedule", "Sched Variations", "Sub Variations", "Purchase Orders", "Labour", "Summary"]

    useEffect(() => {
        setDispatchedTags([])
        setVariationTags([])
        setUnclaimedLabour([])
        setTagsTableData([])
        setVariationTagsTableData([])
        if (open) {
            if (claimEditMode && selectedClaimID) {
                API.get("", `/encore/jobs/${jobId}/claimData/${selectedClaimID}`, {})
                    .then((response: ClaimDataDto) => {
                        if (response) {
                            if (response.s3Location.length > 0){
                                Storage.get(`job/${jobId?.replace("EC","")}.json`, { download: true} ).then((resp: any) => {
                                    resp.Body.text().then((str:string)=>{
                                        //         console.log(str)
                                        const res: ClaimDataDto = JSON.parse(str)
                                        if (res.claim && res.claim.claims) {
                                            // console.log("Added Claims")
                                            setJobScheduleClaims(res.claim.claims)
                                        }
                                        
                                        if (res.dispatchedTags) {
                                            setDispatchedTags(parseTagData(res.dispatchedTags))
                                        }
            
                                        if (res.variationTags) {
                                            setVariationTags(parseTagData(res.variationTags))
                                        }
            
                                        if (res.subVariationTags) {
                                            setSubVariationTags(parseTagData(res.subVariationTags))
                                        }
            
                                        if (res.unclaimedLabour) {
                                            res.unclaimedLabour.forEach((labour: JobLabourInfo) => {
                                                labour.isClaimed = true
                                            })
                                            setUnclaimedLabour(res.unclaimedLabour)
                                        }
                                        
                                    })

                                })
                                .catch((error: any) => {
                                    console.log("Error fetching file from s3", error);
                                })

                            }else{
                                if (response.claim && response.claim.claims) {
                                    setJobScheduleClaims(response.claim.claims)
                                }

                                if (response.dispatchedTags) {

                                    setDispatchedTags(parseTagData(response.dispatchedTags))
    
                                }
    
                                if (response.variationTags) {
                                    setVariationTags(parseTagData(response.variationTags))
                                }
    
                                if (response.subVariationTags) {
                                    setSubVariationTags(parseTagData(response.subVariationTags))
                                }
    
                                if (response.unclaimedLabour) {
                                    response.unclaimedLabour.forEach((labour: JobLabourInfo) => {
                                        labour.isClaimed = true
                                    })
                                    setUnclaimedLabour(response.unclaimedLabour)
                                }
                                

                            }
                            
                        }
                    })
            } else {
                API.get("", `/encore/jobs/${jobId}/claimData`, {})
                    .then((response: ClaimDataDto) => {
                        if (response) {
                            if (response.s3Location.length > 0){
                                Storage.get(`job/${jobId?.replace("EC","")}.json`, { download: true} ).then((resp: any) => {
                                    resp.Body.text().then((str:string)=>{
                                        //         console.log(str)
                                        const res = JSON.parse(str)
                                        if (res.dispatchedTags) {
                                            setDispatchedTags(parseTagData(res.dispatchedTags))
                                        }
            
                                        if (res.variationTags) {
                                            setVariationTags(parseTagData(res.variationTags))
                                        }
            
                                        if (res.subVariationTags) {
                                            setSubVariationTags(parseTagData(res.subVariationTags))
                                        }
            
                                        if (res.unclaimedLabour) {
                                            res.unclaimedLabour.forEach((labour: JobLabourInfo) => {
                                                labour.isClaimed = true
                                            })
                                            setUnclaimedLabour(res.unclaimedLabour)
                                        }
                                    })

                                })
                                .catch((error: any) => {
                                    console.log("Error fetching file from s3", error);
                                })

                            }else{
                                if (response.dispatchedTags) {

                                    setDispatchedTags(parseTagData(response.dispatchedTags))
    
                                }
    
                                if (response.variationTags) {
                                    setVariationTags(parseTagData(response.variationTags))
                                }
    
                                if (response.subVariationTags) {
                                    setSubVariationTags(parseTagData(response.subVariationTags))
                                }
    
                                if (response.unclaimedLabour) {
                                    response.unclaimedLabour.forEach((labour: JobLabourInfo) => {
                                        labour.isClaimed = true
                                    })
                                    setUnclaimedLabour(response.unclaimedLabour)
                                }

                            }
                            
                        }
                    })
                    .catch((error: any) => {
                        console.log("Error: fetching dispatched tags", error)
                    })
            }
        }
    }, [open])

    useEffect(() => {
        if (claimEditMode) {
            handleDialog(true)
        }
    }, [claimEditMode])

    useEffect(() => {
        if (dispatchedTags.length > 0 && open) {
            setTagsTableData(formatData(dispatchedTags))
            calculateClaim(dispatchedTags)
        }
    }, [dispatchedTags, open])

    useEffect(() => {
        if (dispatchedTags.length > 0 && open) {
            setVariationTagsTableData(formatData(variationTags))
            calculateClaim(dispatchedTags)
        }
    }, [variationTags, open])

    useEffect(() => {
        if (dispatchedTags.length > 0 && open) {
            setSubVariationTagsTableData(formatData(subVariationTags))
            calculateClaim(dispatchedTags)
        }
    }, [subVariationTags, open])

    useEffect(() => {
        if (unclaimedLabour.length > 0 && open) {
            formatLabourData(unclaimedLabour)
            calculateLabourClaim(unclaimedLabour)
        }
    }, [unclaimedLabour, open])

    // const handleTagUnclaim = (id: number) => {
    //     let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
    //         if(tag.id === id) {
    //             tag.isClaimed = !tag.isClaimed
    //         }
    //         return tag
    //     })
    //     setDispatchedTags(newDispatchedTags)
    // }

    // const handleOrderUnclaim = (orderNo: string, scheduleName: string, value: boolean) => {
    //     let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
    //         if(tag.orderNo === orderNo && tag.scheduleName === scheduleName) {
    //             tag.isClaimed = value
    //         }
    //         return tag
    //     })
    //     setDispatchedTags(newDispatchedTags)
    // }

    // const handleScheduleUnclaim = (scheduleName: string, value: boolean) => {
    //     let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
    //         if(tag.scheduleName === scheduleName) {
    //             tag.isClaimed = value
    //         }
    //         return tag
    //     })
    //     setDispatchedTags(newDispatchedTags)
    // }



    const getCommentFromJobScheduleClaims = (scheduleNo:number, scheduleName:string)=>{
        const schedule = jobScheduleClaims.find((schedule)=> schedule.scheduleNo === scheduleNo && schedule.schedule === scheduleName)
        if (schedule) {
            return schedule.comment
        }
        return ''
    }


    const handleTagSupplyTicked = (id: number) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isSupplyClaimed = !tag.isSupplyClaimed
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }

    const handleOrderSupplyTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }

    const handleScheduleSupplyTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }



    const handleTagPlaceTicked = (id: number) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isPlaceClaimed = !tag.isPlaceClaimed
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }

    const handleOrderPlaceTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }

    const handleSchedulePlaceTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = dispatchedTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setDispatchedTags(newDispatchedTags)
    }


    const handleVariationTagSupplyTicked = (id: number) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isSupplyClaimed = !tag.isSupplyClaimed
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }

    const handleVariationOrderSupplyTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }

    const handleVariationScheduleSupplyTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }



    const handleVariationTagPlaceTicked = (id: number) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isPlaceClaimed = !tag.isPlaceClaimed
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }

    const handleVariationOrderPlaceTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }

    const handleVariationSchedulePlaceTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = variationTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setVariationTags(newDispatchedTags)
    }


    // SubSchedule Variations

    const handleSubVariationTagSupplyTicked = (id: number) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isSupplyClaimed = !tag.isSupplyClaimed
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }

    const handleSubVariationOrderSupplyTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }

    const handleSubVariationScheduleSupplyTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isSupplyHidden) {
                    tag.isSupplyClaimed = value
                }
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }



    const handleSubVariationTagPlaceTicked = (id: number) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.id === id) {
                tag.isPlaceClaimed = !tag.isPlaceClaimed
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }

    const handleSubVariationOrderPlaceTicked = (orderNo: string, scheduleName: string, value: boolean) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.orderNo === orderNo ) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }

    const handleSubVariationSchedulePlaceTicked = (scheduleName: string, value: boolean) => {
        let newDispatchedTags = subVariationTags.map((tag: JobTagDetailInfo) => {
            if (tag.scheduleName === scheduleName) {
                if (!tag.isPlaceHidden) {
                    tag.isPlaceClaimed = value
                }
            }
            return tag
        })
        setSubVariationTags(newDispatchedTags)
    }








    const handleActivityUnclaim = (activityCode: string, value: boolean) => {
        let newUnclaimedLabour = unclaimedLabour.map((labour: JobLabourInfo) => {
            if (labour.activityCode === activityCode) {
                labour.isClaimed = value
            }
            return labour
        })
        setUnclaimedLabour(newUnclaimedLabour)
    }

    const handleEmployeeUnclaim = (employeeCode: string, startTime: string) => {
        let newUnclaimedLabour = unclaimedLabour.map((labour: JobLabourInfo) => {
            if (labour.employeeCode === employeeCode && labour.startTime === startTime) {
                labour.isClaimed = !labour.isClaimed
            }
            return labour
        })
        setUnclaimedLabour(newUnclaimedLabour)
    }

    const incrementActiveStep = () => {
        setActiveStep(prevStep => prevStep + 1)
    }

    const decrementActiveStep = () => {
        setActiveStep(prevStep => prevStep - 1);
    }

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch (stepIndex) {
            case 0:
                return <ClaimStep1Form dispatchedTags={tagsTableData} handleDialog={handleDialog} incrementActiveStep={incrementActiveStep} claimSummary={claimSummary} handleTagSupplyTicked={handleTagSupplyTicked} handleOrderSupplyTicked={handleOrderSupplyTicked} handleScheduleSupplyTicked={handleScheduleSupplyTicked} handleTagPlaceTicked={handleTagPlaceTicked} handleOrderPlaceTicked={handleOrderPlaceTicked} handleSchedulePlaceTicked={handleSchedulePlaceTicked} setClaimEditMode={setClaimEditMode} setSelectedClaimID={setSelectedClaimID} />
            case 1:
                return <ClaimStep2Form dispatchedTags={variationTagsTableData} handleDialog={handleDialog} incrementActiveStep={incrementActiveStep} decrementActiveStep={decrementActiveStep} claimSummary={claimSummary} handleTagSupplyTicked={handleVariationTagSupplyTicked} handleOrderSupplyTicked={handleVariationOrderSupplyTicked} handleScheduleSupplyTicked={handleVariationScheduleSupplyTicked} handleTagPlaceTicked={handleVariationTagPlaceTicked} handleOrderPlaceTicked={handleVariationOrderPlaceTicked} handleSchedulePlaceTicked={handleVariationSchedulePlaceTicked} setClaimEditMode={setClaimEditMode} setSelectedClaimID={setSelectedClaimID} />
            case 2:
                return <ClaimStep2Form dispatchedTags={subVariationTagsTableData} handleDialog={handleDialog} incrementActiveStep={incrementActiveStep} decrementActiveStep={decrementActiveStep} claimSummary={claimSummary} handleTagSupplyTicked={handleSubVariationTagSupplyTicked} handleOrderSupplyTicked={handleSubVariationOrderSupplyTicked} handleScheduleSupplyTicked={handleSubVariationScheduleSupplyTicked} handleTagPlaceTicked={handleSubVariationTagPlaceTicked} handleOrderPlaceTicked={handleSubVariationOrderPlaceTicked} handleSchedulePlaceTicked={handleSubVariationSchedulePlaceTicked} setClaimEditMode={setClaimEditMode} setSelectedClaimID={setSelectedClaimID} />
            case 3:
                return <ClaimStep3Form incrementActiveStep={incrementActiveStep} decrementActiveStep={decrementActiveStep} />
            case 4:
                return <ClaimStep4Form incrementActiveStep={incrementActiveStep} decrementActiveStep={decrementActiveStep} labourData={labourTableData} labourSummary={labourSummary} handleActivityUnclaim={handleActivityUnclaim} handleEmployeeUnclaim={handleEmployeeUnclaim} />
            case 5:
                return <ClaimStep5Form
                    handleDialog={handleDialog}
                    jobId={jobId}
                    user={user}
                    dispatchedTags={dispatchedTags}
                    refreshData={refreshData}
                    decrementActiveStep={decrementActiveStep}
                    claimSummary={claimSummary}
                    labourSummary={labourSummary}
                    setActiveStep={setActiveStep}
                    unclaimedLabour={unclaimedLabour}
                    quoteData={quoteData}
                    claimData={claimData}
                    claimEditMode={claimEditMode}
                    setClaimEditMode={setClaimEditMode}
                    selectedClaimID={selectedClaimID}
                    setSelectedClaimID={setSelectedClaimID}
                    subVariationTags={subVariationTags}
                    variationTags={variationTags}
                    previousClaim={previousClaim}
                    claimContact={claimContact} />
            default:
                return <div></div>
        }
    }

    const parseTagData = (tags: Array<JobTagDetailInfo>) => {
        tags.forEach((tag: JobTagDetailInfo) => {

            let scheduleQuote = quoteData.find((v: JobMeasureQuoteLineGroup) => v.schedule === tag.scheduleNo)
            // console.log(scheduleQuote)

            if (scheduleQuote) {

                const isGalvProduct = tag.stockCode.toUpperCase().endsWith("GALV") && tag.productCategory === "Bar"
                const tagProduct = isGalvProduct ? tag.stockCode.slice(0, -4) : tag.stockCode
                // let galvPlaceRate = 0
                let galvSupplyRate = 0
                if (isGalvProduct) {
                    let galv = scheduleQuote.lines.find((line: JobMeasureQuoteLineInfo) => line.product.toLowerCase() === "galv")
                    if (galv) {
                        // galvPlaceRate = galv?.placeRate
                        galvSupplyRate = galv?.supplyRate
                    }

                }

                let quotes = scheduleQuote.lines.filter((line: JobMeasureQuoteLineInfo) => line.product.toLowerCase() === tagProduct.toLowerCase() && line.complexityType.toLowerCase().includes(tag.shapeTypeDescr.toLowerCase()))
                let lineQuote = quotes ? quotes[0] : undefined
                if (quotes && quotes.length > 1) {
                    if (tag.placed === 'P') {
                        lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate !== 0 && line.supplyRate !== 0)
                    } else {
                        lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate === 0 && line.supplyRate !== 0)
                    }
                }

                if (tagProduct.toLowerCase().includes("stock")) {
                    let quotes = scheduleQuote.lines.filter((line: JobMeasureQuoteLineInfo) => line.product.toLowerCase() === tagProduct.toLowerCase() && line.type.toLowerCase().includes('stock'))
                    lineQuote = quotes ? quotes[0] : undefined
                    if (quotes && quotes.length > 1) {
                        if (tag.placed === 'P') {
                            lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate !== 0 && line.supplyRate !== 0)
                        } else {
                            lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate === 0 && line.supplyRate !== 0)
                        }
                    }
                }
                //Use basic rate
                if (!lineQuote) {
                    let quotes = scheduleQuote.lines.filter((line: JobMeasureQuoteLineInfo) => line.product.toLowerCase() === tagProduct.toLowerCase() && line.complexityType.toLowerCase().includes('basic'))
                    lineQuote = quotes ? quotes[0] : undefined
                    if (quotes && quotes.length > 1) {
                        if (tag.placed === 'P') {
                            lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate !== 0 && line.supplyRate !== 0)
                        } else {
                            lineQuote = quotes.find((line: JobMeasureQuoteLineInfo) => line.placeRate === 0 && line.supplyRate !== 0)
                        }
                    }
                }


                if (lineQuote) {
                    let qty = tag.quantity
                    if (tag.weightUnits === "Kgs") {
                        qty = tag.weight
                    }
                    let placeTotal = tag.isNoCharge? 0 : ((lineQuote.placeRate) * qty)
                    let supplyTotal = tag.isNoCharge? 0 : ((lineQuote.supplyRate + galvSupplyRate) * qty)
                    tag.placeTotal = parseFloat(placeTotal.toFixed(2))
                    tag.supplyTotal = parseFloat(supplyTotal.toFixed(2))
                    tag.total = parseFloat((placeTotal + supplyTotal).toFixed(2))
                    tag.rateApplied = tag.isNoCharge?"No Charge" : lineQuote.complexityType
                    tag.placeRate = lineQuote.placeRate
                    tag.supplyRate = lineQuote.supplyRate + galvSupplyRate
                    tag.galvanisedTotal = parseFloat((galvSupplyRate * qty).toFixed(2))
                    tag.galvanisedRate = galvSupplyRate
                    if (tag.placed === 'P') {
                        if (!claimEditMode) {
                            tag.isSupplyHidden = tag.isSupplyClaimed ? true : false
                            // tag.isSupplyClaimed = !tag.isSupplyClaimed
                            tag.isPlaceHidden = tag.isPlaceClaimed ? true : false
                            // tag.isPlaceClaimed = !tag.isPlaceClaimed
                        }
                        

                    } else {
                        if (!claimEditMode) {
                            tag.isSupplyHidden = tag.isSupplyClaimed ? true : false
                            // tag.isSupplyClaimed = !tag.isSupplyClaimed
                        }
                        
                        tag.isPlaceClaimed = false
                        tag.isPlaceHidden = true
                    }
                }
            }
        })
        return tags
    }

    const formatData = (data: Array<JobTagDetailInfo>): Array<JobTagDetailOrderGroup> => {
        // let scheduleGroups = Array<JobTagDetailScheduleGroup>()
        let orderGroups = Array<JobTagDetailOrderGroup>()

        data.forEach((tag: JobTagDetailInfo) => {

            let orderIndex = orderGroups.findIndex((j: JobTagDetailOrderGroup) => j.orderId === tag.orderNo)

            // let orderIndex = scheduleGroups[index].orders.findIndex((j: JobTagDetailOrderGroup) => j.orderId === tag.orderNo)
            if (orderIndex >= 0) {
                orderGroups[orderIndex].tags.push(tag)
                if (tag.weightUnits === "Kgs") {
                    orderGroups[orderIndex].totalWeight += tag.weight
                }

                orderGroups[orderIndex].totalTags++

            } else {
                let totalWeight = 0;

                if (tag.weightUnits === "Kgs") {
                    totalWeight = tag.weight
                }

                // orderGroups[orderIndex].totalTags++

                let orderGroup: JobTagDetailOrderGroup = {
                    jobId: tag.jobNo,
                    orderId: tag.orderNo,
                    controlCode: tag.controlCode,
                    sheetNo: tag.sheetNo,
                    scheduleNo: tag.scheduleNo,
                    scheduleName: tag.scheduleName,
                    totalReady: 0,
                    totalNotReady: 0,
                    totalDispatched: 0,
                    totalDelivered: 0,
                    totalWeight: totalWeight,
                    totalTags: 1,
                    tags: [tag],
                    isSupplyClaimed: false,
                    isPlaceClaimed: false,
                    selectedTags: 0,
                    orderDescription: tag.orderDescription,
                    orderPromisedDeliveryDate: tag.orderPromisedDeliveryDate,
                    isLabourClaimed: false
                }
                orderGroups.push(orderGroup)
            }

            //     if(index >= 0) {

            //     } else {
            //         let totalWeight = 0;

            //         if(tag.weightUnits === "Kgs") {
            //             totalWeight = tag.weight
            //         }

            //         let scheduleGroup: JobTagDetailScheduleGroup = {
            //             schedule: tag.scheduleName,
            //             totalReady: 0,
            //             totalNotReady: 0,
            //             totalDispatched: 0,
            //             totalDelivered: 0,
            //             totalWeight: totalWeight,
            //             totalTags: 1,
            //             isSupplyClaimed: true,
            //             isPlaceClaimed: true,
            //             selectedTags: 0,
            //             orders: [{
            //                 orderId: tag.orderNo,
            //                 controlCode: tag.controlCode,
            //                 totalReady: 0,
            //                 totalNotReady: 0,
            //                 totalDispatched: 0,
            //                 totalDelivered: 0,
            //                 totalWeight: totalWeight,
            //                 totalTags: 1,
            //                 tags: [tag],
            //                 isSupplyClaimed: false,
            //                 isPlaceClaimed: false,
            //                 selectedTags: 0
            //             }]
            //         }
            //         scheduleGroups.push(scheduleGroup)
            //     }
        })

        orderGroups.forEach((order: JobTagDetailOrderGroup) => {
            let orderClaimedCount = order.tags.filter((tag: JobTagDetailInfo) => tag.isSupplyClaimed).length
            // scheduleSupplyClaimedCount += orderClaimedCount

            let orderClaimedPlaceCount = order.tags.filter((tag: JobTagDetailInfo) => tag.isPlaceClaimed).length
            // schedulePlaceClaimedCount =+ orderClaimedPlaceCount

            order.selectedTags = orderClaimedCount
            if (orderClaimedCount > 0) {
                order.isSupplyClaimed = true
            } else {
                order.isSupplyClaimed = false
            }

            //Place Order Level
            if (orderClaimedPlaceCount > 0) {
                order.isPlaceClaimed = true
            } else {
                order.isPlaceClaimed = false
            }
        })

        // scheduleGroups.forEach((schedule: JobTagDetailScheduleGroup) => {
        //     let scheduleSupplyClaimedCount = 0
        //     let schedulePlaceClaimedCount = 0

        //     schedule.orders.forEach((order: JobTagDetailOrderGroup) => {
        //         let orderClaimedCount = order.tags.filter((tag: JobTagDetailInfo) => tag.isSupplyClaimed).length
        //         scheduleSupplyClaimedCount += orderClaimedCount

        //         let orderClaimedPlaceCount = order.tags.filter((tag: JobTagDetailInfo) => tag.isPlaceClaimed).length
        //         schedulePlaceClaimedCount =+ orderClaimedPlaceCount

        //         order.selectedTags = orderClaimedCount
        //         if(orderClaimedCount > 0) {
        //             order.isSupplyClaimed = true
        //         } else {
        //             order.isSupplyClaimed = false
        //         }

        //         //Place Order Level
        //         if(orderClaimedPlaceCount > 0) {
        //             order.isPlaceClaimed = true
        //         } else {
        //             order.isPlaceClaimed = false
        //         }
        //     })


        //     schedule.selectedTags = scheduleSupplyClaimedCount
        //     if(scheduleSupplyClaimedCount > 0) {
        //         schedule.isSupplyClaimed = true
        //     } else {
        //         schedule.isSupplyClaimed = false
        //     }

        //     //Place Schedule Level
        //     if(schedulePlaceClaimedCount > 0) {
        //         schedule.isPlaceClaimed = true
        //     } else {
        //         schedule.isPlaceClaimed = false
        //     }
        // })
        // console.log(scheduleGroups)
        return orderGroups

    }

    const groupClaimDataBySchedule = (data: Array<JobTagDetailInfo>, by: string = "schedule"): Array<ClaimScheduleSummary> => {
        let scheduleClaims = Array<ClaimScheduleSummary>()
        // let placeTotal = 0;
        // let supplyTotal = 0;
        // let total = 0;
        // console.log("scheduled Claims")
        // console.log(scheduleClaims)

        data.forEach((tag: JobTagDetailInfo) => {
            if (tag.isSupplyClaimed || tag.isPlaceClaimed) {
                let byScheduleNo
                let isSubSchedule = false
                if (by === "reasonCode") {
                    byScheduleNo = (claim: ClaimScheduleSummary) => claim.reasonCodeId === tag.variationSubScheduleName
                    isSubSchedule = true
                } else {
                    byScheduleNo = (claim: ClaimScheduleSummary) => claim.scheduleNo === tag.scheduleNo

                }
                let claim = scheduleClaims.findIndex(byScheduleNo)
                const tagPlaceTotal = tag.isPlaceClaimed ? tag.placeTotal : 0
                const tagSupplyTotal = tag.isSupplyClaimed ? tag.supplyTotal : 0
                const tagTotal = tagPlaceTotal + tagSupplyTotal

                // placeTotal += tagPlaceTotal
                // supplyTotal += tagSupplyTotal
                // total = (placeTotal + supplyTotal)

                if (claim >= 0) {
                    scheduleClaims[claim].actualPlace += tagPlaceTotal
                    scheduleClaims[claim].actualSupply += tagSupplyTotal
                    scheduleClaims[claim].actualAmount = scheduleClaims[claim].actualPlace + scheduleClaims[claim].actualSupply

                    scheduleClaims[claim].claimPlace = scheduleClaims[claim].actualPlace
                    scheduleClaims[claim].claimSupply = scheduleClaims[claim].actualSupply
                    scheduleClaims[claim].claimAmount = scheduleClaims[claim].actualAmount

                    scheduleClaims[claim].actualPlace = parseFloat(scheduleClaims[claim].actualPlace.toFixed(2))
                    scheduleClaims[claim].actualSupply = parseFloat(scheduleClaims[claim].actualSupply.toFixed(2))
                    scheduleClaims[claim].actualAmount = parseFloat(scheduleClaims[claim].actualAmount.toFixed(2))

                    scheduleClaims[claim].claimPlace = parseFloat(scheduleClaims[claim].claimPlace.toFixed(2))
                    scheduleClaims[claim].claimSupply = parseFloat(scheduleClaims[claim].claimSupply.toFixed(2))
                    scheduleClaims[claim].claimAmount = parseFloat(scheduleClaims[claim].claimAmount.toFixed(2))

                    if (scheduleClaims[claim].lines) {
                        let product = scheduleClaims[claim].lines!.findIndex((line: ClaimLineSummary) => tag.stockCode === line.stockCode)
                        if (product >= 0) {

                            scheduleClaims[claim].lines![product].actualPlace += tagPlaceTotal
                            scheduleClaims[claim].lines![product].actualSupply += tagSupplyTotal
                            scheduleClaims[claim].lines![product].actualAmount = scheduleClaims[claim].lines![product].actualPlace + scheduleClaims[claim].lines![product].actualSupply

                            scheduleClaims[claim].lines![product].claimPlace = scheduleClaims[claim].lines![product].actualPlace
                            scheduleClaims[claim].lines![product].claimSupply = scheduleClaims[claim].lines![product].actualSupply
                            scheduleClaims[claim].lines![product].claimAmount = scheduleClaims[claim].lines![product].actualAmount

                            scheduleClaims[claim].lines![product].actualPlace = parseFloat(scheduleClaims[claim].lines![product].actualPlace.toFixed(2))
                            scheduleClaims[claim].lines![product].actualSupply = parseFloat(scheduleClaims[claim].lines![product].actualSupply.toFixed(2))
                            scheduleClaims[claim].lines![product].actualAmount = parseFloat(scheduleClaims[claim].lines![product].actualAmount.toFixed(2))

                            scheduleClaims[claim].lines![product].claimPlace = parseFloat(scheduleClaims[claim].lines![product].claimPlace.toFixed(2))
                            scheduleClaims[claim].lines![product].claimSupply = parseFloat(scheduleClaims[claim].lines![product].claimSupply.toFixed(2))
                            scheduleClaims[claim].lines![product].claimAmount = parseFloat(scheduleClaims[claim].lines![product].claimAmount.toFixed(2))
                        } else {
                            let claimLine: ClaimLineSummary = {
                                stockCode: tag.stockCode,
                                claimPlace: tagPlaceTotal,
                                claimSupply: tagSupplyTotal,
                                actualPlace: tagPlaceTotal,
                                actualSupply: tagSupplyTotal,
                                claimAmount: tagTotal,
                                actualAmount: tagTotal,
                                actualLabour: 0,
                                claimLabour: 0
                            }

                            scheduleClaims[claim].lines!.push(claimLine)
                        }
                    }

                } else {
                    let claimLine: ClaimLineSummary = {
                        stockCode: tag.stockCode,
                        claimPlace: tagPlaceTotal,
                        claimSupply: tagSupplyTotal,
                        actualPlace: tagPlaceTotal,
                        actualSupply: tagSupplyTotal,
                        claimAmount: tagTotal,
                        actualAmount: tagTotal,
                        actualLabour: 0,
                        claimLabour: 0
                    }

                    let previouslyClaimed = 0
                    const displayText = by === "reasonCode" ? `${tag.scheduleName} - ${tag.variationSubScheduleName}` : tag.scheduleName
                    if (claimData) {
                        claimData.forEach((claim: JobClaimsData) => {
                            claim.claims.forEach((c: JobScheduleClaims) => {
                                if (c.schedule === displayText) {
                                    previouslyClaimed += c.claimAmount
                                }
                            })
                        })
                    }

                    let quoteTotal = 0
                    if (quoteData && by !== "reasonCode") {
                        let quote = quoteData.find((quote: JobMeasureQuoteLineGroup) => quote.schedule === tag.scheduleNo)
                        if (quote) {
                            quoteTotal = parseFloat(quote.total.toFixed(2))
                        }
                    }

                    let claimSchedule: ClaimScheduleSummary = {
                        schedule: tag.scheduleName,
                        claimAmount: tagTotal,
                        adjustmentPercentage: 0,
                        adjustmentTotal: 0,
                        actualAmount: tagTotal,
                        lines: [claimLine],
                        previouslyClaimed: previouslyClaimed,
                        quoteTotal: quoteTotal,
                        claimPlace: tagPlaceTotal,
                        claimSupply: tagSupplyTotal,
                        actualPlace: tagPlaceTotal,
                        actualSupply: tagSupplyTotal,
                        scheduleNo: tag.scheduleNo,
                        reasonCodeId: tag.variationSubScheduleName,
                        displayText: displayText,
                        comment: getCommentFromJobScheduleClaims(tag.scheduleNo, tag.scheduleName),
                        isVariationSubSchedule: isSubSchedule,
                        actualLabour: 0,
                        claimLabour: 0
                    }
                    scheduleClaims.push(claimSchedule)
                }
            }
        })


        return scheduleClaims
    }

    const calculateClaim = (data: Array<JobTagDetailInfo>) => {
        let scheduleClaims = groupClaimDataBySchedule(data)
        let variationClaims = groupClaimDataBySchedule(variationTags)
        let subVariationClaims = groupClaimDataBySchedule(subVariationTags, "reasonCode")
        // console.log(subVariationClaims)
        //Rename schedule.
        variationClaims.forEach((claim, index) => {
            variationClaims[index].schedule = `Sched. Variation-${claim.schedule}`
        })
        let totalClaims = [...scheduleClaims, ...variationClaims, ...subVariationClaims].sort((a, b) => {
            // return a.scheduleNo - b.scheduleNo;
            const nameA = a.displayText.toUpperCase(); // ignore upper and lowercase
            const nameB = b.displayText.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });
        // let scheduleClaims = Array<ClaimScheduleSummary>()
        // let placeTotal = 0;
        // let supplyTotal = 0;
        // let total = 0;

        // data.forEach((tag: JobTagDetailInfo) => {
        //     if(tag.isSupplyClaimed || tag.isPlaceClaimed) {

        //         let claim = scheduleClaims.findIndex((claim: ClaimScheduleSummary) => claim.scheduleNo === tag.scheduleNo)
        //         const tagPlaceTotal = tag.isPlaceClaimed? tag.placeTotal: 0
        //         const tagSupplyTotal =  tag.isSupplyClaimed ? tag.supplyTotal : 0
        //         const tagTotal = tagPlaceTotal + tagSupplyTotal

        //         placeTotal += tagPlaceTotal
        //         supplyTotal += tagSupplyTotal
        //         total = (placeTotal + supplyTotal)

        //         if(claim >= 0) {
        //             scheduleClaims[claim].actualPlace += tagPlaceTotal
        //             scheduleClaims[claim].actualSupply += tagSupplyTotal
        //             scheduleClaims[claim].actualAmount = scheduleClaims[claim].actualPlace + scheduleClaims[claim].actualSupply

        //             scheduleClaims[claim].claimPlace = scheduleClaims[claim].actualPlace
        //             scheduleClaims[claim].claimSupply = scheduleClaims[claim].actualSupply
        //             scheduleClaims[claim].claimAmount = scheduleClaims[claim].actualAmount

        //             scheduleClaims[claim].actualPlace = parseFloat(scheduleClaims[claim].actualPlace.toFixed(2))
        //             scheduleClaims[claim].actualSupply = parseFloat(scheduleClaims[claim].actualSupply.toFixed(2))
        //             scheduleClaims[claim].actualAmount = parseFloat(scheduleClaims[claim].actualAmount.toFixed(2))

        //             scheduleClaims[claim].claimPlace = parseFloat(scheduleClaims[claim].claimPlace.toFixed(2))
        //             scheduleClaims[claim].claimSupply = parseFloat(scheduleClaims[claim].claimSupply.toFixed(2))
        //             scheduleClaims[claim].claimAmount = parseFloat(scheduleClaims[claim].claimAmount.toFixed(2))

        //             if(scheduleClaims[claim].lines) {
        //                 let product = scheduleClaims[claim].lines!.findIndex((line: ClaimLineSummary) => tag.stockCode === line.stockCode)
        //                 if(product >= 0) {

        //                     scheduleClaims[claim].lines![product].actualPlace += tagPlaceTotal
        //                     scheduleClaims[claim].lines![product].actualSupply += tagSupplyTotal
        //                     scheduleClaims[claim].lines![product].actualAmount = scheduleClaims[claim].lines![product].actualPlace + scheduleClaims[claim].lines![product].actualSupply

        //                     scheduleClaims[claim].lines![product].claimPlace = scheduleClaims[claim].lines![product].actualPlace
        //                     scheduleClaims[claim].lines![product].claimSupply = scheduleClaims[claim].lines![product].actualSupply
        //                     scheduleClaims[claim].lines![product].claimAmount = scheduleClaims[claim].lines![product].actualAmount

        //                     scheduleClaims[claim].lines![product].actualPlace = parseFloat(scheduleClaims[claim].lines![product].actualPlace.toFixed(2))
        //                     scheduleClaims[claim].lines![product].actualSupply = parseFloat(scheduleClaims[claim].lines![product].actualSupply.toFixed(2))
        //                     scheduleClaims[claim].lines![product].actualAmount = parseFloat(scheduleClaims[claim].lines![product].actualAmount.toFixed(2))

        //                     scheduleClaims[claim].lines![product].claimPlace = parseFloat(scheduleClaims[claim].lines![product].claimPlace.toFixed(2))
        //                     scheduleClaims[claim].lines![product].claimSupply = parseFloat(scheduleClaims[claim].lines![product].claimSupply.toFixed(2))
        //                     scheduleClaims[claim].lines![product].claimAmount = parseFloat(scheduleClaims[claim].lines![product].claimAmount.toFixed(2))
        //                 } else {
        //                     let claimLine: ClaimLineSummary = {
        //                         stockCode: tag.stockCode,
        //                         claimPlace:  tagPlaceTotal,
        //                         claimSupply: tagSupplyTotal,
        //                         actualPlace:  tagPlaceTotal,
        //                         actualSupply: tagSupplyTotal,
        //                         claimAmount: tagTotal,
        //                         actualAmount: tagTotal
        //                     }

        //                     scheduleClaims[claim].lines!.push(claimLine)
        //                 }
        //             }

        //         } else {
        //             let claimLine: ClaimLineSummary = {
        //                 stockCode: tag.stockCode,
        //                 claimPlace:  tagPlaceTotal,
        //                 claimSupply: tagSupplyTotal,
        //                 actualPlace:  tagPlaceTotal,
        //                 actualSupply: tagSupplyTotal,
        //                 claimAmount: tagTotal,
        //                 actualAmount: tagTotal
        //             }

        //             let previouslyClaimed = 0
        //             let quoteTotal = 0

        //             if(claimData) {
        //                 claimData.forEach((claim: JobClaimsData) => {
        //                     claim.claims.forEach((c: JobScheduleClaims) => {
        //                         if(c.scheduleNo === tag.scheduleNo) {
        //                             previouslyClaimed += c.claimAmount
        //                         }
        //                     })
        //                 })
        //             }

        //             if(quoteData) {
        //                 let quote = quoteData.find((quote: JobMeasureQuoteLineGroup) => quote.schedule === tag.scheduleNo)
        //                 if(quote) {
        //                     quoteTotal = parseFloat(quote.total.toFixed(2))
        //                 }
        //             }

        //             let claimSchedule: ClaimScheduleSummary = {
        //                 schedule: tag.scheduleName,
        //                 claimAmount: tagTotal,
        //                 adjustmentPercentage: 0,
        //                 adjustmentTotal: 0,
        //                 actualAmount: tagTotal,
        //                 lines: [claimLine],
        //                 previouslyClaimed: previouslyClaimed,
        //                 quoteTotal: quoteTotal,
        //                 claimPlace: tagPlaceTotal,
        //                 claimSupply: tagSupplyTotal,
        //                 actualPlace: tagPlaceTotal,
        //                 actualSupply: tagSupplyTotal,
        //                 scheduleNo: tag.scheduleNo
        //             }
        //             scheduleClaims.push(claimSchedule)
        //         }
        //     }
        // })

        //Calculate Previously Claimed
        let adjustmentPreviouslyClaimed = 0

        if (claimData) {
            claimData.forEach((claim: JobClaimsData) => {
                claim.claims.forEach((c: JobScheduleClaims) => {
                    if (c.schedule === "Claim Adjustment") {
                        adjustmentPreviouslyClaimed += c.claimAmount
                    }
                })
            })
        }

        //Calculate Schedule Level Variation


        //Add Claim Adjustment Line
        let claimAdjustment: ClaimScheduleSummary = {
            schedule: "Claim Adjustment",
            claimAmount: 0,
            adjustmentPercentage: 0,
            adjustmentTotal: 0,
            actualAmount: 0,
            quoteTotal: 0,
            claimPlace: 0,
            claimSupply: 0,
            actualPlace: 0,
            actualSupply: 0,
            previouslyClaimed: adjustmentPreviouslyClaimed,
            scheduleNo: 0,
            reasonCodeId: '',
            displayText: '',
            comment: getCommentFromJobScheduleClaims(0, 'Claim Adjustment'),
            isVariationSubSchedule: false,
            actualLabour: 0,
            claimLabour: 0
        }

        totalClaims.push(claimAdjustment)

        // total += labourTotal

        //Set Grand Total Summary Line.

        const placeTotal = totalClaims.reduce((total, claim) => total + claim.actualPlace, 0)
        const supplyTotal = totalClaims.reduce((total, claim) => total + claim.actualSupply, 0)
        const total = parseFloat((placeTotal + supplyTotal).toFixed(2))
        // console.log(scheduleClaims)
        setClaimSummary({
            claimPlace: placeTotal,
            claimSupply: supplyTotal,
            claimTotal: total,
            actualPlace: placeTotal,
            actualSupply: supplyTotal,
            actualTotal: total,
            adjustmentType: 'none',
            adjustmentComment: '',
            schedules: totalClaims,
             claimLabour: 0,
            actualLabour: 0
        })
    }

    const formatLabourData = (data: Array<JobLabourInfo>) => {
        let activityGroups = Array<JobLabourTableData>()
        data.forEach((labour: JobLabourInfo) => {
            let index = activityGroups.findIndex((i: JobLabourTableData) => i.activityCode === labour.activityCode && i.activityName === labour.activityName)
            if (index >= 0) {
                activityGroups[index].totalHours += labour.quantity
                activityGroups[index].labour.push(labour)
            } else {
                let activityGroup: JobLabourTableData = {
                    activityCode: labour.activityCode,
                    activityName: labour.activityName,
                    totalHours: labour.quantity,
                    numEmployees: 0,
                    isClaimed: true,
                    labour: [labour]
                }
                activityGroups.push(activityGroup)
            }
        })

        activityGroups.forEach((group: JobLabourTableData) => {
            let employees = group.labour.map(a => a.employeeCode);
            group.numEmployees = new Set(employees).size;

            if (group.labour.some((labour: JobLabourInfo) => labour.isClaimed)) {
                group.isClaimed = true
            } else {
                group.isClaimed = false
            }
        })
        setLabourTableData(activityGroups)
    }

    const calculateLabourClaim = (labourData: Array<JobLabourInfo>) => {

        let labourTotal = 0;

        labourData.forEach((labour: JobLabourInfo) => {
            if (labour.isClaimed) {
                labourTotal += labour.quantity * 20
            }
        })

        labourTotal = parseFloat(labourTotal.toFixed(2))

        let labourPreviouslyClaimed = 0

        if (claimData) {
            claimData.forEach((claim: JobClaimsData) => {
                claim.claims.forEach((c: JobScheduleClaims) => {
                    if (c.schedule === "Labour") {
                        labourPreviouslyClaimed += c.claimAmount
                    }
                })
            })
        }

        let labourClaim: ClaimScheduleSummary = {
            schedule: "Labour",
            claimAmount: labourTotal,
            adjustmentPercentage: 0,
            adjustmentTotal: 0,
            actualAmount: labourTotal,
            quoteTotal: 0,
            claimPlace: 0,
            claimSupply: 0,
            actualPlace: 0,
            actualSupply: 0,
            previouslyClaimed: labourPreviouslyClaimed,
            scheduleNo: 0,
            reasonCodeId: '',
            displayText: '',
            comment: getCommentFromJobScheduleClaims(0, 'Labour'),
            isVariationSubSchedule: false,
            actualLabour: 0,
            claimLabour: 0
        }

        // console.log(labourClaim)
        setLabourSummary(labourClaim)
    }

    return (
        <Dialog fullWidth maxWidth={'lg'} open={open} disableBackdropClick onClose={() => {
            handleDialog(false)
            setClaimEditMode(false)
            setSelectedClaimID(undefined)
        }} >
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1, padding: "8px 32px" }}>
                    <h2>
                        New Claim
                    </h2>
                </div>
                <div>
                    <IconButton onClick={() => {
                        handleDialog(false)
                        setActiveStep(0)
                        setClaimEditMode(false)
                        setSelectedClaimID(undefined)
                    }}>
                        <Clear />
                    </IconButton>
                </div>

            </div>
            <DialogContent className={classes.dialogContent}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                    {steps.map((label: string, i: number) => (
                        <Step key={i}>
                            <StepLabel>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div style={{ padding: 16 }}>
                    {getStepContent(activeStep)}
                </div>
            </DialogContent>
        </Dialog>
    )
}

interface ClaimStep1FormProps {
    dispatchedTags: Array<JobTagDetailOrderGroup>;
    handleDialog: (open: boolean) => void;
    incrementActiveStep: () => void;
    claimSummary: JobClaimSummary | undefined
    handleTagPlaceTicked: (id: number) => void;
    handleOrderPlaceTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleSchedulePlaceTicked: (scheduleName: string, value: boolean) => void;
    handleTagSupplyTicked: (id: number) => void;
    handleOrderSupplyTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleScheduleSupplyTicked: (scheduleName: string, value: boolean) => void;
    setClaimEditMode: (editMode: boolean) => void;
    setSelectedClaimID: (claim: number | undefined) => void;
}

function ClaimStep1Form(props: ClaimStep1FormProps) {

    const [total, setTotal] = useState<string>("0.00")

    const onSubmit = () => {
        props.incrementActiveStep()
    }

    useEffect(() => {
        let total = 0;
        if (props.claimSummary) {

            props.claimSummary.schedules.forEach((schedule: ClaimScheduleSummary) => {
                if (schedule.schedule !== "Labour") {
                    if (schedule.actualAmount) {
                        total += schedule.actualAmount
                    }

                }
            })
        }
        setTotal(total.toFixed(2))

    }, [props.claimSummary])

    return (
        <div>
            {props.dispatchedTags.length > 0 ?
                <>
                    <div style={{ maxHeight: 600, overflow: "scroll" }}>
                        <DispatchedTagsTable tagDetailData={props.dispatchedTags} handleTagSupplyTicked={props.handleTagSupplyTicked} handleOrderSupplyTicked={props.handleOrderSupplyTicked} handleScheduleSupplyTicked={props.handleScheduleSupplyTicked} handleTagPlaceTicked={props.handleTagPlaceTicked} handleOrderPlaceTicked={props.handleOrderPlaceTicked} handleSchedulePlaceTicked={props.handleSchedulePlaceTicked} />
                    </div>
                    <h4>Total: ${total}</h4>
                </>
                :
                <div>
                    <h3>No Tags to claim</h3>
                </div>
            }

            <div style={{ display: "flex", marginTop: 16 }}>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <Button color="secondary" style={{ marginRight: 8 }} onClick={() => {
                        props.handleDialog(false)
                        props.setClaimEditMode(false)
                        props.setSelectedClaimID(undefined)
                    }} >
                        Cancel
                    </Button>

                    <Button onClick={() => onSubmit()} variant="contained" color="secondary">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface ClaimStep2FormProps {
    dispatchedTags: Array<JobTagDetailOrderGroup>;
    handleDialog: (open: boolean) => void;
    incrementActiveStep: () => void;
    claimSummary: JobClaimSummary | undefined
    handleTagPlaceTicked: (id: number) => void;
    handleOrderPlaceTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleSchedulePlaceTicked: (scheduleName: string, value: boolean) => void;
    handleTagSupplyTicked: (id: number) => void;
    handleOrderSupplyTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleScheduleSupplyTicked: (scheduleName: string, value: boolean) => void;
    setClaimEditMode: (editMode: boolean) => void;
    setSelectedClaimID: (claim: number | undefined) => void;
    decrementActiveStep: () => void;
}

function ClaimStep2Form(props: ClaimStep2FormProps) {

    const [total, setTotal] = useState<string>("0.00")

    // const onSubmit = () => {
    //     props.incrementActiveStep()
    // }

    useEffect(() => {
        let total = 0;
        if (props.claimSummary) {

            props.claimSummary.schedules.forEach((schedule: ClaimScheduleSummary) => {
                if (schedule.schedule !== "Labour") {
                    if (schedule.actualAmount) {
                        total += schedule.actualAmount
                    }

                }
            })
        }
        setTotal(total.toFixed(2))

    }, [props.claimSummary])
    return (
        <div>

            {props.dispatchedTags.length > 0 ?
                <>
                    <div style={{ maxHeight: 600, overflow: "scroll" }}>
                        <DispatchedTagsTable tagDetailData={props.dispatchedTags} handleTagSupplyTicked={props.handleTagSupplyTicked} handleOrderSupplyTicked={props.handleOrderSupplyTicked} handleScheduleSupplyTicked={props.handleScheduleSupplyTicked} handleTagPlaceTicked={props.handleTagPlaceTicked} handleOrderPlaceTicked={props.handleOrderPlaceTicked} handleSchedulePlaceTicked={props.handleSchedulePlaceTicked} />
                    </div>
                    <h4>Total: ${total}</h4>
                </>
                :
                <div>
                    <h3>No Tags to claim</h3>
                </div>
            }

            <div style={{ display: "flex", marginTop: 16 }}>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <Button onClick={() => props.decrementActiveStep()} color="secondary" style={{ marginRight: 8 }}>
                        Back
                    </Button>

                    <Button onClick={() => props.incrementActiveStep()} variant="contained" color="secondary">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface ClaimStep3FormProps {
    incrementActiveStep: () => void;
    decrementActiveStep: () => void;
}


function ClaimStep3Form({ incrementActiveStep, decrementActiveStep }: ClaimStep3FormProps) {
    return (
        <div>

            <h3>No Purchase Orders</h3>

            <div style={{ display: "flex", marginTop: 16 }}>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <Button onClick={() => decrementActiveStep()} color="secondary" style={{ marginRight: 8 }}>
                        Back
                    </Button>

                    <Button onClick={() => incrementActiveStep()} variant="contained" color="secondary">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface ClaimStep4FormProps {
    incrementActiveStep: () => void;
    decrementActiveStep: () => void;
    labourData: Array<JobLabourTableData>;
    labourSummary: ClaimScheduleSummary | undefined
    handleActivityUnclaim: (activityCode: string, value: boolean) => void;
    handleEmployeeUnclaim: (employeeCode: string, startTime: string) => void;
}

function ClaimStep4Form({ incrementActiveStep, decrementActiveStep, labourData, labourSummary, handleActivityUnclaim, handleEmployeeUnclaim }: ClaimStep4FormProps) {

    return (
        <div>
            {labourData.length > 0 ?
                <>
                    <div style={{ maxHeight: 600, overflow: "scroll" }}>
                        <UnclaimedLabourTable labourData={labourData} handleActivityUnclaim={handleActivityUnclaim} handleEmployeeUnclaim={handleEmployeeUnclaim} />
                    </div>
                    {labourSummary &&
                        <>
                            <h4>Previous Labour Total: ${labourSummary.previouslyClaimed}</h4>
                            <h4>Total: ${labourSummary.actualAmount}</h4>
                        </>
                    }
                </>
                :
                <h3>No Labour</h3>
            }

            <div style={{ display: "flex", marginTop: 16 }}>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <Button onClick={() => decrementActiveStep()} color="secondary" style={{ marginRight: 8 }}>
                        Back
                    </Button>

                    <Button onClick={() => incrementActiveStep()} variant="contained" color="secondary">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface ClaimStep5FormProps {
    handleDialog: (open: boolean) => void;
    jobId: string;
    user?: any | null
    dispatchedTags: Array<JobTagDetailInfo>;
    refreshData: () => void;
    decrementActiveStep: () => void;
    claimSummary: JobClaimSummary | undefined
    labourSummary: ClaimScheduleSummary | undefined
    setActiveStep: (step: number) => void;
    unclaimedLabour: Array<JobLabourInfo>;
    quoteData: Array<JobMeasureQuoteLineGroup>
    claimData: Array<JobClaimsData>;
    claimEditMode: boolean;
    setClaimEditMode: (editMode: boolean) => void;
    selectedClaimID: number | undefined
    setSelectedClaimID: (claim: number | undefined) => void;
    previousClaim: JobClaimsData | undefined
    subVariationTags: Array<JobTagDetailInfo>;
    variationTags: Array<JobTagDetailInfo>;
    claimContact: ClaimContactForm
}

function ClaimStep5Form({ handleDialog, jobId, user, dispatchedTags, refreshData, decrementActiveStep, claimSummary, labourSummary, setActiveStep, unclaimedLabour, quoteData, claimData, claimEditMode, setClaimEditMode, selectedClaimID, setSelectedClaimID, previousClaim, variationTags, subVariationTags, claimContact }: ClaimStep5FormProps) {

    const classes = useStyles()
    const methods = useForm<JobClaimSummary>()
    const [loading, setLoading] = useState<boolean>(false)
    const [apiError, setApiError] = useState<string>()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openPreviousClaim = Boolean(anchorEl);

    const onSubmit = (data: JobClaimSummary) => {
        if (data && user) {
            data.schedules.forEach((schedule: ClaimScheduleSummary, i: number) => {
                if (claimSummary?.schedules[i]) {
                    schedule.schedule = claimSummary?.schedules[i].schedule
                    schedule.scheduleNo = claimSummary?.schedules[i].scheduleNo
                    let adjTotal = schedule.claimAmount - schedule.actualAmount
                    schedule.adjustmentTotal = parseFloat(adjTotal.toFixed(2))
                }
            })

            if (labourSummary) {
                data.schedules.push(labourSummary)
            }

            if (methods.getValues("adjustmentType") === "percentage") {
                data.schedules.forEach((schedule: ClaimScheduleSummary) => {
                    schedule.adjustmentPercentage = parseFloat(Number(schedule.adjustmentPercentage).toFixed(2))
                })
            } else if (methods.getValues("adjustmentType") === "amount") {
                data.schedules.forEach((schedule: ClaimScheduleSummary) => {
                    schedule.claimAmount = parseFloat(Number(schedule.claimAmount).toFixed(2))
                })
            }

            // console.log(data)
            setLoading(true)

            if (claimEditMode) {
                if (selectedClaimID) {
                    let params = {
                        body: {
                            ...data,
                            hdrSeqNo: selectedClaimID,
                            jobSeqNo: parseInt(jobId.replace("EC", ""), 10),
                            dispatchedTags: [...dispatchedTags, ...subVariationTags, ...variationTags],
                            unclaimedLabour: unclaimedLabour,
                            createdBy: user.attributes.name,
                            
                        }
                    }

                    const size = new TextEncoder().encode(JSON.stringify(params)).length
                if (size > 6000000) {
                    //Upload to S3
                    var objKey =   `claim_${jobId.replace("EC", "")}`

                            Storage.put(objKey, JSON.stringify(params.body))
                            .then(() => {
                                const params = {
                                body:{
                                    claim: objKey
                                }
                                };
                                API.post("", "/encore/jobs/claims/s3/edit", params)
                                .then(() => {
                                    setLoading(false)
                                    setActiveStep(0)
                                    handleDialog(false)
                                    setClaimEditMode(false)
                                    setSelectedClaimID(undefined)
                                    refreshData()
                                })
                                .catch((error: any) => {
                                    setLoading(false)
                                    setApiError("Error: unable to process request")
                                    console.log("Error: unable to submit job claim", error)
                                })
                            }) // {key: "test.txt"}
                            .catch(() => {
                                setLoading(false)
                                setApiError("Error: unable to process request")
                                setLoading(false)
                            });
                } else{
                    API.post("", "/encore/jobs/editClaim", params)
                        .then(() => {
                            setLoading(false)
                            setActiveStep(0)
                            handleDialog(false)
                            setClaimEditMode(false)
                            setSelectedClaimID(undefined)
                            refreshData()
                        })
                        .catch((error: any) => {
                            setLoading(false)
                            setApiError("Error: unable to process request")
                            console.log("Error: unable to submit job claim", error)
                        })
                    
                }

                    
                }
            } else {
                let params = {
                    body: {
                        ...data,
                        jobSeqNo: parseInt(jobId.replace("EC", ""), 10),
                        dispatchedTags: [...dispatchedTags, ...subVariationTags, ...variationTags],
                        unclaimedLabour: unclaimedLabour,
                        createdBy: user.attributes.name,
                        ...claimContact
                    }
                }

                const size = new TextEncoder().encode(JSON.stringify(params)).length
                if (size > 6000000) {
                    //Upload to S3
                    var objKey =   `claim_${jobId.replace("EC", "")}`

                            Storage.put(objKey, JSON.stringify(params.body))
                            .then(() => {
                                const params = {
                                body:{
                                    claim: objKey
                                }
                                };
                                API.post("", "/encore/jobs/claims/s3/new", params)
                                .then(() => {
                                    setLoading(false)
                                    setActiveStep(0)
                                    handleDialog(false)
                                    setClaimEditMode(false)
                                    setSelectedClaimID(undefined)
                                    refreshData()
                                })
                                .catch((error: any) => {
                                    setLoading(false)
                                    setApiError("Error: unable to process request")
                                    console.log("Error: unable to submit job claim", error)
                                })
                            }) // {key: "test.txt"}
                            .catch(() => {
                                setLoading(false)
                                setApiError("Error: unable to process request")
                                setLoading(false)
                            });
                }else{
                    API.post("", "/encore/jobs/newClaim", params)
                    .then(() => {
                        setLoading(false)
                        setActiveStep(0)
                        handleDialog(false)
                        setClaimEditMode(false)
                        setSelectedClaimID(undefined)
                        refreshData()
                    })
                    .catch((error: any) => {
                        setLoading(false)
                        setApiError("Error: unable to process request")
                        console.log("Error: unable to submit job claim", error)
                    })
                }

                
            }

        } else {
            setApiError("Error: unable to process request")
            console.log("Error: unable to submit job claim")
        }
    }

    const calculateQuoteTotal = (): number => {
        let quoteTotal = 0;

        if (quoteData) {
            quoteData.forEach((quote: JobMeasureQuoteLineGroup) => {
                quoteTotal += quote.total
            })
        }

        return quoteTotal
    }

    const calculatePreviouslyClaimed = (): number => {
        let previouslyClaimed = 0;

        if (claimData) {
            claimData.forEach((claim: JobClaimsData) => {
                previouslyClaimed += claim.claimAmount
            })
        }

        return previouslyClaimed
    }

    const handlePreviousClaimOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePreviousClaimClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {claimSummary &&
                <>
                    <FormProvider {...methods}>

                        {(claimEditMode && previousClaim) &&
                            <div style={{ display: "flex", marginBottom: 16 }}>
                                <Typography variant="subtitle1" style={{ flexGrow: 1 }}>
                                    NOTE: All adjustments have been reset
                                </Typography>
                                <div style={{ display: "flex" }} onMouseEnter={handlePreviousClaimOpen} onMouseLeave={handlePreviousClaimClose}>
                                    <Typography variant="subtitle1">
                                        View Previous Adjustments
                                    </Typography>
                                    <InfoOutlined style={{ marginLeft: 8 }} />
                                </div>

                                <Popover
                                    id="mouse-over-popover"
                                    className={classes.popover}
                                    classes={{
                                        paper: classes.paper,
                                    }}
                                    open={openPreviousClaim}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePreviousClaimClose}
                                    disableRestoreFocus>

                                    <TableContainer component={Paper} >
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Schedule</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Actual Place</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Actual Supply</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Actual Amount</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Adjustment</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Claim Place</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Claim Supply</TableCell>
                                                    <TableCell className={classes.dollarAmountCell}>Claim Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {previousClaim.claims.map((claim: JobScheduleClaims, idx: number) => (
                                                    <>
                                                        {claim.schedule !== "Labour" &&
                                                            <StyledTableRow key={idx}>
                                                                <StyledTableCell>{claim.schedule}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.actualPlace}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.actualSupply}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.actualAmount}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>{claim.adjustmentPercentage}%</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.claimPlace}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.claimSupply}</StyledTableCell>
                                                                <StyledTableCell className={classes.dollarAmountCell}>${claim.claimAmount}</StyledTableCell>
                                                            </StyledTableRow>
                                                        }
                                                    </>
                                                ))}
                                                <StyledTableRow>
                                                    <StyledTableCell>Total</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.actualPlace}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.actualSupply}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.actualAmount}</StyledTableCell>
                                                    <StyledTableCell></StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.claimPlace}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.claimSupply}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${previousClaim.claimAmount}</StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Popover>
                            </div>
                        }

                        <FormControl
                            className={classes.formInput}>
                            <FormLabel className={classes.label}>Adjustment Type</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <Select
                                        variant="filled"
                                        value={value}
                                        onChange={(e) => {
                                            let val = e.target.value as string
                                            onChange(val)
                                            methods.clearErrors()
                                            claimSummary.adjustmentType = val
                                            methods.reset(claimSummary)
                                        }}>
                                        <MenuItem value={'none'}>None</MenuItem>
                                        <MenuItem value={'percentage'}>Percentage</MenuItem>
                                        <MenuItem value={'amount'}>Amount</MenuItem>
                                    </Select>
                                )}
                                name="adjustmentType"
                                control={methods.control}
                                defaultValue={claimSummary.adjustmentType}
                            />
                        </FormControl>

                        {(methods.watch("adjustmentType") === "percentage" || methods.watch("adjustmentType") === "amount") &&
                            <FormControl
                                className={classes.formInput}>

                                <FormLabel className={classes.label}>Adjustment Comment</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            placeholder="Enter comment"
                                            fullWidth
                                            variant="outlined"
                                            multiline
                                            rows={2} />
                                    }
                                    name="adjustmentComment"
                                    control={methods.control}
                                    defaultValue=""
                                />
                            </FormControl>
                        }

                        <TableContainer style={{ marginBottom: 16 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell className={classes.cell} style={{ width: 240 }}>Schedule</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.dollarAmountCell}`} style={{ width: 85 }}>Quote Total</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.dollarAmountCell}`} style={{ paddingRight: 8 }}>Previously Claimed</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Actual Place</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Actual Supply</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Actual Amount</StyledTableCell>
                                        {methods.watch("adjustmentType") === "percentage" &&
                                            <StyledTableCell className={`${classes.cell} ${classes.adjustmentInput} ${classes.dollarAmountCell}`}>Adjustment</StyledTableCell>
                                        }
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Claim Place</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Claim Supply</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.claimInputs} ${classes.dollarAmountCell}`}>Claim Amount</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {claimSummary.schedules.map((schedule: ClaimScheduleSummary, i: number) => (
                                        <React.Fragment key={i}>
                                            {schedule.schedule !== "Labour" &&
                                                <ClaimSummaryRow
                                                    schedule={schedule}
                                                    i={i} />
                                            }
                                        </React.Fragment>
                                    ))}
                                    <StyledTableRow style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                                        <StyledTableCell className={classes.cell}>
                                            <h3>Total</h3>
                                        </StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.totalValue} ${classes.dollarAmountCell}`}>${calculateQuoteTotal().toFixed(2)}</StyledTableCell>
                                        <StyledTableCell className={`${classes.cell} ${classes.totalValue} ${classes.dollarAmountCell}`} style={{ paddingRight: 8 }}>${calculatePreviouslyClaimed().toFixed(2)}</StyledTableCell>
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`actualPlace`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.actualPlace.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`actualSupply`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.actualSupply.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`actualTotal`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.actualTotal.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        {methods.watch("adjustmentType") === "percentage" &&
                                            <StyledTableCell className={classes.cell}></StyledTableCell>
                                        }
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`claimPlace`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.claimPlace.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`claimSupply`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.claimSupply.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell className={classes.cell}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        inputProps={{
                                                            className: `${classes.field} ${classes.totalValue}`
                                                        }}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                        }}
                                                        disabled
                                                        variant="outlined" />
                                                }
                                                name={`claimTotal`}
                                                control={methods.control}
                                                defaultValue={parseFloat(claimSummary.claimTotal.toFixed(2))}
                                            />
                                        </StyledTableCell>
                                        
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FormProvider>

                    <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}></div>
                        <div>
                            {apiError &&
                                <span style={{ color: "red" }}>{apiError}</span>
                            }
                            <Button onClick={() => decrementActiveStep()} color="secondary">
                                Back
                            </Button>
                            {loading ?
                                <Button variant="contained" color="secondary">
                                    <CircularProgress size={25} style={{ color: "white" }} />
                                </Button>
                                :
                                <Button variant="contained" color="secondary" onClick={methods.handleSubmit(onSubmit)} disabled={methods.watch("claimTotal", parseFloat(claimSummary.claimTotal.toFixed(2))) === 0}>
                                    Submit
                                </Button>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

interface ClaimSummaryRowProps {
    schedule: ClaimScheduleSummary;
    i: number
}

function ClaimSummaryRow({ schedule, i }: ClaimSummaryRowProps) {

    const classes = useStyles()
    const { watch, control, getValues, setValue, setError, clearErrors, errors } = useFormContext()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openLines = Boolean(anchorEl);

    const handleLinesOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const [showClaimCommentDialog, setShowClaimCommentDialog] = useState<boolean>(false);

    const handleClaimCommentDialogClose = (comment: string) => {
        setShowClaimCommentDialog(false);
        // console.log(comment)
        setValue(`schedules[${i}].comment`, comment, { shouldDirty: true })
    };

    const handleLinesClose = () => {
        setAnchorEl(null);
    };

    const calculateClaimTotal = () => {
        let total = 0;
        let placeTotal = 0;
        let supplyTotal = 0;
        let values = getValues()
        if (values) {
            values.schedules.forEach((schedule: ClaimScheduleSummary) => {
                if (typeof schedule.claimPlace === "string") {
                    placeTotal += parseFloat(schedule.claimPlace)
                } else {
                    placeTotal += schedule.claimPlace
                }

                if (typeof schedule.claimSupply === "string") {
                    supplyTotal += parseFloat(schedule.claimSupply)
                } else {
                    supplyTotal += schedule.claimSupply
                }
            })
            total = placeTotal + supplyTotal
            setValue("claimTotal", parseFloat(total.toFixed(2)))
            setValue("claimPlace", parseFloat(placeTotal.toFixed(2)))
            setValue("claimSupply", parseFloat(supplyTotal.toFixed(2)))
            
        }
    }

    return (
        <StyledTableRow>
            <StyledTableCell className={classes.cell} style={{ paddingLeft: 8 }}>
                {schedule.lines ?
                    <>
                        <div style={{ display: "flex" }} onMouseEnter={handleLinesOpen} onMouseLeave={handleLinesClose}>
                            <InfoOutlined style={{ marginRight: 10 }} />
                            {/* <div >{schedule.scheduleNo}</div> -  */}
                            <div style={{ flexGrow: 1 }}>{schedule.displayText}</div>
                        </div>

                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.paper,
                            }}
                            open={openLines}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handleLinesClose}
                            disableRestoreFocus>

                            <TableContainer component={Paper} >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Stockcode</TableCell>
                                            <TableCell className={classes.dollarAmountCell}>Actual Place</TableCell>
                                            <TableCell className={classes.dollarAmountCell}>Actual Supply</TableCell>
                                            <TableCell className={classes.dollarAmountCell}>Actual Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {schedule.lines.map((line: ClaimLineSummary, idx: number) => (
                                            <StyledTableRow key={idx}>
                                                <StyledTableCell>{line.stockCode}</StyledTableCell>
                                                <StyledTableCell className={classes.dollarAmountCell}>${line.actualPlace}</StyledTableCell>
                                                <StyledTableCell className={classes.dollarAmountCell}>${line.actualSupply}</StyledTableCell>
                                                <StyledTableCell className={classes.dollarAmountCell}>${line.actualAmount}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Popover>
                    </>
                    :
                    <span>
                        {schedule.schedule}
                    </span>
                }
            </StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.dollarAmountCell}`}>${schedule.quoteTotal.toFixed(2)}</StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.dollarAmountCell}`} style={{ paddingRight: 8 }}>${schedule.previouslyClaimed.toFixed(2)}</StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    as={
                        <TextField
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled
                            variant="outlined" />
                    }
                    name={`schedules[${i}].actualPlace`}
                    control={control}
                    defaultValue={parseFloat(schedule.actualPlace.toFixed(2))}
                />
            </StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    as={
                        <TextField
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled
                            variant="outlined" />
                    }
                    name={`schedules[${i}].actualSupply`}
                    control={control}
                    defaultValue={parseFloat(schedule.actualSupply.toFixed(2))}
                />
            </StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    as={
                        <TextField
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled
                            variant="outlined" />
                    }
                    name={`schedules[${i}].actualAmount`}
                    control={control}
                    defaultValue={parseFloat(schedule.actualAmount.toFixed(2))}
                />
            </StyledTableCell>
            {watch("adjustmentType") === "percentage" &&
                <>
                    {schedule.schedule !== "Variance" ?
                        <StyledTableCell className={`${classes.cell} ${classes.adjustmentInput}`}>
                            <Controller
                                render={({ onChange, value }) => (
                                    <TextField
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            clearErrors([`schedules.[${i}].adjustmentPercentage`])
                                            let input = e.target.value
                                            let found = input.match(/^-?\d+\.?\d{0,2}$/)
                                            if (found) {
                                                let val = parseFloat(found[0]);
                                                if (val < -100 || val > 100) {
                                                    setError(`schedules.[${i}].adjustmentPercentage`, { message: "Invalid input" })
                                                } else {
                                                    let actualPlace = parseFloat(getValues(`schedules[${i}].actualPlace`))
                                                    let actualSupply = parseFloat(getValues(`schedules[${i}].actualSupply`))
                                                    let actualAmount = parseFloat(getValues(`schedules[${i}].actualAmount`))

                                                    let percentagePlace = actualPlace + ((val / 100) * actualPlace)
                                                    let percentageSupply = actualSupply + ((val / 100) * actualSupply)
                                                    let percentageAmount = actualAmount + ((val / 100) * actualAmount)

                                                    setValue(`schedules[${i}].claimPlace`, parseFloat(percentagePlace.toFixed(2)))
                                                    setValue(`schedules[${i}].claimSupply`, parseFloat(percentageSupply.toFixed(2)))
                                                    setValue(`schedules[${i}].claimAmount`, parseFloat(percentageAmount.toFixed(2)))
                                                    calculateClaimTotal()
                                                }
                                            } else {
                                                setError(`schedules.[${i}].adjustmentPercentage`, { message: "Invalid input" })
                                            }
                                        }}
                                        inputProps={{
                                            className: classes.field
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                        variant="outlined"
                                        error={errors.schedules?.[i]?.adjustmentPercentage ? true : false} />
                                )}
                                name={`schedules[${i}].adjustmentPercentage`}
                                control={control}
                                defaultValue={schedule.adjustmentPercentage.toFixed(2)}
                            />
                        </StyledTableCell>
                        :
                        <StyledTableCell className={`${classes.cell}`}></StyledTableCell>
                    }
                </>
            }
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    render={({ onChange, value }) => (
                        <TextField
                            value={value}
                            onChange={(e) => {
                                onChange(e)
                                clearErrors([`schedules.[${i}].claimPlace`])
                                let input = e.target.value
                                let found: RegExpMatchArray | null
                                if (schedule.schedule === "Variance") {
                                    found = input.match(/^-?\d+\.?\d{0,2}$/)
                                } else {
                                    found = input.match(/^-?\d+\.?\d{0,2}$/)
                                }
                                if (found) {
                                    let val = parseFloat(found[0]);
                                    let claimSupply = parseFloat(getValues(`schedules[${i}].claimSupply`))
                                    let claimAmount = val + claimSupply

                                    setValue(`schedules[${i}].claimPlace`, parseFloat(val.toFixed(2)))
                                    setValue(`schedules[${i}].claimAmount`, parseFloat(claimAmount.toFixed(2)))

                                    calculateClaimTotal()
                                } else {
                                    setError(`schedules.[${i}].claimPlace`, { message: "Invalid input" })
                                }
                            }}
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            type="number"
                            disabled={watch("adjustmentType") !== "amount"}
                            variant="outlined"
                            error={errors.schedules?.[i]?.claimPlace ? true : false} />
                    )}
                    name={`schedules[${i}].claimPlace`}
                    control={control}
                    defaultValue={parseFloat(schedule.claimPlace.toFixed(2))}
                />
            </StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    render={({ onChange, value }) => (
                        <TextField
                            value={value}
                            onChange={(e) => {
                                onChange(e)
                                clearErrors([`schedules.[${i}].claimSupply`])
                                let input = e.target.value
                                let found: RegExpMatchArray | null
                                if (schedule.schedule === "Variance") {
                                    found = input.match(/^-?\d+\.?\d{0,2}$/)
                                } else {
                                    found = input.match(/^-?\d+\.?\d{0,2}$/)
                                }
                                if (found) {
                                    let val = parseFloat(found[0]);
                                    let claimPlace = parseFloat(getValues(`schedules[${i}].claimPlace`))

                                    let claimAmount = val + claimPlace
                                    setValue(`schedules[${i}].claimSupply`, parseFloat(val.toFixed(2)))
                                    setValue(`schedules[${i}].claimAmount`, parseFloat(claimAmount.toFixed(2)))
                                    calculateClaimTotal()
                                } else {
                                    setError(`schedules.[${i}].claimSupply`, { message: "Invalid input" })
                                }
                            }}
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled={watch("adjustmentType") !== "amount"}
                            variant="outlined"
                            type="number"
                            error={errors.schedules?.[i]?.claimSupply ? true : false} />
                    )}
                    name={`schedules[${i}].claimSupply`}
                    control={control}
                    defaultValue={parseFloat(schedule.claimSupply.toFixed(2))}
                />
            </StyledTableCell>
            <StyledTableCell className={`${classes.cell} ${classes.claimInputs}`}>
                <Controller
                    as={
                        <TextField
                            inputProps={{
                                className: classes.field
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            disabled
                            variant="outlined" />
                    }
                    name={`schedules[${i}].claimAmount`}
                    control={control}
                    defaultValue={parseFloat(schedule.claimAmount.toFixed(2))}
                />
            </StyledTableCell>
            <Controller
                    as={
                        <Input
                            
                            hidden={true}
                            type="hidden"
                             />
                    }
                    name={`schedules[${i}].comment`}
                    control={control}
                    
                    defaultValue={schedule.comment}
                />
                <Controller
                    as={
                        <Input
                            
                            hidden={true}
                            type="hidden"
                             />
                    }
                    name={`schedules[${i}].isVariationSubSchedule`}
                    control={control}
                    
                    defaultValue={schedule.isVariationSubSchedule}
                />
            <StyledTableCell className={classes.cell}>
                <Tooltip title="Add Comment">
                    <IconButton aria-label="Add Comment" onClick={()=>setShowClaimCommentDialog(true)}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <ClaimScheduleCommentDialog open={showClaimCommentDialog} initialComment={schedule.comment} onClose={handleClaimCommentDialogClose}></ClaimScheduleCommentDialog>
            </StyledTableCell>
        </StyledTableRow>
    )
}

interface DispatchedTagsTableProps {
    tagDetailData: Array<JobTagDetailOrderGroup>
    handleTagSupplyTicked: (id: number) => void;
    handleOrderSupplyTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleScheduleSupplyTicked: (scheduleName: string, value: boolean) => void;
    handleTagPlaceTicked: (id: number) => void;
    handleOrderPlaceTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleSchedulePlaceTicked: (scheduleName: string, value: boolean) => void;
}

function DispatchedTagsTable({ tagDetailData, handleTagSupplyTicked, handleOrderSupplyTicked, handleScheduleSupplyTicked, handleTagPlaceTicked, handleOrderPlaceTicked, handleSchedulePlaceTicked }: DispatchedTagsTableProps) {
    const classes = useStyles();
    const [editMode, setEditMode] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>('')

    const [tagFilteredData, setTagFilteredData] = useState<Array<JobTagDetailOrderGroup>>(tagDetailData)
    tagDetailData.sort((a, b) => a.sheetNo - b.sheetNo)

    useEffect(() => {
        if (filter.length > 0) {
            const sheets = filter.replace(' - ','-').replace(/,\s+/g,",").split(/[\n,\s+]/)
            let mySheets:Array<string> = []
            sheets.forEach((s)=>{
                if (s.includes("-")) {
                    const ss = s.split("-")
                    for (var i = parseInt(ss[0]); i <= parseInt(ss[1]); i++) {
                        mySheets.push(i.toString());
                    }
                }else{
                    mySheets.push(s)
                }
            })
            
            setTagFilteredData(tagDetailData.filter((tag) => {
                return mySheets.includes(tag.sheetNo.toString())
            }))

        } else {
            setTagFilteredData(tagDetailData)
        }


    }, [filter, tagDetailData])

    return (
        <>

            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <h3>Tags</h3>
                </div>
                <Toolbar>
                    <TextField
                        // className={classes.margin}
                        id="input-with-icon-textfield"
                        label="Search by Sheet No."
                        onChange={(e) => setFilter(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Toolbar>
                {/* <div>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode(true)}>
                        Edit
                        <Edit style={{marginLeft: 4}} />
                    </Button>
                </div> */}
            </div>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table size="small" aria-label="simple table">
                    <TableHead style={{ backgroundColor: "#EFFAFF" }}>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Sheet</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Promised</TableCell>
                            <TableCell>Total Weight</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Selected Tags</TableCell>
                            <TableCell>Supply</TableCell>
                            <TableCell>Place</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tagFilteredData.map((order: JobTagDetailOrderGroup, i: number) => (
                            // <DispatchedTagsScheduleRow key={idx} group={schedule} editMode={editMode} handleTagSupplyTicked={handleTagSupplyTicked} handleOrderSupplyTicked={handleOrderSupplyTicked} handleScheduleSupplyTicked={handleScheduleSupplyTicked} handleTagPlaceTicked={handleTagPlaceTicked} handleOrderPlaceTicked={handleOrderPlaceTicked} handleSchedulePlaceTicked={handleSchedulePlaceTicked} />
                            <DispatchedOrderRow key={i} order={order} editMode={editMode} scheduleName={order.scheduleName} handleTagSupplyTicked={handleTagSupplyTicked} handleOrderSupplyTicked={handleOrderSupplyTicked} handleTagPlaceTicked={handleTagPlaceTicked} handleOrderPlaceTicked={handleOrderPlaceTicked} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface DispatchedTagsScheduleRowProps {
    group: JobTagDetailScheduleGroup
    editMode: boolean
    handleTagSupplyTicked: (id: number) => void;
    handleOrderSupplyTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleScheduleSupplyTicked: (scheduleName: string, value: boolean) => void;
    handleTagPlaceTicked: (id: number) => void;
    handleOrderPlaceTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleSchedulePlaceTicked: (scheduleName: string, value: boolean) => void;
}

function DispatchedTagsScheduleRow({ group, editMode, handleTagSupplyTicked, handleOrderSupplyTicked, handleScheduleSupplyTicked, handleTagPlaceTicked, handleOrderPlaceTicked, handleSchedulePlaceTicked }: DispatchedTagsScheduleRowProps) {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{group.schedule}</strong>
                </TableCell>
                <TableCell><strong>{Math.round(group.totalWeight)}</strong></TableCell>
                <TableCell><strong>{group.totalTags}</strong></TableCell>
                <TableCell><strong>{group.selectedTags}</strong></TableCell>
                <TableCell><Checkbox checked={group.isSupplyClaimed} onClick={() => handleScheduleSupplyTicked(group.schedule, !group.isSupplyClaimed)} /></TableCell>
                <TableCell><Checkbox checked={group.isPlaceClaimed} onClick={() => handleSchedulePlaceTicked(group.schedule, !group.isPlaceClaimed)} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6">
                                Orders
                            </Typography>
                            <Table>
                                <TableHead style={{ backgroundColor: "#EFFAFF" }}>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Order ID</TableCell>
                                        
                                        <TableCell>Total Weight</TableCell>
                                        <TableCell>Tags</TableCell>
                                        <TableCell>Selected Tags</TableCell>
                                        <TableCell>Supply</TableCell>
                                        <TableCell>Place</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.orders.map((order: JobTagDetailOrderGroup, i: number) => (
                                        <DispatchedOrderRow key={i} order={order} editMode={editMode} scheduleName={group.schedule} handleTagSupplyTicked={handleTagSupplyTicked} handleOrderSupplyTicked={handleOrderSupplyTicked} handleTagPlaceTicked={handleTagPlaceTicked} handleOrderPlaceTicked={handleOrderPlaceTicked} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}


interface DispatchedOrderRowProps {
    order: JobTagDetailOrderGroup
    editMode: boolean
    scheduleName: string;
    handleTagSupplyTicked: (id: number) => void;
    handleOrderSupplyTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
    handleTagPlaceTicked: (id: number) => void;
    handleOrderPlaceTicked: (orderNo: string, scheduleName: string, value: boolean) => void;
}

function DispatchedOrderRow({ order, editMode, handleTagSupplyTicked, handleOrderSupplyTicked, handleTagPlaceTicked, handleOrderPlaceTicked, scheduleName }: DispatchedOrderRowProps) {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false);
    order.tags.sort((a, b) => a.tagNo - b.tagNo)

    return (
        <>
             <TableRow  style={{ backgroundColor: "#E3F2FD"}}>
                <TableCell colSpan={10} style={{ textAlign: "center"}}><strong>{ order.orderDescription}</strong></TableCell>
            </TableRow>
            <TableRow  style={{ backgroundColor: "#EFFAFF" }}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.sheetNo}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.controlCode}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.orderId}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{formatDate(order.orderPromisedDeliveryDate)}</strong>
                </TableCell>
                
                <TableCell><strong>{Math.round(order.totalWeight)}</strong></TableCell>
                <TableCell><strong>{order.totalTags}</strong></TableCell>
                <TableCell><strong>{order.selectedTags}</strong></TableCell>
                <TableCell><Checkbox checked={order.isSupplyClaimed} onClick={() => handleOrderSupplyTicked(order.orderId, scheduleName, !order.isSupplyClaimed)} /></TableCell>
                <TableCell><Checkbox checked={order.isPlaceClaimed} onClick={() => handleOrderPlaceTicked(order.orderId, scheduleName, !order.isPlaceClaimed)} /></TableCell>
            </TableRow>
           
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6">
                                Tags
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Schedule</StyledTableCell>
                                        <StyledTableCell>Tag No</StyledTableCell>
                                        <StyledTableCell>Stockcode</StyledTableCell>
                                        <StyledTableCell>Length</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                        <StyledTableCell>Weight</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Complexity</StyledTableCell>
                                        <StyledTableCell>Rate Applied</StyledTableCell>
                                        <StyledTableCell>Supply</StyledTableCell>
                                        <StyledTableCell>Place</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.tags.map((tag: JobTagDetailInfo) => (
                                        <DispatchedTagsRow key={tag.id} tag={tag} editMode={editMode} handleTagSupplyTicked={handleTagSupplyTicked} handleTagPlaceTicked={handleTagPlaceTicked} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

interface DispatchedTagRowProps {
    tag: JobTagDetailInfo
    editMode: boolean
    handleTagSupplyTicked: (id: number) => void;
    handleTagPlaceTicked: (id: number) => void;
}

function DispatchedTagsRow({ tag, editMode, handleTagSupplyTicked, handleTagPlaceTicked }: DispatchedTagRowProps) {

    const isPlaceTickable = (placed: string) => {
        return placed === "P"
    }

    return (
        <>
            <StyledTableRow>
                <StyledTableCell>{tag.scheduleNo}</StyledTableCell>
                <StyledTableCell>{tag.tagNo}</StyledTableCell>
                <StyledTableCell>{tag.stockCode}</StyledTableCell>
                <StyledTableCell>{tag.length}</StyledTableCell>
                <StyledTableCell>{tag.quantity}</StyledTableCell>
                <StyledTableCell>{Math.round(tag.weight)}</StyledTableCell>
                <StyledTableCell>{tag.currentTagStatus}</StyledTableCell>
                <StyledTableCell>{tag.shapeTypeDescr}</StyledTableCell>
                <StyledTableCell>{tag.rateApplied}</StyledTableCell>
                <StyledTableCell>{!tag.isSupplyHidden ? <Checkbox checked={tag.isSupplyClaimed} onClick={() => handleTagSupplyTicked(tag.id)} /> : <CheckCircleOutline />}</StyledTableCell>
                <StyledTableCell>{!tag.isPlaceHidden ? <Checkbox checked={tag.isPlaceClaimed} onClick={() => handleTagPlaceTicked(tag.id)} /> : <RemoveCircleOutline />}</StyledTableCell>
            </StyledTableRow>
        </>
    )
}

interface UnclaimedLabourTableProps {
    labourData: Array<JobLabourTableData>
    handleActivityUnclaim: (activityCode: string, value: boolean) => void;
    handleEmployeeUnclaim: (employeeCode: string, startTime: string) => void;
}

export function UnclaimedLabourTable({ labourData, handleActivityUnclaim, handleEmployeeUnclaim }: UnclaimedLabourTableProps) {

    const [editMode, setEditMode] = useState<boolean>(false)

    return (
        <>
            <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                    <h3>Unclaimed Labour</h3>
                </div>
                <div>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode(true)}>
                        Edit
                        <Edit style={{ marginLeft: 4 }} />
                    </Button>
                </div>
            </div>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Activity Code</TableCell>
                            <TableCell>Activity Name</TableCell>
                            <TableCell>Total Hours</TableCell>
                            <TableCell>No. Employees</TableCell>
                            <TableCell>Claim</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {labourData.map((group: JobLabourTableData, idx: number) => (
                            <LabourTableRow key={idx} group={group} editMode={editMode} handleActivityUnclaim={handleActivityUnclaim} handleEmployeeUnclaim={handleEmployeeUnclaim} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface LabourTableRowProps {
    group: JobLabourTableData
    editMode: boolean
    handleActivityUnclaim: (activityCode: string, value: boolean) => void;
    handleEmployeeUnclaim: (employeeCode: string, startTime: string) => void;
}

function LabourTableRow({ group, editMode, handleActivityUnclaim, handleEmployeeUnclaim }: LabourTableRowProps) {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{group.activityCode}</strong>
                </TableCell>
                <TableCell><strong>{group.activityName}</strong></TableCell>
                <TableCell><strong>{group.totalHours}</strong></TableCell>
                <TableCell><strong>{group.numEmployees}</strong></TableCell>
                <TableCell><Checkbox checked={group.isClaimed} onClick={() => handleActivityUnclaim(group.activityCode, !group.isClaimed)} /></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Employee Name</StyledTableCell>
                                        <StyledTableCell>Employee Code</StyledTableCell>
                                        <StyledTableCell>Start Time</StyledTableCell>
                                        <StyledTableCell>End Time</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                        <StyledTableCell>Claim</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.labour.map((labour: JobLabourInfo) => (
                                        <StyledTableRow key={labour.seqNo}>
                                            <StyledTableCell>{labour.employeeName}</StyledTableCell>
                                            <StyledTableCell>{labour.employeeCode}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(labour.startTime)}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(labour.endTime)}</StyledTableCell>
                                            <StyledTableCell>{labour.quantity}</StyledTableCell>
                                            <StyledTableCell><Checkbox checked={labour.isClaimed} onClick={() => handleEmployeeUnclaim(labour.employeeCode, labour.startTime)} /></StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}