import { API } from 'aws-amplify'
import  { useEffect, useState } from 'react'

const useFetch = <T = unknown>(url :string) =>{
    const [data, setData] = useState<T>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<unknown>(null)

    useEffect(()=> { 
        reFetch()

    }, [url])

    const reFetch = () => {
        setLoading(true)
        API.get("", url, {})
        .then((data: T) => {
            setData(data)
        })
        .catch((error: any) => {
            console.log("ERROR: fetching data for ", error)
            setError(error)
        }).finally(()=> {
            setLoading(false)
            }
        )

    }

    return {data, loading, error, reFetch}
}

export default useFetch