import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Grid, TextField, IconButton, InputAdornment, Card, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel, Link as MuiLink
} from "@material-ui/core"; 
import { Search, ClearAll, InsertDriveFileOutlined, OpenInNewOutlined } from "@material-ui/icons";
import {  useNavigate, Link } from "react-router-dom"
import { API } from "aws-amplify";
import { OpportunityFileDetail, OpportunityInfo } from '../types/OpportunityTypes';
import { OpportunitiesTable } from './opportunities/EncoreOpportunities';
import { LoadInfo, ActiveLoadsTable, DispatchedLoadsTable } from './loads/EncoreLoads';
import { JobsInfo, JobsTable } from './jobs/EncoreJobs';
import { TagInfo, TagsTable } from './tags/EncoreTags';
import { format } from 'date-fns';
import { 
    DataGrid, GridColDef, GridRowsProp, GridRowData, GridRowSelectedParams, 
    GridValueFormatterParams,
    GridCellParams,
 } from '@material-ui/data-grid';
 import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import { StyledTableCell, StyledTableRow } from '../../components/StyledTableComponents';
import { formatDate } from '../../utils/utils';

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        margin: {
            display: "flex",
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        dataGrid: {
            backgroundColor: "white"
        },
        table: {
            // minWidth: 1750
        },
    })
);

interface EncoreSearchDto {
    opportunities: Array<OpportunityInfo>
    opportunityFiles: Array<OpportunityFileDetail>
    activeLoads: Array<LoadInfo>
    dispatchedLoads: Array<LoadInfo>
    jobs: Array<JobsInfo>
    tags: Array<TagInfo>
    salesOrders: Array<JobsInfo>
}

interface Props {
    globalBranch: string
}

function EncoreSearch({ globalBranch }: Props) {

    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')

    const [opportunities, setOpportunities] = useState<Array<OpportunityInfo>>([])
    const [opportunityFiles, setOpportunityFiles] = useState<Array<OpportunityFileDetail>>([])
    const [activeLoads, setActiveLoads] = useState<Array<LoadInfo>>([])
    const [dispatchedLoads, setDispatchedLoads] = useState<Array<LoadInfo>>([])
    const [jobs, setJobs] = useState<Array<JobsInfo>>([])
    const [tags, setTags] = useState<Array<TagInfo>>([])
    const [reload, setReload] = useState<boolean>(false)
    const [isNoResults, setIsNoResults] = useState<boolean>(false)
    const [salesOrders, setSalesOrders] = useState<Array<JobsInfo>>([])

    useEffect(() => {
        let savedSearch = sessionStorage.getItem('encore-search')
        if(savedSearch) {
            setReload(true)
            setSearchInput(savedSearch)
        }
    }, [])

    useEffect(() => {
        if(reload) {
            searchItems()
            setReload(false)
        }
    }, [reload])

    useEffect(() => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if(!reload) {
                if (searchInput.length > 0) {
                    sessionStorage.setItem("encore-search", searchInput)
                    searchItems()
                } else {
                    sessionStorage.removeItem("encore-search")
                    clearAllItems()
                }
            }
        }, 1500);
    }, [searchInput])

    const searchItems = () => {
        setLoading(true)
        clearAllItems()
        API.get("", `/encore/all/search/${searchInput}`, {})
        .then((result: EncoreSearchDto) => {
            let noResults = true
            if(result.opportunities) {
                setOpportunities(result.opportunities)
                noResults = false
            }
            if(result.opportunityFiles) {
                setOpportunityFiles(result.opportunityFiles)
                noResults = false
            }
            if(result.activeLoads) {
                setActiveLoads(result.activeLoads)
                noResults = false
            }
            if(result.dispatchedLoads) {
                setDispatchedLoads(result.dispatchedLoads)
                noResults = false
            }
            if(result.jobs) {
                setJobs(result.jobs)
                noResults = false
            }
            if(result.tags) {
                setTags(result.tags)
                noResults = false
            }

            if (result.salesOrders) {
                setSalesOrders(result.salesOrders)
                noResults = false
                
            }
            setLoading(false)
            setIsNoResults(noResults)
        })
        .catch((error: any) => {
            console.log("Error: searching opportunities", error)
            setLoading(false)
        })
    }

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        setSearchInput(event.target.value)
    }

    const handleClearSearch = () => {
        sessionStorage.removeItem("encore-search")
        if(searchInput.length !== 0) {
            setSearchInput('')
            clearAllItems()
            setIsNoResults(false)
        }
    }

    const clearAllItems = () => {
        setOpportunities([])
        setOpportunityFiles([])
        setActiveLoads([])
        setDispatchedLoads([])
        setJobs([])
        setTags([])
    }

    type OrderType = 'asc' | 'desc';
    const [sortOrder, setSortOrder] = useState<OrderType>('desc');
    const [sortColumn, setSortColumn] = useState<string>('seqNo');

    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    function onRequestSort(event: React.MouseEvent<unknown, MouseEvent>, property: string ) {
        const isAsc = sortColumn === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortColumn(property);
        console.log(property , isAsc)
    }
 
    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Search
                </h2>
                <div className={classes.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Search"
                            onChange={handleSearch}
                            value={searchInput}
                            InputProps={{
                                className: classes.input,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton color="inherit" onClick={handleClearSearch}>
                                        <ClearAll />
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Divider/>
            {loading && 
                <LinearProgress 
                    style={{marginBottom: 16}} 
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            {isNoResults ?
                <Card style={{marginTop: 16}}>
                    <p style={{marginLeft: 8}}>
                        No results found.
                    </p>
                </Card>
            :
                <>
                    {!loading &&
                        <Grid container spacing={3}>
                            {(opportunities.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Opportunities</h3>
                                    <OpportunitiesTable globalBranch={globalBranch} opportunities={opportunities} />
                                </Grid>
                            }

                            {(opportunityFiles.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Opportunity Files</h3>
                                    <OpportunityFilesTable files={opportunityFiles} />
                                </Grid>
                            }
            
                            {(activeLoads.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Active Loads</h3>
                                    <ActiveLoadsTable globalBranch={globalBranch} loads={activeLoads} searchInput={searchInput} />
                                </Grid>
                            }
                        
                            {(dispatchedLoads.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Dispatched Loads</h3>
                                    <DispatchedLoadsTable globalBranch={globalBranch} loads={dispatchedLoads} searchInput={searchInput} />
                                </Grid>
                            }
            
                            {(jobs.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Jobs</h3>
                                    <JobsTable jobs={jobs} />
                                </Grid>
                            }

                            {(tags.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Tags</h3>
                                    <TagsTable tags={tags} />
                                </Grid>
                            }

                            {(salesOrders.length !== 0) &&
                                <Grid item xs={12}>
                                    <h3>Sales Orders</h3>
                                    <TableContainer component={Paper} style={{ marginTop: 16 }}>
                            <Table className={classes.table} size="small" aria-label="Opportunities Table">
                                <TableHead>
                                    <TableRow>
                                        
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'jobNo'} direction={sortOrder} onClick={createSortHandler("jobNo")}>Job No.</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'ctrlCode'} direction={sortOrder} onClick={createSortHandler("ctrlCode")}>Ctrl Code</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'jobTitle'} direction={sortOrder} onClick={createSortHandler("jobTitle")}>Job Title</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'accountName'} direction={sortOrder} onClick={createSortHandler("accountName")}>Account Name</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            ExoNet
                                        </StyledTableCell>
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {salesOrders.length !== 0 && salesOrders.map((oi: JobsInfo) => (
                                        <StyledTableRow key={oi.jobNo}>
                                            
                                            <StyledTableCell  component="th" scope="row" style={{width:120}}>
                                                <Link to={`/encore/jobs/${oi.jobNo}`}>
                                                {oi.jobNo} 
                                                </Link> 
                                            </StyledTableCell>
                                            <StyledTableCell>{oi.ctrlCode}</StyledTableCell>
                                            <StyledTableCell>{oi.jobTitle}</StyledTableCell>
                                            <StyledTableCell>{oi.accountName}</StyledTableCell>
                                            <StyledTableCell  component="th" scope="row" style={{width:120}}>
                                                <MuiLink href={`Exo://job(${oi.jobNo})`}>
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        < OpenInNewOutlined/>
                                                    </IconButton>
                                                </MuiLink> 
                                            </StyledTableCell>
                                            
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                                </Grid>
                            }
                        </Grid>
                    }
                </>
            }
        </div>
    )
}

export default EncoreSearch


interface OpportunityFilesTableProps {
    files: Array<OpportunityFileDetail>
}

function OpportunityFilesTable({ files }: OpportunityFilesTableProps) {

    const classes = useStyles()

    const navigate = useNavigate()

    const gridWrapperRef = useRef<HTMLDivElement>(null);
    
    // useLayoutEffect(() => {
    //     const gridDiv = gridWrapperRef.current;
    //     if (gridDiv){
    //         const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
    //         gridEl.style.height = '';
    //     }
    // });

    const fileColumns: GridColDef[] = [
        { field: 'id', headerName: ' ', width: 75, renderHeader: () => <InsertDriveFileOutlined />,
            renderCell: (params: GridCellParams) => (
                <div style={{marginTop: 20, width: 35}}>
                    <FileIcon extension={"" + getFileExtension(params.getValue(params.id, 'filename') as string)} {...defaultStyles[getFileExtension(params.getValue(params.id,'filename') as string)]} />
                </div>
            )},
        { field: 'oppSeqNo', headerName: 'Opportunity No', width: 130 },
        { field: 'folder', headerName: 'Folder', width: 150 },
        { field: 'filename', headerName: 'File', width: 550 },
        { field: 'uploadedAt', headerName: 'Uploaded At', width: 200, type: 'dateTime',
            valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as Date))},
        { field: 'uploadedBy', headerName: 'Uploaded By', width: 150 },
    ]

    function getTableRows(): GridRowsProp {
        let rows: GridRowData[] = []
        files?.forEach((f: OpportunityFileDetail) => {
            let obj: any = {
                ...f
            }
            rows.push(obj)
        })
        return rows
    }

    const formatDateString = (date: Date | string) => {
        if(typeof(date) === 'string') {
            return ""
        }
        return format(date, "dd/MM/yyyy HH:mm")
    }

    function getFileExtension(filename: string) : DefaultExtensionType {
        let strSplit = filename.split(".")
        let extension = strSplit[strSplit.length - 1]
        if(extension === "pdf") return "pdf"
        if(extension === "docx") return "docx"
        if(extension === "doc") return "doc"
        if(extension === "jpg") return "jpg"
        if(extension === "jpeg") return "jpeg"
        if(extension === "png") return "png"
        if(extension === "csv") return "csv"
        return "txt"
    }

    return (
        <div ref={gridWrapperRef}>
            <DataGrid  
                className={classes.dataGrid}
                autoHeight
                rows={getTableRows()} 
                columns={fileColumns} 
                pageSize={25}
                onRowSelected={(params: GridRowSelectedParams) => {
                    navigate(`/encore/opportunities/${params.data.oppSeqNo}`)
                }} />
        </div>

    )
}
