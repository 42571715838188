import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { 
    Paper, TableContainer, Table, TableHead, TableRow, Button,
    TableBody, TableCell, Card, CardContent, CardHeader, Typography, Grid, Checkbox, FormControlLabel,
    Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, FormControl, InputLabel, MenuItem, Select
} from "@material-ui/core";
import { Line } from "@reactchartjs/react-chart.js"
import { handleErrors } from '../../utils/errors';
import { Link } from "react-router-dom";
import moment from "moment";
import { StressTestFileAnalysis, StressTestSupplier, StressTestMonthInfo, StressTestFileInfo } from '../types/StressTestTypes';
import { API } from "aws-amplify";
import { type } from '@testing-library/user-event/dist/type';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        monthCard: {
            width: 750
        },
        tableContainer: {
            maxHeight: 500
        },
        supplierButton: {
            width: 200
        },
        includeCheckbox: {
            '&.MuiFormControlLabel-root': {
                marginRight: 0
            }
        },
        summaryTable: {
            width: 200
        }
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface Props {
    productName?: string | null
    active: boolean
    setLoading: (loading: boolean) => void
    fileInfo?: StressTestFileInfo
    user?: any | null
}

export default function LTQAnalysis({ productName, active, setLoading, fileInfo, user }: Props) {
    const classes = useStyles()

    const [chartData, setChartData] = useState<Object>()
    const [chartOptions, setChartOptions] = useState<Object>()
    const [fileAnalysis, setFileAnalysis] = useState<StressTestFileAnalysis>()
    const [fileSuppliers, setFileSuppliers] = useState<Array<StressTestSupplier>>()
    const [productSupplier, setSupplier] = useState<string>("")
    const [productManufacturer, setManufacturer] = useState<string>("")
    const [selectedMachineName, setSelectedMachineName] = useState<string>("")
    const [includeFile, setIncludeFile] = useState<boolean>(false)
    const [openIncludeDialog, setOpenIncludeDialog] = useState<boolean>(false)
    const [addToLTQ, setAddToLTQ] = useState<boolean | undefined>(fileInfo?.isLtqIncluded)

    useEffect(() => {
        if(active && productName) {
            const getFileSuppliers = () => {
                productName = productName?.substring(0, 5)

                setLoading(true)
                setChartData(undefined)
                setFileAnalysis(undefined)
                fetch(`/api/el/stressfiles/${productName}/suppliers`)
                .then(handleErrors)
                .then(res => res.json())
                .then((result: Array<StressTestSupplier>) => {
                    if(result) {
                        setFileSuppliers(result)
                        if(result.length !== 0) {
                            let supplier = localStorage.getItem('eurocorpSupplier')
                            let manufacturer = localStorage.getItem('eurocorpManufacturer')
                            let machineName = localStorage.getItem('eurocorpMachineName')
                            if(!supplier) {
                                supplier = result[0].supplier
                            }
                            if(!manufacturer) {
                                manufacturer = result[0].manufacturer
                            }
                            if(!machineName) {
                                machineName = result[0].machineName
                            }
                            fetch(`/api/el/stressfiles/${productName}/analysis/${supplier}/${manufacturer}/${machineName}`)
                            .then(handleErrors)
                            .then(res => res.json())
                            .then((result: StressTestFileAnalysis) => {
                                if(result) {
                                    setFileAnalysis(result)
                                    handleSelectedSupplier(supplier!!, manufacturer!!, machineName!!)
                                    chartFileAnalysis(result.chartData, result.chartData200Points)
                                }
                                setLoading(false)
                            })
                            .catch((error: any) => {
                                console.log("Error: fetching file analysis by supplier and manufacturer", error)
                                setLoading(false)
                            })
                        }
                    } else {
                        setLoading(false)
                    }
                })
                .catch((error: any) => {
                    console.log("Error fetching stress test suppliers", error)
                    setLoading(false)
                });
            }
            getFileSuppliers()
        }
    }, [active, productName, setLoading])

    const getAnalysis = (supplier: string, manufacturer: string, machineName:string) => {
        setLoading(true)
        setChartData(undefined)
        setFileAnalysis(undefined)
        productName = productName?.substring(0, 5)
        fetch(`/api/el/stressfiles/${productName}/analysis/${supplier}/${manufacturer}/${machineName}`)
        .then(handleErrors)
        .then(res => res.json())
        .then((result: StressTestFileAnalysis) => {
            if(result) {
                setFileAnalysis(result)
                chartFileAnalysis(result.chartData, result.chartData200Points)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching file analysis by supplier and manufacturer", error)
            setLoading(false)
        })
    }

    const getAnalysisIncludingFile = (supplier: string, manufacturer: string, machineName:string) => {
        if(fileInfo) {
            setLoading(true)
            setChartData(undefined)
            setFileAnalysis(undefined)
            productName = productName?.substring(0, 5)
            fetch(`/api/el/stressfiles/${productName}/include/${fileInfo?.seqNo}/analysis/${supplier}/${manufacturer}/${machineName}`)
            .then(handleErrors)
            .then(res => res.json())
            .then((result: StressTestFileAnalysis) => {
                if(result) {
                    setFileAnalysis(result)
                    chartFileAnalysis(result.chartData, result.chartData200Points)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching file analysis by supplier and manufacturer", error)
                setLoading(false)
            })
        }
    }

    function handleSelectedSupplier(supplier: string, manufacturer: string, machineName: string) {
        setSupplier(supplier)
        setManufacturer(manufacturer)
        setSelectedMachineName(machineName)
        localStorage.setItem('eurocorpSupplier', supplier)
        localStorage.setItem('eurocorpManufacturer', manufacturer)
        localStorage.setItem('eurocorpMachineName', machineName)
    }

    function chartFileAnalysis(data6M: Array<StressTestMonthInfo>, data200points: Array<StressTestMonthInfo>) {
        if(data6M) {
            let chartData: any = {};
            let labels: Set<string> = new Set();
            let datasets: Array<any> = [];
    
            let minKData6M: Array<number> = [];
            let maxKData6M: Array<number> = [];

            let minKData200Points: Array<number> = [];
            let maxKData200Points: Array<number> = [];

            let dataMax: number = 0;
            let dataMin: number = 500;
    
            data6M.forEach((month: StressTestMonthInfo) => {
                labels.add(month.month)
                minKData6M.push(month.kMin)
                maxKData6M.push(month.kMax)
                
                if(month.kMax > dataMax) {
                    dataMax = month.kMax
                }

                if(month.kMin < dataMin) {
                    dataMin = month.kMin
                }
            })

            data200points.forEach((month: StressTestMonthInfo) => {
                labels.add(month.month)
                minKData200Points.push(month.kMin)
                maxKData200Points.push(month.kMax)
                
                if(month.kMax > dataMax) {
                    dataMax = month.kMax
                }

                if(month.kMin < dataMin) {
                    dataMin = month.kMin
                }
            })

            let arrayLength = 0
            if(data6M.length >= data200points.length) { 
                arrayLength = data6M.length
            } else {
                arrayLength = data200points.length
            }
    
            let standardMaxData = new Array(arrayLength).fill(600);
            let standardMinData = new Array(arrayLength).fill(500);

            if (dataMax > 600) {
                dataMax = Math.ceil((dataMax + 50)/50)*50
            } else {
                dataMax = 650
            }

            if (dataMin < 500) {
                dataMin = Math.floor((dataMin - 50)/50)*50
            } else {
                dataMin = 450
            }
    
            let kMin6MDataset = {
                label: "K_Min_6m",
                data: minKData6M,
                fill: false,
                backgroundColor: 'rgb(0, 0, 255)',
                borderColor: 'rgba(0, 0, 255, 0.5)'
            }
    
            let kMax6MDataset = {
                label: "K_Max_6m",
                data: maxKData6M,
                fill: false,
                backgroundColor: 'rgb(255, 0, 0)',
                borderColor: 'rgba(255, 0, 0, 0.5)'
            }

            let kMin200ptDataset = {
                label: "K_Min_200pt",
                data: minKData200Points,
                fill: false,
                backgroundColor: 'rgb(255, 128, 0)',
                borderColor: 'rgba(255, 128, 0, 0.5)'
            }
    
            let kMax200ptDataset = {
                label: "K_Max_200pt",
                data: maxKData200Points,
                fill: false,
                backgroundColor: 'rgb(127, 0, 255)',
                borderColor: 'rgba(127, 0, 255, 0.5)'
            }
    
            let stdMinDataset = {
                label: "Standard_Min",
                data: standardMinData,
                fill: false,
                backgroundColor: 'rgb(38, 35, 84)',
                borderColor: 'rgba(38, 35, 84, 0.2)'
            }
    
            let stdMaxDataset = {
                label: "Standard_Max",
                data: standardMaxData,
                fill: false,
                backgroundColor: 'rgb(38, 35, 84)',
                borderColor: 'rgba(38, 35, 84, 0.2)'
            }
    
    
            datasets.push(stdMaxDataset, kMax6MDataset, kMin6MDataset, kMax200ptDataset, kMin200ptDataset, stdMinDataset)
    
            chartData.labels = Array.from(labels.values());
            chartData.datasets = datasets
            
            let options = {
                scales: {
                    yAxes: [
                    {
                        ticks: {
                            min: dataMin,
                            max: dataMax,
                            padding: 25
                        },
                    },
                    ],
                },
            }
    
            setChartData(chartData)
            setChartOptions(options)
        }
    }

    function getSelectedSupplierButton(supplier: StressTestSupplier) : boolean {
        return (productSupplier === supplier.supplier && productManufacturer === supplier.manufacturer && selectedMachineName === supplier.machineName) ? true : false;
    }

    function formatDate(date: string) : moment.Moment | string {
        if (date === "0000-00-00"){
            return "";
        }
        return date ? moment.parseZone(date).format('DD/MM/YY'): "";
    }

    const handleIncludeFileCheck = (checked: boolean) => {
        setIncludeFile(checked)
        if(checked) {
            getAnalysisIncludingFile(productSupplier, productManufacturer, selectedMachineName)
        } else {
            getAnalysis(productSupplier, productManufacturer,selectedMachineName)
        }
    } 

    const getAnalysisBySupplier = (supplier: string, manufacturer: string, machineName: string) => {
        if(fileInfo?.supplier !== supplier || fileInfo?.manufacturer !== manufacturer) {
            getAnalysis(supplier, manufacturer, machineName)
        } else {
            if(includeFile) {
                getAnalysisIncludingFile(supplier, manufacturer, machineName)
            } else {
                getAnalysis(supplier, manufacturer, machineName)
            }
        }
        setSupplier(supplier)
        setManufacturer(manufacturer)
        setSelectedMachineName(machineName)
        localStorage.setItem('eurocorpSupplier', supplier)
        localStorage.setItem('eurocorpManufacturer', manufacturer)
        localStorage.setItem('eurocorpMachineName', machineName)
    }
    
    return (
        <div>
            {productName && 
                <div>
                    <div style={{display: "flex"}}>
                        <div style={{flexGrow: 1}}>
                            <div>
                                <Typography variant="h4">
                                    {productName.substring(0, 5)}
                                </Typography>
                                {productSupplier !== "" &&
                                    <Typography variant="h6">
                                        <span>Supplier: </span><span style={{fontWeight: "bold"}}>{productSupplier}</span><span>  Manufacturer: </span><span style={{fontWeight: "bold"}}>{productManufacturer}</span> <span>  Machine: </span><span style={{fontWeight: "bold"}}>{fileSuppliers?.find((s)=> s.machineName === selectedMachineName)?.machineDesc}</span>
                                    </Typography>
                                }
                            </div>
                        </div>
                        <div>
                            <Grid container spacing={2} style={{width: 420}} justify="flex-end">
                            <FormControl style={{width: 350, paddingRight:8}}>
                                <InputLabel id="lbl-supplier-machine">Supplier-Location-Machine</InputLabel>
                                <Select
                                    labelId="lbl-supplier-machine"
                                    id="demo-simple-select"
                                    value={`${productSupplier}|${productManufacturer}|${selectedMachineName}`}
                                    onChange={(event:React.ChangeEvent<{ value: unknown }>) => {
                                        const selectedValue = (event.target.value as string).split("|")
                                            // const selectedValue = event?.target.value?.toString().split("|")
                                                if(selectedValue) {
                                                    getAnalysisBySupplier(selectedValue[0], selectedValue[1], selectedValue[2])
                                                }
                                            }
                                        }>
                                        {fileSuppliers && fileSuppliers.map((supplier: StressTestSupplier) => (
                                            <MenuItem 
                                            selected={getSelectedSupplierButton(supplier)} 
                                            value={`${supplier.supplier}|${supplier.manufacturer}|${supplier.machineName}`}>
                                                {`${supplier.supplier}${supplier.manufacturer}-${supplier.machineDesc}`}
                                            </MenuItem>
                                            ))}
                                    
                        
                                </Select>
                            </FormControl>
                                {/* {fileSuppliers && fileSuppliers.map((supplier: StressTestSupplier) => (
                                    <SupplierButton 
                                        supplier={supplier}
                                        getAnalysisBySupplier={getAnalysisBySupplier}
                                        key={`${supplier.supplier}${supplier.manufacturer}-${supplier.machineName}`}
                                        isSelected={getSelectedSupplierButton(supplier)} />
                                ))} */}
                            </Grid>
                            {!addToLTQ &&
                                <div style={{display: "flex"}}>
                                    <div style={{flexGrow: 1}}> </div>
                                    {(productSupplier.toUpperCase() === fileInfo?.supplier.toUpperCase() && productManufacturer.toUpperCase() === fileInfo?.manufacturer.toUpperCase()) &&
                                        <div>
                                            <div>
                                                <FormControlLabel
                                                    className={classes.includeCheckbox}
                                                    control={
                                                        <Checkbox
                                                            checked={includeFile}
                                                            onChange={(_, checked) => handleIncludeFileCheck(checked)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Show File on Chart"
                                                />
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                                <Button variant="contained" color="secondary" onClick={() => setOpenIncludeDialog(true)}>
                                                    Include in LTQ
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <IncludeLTQDialog openDialog={openIncludeDialog} handleDialog={setOpenIncludeDialog} file={fileInfo} getAnalysis={getAnalysis} setAddToLTQ={setAddToLTQ} user={user} />
                        </div>
                    </div>

                    {chartData && chartOptions &&
                        <Card style={{marginTop: 16, padding: 20}}>
                            <Line type="line" data={chartData} options={chartOptions} height={65}/>
                        </Card>
                    }

                    <div style={{display: "flex"}}>

                        {fileAnalysis?.chartData &&
                            <Card style={{marginTop: 16, marginRight: 16}} className={classes.monthCard}>
                                <CardHeader title="Monthly Results - 6 months data" />
                                <CardContent>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Month</StyledTableCell>
                                                <StyledTableCell>Tests</StyledTableCell>
                                                <StyledTableCell>Std Dev</StyledTableCell>
                                                <StyledTableCell>Rek Avg</StyledTableCell>
                                                <StyledTableCell>K Value</StyledTableCell>
                                                <StyledTableCell>AS Factor</StyledTableCell>
                                                <StyledTableCell>K Min</StyledTableCell>
                                                <StyledTableCell>K Max</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {fileAnalysis.chartData.length !== 0 && fileAnalysis.chartData.map((info: StressTestMonthInfo) => (
                                                <StyledTableRow key={info.month}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {info.month}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{info.tests}</StyledTableCell>
                                                    <StyledTableCell>{info.stdDev}</StyledTableCell>
                                                    <StyledTableCell>{info.rekAvg}</StyledTableCell>
                                                    <StyledTableCell>{info.kValue}</StyledTableCell>
                                                    <StyledTableCell>{info.asFactor}</StyledTableCell>
                                                    <StyledTableCell>{info.kMin}</StyledTableCell>
                                                    <StyledTableCell>{info.kMax}</StyledTableCell>
                                                </StyledTableRow>
                                            )) 
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }

                        {fileAnalysis?.stressTest6MonthSummary &&
                            <Card style={{marginTop: 16, marginRight: 16}}>
                                <CardHeader title="6 month summary" />
                                <CardContent>
                                <TableContainer component={Paper} className={classes.summaryTable}>
                                    <Table stickyHeader size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell variant="head">Tests</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.tests}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Std Dev</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.stdDev}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Rek Avg</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.rekAvg}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Value</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.kValue}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">AS Factor</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.asFactor}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Min</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.kMin}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Max</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.kMax}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">AGT</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.agt.toFixed(1)}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Min</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.ratioMin}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Max</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.ratioMax}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Mean</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest6MonthSummary.ratioMean}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }
                    </div>

                    <div style={{display: "flex", marginTop: 16}}>

                        {fileAnalysis?.chartData200Points &&
                            <Card style={{marginTop: 16, marginRight: 16}} className={classes.monthCard}>
                                <CardHeader title="Monthly Results - 200 data points" />
                                <CardContent>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Month</StyledTableCell>
                                                <StyledTableCell>Tests</StyledTableCell>
                                                <StyledTableCell>Std Dev</StyledTableCell>
                                                <StyledTableCell>Rek Avg</StyledTableCell>
                                                <StyledTableCell>K Value</StyledTableCell>
                                                <StyledTableCell>AS Factor</StyledTableCell>
                                                <StyledTableCell>K Min</StyledTableCell>
                                                <StyledTableCell>K Max</StyledTableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {fileAnalysis.chartData200Points.length !== 0 && fileAnalysis.chartData200Points.map((info: StressTestMonthInfo) => (
                                                <StyledTableRow key={info.month}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {info.month}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{info.tests}</StyledTableCell>
                                                    <StyledTableCell>{info.stdDev}</StyledTableCell>
                                                    <StyledTableCell>{info.rekAvg}</StyledTableCell>
                                                    <StyledTableCell>{info.kValue}</StyledTableCell>
                                                    <StyledTableCell>{info.asFactor}</StyledTableCell>
                                                    <StyledTableCell>{info.kMin}</StyledTableCell>
                                                    <StyledTableCell>{info.kMax}</StyledTableCell>
                                                </StyledTableRow>
                                            )) 
                                            }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }

                        {fileAnalysis?.stressTest200DataSummary &&
                            <Card style={{marginTop: 16}}>
                                <CardHeader title="200 point summary" />
                                <CardContent>
                                <TableContainer component={Paper} className={classes.summaryTable}>
                                    <Table stickyHeader size="small" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell variant="head">Tests</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.tests}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Std Dev</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.stdDev}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Rek Avg</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.rekAvg}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Value</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.kValue}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">AS Factor</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.asFactor}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Min</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.kMin}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">K Max</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.kMax}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">AGT</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.agt.toFixed(1)}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Min</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.ratioMin}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Max</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.ratioMax}</StyledTableCell>
                                            </TableRow>
                                            <TableRow>
                                                <StyledTableCell variant="head">Ratio Mean</StyledTableCell>
                                                <StyledTableCell variant="body">{fileAnalysis.stressTest200DataSummary.ratioMean}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }
                    </div>
                    
                    {fileAnalysis?.stressTestDetails && 
                        <Card style={{marginTop: 16}}>
                            <CardHeader title="Test Results" />
                            <CardContent>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <StyledTableCell>ID</StyledTableCell>
                                        <StyledTableCell>Product Name</StyledTableCell>
                                        <StyledTableCell>Supplier</StyledTableCell>
                                        <StyledTableCell>Batch No.</StyledTableCell>
                                        <StyledTableCell>Batch Qty</StyledTableCell>
                                        <StyledTableCell>Avg AGT %</StyledTableCell>
                                        <StyledTableCell>Avg RE</StyledTableCell>
                                        <StyledTableCell>Avg RM/RE</StyledTableCell>
                                        <StyledTableCell>Test Date</StyledTableCell>
                                        <StyledTableCell>Result</StyledTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {fileAnalysis.stressTestDetails.length !== 0 && fileAnalysis.stressTestDetails.map((file: StressTestFileInfo) => (
                                        <StyledTableRow key={file.seqNo}>
                                            <StyledTableCell>
                                                <Link to={`/eurolab/stressfiles/${file.seqNo}`} state={{tab: 0}}>
                                                    {file.seqNo}
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell>{file.productName}</StyledTableCell>
                                            <StyledTableCell>{file.supplier}</StyledTableCell>
                                            <StyledTableCell>{file.batchNumber}</StyledTableCell>
                                            <StyledTableCell>{file.batchQty}</StyledTableCell>
                                            <StyledTableCell>{file.avgAgt}</StyledTableCell>
                                            <StyledTableCell>{file.avgRe}</StyledTableCell>
                                            <StyledTableCell>{file.avgRmRe}</StyledTableCell>
                                            <StyledTableCell>{formatDate(file.reportDate)}</StyledTableCell>
                                            <StyledTableCell>{file.status}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    }
                </div>
            }

        </div>
    )
}

interface ButtonProps {
    supplier: StressTestSupplier
    getAnalysisBySupplier: (supplier: string, manufacturer: string, machineName: string) => void
    isSelected: boolean
}

function SupplierButton({ supplier, getAnalysisBySupplier, isSelected } : ButtonProps) {

    const classes = useStyles()

    function buttonColor() {
        if(isSelected) {
            return "red"
        }
        return "grey"
    }

    return (
        <Grid item xs={6}>
            <Button 
                variant="outlined" 
                className={classes.supplierButton} 
                onClick={() => getAnalysisBySupplier(supplier.supplier, supplier.manufacturer, supplier.machineName)} 
                style={{color: buttonColor(), borderColor: buttonColor()}}>
                {`${supplier.supplier}/${supplier.manufacturer}/${supplier.machineDesc}`}
            </Button>
        </Grid>
    )
}

interface IncludeLTQDialogProps {
    openDialog: boolean;
    handleDialog: (open: boolean) => void;
    file?: StressTestFileInfo
    getAnalysis: (supplier: string, manufacturer: string, machineName:string) => void
    setAddToLTQ: (add: boolean) => void
    user?: any | null
}
function IncludeLTQDialog({ openDialog, handleDialog, file, getAnalysis, setAddToLTQ, user }: IncludeLTQDialogProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const addFileToLTQ = () => {
        if(file && user) {
            setLoading(true)

            var params = {
                body: {
                    SeqNo: file.seqNo,
                    Username:user.attributes.name
                }
            }
    
            API.post("",  `/el/stressfiles/${file.seqNo}/addToLTQ`, params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                setAddToLTQ(true)
                getAnalysis(file.supplier, file.manufacturer, file.machineName)
            })
            .catch((error: any) => {
                console.log("Error: adding file to LTQ", error)
                setLoading(false)
                setError("Unable to add file to LTQ")
            })
        }
    }

    return (
        <Dialog open={openDialog} onClose={() => handleDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                Delete File
            </DialogTitle>
            <DialogContent>
                    <span>Are you sure you want to add this file to LTQ?</span>
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button color="secondary" onClick={() => handleDialog(false)}>
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => addFileToLTQ()} variant="contained" color="secondary">
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}