import React, { useState } from 'react'
import { JobClaimScheduleGroup, JobScheduleClaims } from '../../types/JobTypes';
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Paper, IconButton, TableContainer, Table, TableHead, TableRow, 
    TableBody, Collapse, Box, TableCell, Button
} from "@material-ui/core";
import {
    KeyboardArrowDown, KeyboardArrowUp
} from "@material-ui/icons";
import { 
    StyledTableCell, StyledTableRow 
} from '../../../components/StyledTableComponents';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        table: {
            minWidth: 650,
        },
        green: {
            color: "green"
        },
        red: {
            color: "red"
        }
    })
);

interface JobClaimByScheduleTableProps {
    claimData: Array<JobClaimScheduleGroup>
}

export function JobClaimByScheduleTable({ claimData }: JobClaimByScheduleTableProps) {

    const classes = useStyles()

    return (
        <>
            <TableContainer component={Paper} style={{marginTop: 16}}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Schedule</TableCell>
                        <TableCell>Actual Place</TableCell>
                        <TableCell>Actual Supply</TableCell>
                        <TableCell>Actual Total</TableCell>
                        <TableCell>Claim Place</TableCell>
                        <TableCell>Claim Supply</TableCell>
                        <TableCell>Claim Total</TableCell>
                        <TableCell>Adjustment Total</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {claimData.map((schedule: JobClaimScheduleGroup, idx: number) => (
                        <ClaimByScheduleTableRow key={idx} schedule={schedule} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface ClaimByScheduleTableRowProps {
    schedule: JobClaimScheduleGroup
}

function ClaimByScheduleTableRow({ schedule }: ClaimByScheduleTableRowProps) {

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false);

    const calculateAdjustment = (actual: number, claim: number): string => {
        let adjustment = claim - actual
        return adjustment.toFixed(2)
    }

    const getAdjustmentClass = (actual: number, claim: number) => {
        if(claim > actual) {
            return classes.green
        } else if (claim < actual) {
            return classes.red
        }
    }

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{schedule.schedule}</strong>
                </TableCell>
                <TableCell><strong>${schedule.actualPlace.toFixed(2)}</strong></TableCell>
                <TableCell><strong>${schedule.actualSupply.toFixed(2)}</strong></TableCell>
                <TableCell><strong>${schedule.actualTotal.toFixed(2)}</strong></TableCell>
                <TableCell><strong>${schedule.claimPlace.toFixed(2)}</strong></TableCell>
                <TableCell><strong>${schedule.claimSupply.toFixed(2)}</strong></TableCell>
                <TableCell><strong>${schedule.claimTotal.toFixed(2)}</strong></TableCell>
                <TableCell className={getAdjustmentClass(schedule.actualTotal, schedule.claimTotal)}><strong>${calculateAdjustment(schedule.actualTotal, schedule.claimTotal)}</strong></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>ID</StyledTableCell>
                                        <StyledTableCell>Actual Place</StyledTableCell>
                                        <StyledTableCell>Actual Supply</StyledTableCell>
                                        <StyledTableCell>Actual Amount</StyledTableCell>
                                        <StyledTableCell>Claim Place</StyledTableCell>
                                        <StyledTableCell>Claim Supply</StyledTableCell>
                                        <StyledTableCell>Claim Amount</StyledTableCell>
                                        <StyledTableCell>Adjustment Percentage</StyledTableCell>
                                        <StyledTableCell>Adjustment Amount</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {schedule.claims.map((c: JobScheduleClaims) => (
                                        <StyledTableRow key={c.id}>
                                            <StyledTableCell>{c.hdrSeqNo}</StyledTableCell>
                                            <StyledTableCell>${c.actualPlace.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>${c.actualSupply.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>${c.actualAmount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>${c.claimPlace.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>${c.claimSupply.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>${c.claimAmount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell>{c.adjustmentPercentage}%</StyledTableCell>
                                            <StyledTableCell className={getAdjustmentClass(c.actualAmount, c.claimAmount)}><strong>${c.adjustmentTotal.toFixed(2)}</strong></StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}