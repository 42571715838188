import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, FormControl, Dialog, DialogContent, CircularProgress,
    DialogActions, TextField, FormLabel, Typography
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import {
    OpportunityCustomerDetail, CustomerContactForm
} from '../../../types/OpportunityTypes';
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);


interface ContactDialogProps {
    openDialog: boolean;
    setOpenDialog: (open: boolean) => void
    customer: OpportunityCustomerDetail | undefined;
    getCustomers: () => void
    opportunityNo: string
}

export default function AddContactDialog({ openDialog, customer, setOpenDialog, getCustomers, opportunityNo }: ContactDialogProps) {

    const classes = useStyles()
    const { handleSubmit, control, errors, setValue, clearErrors } = useForm<CustomerContactForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>()
    const [roleOptions, setRoleOptions] = useState<Array<string>>([])
    const [rolesLoading, setRolesLoading] = useState<boolean>(false)

    useEffect(() => {
        if(openDialog) {
            setRolesLoading(true)
            API.get("", '/encore/opportunities/customers/roles', {})
            .then((response: Array<string>) => {
                if(response) {
                    setRoleOptions(response)
                }
                setRolesLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching customer contact roles", error)
                setRolesLoading(false)
            })
        }
    }, [openDialog])

    const onSubmit = (data: CustomerContactForm) => {
        console.log(data)
        if(customer) {
            var params = {
                body: {
                    seqNo: customer.seqNo,
                    opportunitySeqNo: opportunityNo,
                    ...data
                }
            }
    
            setLoading(true)
            API.post("", `/encore/opportunities/customers/addContact`, params)
            .then(() => {
                setLoading(false)
                setOpenDialog(false)
                getCustomers()
            })
            .catch((error: any) => {
                console.log("Error: adding customer contact", error)
                setLoading(false)
                setError("Error: unable to add customer contact.")
            })
        }        
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
            <DialogContent>
                <div style={{marginBottom: 16}}>
                    {customer ?
                        <Typography variant="h6">
                            Add Contact for {customer.customerName}
                        </Typography>
                    :
                        <Typography variant="h6">
                            Add Contact 
                        </Typography>
                    }
                </div>

                <FormControl
                    error={Boolean(errors.contactName)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Contact Name</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Contact Name"
                                fullWidth
                                variant="filled"
                                error={errors.contactName ? true : false}
                                helperText={errors.contactName?.message}/>
                        }
                        name="contactName"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.role)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Role</FormLabel>

                    <Controller
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                freeSolo
                                loading={rolesLoading}
                                options={roleOptions}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="filled" 
                                        fullWidth
                                        placeholder="Select a role"
                                        error={errors.role ? true : false}
                                        helperText={errors.role?.message} />
                                )}
                                onInputChange={(_, newInputValue) => {
                                    if(newInputValue.length !== 0) {
                                        setValue("role", newInputValue)
                                        clearErrors("role")
                                    }
                                }}
                                onChange={(_, data) => props.onChange(data)}/>
                        )}
                        name="role"
                        control={control}
                        rules={{
                            // required: "This is required"
                        }}
                        defaultValue={null}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.email)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Email</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Email"
                                fullWidth
                                variant="filled"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message}/>
                        }
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.phone)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Phone</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Phone"
                                fullWidth
                                variant="filled"
                                error={errors.phone ? true : false}
                                helperText={errors.phone?.message}/>
                        }
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>
                
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => setOpenDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Add
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}