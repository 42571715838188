import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Divider, Breadcrumbs, Typography, Button, Grid,
    TextField, IconButton, InputAdornment, Popover, Chip, LinearProgress, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel
} from "@material-ui/core";
import { Home, Add, Search, ClearAll, InfoOutlined, Cancel } from "@material-ui/icons";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { API } from "aws-amplify";
import OpportunityDetails from './OpportunityDetails';
import { OpportunityInfo } from '../../types/OpportunityTypes';
import {
    DataGrid, GridColDef, GridRowsProp, GridRowData,
    GridValueFormatterParams, GridCellParams, useGridParamsApi, GridRowParams
} from '@mui/x-data-grid';
import { format, parse as parseDate } from "date-fns";

import AddOpportunityDialog from './dialogs/AddOpportunityDialog';
import { parse } from 'url';
import { Autocomplete } from '@material-ui/lab';
import InfiniteScroll from 'react-infinite-scroller';
import { StyledTableCell, StyledTableRow } from '../../../components/StyledTableComponents';
import { formatDate } from '../../../utils/utils';
import AddNoteDialog from './dialogs/AddNoteDialog';


let sTimer: NodeJS.Timeout;

interface OpportunitiesResponse {
    opportunities: Array<OpportunityInfo>;
    opportunitiesCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            // minWidth: 1750
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        header: {
            display: "flex"
        },
        margin: {
            display: "flex",
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        formInput: {
            //marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        },
        popover: {
            marginTop: 8,
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
        },
        dataGrid: {
            backgroundColor: "white"
        },
        skillSearchAutocomplete: {
            marginTop: 16,
            marginBottom: 8,
            marginRight: 8,
            width: 400
        },
        selectedSkillChips: {
            display: "flex",
            flexWrap: "wrap"
        },
        selectedSkillChip: {
            marginRight: 8,
            marginBottom: 8,
            backgroundColor: "#fff9c4"
        },
        skillSearchButton: {
            marginTop: 16,
            marginBottom: 8
        },
    })
);


interface Props {
    user?: any | null
    globalBranch: string
}

export interface SkillType {
    skill: string;
    group: string;
}

export interface SkillGroup {
    Group: string;
    Skills: Array<string>;
}

type OrderType = 'asc' | 'desc';

const UseFocus = (): [React.RefObject<HTMLInputElement>, () => void] => {
    const htmlElRef = useRef<HTMLInputElement>(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

export default function EncoreOpportunities({ user, globalBranch }: Props) {
    const classes = useStyles();

    // let { path } = useRouteMatch()
    const location = useLocation()

    const [opportunities, setOpportunities] = useState<Array<OpportunityInfo>>([])
    // const [originalOpportunities, setOriginalOpportunities] = useState<Array<OpportunityInfo>>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [openAddOpportunityDialog, setOpenAddOpportunityDialog] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>('')

    const [skills, setSkills] = useState<Array<SkillType>>([])
    let jsonSkills = localStorage.getItem("selectedTags");
        let storedSkills: Array<string> = []
        if (jsonSkills) {
            storedSkills = JSON.parse(jsonSkills)
            
        }
    const [selectedTags, setSelectedTags] = useState<Array<string>>(storedSkills||[])
    const [selectedSkills, setSelectedSkills] = useState<Array<SkillType>>([])
    const [skillsLoading, setSkillsLoading] = useState<boolean>(false)
    const [initialLoad, setInitialLoad] = useState<boolean>(false)

    const [skillInputValue, setSkillInputValue] = useState<string>('skill:')
    const [showSkillsAutocomplete, setShowSkillsAutocomplete] = useState<boolean>(false)
    const [showAttributeAutocomplete, setShowAttributeAutocomplete] = useState<boolean>(true)
    const [showOtherAutocomplete, setShowOtherAutocomplete] = useState<boolean>(false)
    const [selectedAttribute, setSelectedAttribute] = useState<string>()
    const [otherInputValue, setOtherInputValue] = useState<string>()
    const [skillsAutoCompleteRef, setSkillsAutoCompleteFocus] = UseFocus()
    const [attributeAutoCompleteRef, setAttributeAutoCompleteFocus] = UseFocus()
    const [otherAutoCompleteRef, setOtherAutoCompleteFocus] = UseFocus()

    const [hasMoreOpportunities, setMoreOpportunities] = useState<boolean>(true)

    const [tag, setTag] = useState<string>()
    const [users, setUsers] = useState<Array<string>>([])

    const [shouldFocus, setShouldFocus] = useState<boolean>(false)

    const [skillSearch, setSkillSearch] = useState<boolean>(false)

    const [page, setPage] = useState<number>(0)
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const [sortOrder, setSortOrder] = useState<OrderType>('desc');
    const [sortColumn, setSortColumn] = useState<string>('seqNo');
    const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false)
    const [selectedOpportunityNumber, setSelectedOpportunityNumber] = useState<number>()
    

    useEffect(() => {
        // let jsonSkills = localStorage.getItem("selectedTags");
        // let storedSkills: Array<string> = []
        // if (jsonSkills) {
        //     storedSkills = JSON.parse(jsonSkills)
        //     setSelectedTags(storedSkills)
        // }
        // setLoading(true)
        // API.get("", "/encore/opportunities", {})
        // .then((response: Array<OpportunityInfo>) => {
        //     if(response) {
        //         setOpportunities(response)
        //         setOriginalOpportunities(response)
        //         let jsonSkills = localStorage.getItem("selectedTags");
        //         let storedSkills: Array<string> = []
        //         if(jsonSkills) {
        //             storedSkills = JSON.parse(jsonSkills)
        //             setSelectedTags(storedSkills)
        //         }
        //     }
        //     setLoading(false)
        // })
        // .catch((error: any) => {
        //     console.log("Error: fetching opportunities", error)
        //     setLoading(false)
        // })

        //Get Users 
        //TODO: Change to hook
        API.get("", "/encore/users", {})
            .then((response: Array<string>) => {
                if (response) {
                    setUsers(response)
                }
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunities", error)
            })



        // if(storedSkills.length > 0) {
        //     filterOpportunitiesByTags(storedSkills)
        // }

        setInitialLoad(true)

    }, [])

    // useEffect(() => {
    //     if (sTimer !== null) {
    //         clearTimeout(sTimer);
    //     }
    //     sTimer = setTimeout(() => {
    //         if (searchInput.length > 0) {
    //             setLoading(true)
    //             API.get("", `/encore/opportunities/search/${searchInput}`, {})
    //             .then((result: Array<OpportunityInfo>) => {
    //                 setOpportunities(result)
    //                 setLoading(false)
    //             })
    //             .catch((error: any) => {
    //                 console.log("Error: searching opportunities", error)
    //                 setLoading(false)
    //             })
    //         }
    //     }, 1500);
    // }, [searchInput])

    useEffect(() => {
        if (selectedTags.length === 0 && initialLoad) {
            setSelectedAttribute(undefined)
            setShowOtherAutocomplete(false)
            setShowSkillsAutocomplete(false)
            setShowAttributeAutocomplete(true)
            setShouldFocus(false)
            setLoading(true)
            setSkillSearch(false)
        }
    }, [selectedTags, initialLoad])

    useEffect(() => {
        getOpportunities()
    
    }, [selectedTags, globalBranch, sortColumn, sortOrder])

    useEffect(() => {
        if (showSkillsAutocomplete) {
            setSkillsAutoCompleteFocus()
        }
    }, [showSkillsAutocomplete])

    useEffect(() => {
        if (showAttributeAutocomplete && shouldFocus) {
            setAttributeAutoCompleteFocus()
        }
    }, [showAttributeAutocomplete, shouldFocus])

    useEffect(() => {
        if (showOtherAutocomplete) {
            setOtherAutoCompleteFocus()
        }
    }, [showOtherAutocomplete])

    // useEffect(() => {
    //     filterOpportunitiesByTags(selectedTags)
    // }, [originalOpportunities])

    useEffect(() => {
        let tags: Array<SkillGroup> = [{ Group: "Skill", Skills: ["Test1", "Test2"] }]
        if (tag === "segment") {
            tags = [{ Group: "Segment", Skills: ["Commercial", "Infrastructure", "Piling", "Precast", "Residential"] }]
        }
        if (tag === "type") {
            tags = [{ Group: "Type", Skills: ["Supply", "Supply, Prefab", "Supply, Install"] }]
        }
        if (tag === "assignedTo") {
            tags = [{ Group: "User", Skills: ["Unassigned", ...users] }]
        }
        if (tag === "status") {
            tags = [{ Group: "Type", Skills: ["New", "Pipeline", "Measured", "Quoting", "Not Quoting", "Submitted", "Following Up", "Won", "Lost"] }]
        }
        handleSkills(tags)
    }, [tag])

    const getOpportunities = () => {
        setMoreOpportunities(true);
        loadMoreOpportunities(1)
    }

    async function loadMoreOpportunities(pageNo: number) {
        let assignedTo: string = ""
        let assignedToTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("assignedTo")).map((tag: string) => tag.replace("assignedTo:", ""))
        if (assignedToTags.length > 0) {
            assignedTo = assignedToTags.join("|")
        }
    
        let status: string = ""
        let statusTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("status")).map((tag: string) => tag.replace("status:", ""))
        if (statusTags.length > 0) {
            status = statusTags.join("|")
        }

        //segment
        let segment: string = ""
        let segmentTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("segment")).map((tag: string) => tag.replace("segment:", ""))
        if (segmentTags.length > 0) {
            segment = segmentTags.join("|")
        }

        //type
        let type: string = ""
        let typeTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("type")).map((tag: string) => tag.replace("type:", ""))
        if (typeTags.length > 0) {
            type = typeTags.join("|")
        }

        //jobNo
        let jobNo: string = ""
        let jobNoTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("jobNo")).map((tag: string) => tag.replace("jobNo:", ""))
        if (jobNoTags.length > 0) {
            jobNo = jobNoTags.join("|")
        }

        //JobName
        let jobName: string = ""
        let jobNameTags: Array<string> = selectedTags.filter((tag: string) => tag.includes("jobName")).map((tag: string) => tag.replace("jobName:", ""))
        if (jobNameTags.length > 0) {
            jobName = jobNameTags.join("|")
        }


        const response = await API.get("", `/encore/opportunities?branch=${globalBranch}&offset=${(pageNo -1) * 50}&assignedTo=${assignedTo}&status=${status}&segment=${segment}&opportunityType=${type}&jobNo=${jobNo}&jobName=${jobName}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`, {})

        if (!response) {
            console.log("ERROR: fetching opportunities", response.status, response.statusText)
            return;
        }

        // const data: OpportunitiesResponse = await response.json()

        // console.log("response", response)

        if (response.opportunities !== null) {
            if(pageNo === 1) {
                setOpportunities([])
                setOpportunities(response.opportunities)
                if (response.opportunities.length === response.opportunitiesCount) {
                    setMoreOpportunities(false);
                }
            }else {
                let newTransactions = [...opportunities, ...response.opportunities]
                setOpportunities(newTransactions)
                if (newTransactions.length === response.opportunitiesCount) {
                    setMoreOpportunities(false);
                }
            }

            
        
        } else {
            setMoreOpportunities(false);
            if (pageNo === 1) {
                setOpportunities([])
            }
        }
    }

    const handleSkills = (skills: Array<SkillGroup>) => {
        let allSkills: Array<SkillType> = []
        skills.forEach((skillGroup: SkillGroup) => {
            skillGroup.Skills.forEach((skill: string) => {
                allSkills.push({ skill: `${tag}:${skill}`, group: skillGroup.Group })
            })
        })

        setSkills(allSkills)
        setSkillsLoading(false)

        let jsonSkills = localStorage.getItem("selectedTags");
        let storedSkills: Array<string> = []
        if (jsonSkills) {
            storedSkills = JSON.parse(jsonSkills)
            let clickedSkills: Array<SkillType> = []
            storedSkills.forEach((s: string) => {
                for (let i = 0; i < allSkills.length; i++) {
                    if (allSkills[i].skill === s) {
                        clickedSkills.push(allSkills[i])
                    }
                }
            })
            setSelectedSkills(clickedSkills)
        }
    }

    const handleDeleteSkill = (skill: string) => {
        let newSkills = selectedTags.filter((s: string) => (s !== skill))
        setSelectedTags(newSkills)
        localStorage.setItem('selectedTags', JSON.stringify(newSkills))
        setSelectedSkills(s => s.filter((ss: SkillType) => ss.skill !== skill))
    }

    // const filterOpportunitiesByTags = (tags: Array<string>) => {

    //     tags.sort()

    //     let regionalOpportunities = originalOpportunities?.filter((o: OpportunityInfo) => globalBranch === "All" ? true : o.branch.toLowerCase() === globalBranch.toLowerCase())
    //     setOpportunities(regionalOpportunities)
    //     if (tags.length > 0) {
    //         let filteredOpportunities: Array<OpportunityInfo> = regionalOpportunities
    //         let newOpportunities: Array<OpportunityInfo> = []

    //         const mAssigned = tags.filter((t) => t.includes("assignedTo:")).map((t) => t.split(":")[1] === "Unassigned" ? "" : t.split(":")[1].toLowerCase())
    //         const mStatus = tags.filter((t) => t.includes("status:")).map((t) => t.split(":")[1].toLowerCase())
    //         const mMarketSegment = tags.filter((t) => t.includes("segment:")).map((t) => t.split(":")[1].toLowerCase())
    //         const mType = tags.filter((t) => t.includes("type:")).map((t) => t.split(":")[1].toLowerCase())
    //         const mJobName = tags.filter((t) => t.includes("jobName:")).map((t) => t.split(":")[1].toLowerCase())
    //         const mJobNo = tags.filter((t) => t.includes("jobNo:")).map((t) => t.split(":")[1].toLowerCase())

    //         if (mAssigned.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mAssigned.includes(o.assignedTo.toLowerCase()))
    //         }
    //         if (mStatus.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mStatus.includes(o.status.toLowerCase()))
    //         }
    //         if (mMarketSegment.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mMarketSegment.includes(o.marketSegment.toLowerCase()))
    //         }
    //         if (mType.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mType.includes(o.type.toLowerCase()))
    //         }
    //         if (mJobName.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mJobName.includes(o.jobName.toLowerCase()))
    //         }
    //         if (mJobNo.length > 0) {
    //             filteredOpportunities = filteredOpportunities.filter((o) => mJobNo.includes(o.jobNo.toLowerCase()))
    //         }
    //         // tags.forEach((tag)=> {

    //         //     const sTag = tag.split(":")
    //         //     const key = sTag[0]
    //         //     const filterTag = sTag[1].toLowerCase()
    //         //     switch (key) {
    //         //         case "assignedTo":
    //         //             if(filterTag === "Unassigned"){
    //         //                 filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.assignedTo === ""))

    //         //             } else{

    //         //                 filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.assignedTo.toLowerCase() === filterTag))
    //         //             }
    //         //             break;

    //         //         case "status":
    //         //             filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.status.toLowerCase() === filterTag))
    //         //             break;

    //         //         case "segment":
    //         //             filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.marketSegment.toLowerCase() === filterTag))
    //         //             break;

    //         //         case "type":
    //         //             filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.type.toLowerCase() === filterTag))
    //         //             break;

    //         //         case "jobName":
    //         //             filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.jobName.toLowerCase().includes(filterTag)))
    //         //             break;

    //         //         case "jobNo":
    //         //                 filteredOpportunities = regionalOpportunities.filter((o: OpportunityInfo) => (o.jobNo === filterTag))
    //         //                 break;

    //         //         default:
    //         //             break;
    //         //     }
    //         //     newOpportunities = [...newOpportunities, ...filteredOpportunities]
    //         // })
    //         setOpportunities(filteredOpportunities)

    //     }
    // }

    function deadlineTextColor(date: string, status: string): string {

        if (status.toLowerCase() === "quoting" || status.toLowerCase() === "measured") {
            let formattedDate = new Date(Date.parse(date));

            let todaysDate = new Date();
            todaysDate.setDate(todaysDate.getDate() - 3);  //subtract two days

            if (todaysDate.getTime() >= formattedDate.getTime()) {
                return "red"
            }
            return "black";

        } else {
            return "black";
        }

    }

    function onRequestSort(event: React.MouseEvent<unknown, MouseEvent>, property: string ) {
        const isAsc = sortColumn === property && sortOrder === 'asc';
        setSortOrder(isAsc ? 'desc' : 'asc');
        setSortColumn(property);
        console.log(property , isAsc)
    }

    const handleNoteDialog = (open: boolean) => {
        setOpenAddNoteDialog(open)
        getOpportunities()
    }

    const onNoteClick = (opportunityNo: number) => {
        setSelectedOpportunityNumber(opportunityNo)
        setOpenAddNoteDialog(true)
    }



    return (
        <div>
            <div>
                <Breadcrumbs>
                    <Link to="/encore" className={classes.link}>
                        <Home className={classes.icon} />
                        Home
                    </Link>
                    <Typography color="textPrimary">Opportunities</Typography>
                </Breadcrumbs>

                <div >
                    <Grid container spacing={1} >
                        {/* <Grid item xs={3}>
                            <h2 style={{ flexGrow: 1 }}>
                                Opportunities
                            </h2>
                        </Grid>
                        <Grid item xs>
                        </Grid> */}
                        <Grid item  >


                            <div>
                                <div className={classes.header}>


                                    {showAttributeAutocomplete &&
                                        <Autocomplete
                                            className={classes.skillSearchAutocomplete}
                                            options={['assignedTo', 'segment', 'status', 'type', 'jobName', 'jobNo']}
                                            value={selectedAttribute}
                                            openOnFocus
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Select attribute to search on"
                                                    variant="outlined"
                                                    inputRef={attributeAutoCompleteRef} />
                                            )}
                                            renderTags={(_) => ""}
                                            onChange={(_, newValue, reason) => {
                                                if (reason === "select-option") {
                                                    if (newValue === "segment" || newValue === "type" || newValue === "assignedTo" || newValue === "status") {
                                                        setSkillInputValue(`${newValue}:`)
                                                        setTag(newValue)
                                                        setShowSkillsAutocomplete(true)
                                                        setShowAttributeAutocomplete(false)
                                                    } else {
                                                        if (newValue) {
                                                            setSelectedAttribute(newValue)
                                                            setOtherInputValue(`${newValue}:`)
                                                        }
                                                        setShowOtherAutocomplete(true)
                                                        setShowAttributeAutocomplete(false)
                                                    }
                                                }
                                            }} />
                                    }

                                    {showOtherAutocomplete &&
                                        <Autocomplete
                                            className={classes.skillSearchAutocomplete}
                                            freeSolo
                                            disableClearable
                                            options={[]}
                                            inputValue={otherInputValue}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    inputRef={otherAutoCompleteRef} />
                                            )}
                                            renderTags={(_) => ""}
                                            onChange={(_, newValue, reason) => {
                                                if (reason === "create-option" && newValue) {
                                                    //Make sure that input is valid and check that value hasn't been entered already
                                                    if (newValue.includes(`${selectedAttribute}:`) &&
                                                        newValue.length > (selectedAttribute!.length + 1)) {

                                                        setOtherInputValue(`${selectedAttribute}:`)
                                                        let insert: boolean = true;
                                                        for (let i = 0; i < selectedTags.length; i++) {
                                                            insert = false
                                                            if (selectedTags[i] === newValue) {
                                                                break
                                                            }
                                                            insert = true
                                                        }
                                                        if (insert) {
                                                            setSelectedTags(s => [...s, newValue])
                                                            localStorage.setItem('selectedTags', JSON.stringify([...selectedTags, newValue]))
                                                        }
                                                    } else {
                                                        return
                                                    }
                                                }
                                            }}
                                            onInputChange={(_, value, reason) => {
                                                if (reason === "input") {
                                                    setOtherInputValue(value)
                                                    if (value.length === 0) {
                                                        setSelectedAttribute(undefined)
                                                        setShowOtherAutocomplete(false)
                                                        setShowAttributeAutocomplete(true)
                                                        setShouldFocus(true)
                                                    }
                                                }
                                            }}
                                        />
                                    }

                                    {showSkillsAutocomplete &&
                                        <Autocomplete
                                            className={classes.skillSearchAutocomplete}
                                            multiple
                                            disableClearable
                                            openOnFocus
                                            selectOnFocus={false}
                                            loading={skillsLoading}
                                            options={skills}
                                            value={selectedSkills}
                                            inputValue={skillInputValue}
                                            getOptionSelected={(option, value) => {
                                                return (option.skill === value.skill && option.group === value.group)
                                            }}
                                            groupBy={(option) => option.group}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    inputRef={skillsAutoCompleteRef} />
                                            )}
                                            renderTags={(_) => ""}
                                            getOptionLabel={(option) => {
                                                return option.skill
                                            }}
                                            onChange={(_, newValue, reason) => {
                                                if (reason === "select-option") {
                                                    setSkillInputValue(`${tag}:`)
                                                    let newSkillList: Array<string> = [];
                                                    let insert: boolean = true;
                                                    newValue.forEach((s: SkillType) => {
                                                        for (let i = 0; i < selectedTags.length; i++) {
                                                            insert = false;
                                                            if (selectedTags[i] === s.skill) {
                                                                break;
                                                            }
                                                            insert = true;
                                                        }
                                                        if (insert) {
                                                            newSkillList.push(s.skill)
                                                        }
                                                    })
                                                    setSelectedTags(s => [...s, ...newSkillList])
                                                    setSelectedSkills(newValue)
                                                    localStorage.setItem('selectedTags', JSON.stringify([...selectedTags, ...newSkillList]))
                                                }
                                            }}
                                            onInputChange={(_, value, reason) => {
                                                if (reason === "input") {
                                                    setSkillInputValue(value)
                                                    if (value.length === 0) {
                                                        setShowSkillsAutocomplete(false)
                                                        setShowAttributeAutocomplete(true)
                                                        setShouldFocus(true)
                                                    }
                                                }
                                            }}
                                            renderOption={(option) => option.skill.split(":")[1]} />
                                    }

                                    {/* <Button 
                                            className={classes.skillSearchButton} 
                                            variant="contained" 
                                            color="primary" 
                                            style={{marginRight: 8}} 
                                            // onClick={() => getCandidatesBySkillSearch(selectedTags)}
                                            disabled={selectedTags.length === 0}>
                                            <Search />
                                        </Button> */}

                                    <Button
                                        className={classes.skillSearchButton}
                                        variant="contained"

                                        style={{ marginRight: 8 }}
                                        disabled={selectedTags.length === 0}
                                        onClick={() => {
                                            localStorage.removeItem('selectedTags');
                                            setSelectedTags([])
                                            setSelectedSkills([])
                                            setSelectedAttribute(undefined)
                                            setShowSkillsAutocomplete(false)
                                            setShowOtherAutocomplete(false)
                                            setShowAttributeAutocomplete(true)
                                            // getCandidates()
                                            setShouldFocus(false)
                                        }}>
                                        <ClearAll />
                                        Clear All
                                    </Button>
                                    <Button style={{ marginTop: 16, marginBottom: 8 }} variant="contained" color="secondary" onClick={() => setOpenAddOpportunityDialog(true)}>
                                        <Add />
                                        Add Opportunity
                                    </Button>
                                </div>
                                <div className={classes.selectedSkillChips}>
                                    {selectedTags.length !== 0 && selectedTags.map((skill: string) => (
                                        <Chip
                                            key={skill}
                                            className={classes.selectedSkillChip}
                                            label={skill}
                                            deleteIcon={<Cancel style={{ color: "#f57f17" }} />}
                                            onDelete={() => {
                                                handleDeleteSkill(skill)
                                            }} />
                                    ))}
                                </div>

                            </div>
                            {/* <TextField 
                                    id="input-with-icon-grid" 
                                    label="Search by Job Name or Customer"
                                    onChange={handleSearch}
                                    value={searchInput}
                                    InputProps={{
                                        className: classes.input,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton color="inherit" onClick={handleClearSearch}>
                                                <ClearAll />
                                            </IconButton>
                                        </InputAdornment>
                                    }} /> */}
                        </Grid>

                    </Grid>
                </div>



            </div>
            <Divider style={{ marginBottom: 16 }} />
            <div style={{ height: "100vh" }}>
                {/* <OpportunitiesTable loading={loading} globalBranch={globalBranch} opportunities={opportunities} /> */}
                {(hasMoreOpportunities || opportunities.length !== 0) &&
                    <InfiniteScroll
                        pageStart={page}
                        loadMore={loadMoreOpportunities}
                        hasMore={hasMoreOpportunities}
                        initialLoad={false}
                        loader={
                            <LinearProgress
                                className={classes.progressBackground}
                                classes={{ barColorPrimary: classes.progress }} key={0} />
                        }>
                        <TableContainer component={Paper} style={{ marginTop: 16 }}>
                            <Table className={classes.table} size="small" aria-label="Opportunities Table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Note</StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'seqNo'} direction={sortOrder} onClick={createSortHandler("seqNo")}>ID</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'job_no'} direction={sortOrder} onClick={createSortHandler("job_no")}>No.</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'deadline'} direction={sortOrder} onClick={createSortHandler("deadline")}>Deadline</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'est_start_date'} direction={sortOrder} onClick={createSortHandler("est_start_date")}>Start</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'job_name'} direction={sortOrder} onClick={createSortHandler("job_name")}>Job Name</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'market_segement'} direction={sortOrder} onClick={createSortHandler("market_segement")}>Market</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'type'} direction={sortOrder} onClick={createSortHandler("type")}>Type</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'branch'} direction={sortOrder} onClick={createSortHandler("branch")}>Branch</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'assigned_to'} direction={sortOrder} onClick={createSortHandler("assigned_to")}>Assigned</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TableSortLabel active={sortColumn === 'status'} direction={sortOrder} onClick={createSortHandler("status")}>Status</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            <TableSortLabel active={sortColumn === 'total'} direction={sortOrder} onClick={createSortHandler("total")}>Total</TableSortLabel>
                                        </StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {opportunities.length !== 0 && opportunities.map((oi: OpportunityInfo) => (
                                        <StyledTableRow key={oi.seqNo}>
                                            <StyledTableCell onClick={()=>onNoteClick(oi.seqNo)} >
                                                <OpportunityDescriptionPopover key={oi.seqNo} description={oi.notesText}  />
                                            </StyledTableCell>
                                            <StyledTableCell  component="th" scope="row" style={{width:120}}>
                                                <Link to={`/encore/opportunities/${oi.seqNo}`}>
                                                {oi.seqNo} 
                                                </Link> 
                                            </StyledTableCell>
                                            <StyledTableCell>{oi.jobNo}</StyledTableCell>
                                            <StyledTableCell style={{ color: deadlineTextColor(oi.deadline, oi.status) }}>{formatDate(oi.deadline)}</StyledTableCell>
                                            <StyledTableCell>{formatDate(oi.estStartDate)}</StyledTableCell>
                                            <StyledTableCell  style={{minWidth:300}}>{oi.jobName}</StyledTableCell>
                                            <StyledTableCell>{oi.marketSegment}</StyledTableCell>
                                            <StyledTableCell>{oi.type}</StyledTableCell>
                                            <StyledTableCell>{oi.branch}</StyledTableCell>
                                            <StyledTableCell style={{width:150}}>{oi.assignedTo}</StyledTableCell>
                                            <StyledTableCell style={{minWidth:150}}>{oi.status}</StyledTableCell>
                                            <StyledTableCell  style={{width:135}}>{`$${oi.measureTotal.toFixed(2)}`}</StyledTableCell>
                                            <StyledTableCell style={{minWidth:400}}>{oi.description}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </InfiniteScroll>
                }
                {(!hasMoreOpportunities && opportunities.length === 0) &&
                    <p>No records found</p>
                }
            </div>

            <AddOpportunityDialog
                openAddOpportunityDialog={openAddOpportunityDialog}
                handleDialog={setOpenAddOpportunityDialog}
                user={user}
                globalBranch={globalBranch}
                getOpportunities={getOpportunities}
            />

            <AddNoteDialog 
                openDialog={openAddNoteDialog} 
                handleNoteDialog={handleNoteDialog}
                user={user}
                oppNo={selectedOpportunityNumber?.toString()} />
        </div>
    )
}

interface OpportunitiesTableProps {
    loading?: boolean
    globalBranch: string
    opportunities: Array<OpportunityInfo>
}

export function OpportunitiesTable({ loading, globalBranch, opportunities }: OpportunitiesTableProps) {

    const classes = useStyles()

    const navigate = useNavigate()

    const gridWrapperRef = useRef<HTMLDivElement>(null);

    // useLayoutEffect(() => {
    //     const gridDiv = gridWrapperRef.current;
    //     if (gridDiv){
    //         const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
    //         gridEl.style.height = '';
    //     }
    // });

    const opportunitiesColumns: GridColDef[] = [
        {
            field: 'seqNo', headerName: 'ID', width: 100, renderCell: (params: GridCellParams) => (
                <>
                    <OpportunityDescriptionPopover  description={params.getValue(params.id, 'notesText') as string} />
                    <div>{params.value as string}</div>
                </>
            )
        },
        { field: 'jobNo', headerName: 'No.', width: 100 },
        {
            field: 'deadline', headerName: 'Deadline', width: 110, type: 'date',
            // valueFormatter: (params: GridValueFormatterParams) => formatDateString(params.value as string) ,
            renderCell: (params: GridCellParams) => (
                <div style={{ color: deadlineTextColor(params.value as string, params.getValue(params.id, 'status') as string) }}>
                    {formatDateString(params.value as string)}
                </div>
            )
        },
        {
            field: 'estStartDate', headerName: 'Start', width: 110, type: 'date',
            // valueFormatter: (params: GridValueFormatterParams) => formatDateString(params.value as string) ,
            renderCell: (params: GridCellParams) => (
                <div >
                    {formatDateString(params.value as string)}
                </div>
            )
        },
        { field: 'jobName', headerName: 'Job Name', width: 200 },
        { field: 'marketSegment', headerName: 'Market Segment', width: 150 },
        { field: 'type', headerName: 'Type', width: 130 },
        { field: 'branch', headerName: 'Branch', width: 125 },
        { field: 'assignedTo', headerName: 'Assigned To', width: 150 },
        { field: 'status', headerName: 'Status', width: 135 },
        {
            field: 'measureTotal', headerName: 'Total $', width: 135, align: 'right', type: 'number', renderCell: (params: GridCellParams) => (
                <div >
                    {'$' + parseFloat(params.value as string).toFixed(2) as string}
                </div>
            )
        },
        { field: 'description', headerName: 'Description', width: 400, type: 'string' },
        // valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as string)) },
    ]

    function getTableRows(): GridRowsProp {
        let rows: GridRowData[] = []
        // let filteredList = opportunities?.filter((o: OpportunityInfo) => globalBranch === "All" ? true : o.branch.toLowerCase() === globalBranch.toLowerCase())
        opportunities?.forEach((opp: OpportunityInfo) => {
            let obj: any = {
                id: opp.seqNo,
                ...opp
            }
            rows.push(obj)
        })
        return rows
    }

    const formatDateString = (date: string) => {
        if (!date || date === '0000-00-00') {
            return ''
        }
        const dt = parseDate(date, "yyyy-MM-dd", new Date())
        // return format(dt, "dd/MM/yyyy")
        return dt.toLocaleDateString()

    }

    function deadlineTextColor(date: string, status: string): string {

        if (status.toLowerCase() === "quoting" || status.toLowerCase() === "measured") {
            let formattedDate = new Date(Date.parse(date));

            let todaysDate = new Date();
            todaysDate.setDate(todaysDate.getDate() - 3);  //subtract two days

            if (todaysDate.getTime() >= formattedDate.getTime()) {
                return "red"
            }
            return "black";

        } else {
            return "black";
        }

    }

    return (
        <div >
            <DataGrid
                className={classes.dataGrid}
                autoHeight
                loading={loading}
                rows={getTableRows()}
                columns={opportunitiesColumns}
                pageSize={25}
                sortModel={[
                    {
                        field: 'seqNo',
                        sort: 'desc',
                    },
                ]}
                onRowClick={(params: GridRowParams) => {
                    navigate(`/encore/opportunities/${params.id}`)
                }}
            />
                
        </div>

    )
}

interface OpportunityDescriptionPopoverProps {
    description: string
    setSelectedOpportunityNo?: (oppNo: string) => void
}

function OpportunityDescriptionPopover({ description  }: OpportunityDescriptionPopoverProps) {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const openInfo = Boolean(anchorEl);

    

    const handleInfoOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleInfoClose = () => {
        setAnchorEl(null);
    };


    const notes = description.split("<br>").map((note, index) =>
        <li key={index}>{note}</li>
    );
    return (
        <>
            <div onMouseEnter={handleInfoOpen} onMouseLeave={handleInfoClose} style={{cursor: 'pointer'}}>
            {description ? <InfoOutlined style={{ marginTop: 0, marginRight: 0, color: 'green'}}  />
                : <InfoOutlined style={{ marginTop: 0, marginRight: 0, color: 'lightgray' }} />
            }
            </div>

            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={openInfo}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleInfoClose}
                disableRestoreFocus>

                {description ?

                    <>
                        <ul>
                            {notes}
                            <li>Click to add more notes</li>
                        </ul>
                        
                    </>
                    :
                    <>
                        Click to add notes
                    </>
                }
            </Popover>
            
        </>
    )
}


