import React, { Suspense } from "react";
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemProps,
  Container,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {  Route, Routes } from "react-router-dom";
// import EncoreSearch from "./components/EncoreSearch"
// import EncoreLoads from "./components/loads/EncoreLoads"
import EncoreShell from "../components/EncoreShell";
// import EncoreJobs from "./components/jobs/EncoreJobs";
// import EncoreCustomers from "./components/customers/EncoreCustomers";
// import EncoreTags from "./components/tags/EncoreTags";
// import EncoreOpportunities from './components/opportunities/EncoreOpportunities';
import {
  LocalShipping,
  Dashboard,
  AccountBox,
  LocalOffer,
  Work,
  Search,
} from "@material-ui/icons";

// import EncoreLogo from "./encore_favicon.png"
import AdminMain from "./pages/Main";
import ReportList from './pages/ReportList'
const ReportDesigner = React.lazy(() => import('./pages/ReportDesigner'));
const DashDesigner = React.lazy(() => import('./pages/DashDesigner'));


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiContainer-root": {
        [theme.breakpoints.down("lg")]: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        [theme.breakpoints.up("xl")]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
      display: "flex",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      [theme.breakpoints.down("lg")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      [theme.breakpoints.up("xl")]: {
        padding: theme.spacing(2),
      },
    },
    logo: {
      margin: theme.spacing(1),
    },
    title: {
      margin: theme.spacing(2),
    },
  })
);

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4894ac",
      main: "#00667d",
      dark: "#003b51",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f73378",
      main: "#f50057",
      dark: "#ab003c",
      contrastText: "#fff",
    },
  },
});

function ListItemLink(props: ListItemProps<"a", { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

interface Props {
  user: any | null;
  globalBranch: string;
  setGlobalBranch: (branch: string) => void;
}

export default function AdminApp({
  user,
  globalBranch,
  setGlobalBranch,
}: Props) {
  const classes = useStyles();

  const drawer = (
    <div>
      <div style={{ display: "flex", backgroundColor: "#eeeeee" }}>
        {/* <Avatar className={classes.logo} src={EncoreLogo}/> */}
        <Typography className={classes.title} variant="h6">
          Admin
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItemLink href="#/admin">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemLink>

        <ListItemLink href="#/admin/reports">
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItemLink>
        {/* <ListItemLink href="#/admin/tags">
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItemLink>
        <ListItemLink href="#/admin/loads">
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="Loads" />
        </ListItemLink>
        <ListItemLink href="#/admin/customers">
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItemLink>
        <ListItemLink href="#/admin/opportunities">
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary="Opportunities" />
        </ListItemLink> */}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <EncoreShell
          drawer={drawer}
          title="Encore"
          theme={theme}
          user={user}
          globalBranch={globalBranch}
          setGlobalBranch={setGlobalBranch}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth={false}>
            <Routes>
              <Route path="reports" element={<ReportList />}/>
              <Route path="designer/:reportId" element={<Suspense fallback={<div>Loading...</div>}><ReportDesigner /></Suspense>}/>
              <Route path="/" element={<AdminMain globalBranch={globalBranch} />}/>
              <Route path="dash-designer/:reportId" element={ <Suspense fallback={<div>Loading...</div>}><DashDesigner /></Suspense>}/>
            </Routes>
          </Container>
        </main>
      </MuiThemeProvider>
    </div>
  );
}
