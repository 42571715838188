import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Breadcrumbs, Typography, Card, IconButton, Grid, TextField,
    InputAdornment, FormControl, Select, MenuItem, List, ListItem, 
    ListItemText, Divider, LinearProgress, CardHeader, CardContent,
    Tabs, Tab, Hidden, ListItemSecondaryAction, Radio, CardActions, 
    GridList, GridListTile, Button, CircularProgress, Dialog, DialogTitle,
    DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import { Link, useParams, useLocation, useNavigate, Navigate } from "react-router-dom";
import { LocalShipping, Home, Refresh, Search, ClearAll, PictureAsPdf } from "@material-ui/icons";
import { LoadInfo } from './EncoreLoads';
import moment from "moment";
import TabPanel from '../../../components/TabPanel';
import { downloadBlob } from '../../../utils/s3';
import { Storage } from "aws-amplify";
import { handleErrors } from '../../../utils/errors';
import { API } from "aws-amplify";

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            width: 106,
        },
        header: {
            display: "flex",
            marginTop: 16
        },
        margin: {
            margin: theme.spacing(1),
        },
        search: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        cardHeader: {
            backgroundColor: "#f5f5f5",
            padding: 16
        },
        cardValue: {
            fontWeight: "bold"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        },
        gridItem: {
            width: 400,
            height: 400,
        },
        cardAction: {
            display: "flex",
            backgroundColor: "#f5f5f5"
        },
        loadDetail: {
            [theme.breakpoints.up('md')] : {
                flexGrow: 1,
                marginLeft: 24
            },
            [theme.breakpoints.down('sm')] : {
                width: "100%"
            }
        },
        pdfButton: {
            width: 80
        }
    })
);

interface ParamTypes {
    loadNo: string;
}

type LoadDetailInfo = {
    loadInfo: LoadInfo;
    jobLoads: Array<JobLoadInfo>;
    loadImages: Array<ImageInfo>;
}

type JobLoadInfo = {
    id: number;
    loadNo: number;
    vehicleNo: string;
    loadStartedAt: string;
    loadStartedBy: string;
    totalLoadedWeight: number;
    totalLoadedTags: number;
    deliveryDate: string;
    jobNo: number;
    sheetType: number;
    accountName: string;
    jobTitle: string;
    totalTags: number;
    totalWeightT: number;
    isLoadCompleted: boolean;
    totalReady: number;
    totalBundles: number;
}

type ImageInfo = {
    id: number;
    url: string;
}

interface Props {
    type: string | undefined;
    selectedLoadIdx: number | undefined;
    loads: Array<LoadInfo> | undefined;
    searchInput: string | undefined;
    test: string
}

interface LoadDetailsProps {
    user?: any | null
    globalBranch: string
}

interface CustomizedState {
    type: string,
    selectedLoadIdx: number,
    loads: Array<LoadInfo>,
    searchInput: string

}

export default function LoadDetails({ user, globalBranch }: LoadDetailsProps) {
    const classes = useStyles();
    const { loadNo } = useParams()
    const location = useLocation()
    const state = location.state as CustomizedState;
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loads, setLoads] = useState<Array<LoadInfo>>([]);
    const [loadType, setType] = useState<string>(''); 
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [detailLoading, setDetailLoading] = useState<boolean>(true)
    const [loadDetail, setLoadDetail] = useState<LoadDetailInfo | undefined>(undefined)
    const [selectedLoadIdx, setSelectedLoadIdx] = useState<number>(-1)
    const [pdfLoading, setPdfLoading] = useState<boolean>(false)
    const [openDispatchDialog, setOpenDispatchDialog] = useState<boolean>(false)
    const [loadToDispatch, setLoadToDispatch] = useState<LoadInfo>()
    const [dispatchLoading, setDispatchLoading] = useState<boolean>(false)

    const navigate = useNavigate()
    
    useEffect(() => {
        const { type, selectedLoadIdx, loads, searchInput } = state;
        if(location.state) {
            if(type) {
                setType(type);
            }
            if(selectedLoadIdx !== undefined) {
                setSelectedLoadIdx(selectedLoadIdx);
            }
            if(loads) {
                setLoads(loads);
                setIsLoaded(true)
            }
            if(searchInput) {
                setSearchInput(searchInput);
            }
            setDetailLoading(true)
            fetch(`/api/loads/${loadNo}`)
            .then(res => res.json())
            .then((result: LoadDetailInfo) => {
                setLoadDetail(result)
                setDetailLoading(false)
            })
        }
        
    }, [location.state, loadNo])


    function getLoads(type: string) {
        setType(type);
        setSelectedLoadIdx(-1)
        setLoads([])
        if(searchInput.length === 0) {
            setIsLoaded(false)
            fetch(`/api/loads/${type}/type/tags`)
            .then(res => res.json())
            .then((result: Array<LoadInfo>) => {
                if(result) {
                    setLoads(result)
                }
                setIsLoaded(true)
            })
        } else {
            setIsLoaded(false)
            fetch(`/api/loads/status/${type}/type/tags/search/${searchInput}`)
            .then(res => res.json())
            .then((result: Array<LoadInfo>) => {
                if(result) {
                    setLoads(result)
                } else {
                    setLoads([])
                }
                setIsLoaded(true)
            })
        }
    }

    function onSearchInput(input: string) {
        setSearchInput(input)
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
        if (input.length > 0) {
            setIsLoaded(false)
            fetch(`/api/loads/status/${loadType}/type/tags/search/${input}`)
            .then(res => res.json())
            .then((result: Array<LoadInfo>) => {
                if(result) {
                    setLoads(result)
                } else {
                    setLoads([])
                }
                setIsLoaded(true)
            })
        }
        }, 1500);
    }

    const getLoadDetail = () => {
        setDetailLoading(true)
        API.get("", `/loads/${loadNo}`, {})
        .then((result: LoadDetailInfo) => {
            setLoadDetail(result)
            setDetailLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching load detail", error)
            setDetailLoading(false)
        })
    }

    function formatDate(date: string) : moment.Moment | string {
        if (moment.parseZone(date).get('year') < 1900){
            return "";
        }
        return date ? moment.parseZone(date).format('DD/MM/YY HH:mm'): "";
    }

    function formatBool(value: boolean): string {
        return value ? "Yes" : "No";
    }

    const handleType = (event: React.ChangeEvent<{ value: unknown }>) => {
        getLoads(event.target.value as string);
      };

    const handleRefresh = () => {
        getLoads(loadType)
        setSelectedLoadIdx(-1)
    }

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        setSelectedLoadIdx(-1);
        onSearchInput(event.target.value);
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
            setSelectedLoadIdx(-1)
            getLoads(loadType)
        }
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    function isSelected(idx: number) : boolean {
        return selectedLoadIdx === idx;
    }

    function handleLoadClick(idx: number, loadNo: number) {
        setSelectedLoadIdx(idx);
        navigate(`/encore/loads/${loadNo}`,{
            state: { 
                type: loadType, 
                selectedLoadIdx: idx, 
                loads: loads, 
                searchInput: searchInput
            }
        })
    }

    const handlePdfClick = (id: number) => {
        setPdfLoading(true)
        fetch(`/api/pdf/job/${id}`, {
            credentials: 'include'
        })
        .then(handleErrors)
        .then(resp => resp.json())
        .then(filename => {
            if(filename !== "") {
                Storage.get(filename, { download: true })
                .then((res: any) => {
                    downloadBlob(res.Body, filename)
                    setPdfLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error fetching job pdf from s3", error);
                    setPdfLoading(false)
                })
            } else {
                setPdfLoading(false)
            }
            
        })
        .catch((error: any) => {
            console.log("Error fetching load pdf", error);
            setPdfLoading(false)
        })
    }

    const handleLoadPdfClick = (loadNo: number) => {
        setPdfLoading(true)
        fetch(`/api/loads/${loadNo}/jobs/pdf`, {
            credentials: 'include'
        })
        .then(handleErrors)
        .then(resp => resp.json())
        .then(filename => {
            if(filename !== "") {
                Storage.get(filename, { download: true })
                .then((res: any) => {
                    downloadBlob(res.Body, filename)
                    setPdfLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error fetching load pdf from s3", error);
                    setPdfLoading(false)
                })
            } else {
                setPdfLoading(false)
            }
            
        })
        .catch((error: any) => {
            console.log("Error fetching load pdf", error);
            setPdfLoading(false)
        })
    }

    const disableDispatchButton = (): boolean => {
        if(loadDetail?.jobLoads) {
            if(loadDetail.jobLoads.length > 0) {
                let allLoadsCompleted = loadDetail.jobLoads.every((job: JobLoadInfo) => job.isLoadCompleted)
                if(allLoadsCompleted) {
                    return false
                }
            }
        }
        return true
    }

    const handleDispatchLoad = () => {
        if(loadToDispatch) {
            setDispatchLoading(true)
            var params = {
                body: {
                    loadNo: loadToDispatch.loadNo,
                    username:user.attributes.name,
                    signatureSvg: ""
                }
            }

            API.post("", `/loads/dispatchLoad`, params)
            .then((response: any) => {
                setDispatchLoading(false)
                setOpenDispatchDialog(false)
                getLoads('dispatched')
                setSelectedLoadIdx(0)
                getLoadDetail()
            })
            .catch((error: any) => {
                console.log("Error: dispatching load", error)
                setDispatchLoading(false)
            })
        }
    }

    const getLoadsByBranch = (): Array<LoadInfo> => {
        return loads.filter((load: LoadInfo) =>  globalBranch === "All" ? true : load.location.toLowerCase() === globalBranch.toLowerCase())
    }
    
    return location.state ? (
        <div>
            <Breadcrumbs>
                <Link to="/encore" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Link to="/encore/loads" className={classes.link}>
                    <LocalShipping className={classes.icon} />
                    Loads
                </Link>
                <Typography color="textPrimary">{loadNo}</Typography>
            </Breadcrumbs>

            <div className={classes.header}>
            <Hidden smDown>
                <div style={{width: 375}}>
                    <Card>
                        <div className={classes.cardHeader}>
                            <div>
                                <div>
                                    <Typography variant="h6">Loads</Typography>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <FormControl className={classes.formControl} style={{width: 125}}>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={loadType}
                                    onChange={handleType}>
                                        <MenuItem value={'active'}>Active</MenuItem>
                                        <MenuItem value={'dispatched'}>Dispatched</MenuItem>
                                    </Select>
                                </FormControl>
                                <IconButton color="inherit" onClick={handleRefresh}>
                                <Refresh />
                                </IconButton>
                            </div>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                    id="input-with-icon-grid" 
                                    label="Search by Vehicle or Job No"
                                    onChange={handleSearch}
                                    value={searchInput}
                                    InputProps={{
                                        className: classes.search,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton color="inherit" onClick={handleClearSearch}>
                                                <ClearAll />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                </Grid>
                            </Grid>
                        </div>
                        {!isLoaded && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
                        <div>
                            <List>
                                {isLoaded && getLoadsByBranch().map((load: LoadInfo, i: number) => (
                                    <div key={load.loadNo}>
                                        <ListItem>
                                            <ListItemText 
                                                primary={`${load.vehicleNo} - ${load.freightCo}`}
                                                secondaryTypographyProps={{
                                                    component: 'div'
                                                }}
                                                secondary={
                                                    <div>
                                                        <div>Location: {load.location}</div>
                                                        <div>Load Started At: {formatDate(load.loadStartedAt.Time)}</div>
                                                    </div>
                                                }/>
                                            <ListItemSecondaryAction>
                                                <Radio
                                                    checked={isSelected(i)}
                                                    onChange={() => handleLoadClick(i, load.loadNo)}/>
                                            </ListItemSecondaryAction>
                                                
                                        </ListItem>
                                        {i !== getLoadsByBranch().length -1 &&
                                            <Divider />
                                        }
                                    </div>
                                ))}
                                {(isLoaded && getLoadsByBranch().length === 0 && loadType === "dispatched" && searchInput.length === 0) &&
                                    <p style={{marginLeft: 8}}>No loads dispatched in the last 7 days.</p>
                                }
                                {(isLoaded && getLoadsByBranch().length === 0 && loadType === "active" && searchInput.length === 0) &&
                                    <p style={{marginLeft: 8}}>No current active loads.</p>
                                }
                                {(isLoaded && getLoadsByBranch().length === 0 && searchInput.length !== 0) &&
                                    <p style={{marginLeft: 8}}>No loads found for that search input.</p>
                                }
                            </List>  
                        </div>
                    </Card>
                </div>
            </Hidden>
                <div className={classes.loadDetail}>
                    <div style={{padding: 16}}>
                        <div>
                            <h2>Load Details</h2>
                        </div>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
                            <Tab label="Detail" />
                            <Tab label="Orders" />
                            <Tab label="Images" />
                        </Tabs>
                        <Divider />
                        {detailLoading && 
                            <LinearProgress 
                                className={classes.progressBackground} 
                                classes={{barColorPrimary: classes.progress}}/>
                        }
                        <TabPanel value={selectedTab} index={0}>
                            {loadDetail &&
                                <Card style={{marginTop: 16}}>
                                    <CardHeader title={`${loadDetail.loadInfo.vehicleNo} - ${loadDetail.loadInfo.freightCo}`}/>
                                    <CardContent>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Load Status: <span className={classes.cardValue}>{loadDetail.loadInfo.loadStatus}</span></span>
                                        </div>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Load Started By: <span className={classes.cardValue}>{loadDetail.loadInfo.loadStartedBy}</span></span>
                                            <span>Load Started At: <span className={classes.cardValue}>{formatDate(loadDetail.loadInfo.loadStartedAt.Time)}</span></span>
                                        </div>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Load Completed By: <span className={classes.cardValue}>{loadDetail.loadInfo.loadCompletedBy}</span></span>
                                            <span>Load Completed At: <span className={classes.cardValue}>{formatDate(loadDetail.loadInfo.loadCompletedAt.Time)}</span></span>
                                        </div>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Dispatched By: <span className={classes.cardValue}>{loadDetail.loadInfo.dispatchedBy}</span></span>
                                            <span>Dispatched At: <span className={classes.cardValue}>{formatDate(loadDetail.loadInfo.dispatchedAt.Time)}</span></span>
                                        </div>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Total Loaded Weight: <span className={classes.cardValue}>{loadDetail.loadInfo.totalLoadedWeight}</span></span>
                                            <span>Total Loaded Tags: <span className={classes.cardValue}>{loadDetail.loadInfo.totalLoadedTags}</span></span>
                                        </div>
                                        <div className={classes.horizontalJustifiedLayout}>
                                            <span>Number of Jobs: <span className={classes.cardValue}>{loadDetail.loadInfo.numJobs}</span></span>
                                            <span>Location: <span className={classes.cardValue}>{loadDetail.loadInfo.location}</span></span>
                                        </div>
                                    </CardContent>
                                    <CardActions className={classes.cardAction}>
                                        <div style={{flexGrow: 1}} />
                                        {pdfLoading ? 
                                            <Button variant="contained" color="primary" className={classes.pdfButton}>
                                                <CircularProgress size={25} style={{color: "white"}}/>
                                            </Button>
                                        :
                                            <Button variant="contained" color="primary" className={classes.pdfButton} href={`/#/report/viewer/100?loadNo=${loadDetail.loadInfo.loadNo}`} target="_blank">
                                                <PictureAsPdf />
                                                <span style={{marginLeft: 8}}>PDF</span>
                                            </Button>
                                        }
                                        {loadDetail.loadInfo.loadStatus === "LOADING" &&
                                            <Button variant="contained" color="secondary" disabled={disableDispatchButton()} 
                                                onClick={() => {
                                                    setOpenDispatchDialog(true)
                                                    setLoadToDispatch(loadDetail.loadInfo)
                                                }}>
                                                Dispatch
                                            </Button>
                                        }

                                    </CardActions>
                                </Card>
                            }

                            <Dialog open={openDispatchDialog} onClose={() => setOpenDispatchDialog(false)} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Dispatch Load</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {`Are you sure you want to dispatch ${loadToDispatch?.vehicleNo} - ${loadToDispatch?.freightCo}?`}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setOpenDispatchDialog(false)}  color="secondary">
                                        No
                                    </Button>
                                    {dispatchLoading ?
                                        <Button variant="contained" color="secondary">
                                            <CircularProgress size={25} style={{color: "white"}} />
                                        </Button>
                                    :
                                        <Button onClick={() => handleDispatchLoad()} variant="contained" color="secondary">
                                            Yes
                                        </Button>
                                    }
                                    
                                </DialogActions>
                            </Dialog>
                        </TabPanel>

                        <TabPanel value={selectedTab} index={1}>
                            {loadDetail !== undefined && 
                                <div>
                                    {loadDetail.jobLoads !== null && loadDetail.jobLoads.map((job: JobLoadInfo) => (
                                        <Card style={{marginTop: 16}} key={job.id}>
                                            <CardHeader title={`${job.jobNo} - ${job.accountName}`}/>
                                            <CardContent>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Vehicle No: <span className={classes.cardValue}>{job.vehicleNo}</span></span>
                                                    <span>Job Title: <span className={classes.cardValue}>{job.jobTitle}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Load Started By: <span className={classes.cardValue}>{job.loadStartedBy}</span></span>
                                                    <span>Load Started At: <span className={classes.cardValue}>{formatDate(job.loadStartedAt)}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Total Loaded Weight: <span className={classes.cardValue}>{job.totalLoadedWeight}</span></span>
                                                    <span>Total Loaded Items: <span className={classes.cardValue}>{job.totalLoadedTags}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Total Weight: <span className={classes.cardValue}>{job.totalWeightT}</span></span>
                                                    <span>Total Items: <span className={classes.cardValue}>{job.totalTags}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Total Picked: <span className={classes.cardValue}>{job.totalReady}</span></span>
                                                    <span>Load Completed: <span className={classes.cardValue}>{formatBool(job.isLoadCompleted)}</span></span>
                                                </div>
                                            </CardContent>
                                            <CardActions className={classes.cardAction}>
                                                <div style={{flexGrow: 1}} />
                                                {pdfLoading ? 
                                                    <Button variant="contained" color="primary" className={classes.pdfButton}>
                                                        <CircularProgress size={25} style={{color: "white"}}/>
                                                    </Button>
                                                :
                                                    <Button variant="contained" color="primary" className={classes.pdfButton} onClick={() => handlePdfClick(job.id)}>
                                                        <PictureAsPdf />
                                                        <span style={{marginLeft: 8}}>PDF</span>
                                                    </Button>
                                                }
                                            </CardActions>
                                        </Card>
                                    ))}
                                </div>
                            }
                        </TabPanel>

                        <TabPanel value={selectedTab} index={2}>
                            {loadDetail !== undefined && 
                                <div style={{marginTop: 16}}>
                                    <GridList cellHeight={400} cols={0} spacing={4}>
                                            {loadDetail.loadImages !== null && loadDetail.loadImages.map((image: ImageInfo) => (
                                                <GridListTile key={image.url} cols={1} className={classes.gridItem}>
                                                    <img src={image.url} alt=""/>
                                                </GridListTile>
                                            ))}
                                    </GridList>
                                </div>
                            }
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/encore/loads" replace/>
    )
}
