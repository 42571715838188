import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { 
    Divider, Card, LinearProgress, Breadcrumbs, Typography, CardHeader, CardContent,
    Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Paper
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Home, AccountBox } from "@material-ui/icons";
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        table: {
            minWidth: 650,
        }
    })
);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

interface ParamTypes {
    orderId: string;
    customerId: string
}

interface OrderInfo {
    salesOrderId: number;
    statusText: string;
    orderDate: string;
    dueDate: string
    orderTotal: number;
    salesOrderLines: Array<SalesOrderLineInfo>;
}

interface SalesOrderLineInfo {
    soLineId: number;
    stockcode: string;
    description: string;
    ordQuantity: number;
    supQuantity: number;
    invQuantity: number;
    unitPrice: number;
    lineTotal: number;
}

export default function OrderDetails() {
    const classes = useStyles()
    const { customerId, orderId } = useParams()
    const [loading, setLoading] = useState<boolean>(true)
    const [orderInfo, setOrderInfo] = useState<OrderInfo | undefined>(undefined)

    useEffect(() => {
        const getOrderInfo = () => {
            setLoading(true)
            fetch(`/api/encore/orders/${orderId}`)
            .then(res => res.json())
            .then((result: OrderInfo) => {
                if(result) {
                    setOrderInfo(result)
                }
                setLoading(false)
            })
        }
        getOrderInfo()
            
    }, [orderId, customerId])

    function formatDate(date: string) : moment.Moment | string {
        if (moment.parseZone(date).get('year') < 1900){
            return "";
        }
        return date ? moment.parseZone(date).format('DD MMM YYYY'): "";
    }

    function format2dp(n: number) {
        return n.toFixed(2);
    }

    return (
        <div>
            <Breadcrumbs>
                <Link to="/encore" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Link to="/encore/customers" className={classes.link}>
                    <AccountBox className={classes.icon} />
                    Customers
                </Link>
                <Link to={`/encore/customers/${customerId}`} className={classes.link}>
                    {customerId}
                </Link>
                <Typography color="textPrimary">Orders</Typography>
                <Typography color="textPrimary">{orderId}</Typography>
            </Breadcrumbs>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Order Details
                </h2>
            </div>
            <Divider />
            {loading && 
                <LinearProgress 
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }
            {(!loading && orderInfo !== undefined) &&
                <div>
                    <div style={{marginTop: 8}}>
                        <Card>
                            <CardHeader title={`${orderInfo.salesOrderId} - ${orderInfo.statusText}`}>
                            </CardHeader>
                            <CardContent>
                                <div>
                                    <span>Order Date: {formatDate(orderInfo.orderDate)}</span>
                                </div>
                                <div>
                                    <span>Due Date: {formatDate(orderInfo.dueDate)}</span>
                                </div>
                                <div>
                                    <span>Total: {orderInfo.orderTotal}</span>
                                </div>
                            </CardContent>
                        </Card>
                    </div>

                    <div style={{marginTop: 16}}>
                        {(!loading && orderInfo.salesOrderLines.length !== 0) &&
                            <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Stockcode</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell>Order Qty</StyledTableCell>
                                        <StyledTableCell>Sup Qty</StyledTableCell>
                                        <StyledTableCell>Invoice Qty</StyledTableCell>
                                        <StyledTableCell>Unit Price</StyledTableCell>
                                        <StyledTableCell>Line Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {orderInfo.salesOrderLines.length !== 0 && orderInfo.salesOrderLines.map((line: SalesOrderLineInfo) => (
                                    <StyledTableRow key={line.soLineId}>
                                        <StyledTableCell component="th" scope="row">
                                                {line.stockcode}
                                        </StyledTableCell>
                                        <StyledTableCell>{line.description}</StyledTableCell>
                                        <StyledTableCell>{line.ordQuantity}</StyledTableCell>
                                        <StyledTableCell>{line.supQuantity}</StyledTableCell>
                                        <StyledTableCell>{line.invQuantity}</StyledTableCell>
                                        <StyledTableCell>{format2dp(line.unitPrice)}</StyledTableCell>
                                        <StyledTableCell>{format2dp(line.lineTotal)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        }
                    </div>
                </div>
            }
        </div>
    )
}
