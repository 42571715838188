import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Card, CardHeader, CardContent, Button, IconButton
} from "@material-ui/core";
import AddNoteDialog from '../dialogs/AddNoteDialog';
import { OpportunityNoteDetail, OpportunityInfo } from '../../../types/OpportunityTypes';
import { formatDateTime } from '../../../../utils/utils';
import { Add, GetApp } from '@material-ui/icons';
import { Storage } from "aws-amplify";
import { downloadBlob } from '../../../../utils/s3';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        downloadIcon: {
            '&.MuiIconButton-root': {
                padding: 0
            },
            marginLeft: 4
        }
    })
);

interface OpportunityNotesTabProps {
    opportunityDetail: OpportunityInfo | undefined
    user: any | null
    opportunityNo: string
    notes: Array<OpportunityNoteDetail>
    getNotes: () => void
}

export default function OpportunityNotesTab({ opportunityDetail, user, opportunityNo, notes, getNotes }: OpportunityNotesTabProps) {

    const classes = useStyles()

    const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false)

    const downloadFile = (fileUrl: string) => {
    
        Storage.get(fileUrl, { download: true} )
        .then((res: any) => {
            downloadBlob(res.Body, fileUrl)
        })
        .catch((error: any) => {
            console.log("Error fetching file from s3", error);
        })
    }

    const handleNoteDialog = (open: boolean) => {
        setOpenAddNoteDialog(open)
        getNotes()
    }

    return (
        <div>
            <div style={{display: "flex", marginTop: 8}}>
                <div style={{flexGrow: 1}}></div>
                <div>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => setOpenAddNoteDialog(true)} 
                        disabled={opportunityDetail?.status.includes("JOB") || opportunityDetail?.status === "Not Quoting"}>
                        <Add />
                        Add Note
                    </Button>
                </div>
            </div>

            {notes.length !== 0 && notes.map((note: OpportunityNoteDetail) => (
                <Card style={{marginTop: 16}} key={note.id}>
                    <CardHeader 
                        title={`${note.category} Note`} />
                    <CardContent>
                        <div>
                            {note.note}
                        </div>
                        <div style={{display: "flex", marginTop: 16}}>
                            <div style={{flexGrow: 1}}>
                                {note.fileUrl &&
                                    <>
                                        <span>Uploaded File: <strong>{note.filename}</strong></span>
                                        <IconButton className={classes.downloadIcon} onClick={() => downloadFile(note.fileUrl)}>
                                            <GetApp />
                                        </IconButton>
                                    </>
                                }
                            </div>
                            <div>
                                {`${note.postedBy} - ${formatDateTime(note.postedAt)}`}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}

            <AddNoteDialog 
                openDialog={openAddNoteDialog} 
                handleNoteDialog={handleNoteDialog}
                user={user}
                oppNo={opportunityNo} />
        </div>
    )
}
