import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Typography, Button, FormControl, Dialog, DialogContent, 
    DialogActions, TextField, Grid, FormLabel
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { Controller, useForm } from "react-hook-form";
import { API } from "aws-amplify";
import {
    AddCustomerForm, OpportunityCustomer, OpportunityCustomerDetail
} from '../../../types/OpportunityTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface CustomerDialogProps {
    openDialog: boolean;
    handleAddDialog: (open: boolean) => void;
    handleCreateDialog: (open: boolean) => void;
    setAddCustomerForm: (data: AddCustomerForm) => void;
    // setOpenGenQuoteDialog: (open: boolean) => void;
    customers: Array<OpportunityCustomerDetail>;
    user?: any | null;
    oppNo?: string;
    getCustomers: () => void;
}

export default function AddCustomerDialog({ openDialog, handleAddDialog, handleCreateDialog, setAddCustomerForm, customers, user, oppNo, getCustomers }: CustomerDialogProps) {
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>()
    const [customerOptions, setCustomerOptions] = useState<OpportunityCustomer[]>([]);
    const [roleOptions, setRoleOptions] = useState<Array<string>>([])
    const [rolesLoading, setRolesLoading] = useState<boolean>(false)
    const { handleSubmit, control, errors, clearErrors, setValue } = useForm<AddCustomerForm>();

    let sTimer: NodeJS.Timeout;

    useEffect(() => {
        if(openDialog) {
            setRolesLoading(true)
            API.get("", '/encore/opportunities/customers/roles', {})
            .then((response: Array<string>) => {
                if(response) {
                    setRoleOptions(response)
                }
                setRolesLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching customer contact roles", error)
                setRolesLoading(false)
            })
        }
    }, [openDialog])

    const handleInputChange = (input: string) => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if(input.length > 0) {
                setLoading(true)
                API.get("", `/encore/opportunities/customers/search/${input}`, {})
                .then((response: Array<OpportunityCustomer>) => {
                    if(response) {
                        setCustomerOptions(response.filter((c: OpportunityCustomer) => {
                            for(let i = 0; i < customers.length; i++) {
                                if(customers[i].customerName === c.customerName) {
                                    return false
                                }
                            }
                            return true
                        }))
                    }
                    setLoading(false)
                })
                .catch((error: any) => {
                    console.log("Error: fetching opportunity customers", error)
                    setLoading(false)
                })
            }
        }, 500)
    }
    
    function onSubmit(data: AddCustomerForm) {
        console.log(data)


        var params = {
            body: {
                opportunityNo: oppNo,
                username:user.attributes.name,
                genQuote: false,
                ...data
            }
        }

        API.post("", `/encore/opportunities/addCustomer`, params)
            .then(() => {
                setLoading(false)
                handleAddDialog(false)
                getCustomers()
            })
            .catch((error: any) => {
                console.log("Error: adding opportunity customer", error)
                setLoading(false)
                
                // setError("Error: unable to create customer.")
            })


        // setAddCustomerForm(data)
        
        // setOpenGenQuoteDialog(true)
    }
 
    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleAddDialog(false)} aria-labelledby="form-dialog-title">
            <DialogContent>
                <div style={{display: "flex", marginBottom: 16}}>
                    <div style={{flexGrow: 1}}>
                        <Typography variant="h6">
                            Add Customer
                        </Typography>
                    </div>
                    <div>
                        <Button variant="contained" color="secondary" onClick={() => {
                            localStorage.removeItem("createCustomerForm")
                            localStorage.removeItem("createContactForm")
                            handleCreateDialog(true)
                            handleAddDialog(false)
                        }}>
                            Create Customer
                        </Button>
                    </div>
                </div>

                <FormControl
                    error={Boolean(errors.customer?.customerName)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Customer</FormLabel>

                    <Controller
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                loading={loading}
                                options={customerOptions}
                                getOptionLabel={(option) => {
                                    if(!option.customerName) {
                                        return ""
                                    }
                                    return option.customerName
                                }}
                                getOptionSelected={(option, value) => {
                                    if(!value.customerName) {
                                        return true
                                    }
                                    return option.customerName === value.customerName
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="filled" 
                                        fullWidth
                                        placeholder="Search for customer"
                                        error={errors.customer ? true : false}
                                        helperText={errors.customer ? "This is required" : ""} />
                                )}
                                onInputChange={(_, newInputValue) => {
                                    handleInputChange(newInputValue)
                                }}
                                renderOption={(option) => {
                                    return (
                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="body1">
                                                    {option.customerName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                }}
                                onChange={(_, data) => props.onChange(data)}/>
                        )}
                        name="customer"
                        control={control}
                        rules={{
                            required: "This is required"
                        }}
                        defaultValue={null}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.contactName)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Contact Name</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Contact Name"
                                fullWidth
                                variant="filled"
                                error={errors.contactName ? true : false}
                                helperText={errors.contactName?.message}/>
                        }
                        name="contactName"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.role)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Role</FormLabel>

                    <Controller
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                freeSolo
                                loading={rolesLoading}
                                options={roleOptions}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="filled" 
                                        fullWidth
                                        placeholder="Select a role"
                                        error={errors.role ? true : false}
                                        helperText={errors.role?.message} />
                                )}
                                onInputChange={(_, newInputValue) => {
                                    if(newInputValue.length !== 0) {
                                        setValue("role", newInputValue)
                                        clearErrors("role")
                                    }
                                }}
                                onChange={(_, data) => props.onChange(data)}/>
                        )}
                        name="role"
                        control={control}
                        rules={{
                            // required: "This is required"
                        }}
                        defaultValue={null}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.email)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Email</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Email"
                                fullWidth
                                variant="filled"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message}/>
                        }
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.phone)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Phone</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Phone"
                                fullWidth
                                variant="filled"
                                error={errors.phone ? true : false}
                                helperText={errors.phone?.message}/>
                        }
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAddDialog(false)}  color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}