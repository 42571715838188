import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
    Button,
    FormControl,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    FormLabel,
    DialogTitle,
    DialogContentText,
    Select,
    MenuItem,
    Grid,
    InputAdornment,
    FilledInput,
    Checkbox,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableBody,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { JobMeasureQuoteLineInfo, JobTagDetailOrderGroup, JobVariationSubSchedules, NewTagDetailInfo } from "../../types/JobTypes";
import NumberFormat from "react-number-format";
import { API } from "aws-amplify";
import VariationTree from "./VariationTree";
import { AddNewDetailingScheduleScheduleDialog } from "./AddNewDetailingScheduleDialogProps";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%",
        },
        label: {
            marginBottom: 6,
        },
    })
);


interface ScheduleInfo{
    scheduleNo : string
    scheduleName: string
}

interface QuoteDialogProps {
    openDialog: boolean;
    handleCloseDialog: (open: boolean) => void;
    schedules: Array<ScheduleInfo>
    order: JobTagDetailOrderGroup
    refreshData: () => void
    // jobMeasureQuoteInfo?: JobMeasureQuoteLineInfo;
    // isNewItem: boolean;
    // similarQuotes?: Array<JobMeasureQuoteLineInfo>;
    // isUpdateMode?: boolean
}


export default function NewTagDetailDialog({
    openDialog,
    handleCloseDialog,
    schedules,
    order,
    refreshData
    // jobMeasureQuoteInfo,
    // isNewItem,
    // similarQuotes,
    // isUpdateMode
}: QuoteDialogProps) {
    const classes = useStyles();

    const { handleSubmit, control, errors, reset } = useForm<NewTagDetailInfo>();
    const [error, setError] = useState<string>()
    // const [open, setOpen] = React.useState(false);
    // const [subSchedules, setSubSchedules] = React.useState(Array<JobVariationSubSchedules>());
    const [allSchedules, setAllSchedule] = React.useState(schedules);
    const [openNewScheduleDialog, setOpenNewScheduleDialog] = React.useState(false);


    const handleDialogClose = async (scheduleId: string, scheduleName: string) => {
        // console.log(scheduleId, scheduleName)
        setAllSchedule([...allSchedules,{scheduleNo:scheduleId, scheduleName:`${ scheduleId} -${scheduleName}`}])
        setOpenNewScheduleDialog(false)
    };

    const handleDialogClickOpen = () => {
        setOpenNewScheduleDialog(true);
    };

    // useEffect(() => {
    //     console.log(jobMeasureQuoteInfo)
    //     reset(jobMeasureQuoteInfo)
    //     if (jobMeasureQuoteInfo?.jobSeqNo) {
    //         fetchSubschedules()
    //     }
    // }, [jobMeasureQuoteInfo, reset]);

    async function onSubmit(data: NewTagDetailInfo) {

        data.orderId = order.orderId
        data.controlCode = order.controlCode
        data.sheetNo = order.sheetNo
        data.quantity = parseInt(data.quantity.toString())
        data.jobId = parseInt(order.jobId.toString())
    

        let params = {
            body: {
                ...data
            }
        }
        //Post 
        try {
            await API.post("", `/encore/jobs/${order.jobId}/order/${order.orderId}/tag/new`, params)

            handleCloseDialog(false);
            refreshData()
        } catch (error) {
            console.log(error)
            setError("Error: Unable to post record.")
        }

    }

    

    return (
        <Dialog
            fullWidth
            maxWidth={"sm"}
            open={openDialog}
            onClose={() => handleCloseDialog(false)}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>New Tag Detail</DialogTitle>
            <DialogContent>
                

                <Grid container spacing={2}>
                
                
                    <Grid item xs={10}>
                        <FormControl
                            error={Boolean(errors.schedule)}
                            className={classes.formInput}
                        >
                            <FormLabel className={classes.label}>Schedule</FormLabel>

                            <Controller
                                    render={({ onChange, value }) => (
                                        <Select
                                            variant="filled"
                                            value={value}
                                            onChange={(e) => {
                                                let val = e.target.value as string
                                                onChange(val)
                                                // methods.clearErrors()
                                                // claimSummary.type = val
                                                // methods.reset(claimSummary)
                                            }}>

                                            {
                                                allSchedules.map((schedule)=>(
                                                    <MenuItem key={schedule.scheduleNo} value={`${schedule.scheduleNo}|${schedule.scheduleName}`}>{schedule.scheduleName}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    )}
                                    name="schedule"
                                    control={control}
                                    defaultValue=""
                                /> 
                        </FormControl>

                    </Grid>
                    <Grid item xs={2}>
                        <Button color="secondary"  onClick={handleDialogClickOpen}>
                            + Add
                        </Button>
                        <AddNewDetailingScheduleScheduleDialog open={openNewScheduleDialog} selectedValue={""} onClose={handleDialogClose}></AddNewDetailingScheduleScheduleDialog>
                    </Grid>
                </Grid>


                <FormControl error={Boolean(errors.product)} className={classes.formInput}>
                    <FormLabel className={classes.label}>Product</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Product"
                                fullWidth
                                variant="filled"
                                error={errors.product ? true : false}
                                helperText={errors.product?.message}
                            

                            />
                        }
                        name="product"
                        control={control}
                        defaultValue=""
                        variant="filled"
                        placeholder="Enter product"
                        rules={{
                            required: "This is required",
                        }}
                    />
                </FormControl>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl error={Boolean(errors.quantity)} className={classes.formInput}>
                            <FormLabel className={classes.label}>Quantity</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Quantity"
                                        fullWidth
                                        variant="filled"
                                        error={errors.quantity ? true : false}
                                        helperText={errors.quantity?.message}
                                        type="number"
                                    

                                    />
                                }
                                name="quantity"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required",
                                }}
                            />
                        </FormControl>

                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormControl error={Boolean(errors.uom)} className={classes.formInput}>
                            <FormLabel className={classes.label}>Unit</FormLabel>

                                <Controller
                                    render={({ onChange, value }) => (
                                        <Select
                                            variant="filled"
                                            value={value}
                                            onChange={(e) => {
                                                let val = e.target.value as string
                                                onChange(val)
                                                // methods.clearErrors()
                                                // claimSummary.type = val
                                                // methods.reset(claimSummary)
                                            }}>

                                            <MenuItem value={'Each'}>Each</MenuItem>
                                            <MenuItem value={'Kg'}>Kg</MenuItem>
                                        </Select>
                                    )}
                                    name="uom"
                                    control={control}
                                    defaultValue=""
                                /> 
                                


                        </FormControl>
                    </Grid> */}
                </Grid>

                

                <FormControl error={Boolean(errors.orderDescription)} className={classes.formInput}>
                    <FormLabel className={classes.label}>Description</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Description"
                                fullWidth
                                variant="filled"
                                error={errors.orderDescription ? true : false}
                                helperText={errors.orderDescription?.message}
                            

                            />
                        }
                        name="orderDescription"
                        control={control}
                        defaultValue=""
                        variant="filled"
                        placeholder="Description"
                        rules={{
                            required: "This is required",
                        }}
                    />
                </FormControl>




                {/* <FormControl
                    className={classes.formInput}>
                    <FormLabel className={classes.label}>Type</FormLabel>

                    <Controller
                        render={({ onChange, value }) => (
                            <Select
                                variant="filled"
                                value={value}
                                onChange={(e) => {
                                    let val = e.target.value as string
                                    onChange(val)
                                    // methods.clearErrors()
                                    // claimSummary.type = val
                                    // methods.reset(claimSummary)
                                }}>
                                <MenuItem value={''}></MenuItem>
                                <MenuItem value={'Cut/Bend'}>Cut/Bend</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                                <MenuItem value={'Cut/CBend'}>Cut/CBend</MenuItem>
                                <MenuItem value={'Blockwork'}>Blockwork</MenuItem>
                                <MenuItem value={'Epoxied'}>Epoxied</MenuItem>
                                <MenuItem value={'Not Tied'}>Not Tied</MenuItem>
                                <MenuItem value={'Spacers'}>Spacers</MenuItem>
                                <MenuItem value={'Spiral'}>Spiral</MenuItem>
                                <MenuItem value={'Stirrup'}>Stirrup</MenuItem>
                                <MenuItem value={'Stock Bar'}>Stock Bar</MenuItem>
                                <MenuItem value={'Straight'}>Straight</MenuItem>
                            </Select>
                        )}
                        name="type"
                        control={control}
                        defaultValue=""
                    />
                </FormControl> */}
                {/* <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.supplyRate)}
                            className={classes.formInput}
                        >
                            <FormLabel className={classes.label}>Weight:</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Weight"
                                        fullWidth
                                        variant="filled"
                                        error={errors.supplyRate ? true : false}
                                        helperText={errors.supplyRate?.message}
                                        type="number"
                                    />
                                }
                                name="supplyRate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required",
                                }}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.placeRate)}
                            className={classes.formInput}
                        >
                            <FormLabel className={classes.label}>Length:</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Length"
                                        fullWidth
                                        variant="filled"
                                        error={errors.placeRate ? true : false}
                                        helperText={errors.placeRate?.message}
                                        type="number"
                                    />

                                }
                                name="placeRate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required",
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid> */}


                {/* <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.supplyRate)}
                            className={classes.formInput}
                        >
                            <FormLabel className={classes.label}>Sheet No.:</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Sheet No."
                                        fullWidth
                                        variant="filled"
                                        error={errors.supplyRate ? true : false}
                                        helperText={errors.supplyRate?.message}
                                        type="number"
                                    />
                                }
                                name="supplyRate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required",
                                }}
                            />
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.placeRate)}
                            className={classes.formInput}
                        >
                            <FormLabel className={classes.label}>Tag No.:</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Tag No."
                                        fullWidth
                                        variant="filled"
                                        error={errors.placeRate ? true : false}
                                        helperText={errors.placeRate?.message}
                                        type="number"
                                    />

                                }
                                name="placeRate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required",
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid> */}


            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{ color: "red" }}>{error}</span>
                }
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
               
                <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        color="secondary"
                    >
                        Add
                    </Button> 
            </DialogActions>
        </Dialog>
    );
}


export interface VariationDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

function VariationScheduleDialog(props: VariationDialogProps) {
    const classes = useStyles();
    const [name, setName] = useState('')

    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose(selectedValue);
    }

    function handleSubmit(): void {
        if (name.length === 0) {
            return
        }
        onClose(name)
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">New Variation Schedule</DialogTitle>
            <DialogContent>
                <TextField
                    placeholder="Enter Schedule"
                    fullWidth
                    variant="filled"
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>

            <DialogActions>
                {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}