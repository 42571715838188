import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Card, CardHeader, CardContent, Button
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { OpportunityInfo } from '../../../types/OpportunityTypes';
import { formatDate } from '../../../../utils/utils';
import AddOpportunityDialog from '../dialogs/AddOpportunityDialog';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        cardValue: {
            fontWeight: "bold"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        }
    })
);

interface OpportunityDetailTabProps {
    opportunityDetail: OpportunityInfo | undefined
    user: any | null
    getOpportunityDetail: () => void
}

export default function OpportunityDetailTab({ opportunityDetail, user, getOpportunityDetail }: OpportunityDetailTabProps) {
    
    const classes = useStyles()

    const [openEditOpportunityDialog, setOpenEditOpportunityDialog] = useState<boolean>(false)

    return opportunityDetail ? (
        <div>
            <div style={{display: "flex", marginTop: 16}}>
                <div style={{flexGrow: 1}}></div>
                <div>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        // disabled={opportunityDetail.status.includes("JOB") || opportunityDetail.status === "Not Quoting"}
                        onClick={() => setOpenEditOpportunityDialog(true)}>
                        Edit Opportunity
                        <Edit style={{marginLeft: 4}} />
                    </Button>
                </div>
            </div>
            <Card style={{marginTop: 16}}>
                <CardHeader title="Opportunity details"/>
                <CardContent>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Opportunity No: <span className={classes.cardValue}>{opportunityDetail.seqNo}</span></span>
                        <span>Status: <span className={classes.cardValue}>{opportunityDetail.status}</span></span>
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Branch: <span className={classes.cardValue}>{opportunityDetail.branch}</span></span>
                        <span>Assigned To: <span className={classes.cardValue}>{opportunityDetail.assignedTo}</span></span>
                    </div>
                </CardContent>
            </Card>

            <Card style={{marginTop: 16}}>
                <CardHeader title="Job details"/>
                <CardContent>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Job Name: <span className={classes.cardValue}>{opportunityDetail.jobName}</span></span>
                        {opportunityDetail.exoJobNo &&
                            <span>Exo Job No: <span className={classes.cardValue}>{opportunityDetail.exoJobNo}</span></span>
                        }
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Description: <span className={classes.cardValue}></span><strong>{opportunityDetail.description}</strong></span>
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Job Address: <span className={classes.cardValue}></span><strong>{opportunityDetail.jobAddress}</strong></span>
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Customer Job Reference: <span className={classes.cardValue}></span></span>
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>RFQ Date: <span className={classes.cardValue}>{formatDate(opportunityDetail.rfqDate)}</span></span>
                        <span>Deadline: <span className={classes.cardValue}>{formatDate(opportunityDetail.deadline)}</span></span>
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Submitted Date: <span className={classes.cardValue}>{formatDate(opportunityDetail.submittedDate)}</span></span>
                        {opportunityDetail.lostDate && <span>Lost Date: <span className={classes.cardValue}>{formatDate(opportunityDetail.lostDate)}</span></span>}
                        
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        
                        <span>Won Date: <span className={classes.cardValue}>{formatDate(opportunityDetail.wonDate)}</span></span>
                        
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Est Start Date: <span className={classes.cardValue}></span><strong>{formatDate(opportunityDetail.estStartDate)}</strong></span>
                        {opportunityDetail.estTonnes &&
                            <span>Est Tonnes: <span className={classes.cardValue}></span><strong>{opportunityDetail.estTonnes}</strong></span>
                        }
                    </div>
                    <div className={classes.horizontalJustifiedLayout}>
                        <span>Region: <span className={classes.cardValue}></span><strong>{opportunityDetail.region}</strong></span>
                        <span>Market Segment: <span className={classes.cardValue}></span><strong>{opportunityDetail.marketSegment}</strong></span>
                    </div>
                </CardContent>
            </Card>

            <AddOpportunityDialog
                openAddOpportunityDialog={openEditOpportunityDialog}
                handleDialog={setOpenEditOpportunityDialog}
                user={user}
                getOpportunities={getOpportunityDetail}
                opportunityDetail={opportunityDetail}
            />
        </div>
    ) : <div></div>
}
