import moment from "moment";

export function formatDate(date: string) : moment.Moment | string {
    if (date === '0000-00-00' || moment.parseZone(date).get('year') < 1900){
        return "";
    }
    return date ? moment.parseZone(date).format('DD/MM/YYYY'): "";
}

export function formatDateTime(date: string) : moment.Moment | string {
    if (date === '0000-00-00' || moment.parseZone(date).get('year') < 1900){
        return "";
    }
    return date ? moment.parseZone(date).format('DD/MM/YYYY HH:mm'): "";
}