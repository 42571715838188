
import { 
    Button, DialogTitle, Dialog, DialogContent,
    DialogActions
} from "@material-ui/core";


interface ConfirmCreateCustomerQuoteDialogProps {
    openDialog: boolean;
    handleDialog: (open: boolean) => void;
    // user?: any | null;
    // oppNo?: string;
    // getCustomers: () => void;
    // newCustomerData?: CreateNewCustomerForm
    // addCustomerData?: AddCustomerForm
}

export default function ConfirmCreateCustomerQuoteDialog({ openDialog, handleDialog }: ConfirmCreateCustomerQuoteDialogProps) {

    return (
        <Dialog open={openDialog} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Customer Quote Confirmation
            </DialogTitle>
            <DialogContent>
                Are you sure you want to create a customer quote?
            </DialogContent>
            <DialogActions>
                <Button  onClick={() => handleDialog(false)} variant="contained" color="secondary">
                    No
                </Button>
                <Button onClick={() => handleDialog(true)} variant="contained" color="secondary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
            
        </Dialog>   
    )
}