import React, { useEffect, useState } from 'react'
import {
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import {
    Paper, IconButton, TableContainer, Table, TableHead, TableRow,InputAdornment,
    TableBody, Collapse, Box, Typography, TableCell, Checkbox, Toolbar, Tooltip, lighten, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {
    Compare,
    Search,
    Edit,
    EditLocation,
    KeyboardArrowDown, KeyboardArrowUp, Schedule, Add
} from "@material-ui/icons";
import {
    ScheduleInfo, JobTagDetailOrderGroup, JobTagDetailInfo
} from '../../types/JobTypes';
import { useRowStyles } from './JobDetails';
import {
    StyledTableCell, StyledTableRow
} from '../../../components/StyledTableComponents';
import { API } from "aws-amplify";
import NewTagDetailDialog from './NewTagDetailDialog';
import { AddNewDetailingScheduleScheduleDialog } from './AddNewDetailingScheduleDialogProps';
import { formatDate } from '../../../utils/utils';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        }
    })
);


interface JobTagDetailTableProps {
    tagDetailData: Array<JobTagDetailOrderGroup>
    refreshData: () => void;
    schedules: Array<ScheduleInfo>
}



export function JobTagDetailTable({ tagDetailData, refreshData, schedules }: JobTagDetailTableProps) {

    const classes = useStyles()
    const [filter, setFilter] = useState<string>('')

    

    const uniqueSchedules : Array<ScheduleInfo> = [...new Map(schedules.map(item=> [item['scheduleNo'],item])).values()]
    const [tagFilteredData, setTagFilteredData] = useState<Array<JobTagDetailOrderGroup>>(tagDetailData)
    tagDetailData.sort((a, b) => a.sheetNo - b.sheetNo)

    useEffect(() => {
        if (filter.length > 0) {
            const sheets = filter.replace(' - ','-').replace(/,\s+/g,",").split(/[\n,\s+]/)
            let mySheets:Array<string> = []
            sheets.forEach((s)=>{
                if (s.includes("-")) {
                    const ss = s.split("-")
                    for (var i = parseInt(ss[0]); i <= parseInt(ss[1]); i++) {
                        mySheets.push(i.toString());
                    }
                }else{
                    mySheets.push(s)
                }
            })
            
            setTagFilteredData(tagDetailData.filter((tag) => {
                return mySheets.includes(tag.sheetNo.toString())
            }))

        } else {
            setTagFilteredData(tagDetailData)
        }


    }, [filter, tagDetailData])
    
    return (
        <>
            <Toolbar>
                <TextField
                    // className={classes.margin}
                    id="input-with-icon-textfield"
                    label="Search by Sheet No."
                    onChange={(e) => setFilter(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />

            </Toolbar>
            
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table className={classes.table} size="small" aria-label="simple table">
                <TableHead style={{ }}>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Sheet</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Order ID</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Promised</TableCell>
                                        <TableCell>Total Weight</TableCell>
                                        <TableCell>Tags</TableCell>
                                        <TableCell>Not Ready</TableCell>
                                        <TableCell>Ready</TableCell>
                                        <TableCell>Dispatched</TableCell>
                                        {/* <TableCell>Delivered</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tagFilteredData.map((order: JobTagDetailOrderGroup, i: number) => (
                                        <TagDetailOrderRow key={i} order={order} schedules={uniqueSchedules} refreshData={refreshData}/>
                                    ))}
                                </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

// interface TagDetailScheduleRowProps {
//     group: JobTagDetailScheduleGroup
// }

// function TagDetailScheduleRow({ group }: TagDetailScheduleRowProps) {

//     const classes = useRowStyles()
//     const [open, setOpen] = useState<boolean>(false);

//     return (
//         <>
        
//             <TableRow className={classes.root}>
//                 <TableCell>
//                     <IconButton onClick={() => setOpen(!open)}>
//                         {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
//                     </IconButton>
//                 </TableCell>
//                 <TableCell component="th" scope="row">
//                     <strong>{group.schedule}</strong>
//                 </TableCell>
//                 <TableCell><strong>{group.totalWeight}</strong></TableCell>
//                 <TableCell><strong>{group.totalTags}</strong></TableCell>
//                 <TableCell><strong>{group.totalNotReady}</strong></TableCell>
//                 <TableCell><strong>{group.totalReady}</strong></TableCell>
//                 <TableCell><strong>{group.totalDispatched}</strong></TableCell>
//                 <TableCell><strong>{group.totalDelivered}</strong></TableCell>
//             </TableRow>
//             <TableRow>
//                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
//                     <Collapse in={open} timeout="auto" unmountOnExit>
//                         <Box margin={1}>
//                             <Typography variant="h6">
//                                 Orders
//                             </Typography>
//                             <Table>
//                                 <TableHead style={{ backgroundColor: "#EFFAFF" }}>
//                                     <TableRow>
//                                         <TableCell></TableCell>
//                                         <TableCell>Code</TableCell>
//                                         <TableCell>Order ID</TableCell>
//                                         <TableCell>Total Weight</TableCell>
//                                         <TableCell>Tags</TableCell>
//                                         <TableCell>Not Ready</TableCell>
//                                         <TableCell>Ready</TableCell>
//                                         <TableCell>Dispatched</TableCell>
//                                         <TableCell>Delivered</TableCell>
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody>
//                                     {group.orders.map((order: JobTagDetailOrderGroup, i: number) => (
//                                         <TagDetailOrderRow key={i} order={order} />
//                                     ))}
//                                 </TableBody>
//                             </Table>
//                         </Box>
//                     </Collapse>
//                 </TableCell>
//             </TableRow>
//         </>
//     )
// }


interface TagDetailOrderRowProps {
    order: JobTagDetailOrderGroup
    schedules: Array<ScheduleInfo>
    refreshData: () => void;
}

function TagDetailOrderRow({ order, schedules, refreshData }: TagDetailOrderRowProps) {
    order.tags.sort((a,b)=> a.tagNo - b.tagNo)
    const classes = useRowStyles()
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = React.useState<number[]>([]);

    const rowCount = order.tags.length
    const numSelected = selected.length

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = order.tags.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const onChangeSchedule = () =>{

    }

    const handleScheduleChangeSubmit = async( id: string) => {
        // console.log(id)
        // console.log(selected)
        let params = {
            body: {
                tagIds: selected,
                scheduleId: id
            }
        }
        //Post 
        try {
            await API.put("", `/encore/job/orders/${order?.orderId}/schedule/update`, params)
            setSelected([]);
            
        } catch (error) {
            console.log(error)
            
        }finally{
            refreshData()
        }
    }

    const handlePlacingChangeSubmit = async ( id: string) => {
        // console.log(id)
        // console.log(selected)
        let params = {
            body: {
                tagIds: selected,
                placingCode: id
            }
        }
        //Post 
        try {
            await API.put("", `/encore/job/orders/${order?.orderId}/placingcode/update`, params)
            setSelected([]);
            
        } catch (error) {
            console.log(error)
            
        }finally{
            refreshData()
        }
    }

    const handleReasonChangeSubmit = async( id: string) => {
        // console.log(id)
        // console.log(selected)
        let params = {
            body: {
                tagIds: selected,
                reasonCode: id
            }
        }
        //Post 
        try {
            await API.put("", `/encore/job/orders/${order?.orderId}/reasoncode/update`, params)
            setSelected([]);
            
        } catch (error) {
            console.log(error)
            
        }finally{
            refreshData()
        }
    }

    return (
        <>
            <TableRow className={classes.root} style={{  }}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.sheetNo}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.controlCode}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.orderId}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{order.orderDescription}</strong>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{formatDate(order.orderPromisedDeliveryDate)}</strong>
                </TableCell>
                <TableCell><strong>{Math.round(order.totalWeight)}</strong></TableCell>
                <TableCell><strong>{order.totalTags}</strong></TableCell>
                <TableCell><strong>{order.totalNotReady}</strong></TableCell>
                <TableCell><strong>{order.totalReady}</strong></TableCell>
                <TableCell><strong>{order.totalDispatched}</strong></TableCell>
                {/* <TableCell><strong>{order.totalDelivered}</strong></TableCell> */}
            </TableRow>
            
            <TableRow>
            
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {/* <Typography variant="h6">
                                Tags
                            </Typography> */}
                            <EnhancedTableToolbar numSelected={selected.length} schedules={schedules} handleScheduleChangeSubmit={handleScheduleChangeSubmit} handlePlacingChangeSubmit={handlePlacingChangeSubmit} handleReasonChangeSubmit={handleReasonChangeSubmit} order={order} refreshData={refreshData} />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                                checked={rowCount > 0 && numSelected === rowCount}
                                                onChange={handleSelectAllClick}
                                                inputProps={{ 'aria-label': 'select all desserts' }}
                                            />
                                        </TableCell>
                                        <StyledTableCell>Sched.</StyledTableCell>
                                        <StyledTableCell>Sheet</StyledTableCell>
                                        <StyledTableCell>Stockcode</StyledTableCell>
                                        <StyledTableCell>Tag No</StyledTableCell>
                                        <StyledTableCell>Length</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                        <StyledTableCell>Weight</StyledTableCell>
                                        <StyledTableCell>Reason Code</StyledTableCell>
                                        <StyledTableCell>Placing Code</StyledTableCell>
                                        <StyledTableCell>Sub Variation</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.tags.map((tag: JobTagDetailInfo) => {
                                        const isItemSelected = isSelected(tag.id);
                                        return(
                                            <StyledTableRow
                                                key={tag.id}
                                                hover
                                                onClick={(event) => handleClick(event, tag.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                    // inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <StyledTableCell>{`${tag.scheduleName}`}</StyledTableCell>
                                                <StyledTableCell>{tag.sheetNo}</StyledTableCell>
                                                <StyledTableCell>{tag.stockCode}</StyledTableCell>
                                                <StyledTableCell>{tag.tagNo}</StyledTableCell>
                                                <StyledTableCell>{tag.length}</StyledTableCell>
                                                <StyledTableCell>{tag.quantity}</StyledTableCell>
                                                <StyledTableCell>{Math.round(tag.weight)}</StyledTableCell>
                                                <StyledTableCell>{tag.isNoCharge?`${tag.reasonCodeId} - No Charge`: tag.reasonCodeId}</StyledTableCell>
                                                <StyledTableCell>{tag.placingCodeID}</StyledTableCell>
                                                <StyledTableCell>{tag.variationSubScheduleName}</StyledTableCell>
                                                <StyledTableCell>{tag.currentTagStatus}</StyledTableCell>
                                                
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}


const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);
interface EnhancedTableToolbarProps {
    numSelected: number;
    schedules: Array<ScheduleInfo>
    handleScheduleChangeSubmit: (id:string) => void
    handlePlacingChangeSubmit: (id:string) => void
    handleReasonChangeSubmit: (id:string) => void
    order: JobTagDetailOrderGroup
    refreshData: () => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, schedules, handleScheduleChangeSubmit, handlePlacingChangeSubmit, handleReasonChangeSubmit, order, refreshData } = props;
    const [openPlacingDialog, setOpenPlacingDialog] = React.useState(false);
    const [openReasonDialog, setOpenReasonDialog] = React.useState(false);
    const [openScheduleDialog, setOpenScheduleDialog] = React.useState(false);
    const [openNewTagDetailDialog, setOpenNewTagDetailDialog] = useState<boolean>(false)

    const onChangePlacing = () =>{
        setOpenPlacingDialog(true)

    }
    const onClosePlacingDialog = () =>{
        setOpenPlacingDialog(false)
    }

    const onChangeReason = () =>{
        setOpenReasonDialog(true)
        
    }

    const onCloseReasonDialog = () =>{
        setOpenReasonDialog(false)
    }

    const onChangeSchedule = () =>{
        setOpenScheduleDialog(true)

    }

    const onCloseScheduleDialog = () =>{
        setOpenScheduleDialog(false)
    }

    const handleNewTagDetailClose = (value: boolean) => {
        setOpenNewTagDetailDialog(false);
        refreshData();

    };

    return (
        <Toolbar
            // className={clsx(classes.root, {
            //     [classes.highlight]: numSelected > 0,
            // })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Tags
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <Button variant="contained" onClick={() => onChangeSchedule()} >
                        Schedule
                        <EditLocation  />
                    </Button>
                    <ChangeScheduleDialog open={openScheduleDialog} selectedValue={''} onClose={onCloseScheduleDialog} schedules={schedules} handleScheduleChangeSubmit={handleScheduleChangeSubmit}></ChangeScheduleDialog>
                    
                    <Button variant="contained"  style={{marginLeft:8, marginRight:8}} onClick={() => onChangeReason()}>
                        Reason
                        <Edit  />
                    </Button>
                    <ChangeReasonDialog open={openReasonDialog} selectedValue={''} onClose={onCloseReasonDialog }  handleReasonChangeSubmit={handleReasonChangeSubmit} ></ChangeReasonDialog>

                    <Button variant="contained" onClick={() => onChangePlacing()} >
                        Placing
                        <Compare  />
                    </Button>
                    <ChangePlacingDialog open={openPlacingDialog} selectedValue={''} onClose={onClosePlacingDialog } handlePlacingChangeSubmit={handlePlacingChangeSubmit}  ></ChangePlacingDialog>
                    {/* <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <Delete />
                    </IconButton>
                    </Tooltip> */}
                
                </>
                
            ) : 
            <>
                <Button variant="contained"  style={{marginLeft:8, marginRight:8}} onClick={()=> setOpenNewTagDetailDialog(true)}>
                    Detail
                    <Add  />
                </Button>
                <NewTagDetailDialog openDialog={openNewTagDetailDialog} handleCloseDialog={handleNewTagDetailClose} schedules={schedules} order={order} refreshData={refreshData}></NewTagDetailDialog>
            </>}
        </Toolbar>
    );
};


export interface ChangeScheduleDialogProps {
    open: boolean;
    selectedValue: string;
    schedules: Array<ScheduleInfo>
    onClose: (value: string) => void;
    handleScheduleChangeSubmit:(schedule: string)=> void
}

function ChangeScheduleDialog(props: ChangeScheduleDialogProps) {
    const classes = useStyles();
    const [schedule, setSchedule] = React.useState('');
    const [openNewScheduleDialog, setOpenNewScheduleDialog] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSchedule(event.target.value as string);
    };

    const { onClose, selectedValue, open , schedules, handleScheduleChangeSubmit} = props;
    const [allSchedules, setAllSchedule] = React.useState(schedules);

    const handleClose = () => {
        onClose(selectedValue);
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose(selectedValue);
    }

    function handleSubmit(): void {
        // if (name.length === 0) {
        //     return
        // }
        // onClose(name)
        onClose(schedule)
        handleScheduleChangeSubmit(schedule)
    }

    const handleDialogClose = async (scheduleId: string, scheduleName: string) => {
        // console.log(scheduleId, scheduleName)
        setAllSchedule([...allSchedules,{scheduleNo:scheduleId, scheduleName:`${ scheduleId} -${scheduleName}`}])
        setOpenNewScheduleDialog(false)
    };

    const handleDialogClickOpen = () => {
        setOpenNewScheduleDialog(true);
    };


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Change Schedule</DialogTitle>
            <DialogContent>
                <FormControl variant="filled" >
                    <InputLabel id="demo-simple-select-filled-label">Schedule</InputLabel>
                    <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={schedule}
                    onChange={handleChange}
                    style={{width:200}}
                    >
                    {/* <MenuItem value="">
                        <em>None</em>
                    </MenuItem> */}
                    {
                        allSchedules.map((schedule)=>(
                            <MenuItem key={schedule.scheduleNo} value={`${schedule.scheduleNo}|${schedule.scheduleName}`}>{schedule.scheduleName}</MenuItem>
                        ))
                    }
                    
                    
                    </Select>
                </FormControl>
                <div>
                    <Button color="secondary"  onClick={handleDialogClickOpen}>
                        + Add
                    </Button>
                </div>
                <AddNewDetailingScheduleScheduleDialog open={openNewScheduleDialog} selectedValue={""} onClose={handleDialogClose}></AddNewDetailingScheduleScheduleDialog>
            
            </DialogContent>

            <DialogActions>
                {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Set Schedule
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export interface ChangePlacingDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    handlePlacingChangeSubmit:(value: string)=> void
}

function ChangePlacingDialog(props: ChangePlacingDialogProps) {
    const classes = useStyles();
    // const [name, setName] = useState('')

    const { onClose, selectedValue, open, handlePlacingChangeSubmit } = props;
    const [placing, setPlacing] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPlacing(event.target.value as string);
    };

    const handleClose = () => {
        onClose(selectedValue);
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose(selectedValue);
    }

    function handleSubmit(): void {
        // if (placing.length === 0) {
        //     return
        // }
        onClose(placing)
        handlePlacingChangeSubmit(placing)
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Change Placing</DialogTitle>
            <DialogContent>
            <FormControl variant="filled" >
                <InputLabel id="demo-simple-select-filled-label">Placing</InputLabel>
                <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={placing}
                onChange={handleChange}
                style={{width:200}}
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'Bar & Mesh'}>Place Bar & Mesh</MenuItem>
                <MenuItem value={'Not Tied'}>Supply Only</MenuItem>
                <MenuItem value={'Sacrificial'}>Sacrificial items not chargable</MenuItem>
                </Select>
            </FormControl>
            </DialogContent>

            <DialogActions>
                {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
                <Button color="secondary" onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Set Placing
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export interface ChangeReasonDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    handleReasonChangeSubmit:(value: string)=> void
}

function ChangeReasonDialog(props: ChangeReasonDialogProps) {
    const classes = useStyles();
    // const [name, setName] = useState('')

    const { onClose, selectedValue, open, handleReasonChangeSubmit } = props;
    const [reason, setReason] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setReason(event.target.value as string);
    };

    const handleClose = () => {
        onClose(selectedValue);
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose(selectedValue);
    }

    function handleSubmit(): void {
        // if (reason.length === 0) {
        //     return
        // }
        onClose(reason)
        handleReasonChangeSubmit(reason)
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Change Reason Code</DialogTitle>
            <DialogContent>
            <FormControl variant="filled" >
                <InputLabel id="demo-simple-select-filled-label">Reason</InputLabel>
                <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={reason}
                onChange={handleChange}
                style={{width:200}}
                >
                <MenuItem value="" >
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'CE'}>Customer Error</MenuItem>
                <MenuItem value={'DC'}>Design Change</MenuItem>
                <MenuItem value={'DE'}>Detailing Error</MenuItem>
                <MenuItem value={'EC'}>Extra to Contract</MenuItem>
                <MenuItem value={'FE'}>Freight Error</MenuItem>
                <MenuItem value={'PE'}>Placing Error</MenuItem>
                <MenuItem value={'PS'}>Product Substitution</MenuItem>
                <MenuItem value={'SE'}>Shop Error</MenuItem>
                </Select>
            </FormControl>
            </DialogContent>

            <DialogActions>
                {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
                <Button color="secondary" onClick={() => handleClose()}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Set Reason
                </Button>
            </DialogActions>
        </Dialog>
    );
}

