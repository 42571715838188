import { useState } from 'react'
import { makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import { 
    Divider, LinearProgress} from "@material-ui/core"; 
// import { useNavigate } from "react-router-dom"



let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        margin: {
            display: "flex",
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        dataGrid: {
            backgroundColor: "white"
        }
    })
);

// interface EncoreSearchDto {
//     opportunities: Array<OpportunityInfo>
//     opportunityFiles: Array<OpportunityFileDetail>
//     activeLoads: Array<LoadInfo>
//     dispatchedLoads: Array<LoadInfo>
//     jobs: Array<JobsInfo>
//     tags: Array<TagInfo>
// }

interface Props {
    globalBranch: string
}

function AdminMain({ globalBranch }: Props) {

    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    // const [searchInput, setSearchInput] = useState<string>('')

    // const [opportunities, setOpportunities] = useState<Array<OpportunityInfo>>([])
    // const [opportunityFiles, setOpportunityFiles] = useState<Array<OpportunityFileDetail>>([])
    // const [activeLoads, setActiveLoads] = useState<Array<LoadInfo>>([])
    // const [dispatchedLoads, setDispatchedLoads] = useState<Array<LoadInfo>>([])
    // const [jobs, setJobs] = useState<Array<JobsInfo>>([])
    // const [tags, setTags] = useState<Array<TagInfo>>([])
    // const [reload, setReload] = useState<boolean>(false)
    // const [isNoResults, setIsNoResults] = useState<boolean>(false)

    // useEffect(() => {
    //     let savedSearch = sessionStorage.getItem('encore-search')
    //     if(savedSearch) {
    //         setReload(true)
    //         setSearchInput(savedSearch)
    //     }
    // }, [])

    // useEffect(() => {
    //     if(reload) {
    //         searchItems()
    //         setReload(false)
    //     }
    // }, [reload])

    // useEffect(() => {
    //     if (sTimer !== null) {
    //         clearTimeout(sTimer);
    //     }
    //     sTimer = setTimeout(() => {
    //         if(!reload) {
    //             if (searchInput.length > 0) {
    //                 sessionStorage.setItem("encore-search", searchInput)
    //                 searchItems()
    //             } else {
    //                 sessionStorage.removeItem("encore-search")
    //                 clearAllItems()
    //             }
    //         }
    //     }, 1500);
    // }, [searchInput])

    // const searchItems = () => {
    //     setLoading(true)
    //     clearAllItems()
    //     API.get("", `/encore/all/search/${searchInput}`, {})
    //     .then((result: EncoreSearchDto) => {
    //         let noResults = true
    //         if(result.opportunities) {
    //             setOpportunities(result.opportunities)
    //             noResults = false
    //         }
    //         if(result.opportunityFiles) {
    //             setOpportunityFiles(result.opportunityFiles)
    //             noResults = false
    //         }
    //         if(result.activeLoads) {
    //             setActiveLoads(result.activeLoads)
    //             noResults = false
    //         }
    //         if(result.dispatchedLoads) {
    //             setDispatchedLoads(result.dispatchedLoads)
    //             noResults = false
    //         }
    //         if(result.jobs) {
    //             setJobs(result.jobs)
    //             noResults = false
    //         }
    //         if(result.tags) {
    //             setTags(result.tags)
    //             noResults = false
    //         }
    //         setLoading(false)
    //         setIsNoResults(noResults)
    //     })
    //     .catch((error: any) => {
    //         console.log("Error: searching opportunities", error)
    //         setLoading(false)
    //     })
    // }

    // const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
    //     setSearchInput(event.target.value)
    // }

    // const handleClearSearch = () => {
    //     sessionStorage.removeItem("encore-search")
    //     if(searchInput.length !== 0) {
    //         setSearchInput('')
    //         clearAllItems()
    //         setIsNoResults(false)
    //     }
    // }

    // const clearAllItems = () => {
    //     setOpportunities([])
    //     setOpportunityFiles([])
    //     setActiveLoads([])
    //     setDispatchedLoads([])
    //     setJobs([])
    //     setTags([])
    // }
 
    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Admin Dashboard
                </h2>
                <div className={classes.margin}>
                    {/* <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Search"
                            value={searchInput}
                            InputProps={{
                                className: classes.input,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton color="inherit" onClick={handleClearSearch}>
                                        <ClearAll />
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        </Grid>
                    </Grid> */}
                </div>
            </div>
            <Divider/>
            {loading && 
                <LinearProgress 
                    style={{marginBottom: 16}} 
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            
        </div>
    )
}

export default AdminMain
