import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import { 
    Typography, Button,
    Dialog, DialogTitle, DialogContent, DialogActions, Grid,
    FormControl, FormLabel, TextField, Select, MenuItem, FormHelperText,
    CircularProgress
} from "@material-ui/core"; 
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import PlacesAutocomplete from "react-places-autocomplete";
import { OpportunityInfo } from '../../../types/OpportunityTypes';
import { Autocomplete } from '@material-ui/lab';
import { ContactsOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        }
    })
);

interface JobCardDetail {
    deadline: Date;
    jobName: string;
    exoJobNo: string;
    description: string;
    jobAddress: string;
    marketSegment: string;
    type: string;
    branch: string;
    assignedTo: string;
    rfqDate: Date;
    estStartDate: Date;
    estTonnes: string;
    streetNumber: string;
    street: string;
    suburb: string;
    region: string;
    postcode: string;
    status: string;
    wonDate: Date;
    lostDate: Date;
    submittedDate: Date;
    contractType: string;
    contractValue: Number;
}

interface Props {
    openAddOpportunityDialog: boolean
    handleDialog: (open: boolean) => void
    globalBranch?: string
    user: any | null
    getOpportunities: () => void
    opportunityDetail?: OpportunityInfo
}
 
export default function AddOpportunityDialog({ openAddOpportunityDialog, handleDialog, globalBranch, user, getOpportunities, opportunityDetail }: Props) {

    const classes = useStyles()

    const [addressInput, setAddressInput] = useState<string>('')
    const [addressValue, setAddressValue] = useState<string>()
    const [addOpportunityLoading, setAddOpportunityLoading] = useState<boolean>(false)
    const [users, setUsers] = useState<Array<string>>([])
    const [usersLoading, setUsersLoading] = useState<boolean>(false)


    const { handleSubmit, control, errors, clearErrors, setValue, setError, reset } = useForm<JobCardDetail>({
        shouldUnregister: false
    });

    useEffect(() => {
        if(openAddOpportunityDialog) {
            clearErrors()

            if(globalBranch && globalBranch !== "All") {
                setValue("branch", globalBranch)
            } else if(globalBranch && globalBranch === "All" && !opportunityDetail) {
                setValue("branch", "")
            }
        }
    }, [globalBranch, openAddOpportunityDialog])

    useEffect(() => {
        if(openAddOpportunityDialog && opportunityDetail) {
            reset(opportunityDetail)
            setAddressInput(opportunityDetail.jobAddress)
            setAddressValue(opportunityDetail.jobAddress)
        }
    }, [openAddOpportunityDialog, opportunityDetail])

    useEffect(() => {
        if(openAddOpportunityDialog ) {
            //Get Users
            setUsersLoading(true)
            API.get("", "/encore/users", {})
            .then((response: Array<string>) => {
                if(response) {
                    setUsers(response)
                }
                setUsersLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunities", error)
                setUsersLoading(false)
            })
        }
    }, [openAddOpportunityDialog])


    function onSubmit(data: JobCardDetail) {
        // if(!addressValue) {
        //     setError("jobAddress", {message: "Invalid address"})
        // } else {

            


        // }
        data.jobAddress = addressValue?addressValue:"";

            console.log(data);

            var params = {
                body: {
                    username:user.attributes.name,
                    ...data
                }
            }

            setAddOpportunityLoading(true)

            if(opportunityDetail) {
                API.put("", "/encore/opportunities/postOpportunity", params)
                .then(() => {
                    setAddOpportunityLoading(false)
                    handleDialog(false)
                    getOpportunities()
                })
                .catch((error: any) => {
                    console.log("Error: updating opportunity", error)
                    setAddOpportunityLoading(false)
                })

            } else {
               
                API.post("", "/encore/opportunities/postOpportunity", params)
                .then(() => {
                    setAddOpportunityLoading(false)
                    handleDialog(false)
                    getOpportunities()
                })
                .catch((error: any) => {
                    console.log("Error: posting opportunity", error)
                    setAddOpportunityLoading(false)
                })
            }
    }

    return (
        <Dialog fullWidth maxWidth={'md'}  open={openAddOpportunityDialog} onClose={() => handleDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Opportunity</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                <Grid item xs={6}>
                        <FormControl
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Exo Job No.</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Exo Job No"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="exoJobNo"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.status)}
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Status</FormLabel>

                            <Controller
                                as={
                                    <Select variant="filled" 
                                        defaultValue="New">
                                        <MenuItem value="New">New</MenuItem>
                                        <MenuItem value="Pipeline">Pipeline</MenuItem>
                                        <MenuItem value="Measured">Measured</MenuItem>
                                        <MenuItem value="Not Quoting">Not Quoting</MenuItem>
                                        <MenuItem value="Quoting">Quoting</MenuItem>
                                        <MenuItem value="Submitted">Submitted</MenuItem>
                                        <MenuItem value="Following Up">Following Up</MenuItem>
                                        <MenuItem value="Won">Won</MenuItem>
                                        <MenuItem value="Lost">Lost</MenuItem>
                                    </Select>
                                }
                                name="status"
                                control={control}
                                defaultValue="New"
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.status && errors.status.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            error={Boolean(errors.jobName)} 
                            className={classes.formInput}
                            size='small'>

                            <FormLabel className={classes.label}>Job Name</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Job Name"
                                        fullWidth
                                        variant="filled"
                                        size="small"
                                        error={errors.jobName ? true : false}
                                        helperText={errors.jobName?.message}/>
                                }
                                name="jobName"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>
                    </Grid>

                    

                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formInput}
                            size='small'>

                            <FormLabel className={classes.label}>Description</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter description"
                                        fullWidth
                                        variant="filled"
                                        size="small"
                                        multiline
                                        rows={3}/>
                                }
                                name="description"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            error={Boolean(errors.jobAddress)} 
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Job Address</FormLabel>

                            <PlacesAutocomplete 
                                value={addressInput}
                                onChange={(address: string) => {
                                    setAddressInput(address)
                                    clearErrors("jobAddress")
                                    setAddressValue(address)
                                }}
                                onSelect={(_, placeID: string) => {
                                    var request = {
                                        placeId: placeID
                                    };
                            
                                    var placeRequest: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
                                        new google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                                if (place != null) {
                                                    resolve(place);
                                                }
                                                
                                            } else { reject() };
                                        });
                                    })
                                    placeRequest.then((result: google.maps.places.PlaceResult) => {
                                        if(result && result?.formatted_address && result?.address_components) {
                                            setAddressInput(result.formatted_address)
                                            setAddressValue(result.formatted_address)

                                            result.address_components.forEach((c) => {
                                                if(c.types.includes("street_number")) {
                                                    setValue("streetNumber", c.long_name)

                                                } else if(c.types.includes("route")) {
                                                    setValue("street", c.long_name)

                                                } else if(c.types.includes("sublocality_level_1")) {
                                                    setValue("suburb", c.long_name)

                                                } else if(c.types.includes("locality")) {
                                                    setValue("region", c.long_name)
                                                
                                                } else if(c.types.includes("postal_code")) {
                                                    setValue("postcode", c.long_name)

                                                }
                                            })
                                        }
                                    })
                                    .catch((error: any) => {
                                        console.log("Error: ", error)
                                    })
                                }}
                                searchOptions={{
                                    componentRestrictions: { country: ['nz'] },
                                    types: ['address']
                                }}
                                onError={(_, clearSuggestions) => {
                                    clearSuggestions()
                                }}>

                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            size="small"

                                            {...getInputProps({
                                                placeholder: 'Enter address',
                                            })}
                                        />
                                        <div className={classes.addressOptions}>
                                        {loading && 
                                            <div style={{backgroundColor: '#ffffff', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa"}}>
                                                <Typography variant="body1">
                                                    Loading ...
                                                </Typography>
                                            </div>
                                        }

                                        {suggestions.map((suggestion: any, i: number) => {
                                            let style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" };

                                            if((i === suggestions.length - 1) && !suggestion.active) {
                                                style = { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "2px solid #fafafa" }
                                            }

                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                                    <Typography variant="body1">{suggestion.description}</Typography>
                                                </div>
                                            );
                                        })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <FormHelperText>
                                {errors.jobAddress && "Invalid address"}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.branch)}
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Branch</FormLabel>

                            <Controller
                                as={
                                    <Select variant="filled"
                                        displayEmpty>
                                        <MenuItem value="" disabled>Select an option</MenuItem>
                                        <MenuItem value="Auckland">Auckland</MenuItem>
                                        <MenuItem value="Christchurch">Christchurch</MenuItem>
                                        <MenuItem value="Cromwell">Cromwell</MenuItem>
                                        <MenuItem value="Dunedin">Dunedin</MenuItem>
                                        <MenuItem value="Nelson">Nelson</MenuItem>
                                        <MenuItem value="Mt Maunganui">Mt Maunganui</MenuItem>
                                        <MenuItem value="Wellington">Wellington</MenuItem>
                                    </Select>
                                }
                                name="branch"
                                control={control}
                                defaultValue={globalBranch !== "All" ? globalBranch : ""}
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.branch && errors.branch.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.assignedTo)} 
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Assigned To</FormLabel>

                            {/* <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Assigned To"
                                        fullWidth
                                        variant="filled"
                                        size="small"
                                        error={errors.assignedTo ? true : false}
                                        helperText={errors.assignedTo?.message}/>
                                }
                                name="assignedTo"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            /> */}
                            <Controller
                                render={(props) => (
                                <Autocomplete
                                    {...props}
                                    freeSolo
                                    loading={usersLoading}
                                    options={users}
                                    // getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params} 
                                            placeholder="Enter Assigned To"
                                            fullWidth
                                            variant="filled"
                                            size="small"
                                            error={errors.assignedTo ? true : false}
                                            helperText={errors.assignedTo?.message}/>
                                    )}
                                    onInputChange={async (_, newInputValue) => {
                                        if(newInputValue.length !== 0) {
                                            // setValue("assignedTo", newInputValue)
                                            clearErrors("assignedTo")
                                        }
                                    }}
                                    onChange={(_, data) => {
                                        setValue("assignedTo", data)
                                        props.onChange(data)}}
                                    />
                            )}
                        name="assignedTo"
                        control={control}
                        rules={{
                            
                        }}
                        defaultValue={null}
                    />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl
                            error={Boolean(errors.marketSegment)}
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Market Segment</FormLabel>

                            <Controller
                                as={
                                    <Select variant="filled" 
                                        defaultValue="Residential">
                                        <MenuItem value="Commercial">Commercial</MenuItem>
                                        <MenuItem value="Infrastructure">Infrastructure</MenuItem>
                                        <MenuItem value="Piling">Piling</MenuItem>
                                        <MenuItem value="Precast">Precast</MenuItem>
                                        <MenuItem value="Residential">Residential</MenuItem>
                                    </Select>
                                }
                                name="marketSegment"
                                control={control}
                                defaultValue="Residential"
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.marketSegment && errors.marketSegment.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.rfqDate)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>RFQ Date</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker3"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="rfqDate"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.rfqDate && errors.rfqDate.message}
                            </FormHelperText>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.submittedDate)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Submitted Date</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker3"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="submittedDate"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    // required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.submittedDate && errors.submittedDate.message}
                            </FormHelperText>
                        </FormControl>
                        
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.type)}
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Type</FormLabel>

                            <Controller
                                as={
                                    <Select variant="filled" 
                                        defaultValue="Supply">
                                        <MenuItem value="Supply">Supply</MenuItem>
                                        <MenuItem value="Supply, Prefab">Supply, Prefab</MenuItem>
                                        <MenuItem value="Supply, Install">Supply, Install</MenuItem>
                                    </Select>
                                }
                                name="type"
                                control={control}
                                defaultValue="Supply"
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.type && errors.type.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.contractType)}
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Contract Type</FormLabel>

                            <Controller
                                as={
                                    <Select variant="filled" 
                                        defaultValue="">
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="Lump sum">Lump sum</MenuItem>
                                        <MenuItem value="Rate">Rate</MenuItem>
                                    </Select>
                                }
                                name="contractType"
                                control={control}
                                defaultValue="Supply"
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.contractType && errors.contractType.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.deadline)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Quote Deadline</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker2"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="deadline"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.deadline && errors.deadline.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.wonDate)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Won Date</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker3"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="wonDate"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    // required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.wonDate && errors.wonDate.message}
                            </FormHelperText>
                        </FormControl>
                        
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Est Tonnes</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Est Tonnes"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="estTonnes"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl
                            className={classes.formInput}
                            size="small">

                            <FormLabel className={classes.label}>Contract Value</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Contract Value"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="contractValue"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.estStartDate)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Est Start Date</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker4"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="estStartDate"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.estStartDate && errors.estStartDate.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl
                            error={Boolean(errors.lostDate)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Lost Date</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker3"
                                                value={value}
                                                onChange={date => onChange(date)}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="lostDate"
                                control={control}
                                defaultValue={null}
                                rules={{
                                    // required: "This is required"
                                }}
                            />
                            <FormHelperText style={{marginLeft: 14}}>
                                {errors.lostDate && errors.lostDate.message}
                            </FormHelperText>
                        </FormControl>
                        
                    </Grid>

                </Grid>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {addOpportunityLoading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        {opportunityDetail ?
                            <span>Submit</span>
                        :
                            <span>Create</span>
                        }
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
