import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Typography, Card, Divider, CardHeader, CardContent, Tabs, Tab, Radio, Button,
    IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Chip
} from "@material-ui/core";
import { 
    Add, Edit, FileCopyOutlined, FormatAlignJustify
} from "@material-ui/icons";
import TabPanel from '../../../../components/TabPanel';
import {
    OpportunityInfo, OpportunityCustomerDetail, CreateNewCustomerForm, AddCustomerForm,
    OpportunityCustomerQuoteDetail, OpportunityCustomerContactDetail
} from '../../../types/OpportunityTypes';
import { formatDateTime } from '../../../../utils/utils';
// import GenCustomerQuoteDialog from '../dialogs/GenCustomerQuoteDialog';
import ConvertToJobDialog from '../dialogs/ConvertToJobDialog';
import AddCustomerDialog from '../dialogs/AddCustomerDialog';
import CreateCustomerDialog from '../dialogs/CreateCustomerDialog';
import AddQuoteDialog from '../dialogs/AddQuoteDialog';
import AddContactDialog from '../dialogs/AddContactDialog';
import ConfirmCreateCustomerQuoteDialog from '../dialogs/ConfirmCreateCustomerQuoteDialog';
import { API } from 'aws-amplify';
import { CustomerQuoteEditDetailDialog } from '../dialogs/CustomerQuoteEditDetailDialog';
import { CreateCustomerMeasureQuoteDialog } from '../dialogs/CreateCustomerMeasureQuoteDialog';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        cardHeader: {
            backgroundColor: "#f5f5f5",
            padding: 16
        },
        cardValue: {
            fontWeight: "bold"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        }
    })
);

interface OpportunityCustomersTabProps {
    opportunityNo: string
    user: any | null
    opportunityDetail: OpportunityInfo | undefined
    customers: Array<OpportunityCustomerDetail>
    selectedCustomer: OpportunityCustomerDetail | undefined
    selectedCustomerIdx: number
    setSelectedTab: (tab: number) => void;
    setSelectedCustomer: (customer: OpportunityCustomerDetail) => void
    setSelectedCustomerIdx: (idx: number) => void
    setSelectedMeasureFileIdx: (idx: number) => void
    getOpportunityDetail: () => void;
    getCustomers: () => void;
}

export default function OpportunityCustomersTab({ opportunityNo, user, opportunityDetail, customers, selectedCustomer, selectedCustomerIdx, setSelectedTab, setSelectedCustomer, setSelectedCustomerIdx, setSelectedMeasureFileIdx, getOpportunityDetail, getCustomers }: OpportunityCustomersTabProps) {
    
    const classes = useStyles()

    const [selectedCustomerTab, setSelectedCustomerTab] = useState<number>(0)
    const [openAddCustomerDialog, setOpenAddCustomerDialog] = useState<boolean>(false)
    const [openCreateCustomerDialog, setOpenCreateCustomerDialog] = useState<boolean>(false)
    const [openAddQuiteDialog, setOpenAddQuoteDialog] = useState<boolean>(false)
    // const [openGenCustomerQuoteDialog, setOpenGenCustomerQuoteDialog] = useState<boolean>(false)
    const [newCustomerForm, setNewCustomerForm] = useState<CreateNewCustomerForm>()
    const [addCustomerForm, setAddCustomerForm] = useState<AddCustomerForm>()
    const [openAddContactDialog, setOpenAddContactDialog] = useState<boolean>(false)
    const [selectedWinner, setSelectedWinner] = useState<OpportunityCustomerDetail>()
    const [selectedQuoteIdx, setSelectedQuoteIdx] = useState<number>()
    const [showConvertToJobDialog, setConvertToJobDialog] = useState<boolean>(false)
    const [openConfirmCreateCustomerQuoteDialog, setOpenConfirmCreateCustomerQuoteDialog] = useState<boolean>(false)
    const [openCustomerQuoteDetailDialog, setOpenCustomerQuoteDetailDialog] = useState<boolean>(false)
    const [selectedQuote, setSelectedQuote] = useState<OpportunityCustomerQuoteDetail>()
    const [openCreateCustomerQuoteDialog, setOpenCreateCustomerQuoteDialog] = useState<boolean>(false)

    const handleConfirmCreateCustomerQuote = async (isOk: boolean) => {
        setOpenConfirmCreateCustomerQuoteDialog(false)
        if (isOk){
            console.log("OK")
            try {
                let params = {
                    body: {
                        oppNo: parseInt(opportunityNo, 10),
                        customerId: selectedCustomer?.seqNo,
                        customerName: selectedCustomer?.customerName,
                        username:user.attributes.name,
                    }
                }
                await API.post("", `/encore/opportunities/addCustomer/${opportunityNo}/customers/${selectedCustomer?.seqNo}/quotes/new`, params)
                
            } catch (error) {
                console.log(error)
                
            }
            finally{
                getCustomers()
            }

        }

    }
    
    return (
        <div style={{marginTop: 8}}>
            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button variant="contained" color="secondary" onClick={() => setOpenAddCustomerDialog(true)} disabled={opportunityDetail?.status.includes("JOB") || opportunityDetail?.status === "Not Quoting"}>
                        <Add />
                        Add Customer
                    </Button>
                </div>
            </div>
            {customers.length !== 0 && 
                <div style={{marginTop: 16, display: "flex"}}>
                    <div style={{width: 300, marginRight: 16}}>
                        <Card raised>
                            <div className={classes.cardHeader}>
                                <div>
                                    <div>
                                        <Typography variant="h6">Customers</Typography>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor: "white"}}>
                                <List>
                                    { customers.length !== 0 && customers.map((customer: OpportunityCustomerDetail, i: number) => (
                                        <div key={customer.seqNo}>
                                            <ListItem>
                                                <ListItemText 
                                                    primary={`${customer.customerName}`}
                                                    secondaryTypographyProps={{
                                                        component: 'div'
                                                    }}
                                                    secondary={
                                                        customer.contacts && (<div>
                                                            <div>Contact Name: {customer.contacts[0].contactName}</div>
                                                            <div>Email: {customer.contacts[0].email}</div>
                                                            <div>Phone: {customer.contacts[0].phone}</div>
                                                            {opportunityDetail?.winnerCustomerSeqNo === customer.seqNo &&
                                                                <Chip style={{marginTop: 8, marginRight: 4}} label="WINNER" color="secondary" />
                                                            }
                                                            {customer.exoAccNo !== 0 &&
                                                                <Chip style={{marginTop: 8}} label="DEBTOR" color="primary" />
                                                            }
                                                        </div>)
                                                        
                                                    }/>
                                                <ListItemSecondaryAction>
                                                    <Radio
                                                        checked={selectedCustomerIdx === i}
                                                        onChange={() => {
                                                            setSelectedCustomerIdx(i);
                                                            setSelectedCustomer(customers[i])
                                                        }}/>
                                                </ListItemSecondaryAction>
                                                    
                                            </ListItem>
                                            {i !== customers.length -1 &&
                                                <Divider />
                                            }
                                        </div>
                                    ))}
                                </List>
                            </div>
                        </Card>  
                    </div>
                    {selectedCustomer && 
                        <div style={{flexGrow: 1, padding: 8}}>
                            <div>
                                <h2>Customer Details</h2>
                            </div>
                            <Tabs value={selectedCustomerTab} onChange={(_, value) => setSelectedCustomerTab(value)} aria-label="customer tabs">
                                <Tab label="Detail" />
                                <Tab label="Quotes" />
                                <Tab label="Contacts" />
                            </Tabs>
                            <Divider />

                            <TabPanel value={selectedCustomerTab} index={0}>
                                <div>
                                    <Card style={{marginTop: 16}}>
                                        <CardHeader title={selectedCustomer.customerName} />
                                        <CardContent>
                                            <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Customer No: <span className={classes.cardValue}>{selectedCustomer.seqNo}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Address: <span className={classes.cardValue}>{selectedCustomer.address}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Accounts Email: <span className={classes.cardValue}>{selectedCustomer.accountsEmail}</span></span>
                                                </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </TabPanel>

                            <TabPanel value={selectedCustomerTab} index={1}>
                                <div>
                                    <div style={{display: "flex", marginTop:"16px"}}>
                                        <div style={{flexGrow: 1}}>
                                        </div>
                                        <div>
                                            <Button variant="contained" color="secondary" onClick={() => setOpenCreateCustomerQuoteDialog(true)} >
                                                <Add />
                                                Create Customer Quote
                                            </Button>
                                        </div>
                                    </div>
                                    {customers[selectedCustomerIdx].quotes && customers[selectedCustomerIdx].quotes.map((quote: OpportunityCustomerQuoteDetail, i: number) => (
                                        <Card style={{marginTop: 16}} key={`${quote.custSeqNo}${quote.opportunitySeqNo}${quote.measureId}`}>
                                            <CardHeader 
                                                title={`Quote details v${customers[selectedCustomerIdx].quotes.length-i}`}
                                                action={
                                                    <div>
                                                        <Button 
                                                            style={{marginRight: 8 }}
                                                            variant="contained" 
                                                            color="secondary" 
                                                            onClick={() => {
                                                                setSelectedQuote(quote)
                                                                setOpenCustomerQuoteDetailDialog(true)    
                                                            }} 
                                                            >

                                                            Detail
                                                            <Edit style={{ marginLeft: 4 }} />
                                                        </Button>
                                                        <Button variant="contained" style={{marginRight: 8 }} color="secondary" target='_blank' href={`#/report/viewer/105?MeasureID=${quote?.measureId}&Customer=${customers[selectedCustomerIdx].seqNo}`}>
                                                            Quote Report
                                                            <FileCopyOutlined style={{ marginLeft: 4 }} />
                                                        </Button>
                                                        <Button 
                                                            variant="contained" 
                                                            color="secondary" 
                                                            onClick={() => {
                                                                setConvertToJobDialog(true)
                                                                setSelectedWinner(customers[selectedCustomerIdx])
                                                                setSelectedQuoteIdx(i)
                                                            }} 
                                                            disabled={opportunityDetail!.jobNo.length === 0 || opportunityDetail?.status === "Not Quoting"}>

                                                            Convert to Job
                                                        </Button>
                                                        <IconButton 
                                                            onClick={() => {
                                                                setSelectedTab(2)
                                                                setSelectedMeasureFileIdx(i)
                                                            }}>
                                                            <FormatAlignJustify />
                                                        </IconButton>
                                                    </div>
                                                }/>
                                            <CardContent>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Measure Filename: <span className={classes.cardValue}>{quote.filename}</span></span>
                                                    <span>Service Type: <span className={classes.cardValue}>{quote.serviceType}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Uploaded By: <span className={classes.cardValue}>{quote.uploadedBy}</span></span>
                                                    <span>Uploaded At: <span className={classes.cardValue}>{formatDateTime(quote.uploadedAt)}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Supply Tonnes: <span className={classes.cardValue}>{quote.supplyTonnes}</span></span>
                                                    <span>Place Tonnes: <span className={classes.cardValue}>{quote.placeTonnes}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Supply Amount: <span className={classes.cardValue}>{`$${quote.supplyTotal}`}</span></span>
                                                    <span>Place Amount: <span className={classes.cardValue}>{`$${quote.placeTotal}`}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Mesh Amount: <span className={classes.cardValue}>{quote.meshTotal}</span></span>
                                                    <span>Other Amount: <span className={classes.cardValue}>{quote.otherTotal}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Total Amount: <span className={classes.cardValue}>{`$${quote.total}`}</span></span>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>

                            </TabPanel>

                            <TabPanel value={selectedCustomerTab} index={2}>
                                <div style={{display: "flex", marginTop: 8}}>
                                    <div style={{flexGrow: 1}}>
                                    </div>
                                    <div>
                                        <Button variant="contained" color="secondary" onClick={() => setOpenAddContactDialog(true)} disabled={opportunityDetail?.status.includes("JOB") || opportunityDetail?.status === "Not Quoting"}>
                                            <Add />
                                            Add Contact
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    {selectedCustomer.contacts && selectedCustomer.contacts.map((contact: OpportunityCustomerContactDetail) => (
                                        <Card style={{marginTop: 16}} key={contact.seqNo}>
                                            <CardHeader title={contact.contactName} />
                                            <CardContent>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Role: <span className={classes.cardValue}>{contact.role}</span></span>
                                                </div>
                                                <div className={classes.horizontalJustifiedLayout}>
                                                    <span>Email: <span className={classes.cardValue}>{contact.email}</span></span>
                                                    <span>Phone: <span className={classes.cardValue}>{contact.phone}</span></span>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>

                            </TabPanel>
                        </div>  
                    }                                 
                </div>
            }

                <AddCustomerDialog 
                    openDialog={openAddCustomerDialog} 
                    handleAddDialog={setOpenAddCustomerDialog} 
                    handleCreateDialog={setOpenCreateCustomerDialog} 
                    setAddCustomerForm={setAddCustomerForm}
                    user={user}
                    oppNo={opportunityNo}
                    getCustomers={getCustomers}
                    // setOpenGenQuoteDialog={setOpenGenCustomerQuoteDialog} 
                    customers={customers} />

                <CreateCustomerDialog 
                    openDialog={openCreateCustomerDialog} 
                    handleAddDialog={setOpenAddCustomerDialog} 
                    handleCreateDialog={setOpenCreateCustomerDialog}
                    user={user}
                    oppNo={opportunityNo}
                    getCustomers={getCustomers}
                    // setOpenGenQuoteDialog={setOpenGenCustomerQuoteDialog}
                    setNewCustomerForm={setNewCustomerForm} />

                <AddQuoteDialog 
                    openDialog={openAddQuiteDialog} 
                    handleQuoteDialog={setOpenAddQuoteDialog} />

                <AddContactDialog 
                    openDialog={openAddContactDialog} 
                    setOpenDialog={setOpenAddContactDialog} 
                    customer={selectedCustomer} 
                    getCustomers={getCustomers} 
                    opportunityNo={opportunityNo}/>

                {/* <GenCustomerQuoteDialog 
                    openDialog={openGenCustomerQuoteDialog}
                    handleDialog={setOpenGenCustomerQuoteDialog}
                    oppNo={opportunityNo}
                    user={user}
                    getCustomers={getCustomers}
                    newCustomerData={newCustomerForm}
                    addCustomerData={addCustomerForm}
                /> */}

                <ConvertToJobDialog
                    openDialog={showConvertToJobDialog}
                    handleCompleteOppDialog={setConvertToJobDialog}
                    oppNo={opportunityNo}
                    user={user}
                    jobNo={opportunityDetail?.jobNo}
                    selectedCustomer={selectedWinner}
                    selectedQuoteIdx={selectedQuoteIdx}
                    getOpportunityDetail={getOpportunityDetail} />

                    <ConfirmCreateCustomerQuoteDialog
                openDialog={openConfirmCreateCustomerQuoteDialog} 
                handleDialog={handleConfirmCreateCustomerQuote }
                />

                <CustomerQuoteEditDetailDialog open={openCustomerQuoteDetailDialog} 
                customerQuote={selectedQuote} 

                onClose={()=> {
                    setOpenCustomerQuoteDetailDialog(false)
                    getCustomers()
                }} />

                <CreateCustomerMeasureQuoteDialog 
                open={openCreateCustomerQuoteDialog}
                opportunityNo={opportunityNo}
                selectedCustomer={selectedCustomer}
                user={user}
                onClose={() => {
                    setOpenCreateCustomerQuoteDialog(false);
                    getCustomers();
                } } />
        </div>
    )
}
