import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, DialogTitle, Dialog, DialogContent, CircularProgress,
    DialogActions, FormControl, FormLabel, TextField
} from "@material-ui/core";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface OpportunityLostDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    user?: any | null;
    oppNo?: string;
    getOpportunityDetail: () => void;
    jobLostType: string | undefined;
}

export default function OpportunityLostDialog({ open, handleDialog, user, oppNo, getOpportunityDetail, jobLostType }: OpportunityLostDialogProps) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [reason, setReason] = useState<string>()
    const [reasonError, setReasonError] = useState<string>()

    const onSubmit = () => {
        if(user && oppNo) {
            if(reason) {
                setLoading(true)
                let params = {
                    body: {
                        oppNo: parseInt(oppNo, 10),
                        username:user.attributes.name,
                        jobLostReason: reason,
                        jobLostType: jobLostType
                    }
                }
                API.post("", `/encore/opportunities/markAsLost`, params)
                .then(() => {
                    setLoading(false)
                    handleDialog(false)
                    getOpportunityDetail()
                })
                .catch((error: any) => {
                    console.log("Error: marking opportunity as lost", error)
                    setError("Error: processing request")
                    setLoading(false)
                })
            } else {
                setReasonError("This is required")
            }
        } else {
            setError("Error: processing request")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                Set Opportunity Lost - {jobLostType}
            </DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to mark this opportunity as lost. This action will finish all activity on this opportunity.
                </p>

                <FormControl
                    error={Boolean(reasonError)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Reason</FormLabel>

                    <TextField
                        placeholder="Enter reason"
                        fullWidth
                        variant="filled"
                        multiline
                        rows={3}
                        value={reason}
                        onChange={(event) => {
                            setReasonError(undefined)
                            setReason(event.target.value as string)
                        }}
                        error={reasonError ? true : false}
                        helperText={reasonError}/>
                </FormControl>

            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="secondary">
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
