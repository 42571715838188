import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormLabel,
  DialogTitle,
  DialogContentText,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  FilledInput,
  Checkbox,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import {
  JobMeasureQuoteLineInfo,
  JobVariationSubSchedules,
} from "../../types/JobTypes";
import NumberFormat from "react-number-format";
import { API } from "aws-amplify";
import VariationTree from "./VariationTree";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
  })
);

interface QuoteDialogProps {
  openDialog: boolean;
  handleCloseDialog: (open: boolean) => void;
  jobMeasureQuoteInfo?: JobMeasureQuoteLineInfo;
  // isNewItem: boolean;
}

export default function SelectSubScheduleDialog({
  openDialog,
  handleCloseDialog,
  jobMeasureQuoteInfo,
}: QuoteDialogProps) {
  const classes = useStyles();

  const { handleSubmit, control, errors, reset } =
    useForm<JobMeasureQuoteLineInfo>();
  const [error, setError] = useState<string>();
  const [open, setOpen] = React.useState(false);
  const [subSchedules, setSubSchedules] = React.useState(
    Array<JobVariationSubSchedules>()
  );

  useEffect(() => {
    // console.log(jobMeasureQuoteInfo)
    reset(jobMeasureQuoteInfo);
    if (jobMeasureQuoteInfo?.jobSeqNo) {
      fetchSubschedules();
    }
  }, [jobMeasureQuoteInfo, reset]);

  async function onSubmit(data: JobMeasureQuoteLineInfo) {
    data.jobSeqNo = jobMeasureQuoteInfo?.jobSeqNo;
    // data.kg = parseInt(data.kg.toString())
    // data.placeRate = parseFloat(data.placeRate.toString())
    // data.supplyRate = parseFloat(data.supplyRate.toString())
    // data.schedule = parseFloat(data.schedule.toString())
    data.id = jobMeasureQuoteInfo?.id!;

    let params = {
      body: {
        ...data,
      },
    };
    //Post
    try {
      await API.post(
        "",
        `/encore/tags/${jobMeasureQuoteInfo?.id}/subschedule/update`,
        params
      );
      handleCloseDialog(false);
    } catch (error) {
      console.log(error);
      setError("Error: Unable to post record.");
    }
  }

  const handleDialogClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = async (value: string) => {
    // setSelectedValue(value);
    let params = {
      body: {
        jobSeqNo: jobMeasureQuoteInfo?.jobSeqNo,
        variationSubScheduleName: value,
      },
    };
    //Post
    try {
      await API.post(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/subschedules/add`,
        params
      );
    } catch (error) {
      console.log(error);
      setError("Error: Unable to post record.");
    } finally {
      fetchSubschedules();
      setOpen(false);
    }
  };

  const fetchSubschedules = async () => {
    try {
      const response = await API.get(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/subschedules`,
        {}
      );
      // console.log(response)
      setSubSchedules(response);
    } catch (error) {
      console.log(error);
      setError("Error: Fetching Subschedules for job.");
    } finally {
      setOpen(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={openDialog}
      onClose={() => handleCloseDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Select a Sub Schedule</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            Select or add a Sub Schedule
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formInput}>
              <FormLabel className={classes.label}>Sub Schedule</FormLabel>

              <Controller
                render={({ onChange, value }) => (
                  <Select
                    variant="filled"
                    value={value}
                    onChange={(e) => {
                      let val = e.target.value as string;
                      onChange(val);
                      // methods.clearErrors()
                      // claimSummary.type = val
                      // methods.reset(claimSummary)
                    }}
                  >
                    {/* <MenuItem value={0}>Select a Sub Schedule</MenuItem> */}
                    {subSchedules &&
                      subSchedules.map((ss) => (
                        <MenuItem value={ss.id}>
                          {ss.variationSubScheduleName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                name="variationSubScheduleId"
                control={control}
                // defaultValue="0"
                rules={{
                  required: "This is required",
                }}
              />
            </FormControl>

            <Button color="secondary" onClick={handleDialogClickOpen}>
              + Add
            </Button>

            <VariationScheduleDialog
              open={open}
              selectedValue={""}
              onClose={handleDialogClose}
            ></VariationScheduleDialog>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {error && <span style={{ color: "red" }}>{error}</span>}
        <Button color="secondary" onClick={() => handleCloseDialog(false)}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="secondary"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface VariationDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function VariationScheduleDialog(props: VariationDialogProps) {
  const classes = useStyles();
  const [name, setName] = useState("");

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  function handleCloseDialog(arg0: boolean): void {
    onClose(selectedValue);
  }

  function handleSubmit(): void {
    if (name.length === 0) {
      return;
    }
    onClose(name);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">New Variation Schedule</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Enter Schedule"
          fullWidth
          variant="filled"
          required
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
        <Button color="secondary" onClick={() => handleCloseDialog(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
