import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, FormControl, Dialog, DialogContent, Select, MenuItem,
    DialogActions, TextField, FormLabel, DialogTitle, CircularProgress,
    FormHelperText, Paper, List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import { Add, InsertDriveFile } from "@material-ui/icons"; 
import { Controller, useForm } from "react-hook-form";
import { API, Storage } from "aws-amplify";
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            width: "100%", 
            marginBottom: 16
        },
        label: {
            marginBottom: 6
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 75,
            paddingBottom: 75,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        }
    })
);


interface NoteDialogProps {
    openDialog: boolean;
    handleNoteDialog: (open: boolean) => void;
    user?: any | null;
    oppNo?: string;
    // getNotes?: () => void;
}

interface AddNoteForm {
    category: string
    note: string
    filename: string
    fileUrl: string
}

export default function AddNoteDialog({ openDialog, handleNoteDialog, user, oppNo }: NoteDialogProps) {

    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const { handleSubmit, control, errors, setValue, reset } = useForm<AddNoteForm>({
        shouldUnregister: false
    });
    const [showDropzone, setShowDropzone] = useState<boolean>(false)
    const [uploadedFile, setUploadedFile] = useState<Array<File>>([])

    useEffect(() => {
        if(openDialog) {
            reset()
        }
    }, [openDialog])

    function onSubmit(data: AddNoteForm) {
        if (user && oppNo) {
            setLoading(true)

            var params = {
                body: {
                    ...data,
                    oppSeqNo: parseInt(oppNo, 10),
                    postedBy:user.attributes.name
                }
            }
            API.post("", "/encore/opportunities/notes/postNote", params)
            .then(() => {
                setLoading(false)
                handleNoteDialog(false)
                // getNotes()
            })
            .catch((error: any) => {
                console.log(error)
                setLoading(false)
                setError(error)
            })
        }
    }

    const handleDrop = (files: Array<File>) => {
        let timeInMills = new Date().getTime()
        var objKey = oppNo + "_" + timeInMills + "_" + files[0].name;
    
        Storage.put(objKey, files[0])
        .then(() => {
            Storage.get(objKey, { level: 'public', download:false })
                .then(() => {
                    setUploadedFile(files)
                    setValue("filename", files[0].name)
                    setValue("fileUrl", objKey)
                })
                .catch((error: any) => { 
                  console.log("ERROR fetching from s3", error);
                });
        })
        .catch((error: any) => {
          console.log("ERROR uploading to s3", error);
        });
    }
    
    function calcSize(fileSize: number): string {
        if (fileSize < 1000000) {
            return `${Math.round(fileSize/Math.pow(10,3))} KB`
        }
        return `${(fileSize/Math.pow(10,6)).toFixed(1)} MB`
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleNoteDialog(false)} aria-labelledby="form-dialog-title">
            <DialogTitle>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}>
                        Add Note
                    </div>
                </div>

            </DialogTitle>
            <DialogContent>

                <FormControl
                    error={Boolean(errors.category)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Category</FormLabel>

                    <Controller
                        as={
                            <Select variant="filled" 
                                defaultValue=""
                                displayEmpty>
                                <MenuItem value="">Select category</MenuItem>
                                <MenuItem value="Email">Email</MenuItem>
                                <MenuItem value="Phone call">Phone call</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                            </Select>
                        }
                        name="category"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />

                    <FormHelperText>
                        {errors.category && errors.category.message}
                    </FormHelperText>
                </FormControl>

                <FormControl
                    error={Boolean(errors.note)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Note detail</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter note detail"
                                fullWidth
                                variant="filled"
                                multiline
                                rows={10}
                                error={errors.note ? true : false}
                                helperText={errors.note?.message}/>
                        }
                        name="note"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <Button variant="contained" color="secondary" style={{marginBottom: 16}} onClick={() => setShowDropzone(true)}>
                    <Add />
                    Upload File
                </Button>

                {showDropzone &&
                    <FormControl
                        className={classes.formInput}>
            
                        <FormLabel className={classes.label}>Upload File</FormLabel>
                        
                        <Controller
                            control={control}
                            name="filename"
                            defaultValue=""
                            render={() => (
                                <div>
                                    <Dropzone 
                                        onDrop={handleDrop} 
                                        multiple={false}
                                        accept={[
                                            'image/jpeg',
                                            'image/png',
                                            'application/pdf',
                                            'application/vnd.ms-outlook',
                                            'application/octet-stream',
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                        {({ getRootProps, getInputProps }) => (
                                            <Paper
                                                variant="outlined"
                                                {...getRootProps({className: classes.dropzone})}
                                                >
                                                <input {...getInputProps()} name="filename" />
                                                <p>Drag 'n' drop files here, or click to select files</p>
                                            </Paper>
                                        )}
                                    </Dropzone>
                                    
                                    {uploadedFile.length !== 0 && 
                                        <List>
                                            {uploadedFile.map((f: File, index: number) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                        <InsertDriveFile />
                                                    </ListItemIcon>
                                                    <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                </ListItem>
                                            ))}
                                        </List>
                                    }
                                </div>
                            )} />
                    </FormControl>
                }

            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleNoteDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Add
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}