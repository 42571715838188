import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Breadcrumbs, Typography, Divider, LinearProgress,
    Tabs, Tab, Button, MenuItem, Stepper, Step, StepLabel, Menu
} from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { 
    Work, Home, ArrowDropDown
} from "@material-ui/icons";
import TabPanel from '../../../components/TabPanel';
import { API } from "aws-amplify";
import { 
    OpportunityInfo, OpportunityCustomerDetail, OpportunityFileDetail, 
    OpportunityNoteDetail, OpportunityHistoryDetail
} from '../../types/OpportunityTypes';
import OpportunityMeasureTab from './tabs/OpportunityMeasureTab';
import OpportunityHistoryTab from './tabs/OpportunityHistoryTab';
import OpportunityNotesTab from './tabs/OpportunityNotesTab';
import OpportunityDetailTab from './tabs/OpportunityDetailTab';
import OpportunityCustomersTab from './tabs/OpportunityCustomersTab';
import OpportunityFilesTab from './tabs/OpportunityFilesTab';
import OpportunityLostDialog from './dialogs/OpportunityLostDialog';
import {createFolders} from "../../../utils/MsGraphApi"
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex",
            marginTop: 16
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        }
    })
);

interface ParamTypes {
    opportunityNo: string;
}

interface Props {
    user?: any | null;
}

export default function OpportunityDetails({ user }: Props) {
    const classes = useStyles();
    
    //OPPORTUNITY
    const { opportunityNo } = useParams()
    const [loading, setLoading] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [opportunityDetail, setOpportunityDetail] = useState<OpportunityInfo>()
    const [showOpportunityLostDialog, setShowOpportunityLostDialog] = useState<boolean>(false)
    const [jobLostMenu, setJobLostMenu] = useState<null | HTMLElement>(null)
    const [jobLostType, setJobLostType] = useState<string>()
    const [steps, setSteps] = useState<Array<string>>(["Opportunity Created", "Quoting", "Job started"])
    
    //CUSTOMERS TAB
    const [selectedCustomerIdx, setSelectedCustomerIdx] = useState<number>(-1)
    const [customers, setCustomers] = useState<Array<OpportunityCustomerDetail>>([])
    const [selectedCustomer, setSelectedCustomer] = useState<OpportunityCustomerDetail>()
    
    //MEASURE TAB
    const [selectedMeasureFileIdx, setSelectedMeasureFileIdx] = useState<number>(-1)

    //NOTES TAB
    const [notes, setNotes] = useState<Array<OpportunityNoteDetail>>([])

    //HISTORY TAB
    const [history, setHistory] = useState<Array<OpportunityHistoryDetail>>([])

    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState<string|null>(null)
    const [spFolder, setSpFolder] = useState<string>('')

    useEffect(() => {
        if (inProgress === "none" && accounts.length > 0) {
            // Retrieve an access token
            instance.acquireTokenSilent({
                account: accounts[0],
                scopes: ['files.readwrite']
            }).then(response => {
                if (response.accessToken) {
                    
                     
                    createFolders(opportunityNo!, true)
                        .then((id)=> { 
                            console.log(id)
                            setSpFolder(id)
                            setAccessToken(response.accessToken);
                            
                        })
                }
            });
        }
    }, [inProgress, accounts, instance]);
    
    useEffect(() => {
        if(selectedTab === 0) {
            setLoading(true)
            API.get("", `/encore/opportunities/${opportunityNo}`, {})
            .then((response: OpportunityInfo) => {
                if(response) {
                    setOpportunityDetail(response)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunity detail", error)
                setLoading(false)
            })

        } else if(selectedTab === 1) {
            setLoading(true)
            API.get("", `/encore/opportunities/${opportunityNo}/customers`, {})
            .then((response: Array<OpportunityCustomerDetail>) => {
                if(response) {
                    if(response.length !== 0) {
                        setCustomers(response)
                        setSelectedCustomerIdx(0)
                        setSelectedCustomer(response[0])
                    }
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching customers by opportunity", error)
                setLoading(false)
            })

        } else if(selectedTab === 4) {
            setLoading(true)
            API.get("", `/encore/opportunities/${opportunityNo}/notes`, {})
            .then((response: Array<OpportunityNoteDetail>) => {
                if(response) {
                    setNotes(response)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunity notes", error)
                setLoading(false)
            })
        
        } else if(selectedTab === 5) {
            setLoading(true)
            API.get("", `/encore/opportunities/${opportunityNo}/history`, {})
            .then((response: Array<OpportunityHistoryDetail>) => {
                if(response) {
                    setHistory(response)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching opportunity history data", error)
                setLoading(false)
            })
        }
        
    }, [opportunityNo, selectedTab])

    useEffect(() => {
        if(opportunityDetail) {
            if(opportunityDetail.status === "Quoting") {
                setActiveStep(1)
            } else if (opportunityDetail.status === "Not Quoting" || opportunityDetail.status === "Lost") {
                setSteps(["Opportunity Created", "Quoting", "Opportunity Lost"])
                setActiveStep(2)
            }else if (opportunityDetail.status === "JOB STARTED") {
                setActiveStep(2)
            }
        }
    }, [opportunityDetail])

    const getOpportunityDetail = () => {
        setLoading(true)
        API.get("", `/encore/opportunities/${opportunityNo}`, {})
        .then((response: OpportunityInfo) => {
            if(response) {
                setOpportunityDetail(response)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching opportunity detail", error)
            setLoading(false)
        })
    }

    const getCustomers = () => {
        setLoading(true)
        API.get("", `/encore/opportunities/${opportunityNo}/customers`, {})
        .then((response: Array<OpportunityCustomerDetail>) => {
            if(response) {
                if(response.length !== 0) {
                    setCustomers(response)
                    if(selectedCustomerIdx >= 0 && selectedCustomerIdx < response.length) {
                        setSelectedCustomer(response[selectedCustomerIdx])
                    }
                }
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching customers by opportunity", error)
            setLoading(false)
        })

        API.get("", `/encore/opportunities/${opportunityNo}`, {})
        .then((response: OpportunityInfo) => {
            if(response) {
                setOpportunityDetail(response)
            }
        })
        .catch((error: any) => {
            console.log("Error: fetching opportunity detail", error)
        })
    }

    const getNotes = () => {
        setLoading(true)
        API.get("", `/encore/opportunities/${opportunityNo}/notes`, {})
        .then((response: Array<OpportunityNoteDetail>) => {
            if(response) {
                setNotes(response)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching opportunity notes", error)
            setLoading(false)
        })
    }

    return (
        <div>
            <Breadcrumbs>
                <Link to="/encore" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Link to="/encore/opportunities" className={classes.link}>
                    <Work className={classes.icon} />
                    Opportunities
                </Link>
                <Typography color="textPrimary">{opportunityNo}</Typography>
            </Breadcrumbs>

            <div className={classes.header}>
                <div style={{width: "100%"}}>
                    <div style={{padding: 16}}>
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}>
                                <h2>Opportunity Details</h2>
                            </div>
                            <div style={{marginTop: 16}}>
                                {(opportunityDetail?.status === "Won" && opportunityDetail.winnerMeasureId > 0)  &&
                                    <Button variant="contained" color="secondary" style={{marginRight: 8 }} href={`#/encore/jobs/EC${opportunityDetail.jobNo}`}>
                                        View Job
                                    </Button>
                                }
                                <Button variant="contained" color="secondary" onClick={(event) => setJobLostMenu(event.currentTarget)} disabled={opportunityDetail?.status.includes("JOB") || opportunityDetail?.status === "Not Quoting"}> 
                                    Actions
                                    <ArrowDropDown style={{marginLeft: 4}} />
                                </Button>

                                <Menu 
                                    open={Boolean(jobLostMenu)}
                                    anchorEl={jobLostMenu}
                                    onClose={() => setJobLostMenu(null)}>

                                    <MenuItem onClick={() => {
                                        setJobLostMenu(null)
                                        setJobLostType("Lost")
                                        setShowOpportunityLostDialog(true)
                                    }}>
                                        Job Lost
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        setJobLostMenu(null)
                                        setJobLostType("Not Quoting")
                                        setShowOpportunityLostDialog(true)
                                    }}>
                                        Not Quoting
                                    </MenuItem>

                                </Menu>
                            </div> 
                        </div>

                        <div>
                            <Stepper activeStep={activeStep} style={{backgroundColor: "#fafafa"}}>
                                {steps.map((label: string, i: number) => (
                                    <Step key={i}>
                                    <StepLabel> {label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)} aria-label="simple tabs example">
                            <Tab label="Detail" />
                            <Tab label="Customers" />
                            <Tab label="Measure" />
                            <Tab label="Files" />
                            <Tab label="Notes" />
                            <Tab label="History" />
                        </Tabs>
                        <Divider />
                        {loading && 
                            <LinearProgress 
                                className={classes.progressBackground} 
                                classes={{barColorPrimary: classes.progress}}/>
                        }
                        <TabPanel value={selectedTab} index={0}>
                            <OpportunityDetailTab 
                                opportunityDetail={opportunityDetail}
                                getOpportunityDetail={getOpportunityDetail}
                                user={user} />
                        </TabPanel>
                        
                        <TabPanel value={selectedTab} index={1}>
                            <OpportunityCustomersTab
                                opportunityNo={opportunityNo!}
                                user={user}
                                opportunityDetail={opportunityDetail}
                                customers={customers}
                                selectedCustomer={selectedCustomer}
                                selectedCustomerIdx={selectedCustomerIdx}
                                setSelectedTab={setSelectedTab}
                                setSelectedCustomer={setSelectedCustomer}
                                setSelectedCustomerIdx={setSelectedCustomerIdx}
                                setSelectedMeasureFileIdx={setSelectedMeasureFileIdx}
                                getOpportunityDetail={getOpportunityDetail}
                                getCustomers={getCustomers}
                            />
                        </TabPanel>
                        
                        <TabPanel value={selectedTab} index={2}>
                            <OpportunityMeasureTab 
                                user={user} 
                                oppNo={opportunityNo} 
                                oppStatus={opportunityDetail?.status} 
                                setLoading={setLoading} 
                                selectedMeasureFileIdx={selectedMeasureFileIdx} 
                                setSelectedMeasureFileIdx={setSelectedMeasureFileIdx}
                                setOpportunityDetail={setOpportunityDetail} />
                        </TabPanel>

                        <TabPanel value={selectedTab} index={3}>
                            <OpportunityFilesTab
                                user={user}
                                opportunityNo={opportunityNo!}
                                opportunityDetail={opportunityDetail}
                                currentTab={selectedTab}
                                loading={loading}
                                setLoading={setLoading}
                                spFolder = {spFolder}
                                accessToken = {accessToken}
                            />
                        </TabPanel>

                        <TabPanel value={selectedTab} index={4}>
                            <OpportunityNotesTab
                                opportunityDetail={opportunityDetail} 
                                user={user} 
                                opportunityNo={opportunityNo!}
                                notes={notes}
                                getNotes={getNotes} />
                        </TabPanel>

                        <TabPanel value={selectedTab} index={5}>
                            <OpportunityHistoryTab
                                history={history} />
                        </TabPanel>
                        
                    </div>
                </div>

                <OpportunityLostDialog
                    open={showOpportunityLostDialog}
                    handleDialog={setShowOpportunityLostDialog}
                    user={user}
                    oppNo={opportunityNo}
                    getOpportunityDetail={getOpportunityDetail}
                    jobLostType={jobLostType} />
            </div>
        </div>
    )
}