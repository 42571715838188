import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
// import Amplify from "aws-amplify";
// import awsExports from "./aws-exports";
// import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
// import Authentication from './Authentication';
import {Amplify} from "aws-amplify";

// Amplify.configure(awsExports);

import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./msAuthConfig";
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';

export const msalInstance = new PublicClientApplication(msalConfig);

// window.addEventListener("mousemove", function() {
//     console.log("rendered");
//     // ReactDOM.findDOMNode(OpportunityFilesTab) // Returns the elements
//     setTimeout(()=>{
        
//             const btnDownload:HTMLDivElement|null = document.querySelector('[data-automationid="malwareDownloadCommand"]');
//             console.log(btnDownload)
//             if(btnDownload){
//                 btnDownload.style.visibility="hidden"
//             }
    
//             const btnWord: HTMLDivElement|null = document.querySelector('[data-automationid="createWordCommand"]');
//             if(btnWord){
//                 btnWord.style.visibility="hidden"
//             }
       
        
//      }, 1000);
    
//   });


Amplify.configure({
    Auth: {
      identityPoolId: 'ap-southeast-2:c4e04bbe-3a3e-445b-89d7-58fbb5949ab3', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'ap-southeast-2_HvIS1xBp4', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '6rcqjlsu9shegrsl86kh4andfh', //OPTIONAL - Amazon Cognito Web Client ID
       // OPTIONAL - Hosted UI configuration
    oauth: {
        domain: 'encore.auth.ap-southeast-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: `${window.location.protocol}//${window.location.host}`,
        redirectSignOut: `${window.location.protocol}//${window.location.host}`,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
    },
    Storage: {
        AWSS3: {
            bucket: 'encore-tag-files', //REQUIRED -  Amazon S3 bucket
            region: 'ap-southeast-2', //OPTIONAL -  Amazon service region
        }
    },
    API: {
    endpoints: [
        {
            name: "",
            endpoint: "/api"
        }
    ]
}
});

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        // if (event.error instanceof AuthError) {
        //     // Do something with the error
        // }
        console.log(event.error)
     }
});

ReactDOM.render(
    <React.StrictMode>
        <App pca={msalInstance} />
        {/* <Pages/> */}
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
