import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, Breadcrumbs, Typography, Tabs, Tab, LinearProgress,
    Card, CardHeader, CardContent, CardActions, Button, CircularProgress
} from "@material-ui/core"; 
import { LocalOffer, Home, Edit } from "@material-ui/icons"
import TabPanel from '../../../components/TabPanel';
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { API, Storage } from "aws-amplify"
import EditJobTagMaterialDialog from './EditJobTagMaterialDialog';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        tagDetailCardRow: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 4
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        textField: {
            marginRight: 8
        },
        sheetSummaryCard: {
            marginTop: 16, 
            backgroundColor: "#1E88E5", 
            padding: 8,
            display: "flex"
        },
        horizontalJustifiedLayout: {
            display: "flex",
            justifyContent: "space-between"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        cardValue: {
            fontWeight: "bold"
        },
        dropzone: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 75,
            paddingBottom: 75,
            paddingLeft: 20,
            paddingRight: 20,
            borderWidth: 2,
            borderRadius: 2,
            borderColor: "#bdbdbd",
            borderStyle: "dashed",
            backgroundColor: "#eeeeee",
            color: "#757575",
            outline: "none",
            transition: "border .24s ease-in-out"
        }
    })
);


type TagInfo = {
    id: number;
    jobNo: number;
    sheetNo: number;
    tagNo: number;
    location: string;
    stockCode: string;
    description: string;
    quantity: number;
    length: number;
    weight: number;
    shape: string;
    deliveryDate: string;
    sheetType: string;
    prefabDate: string;
    scheduleNo: number;
    detailer: string;
    address1: string;
    contact: string;
    currentTagStatus: string;
    orderNo: string;
    updatedAt: string;
    bundleTotal: number;
    diameter: number;
    rawMaterials: Array<RawMaterialsInfo>;
    endManufacturingDate: string;
    machineNo: string;
    startManufacturingBy: string;
    zoneSetAt: string;
    zoneSetBy: string;
    zone: string;
    dispatchedAt: string;
    dispatchedBy: string;
    dispatchVehicle: string;
    testCertificateFiles: Array<TestCertFileInfo>;
}

export type RawMaterialsInfo = {
    id: number;
    tagNo: number;
    startDate: string;
    rawMaterial: string;
    batchHeatNo: string;
    testCertificateNo: string;
    mill: string;
    diameter: number;
}

type TestCertFileInfo = {
    tagId: number;
    dateTaken: string;
    s3Url: string;
    s3Filename: string;
    materialCode: string;
    contentType: string;
    username: string;
}


export default function TagDetails() {
    const classes = useStyles()
    const { tagId } = useParams()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [tagInfo, setTagInfo] = useState<TagInfo | undefined>(undefined)
    const [isFileLoading, setFileLoading] = useState<boolean>(false)

    const [selectedJobTagMaterial, setSelectedJobTagMaterial] = useState<RawMaterialsInfo>()
    const [openEditJobTagMaterialDialog, setOpenEditJobTagMaterialDialog] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const getTagInfo = () => {
        setLoading(true)
        fetch(`/api/tags/${tagId}`)
        .then(res => res.json())
        .then((result: TagInfo) => {
            if(result) {
                setTagInfo(result)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getTagInfo()
    }, [tagId]);

    function formatDateTime(date: string) : moment.Moment | string {
        if (moment.parseZone(date).get('year') < 1900){
            return "";
        }
        return date ? moment.parseZone(date).format('DD MMM YYYY HH:mm'): "";
    }

    function formatDate(date: string) : moment.Moment | string {
        if (moment.parseZone(date).get('year') < 1900){
            return "";
        }
        return date ? moment.parseZone(date).format('DD MMM YYYY'): "";
    }

    function isInBundle(bundleTotal: number) : boolean {
        return bundleTotal > 0;
    }

    function _getColorByStatusCount(v: string) : string {
        let color = '#fff';
        switch (v) {
            case 'PLANNED':
                color = '#e0e0e0';
                break;
            case 'MAKING':
                color = '#FFF8E1';
                break;

            case 'CONF':
                color = '#E3F2FD';
                break;

            case 'MADE':
                color = '#EDE7F6';
                break;

            case 'ZONE':
                color = '#FFFDE7';
                break;

            case 'DISPATCHED':
                color = '#E8F5E9';
                break;

            default:
                break;
        }
        return color;
    }

    const handleDrop = (files: any[]) => {

        let material = "";
        if (tagInfo && tagInfo.rawMaterials && tagInfo.rawMaterials.length > 0){
            material = tagInfo.rawMaterials[0].rawMaterial;
        }

        files.forEach((f)=>{
            setFileLoading(true)
    
            var date = new Date();
            var timestamp = date.getTime();
    
            var objKey =   timestamp + "_" + f.name.replace(" ","-");

            Storage.put(objKey, f)
            .then (result => {
                console.log(result);
                let url = "";
                Storage.get(objKey, { level: 'public', download:false })
                    .then ( (result: any) => { 
                        // console.log(result); 
                        url = result;
                        
                        const params = {
                            body: {
                                tagId: tagId,
                                s3URL: url,
                                s3FileName: objKey,
                                materialCode: material,
                                contentType: f.type,
                                username: "demo"
                            },
                        };
                        API.post("", "/loads/tag/upload",params)
                            .then(response => {
                                // Add your code here
                                console.log(response);
                                getTagInfo()
                                // this.$.ctlFileUpload.files = [];
                                setFileLoading(false)
                                
                            })
                            .catch(error => {
                                console.log(error.response);
                                getTagInfo()
                                // this.$.ctlFileUpload.files = [];
                                setFileLoading(false)
                            })
                    })
                    .catch( (err) => { 
                        console.log(err);
                    });
                
            }) // {key: "test.txt"}
            .catch(err => {
                console.log(err);
            })
    });
    }
    
    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted: handleDrop,
        multiple: false,
        accept: 'text/csv'
    });
    

    return (
        <div>
            <Breadcrumbs>
                <Link to="/encore" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Link to="/encore/tags" className={classes.link}>
                    <LocalOffer className={classes.icon} />
                    Tags
                </Link>
                <Typography color="textPrimary">{tagId}</Typography>
            </Breadcrumbs>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Tag Details
                </h2>
            </div>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Tag Details" />
                <Tab label="Manufacturing" />
                <Tab label="Logistics" />
                <Tab label="Tests" />
            </Tabs>
            <Divider />
            {loading && 
                <LinearProgress 
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            <TabPanel value={selectedTab} index={0}>
                {(!loading && tagInfo !== undefined) &&
                    <div style={{marginTop: 16}}>
                        <Card style={{ backgroundColor: _getColorByStatusCount(tagInfo.currentTagStatus)}}>
                            <CardHeader title={`ET${tagInfo.id}`}>
                            </CardHeader>
                            <CardContent>
                                <div className={classes.tagDetailCardRow}>
                                    <span>Sheet Type: <span className={classes.cardValue}>{tagInfo.sheetType}</span></span>
                                    <span>Job No: <span className={classes.cardValue}>{tagInfo.jobNo}</span></span>
                                </div>
                                <div className={classes.tagDetailCardRow}>
                                    <span>Description: <span className={classes.cardValue}>{tagInfo.description}</span></span>
                                </div>
                                <div className={classes.tagDetailCardRow}>
                                    <span>Sheet #: <span className={classes.cardValue}>{tagInfo.sheetNo}</span></span>
                                    <span>Tag #: <span className={classes.cardValue}>{tagInfo.tagNo}</span></span>
                                    <span>Status: <span className={classes.cardValue}>{tagInfo.currentTagStatus}</span></span>
                                </div>
                                <div className={classes.tagDetailCardRow}>
                                    <span>Updated At: <span className={classes.cardValue}>{formatDateTime(tagInfo.updatedAt)}</span></span>
                                    {isInBundle(tagInfo.bundleTotal) && 
                                        <span className={classes.cardValue}>IN BUNDLE</span>
                                    }
                                </div>
                                <div className={classes.tagDetailCardRow}>
                                    <span>QTY: <span className={classes.cardValue}>{tagInfo.quantity}</span></span>
                                    <span>SHAPE: <span className={classes.cardValue}>{tagInfo.shape}</span></span>
                                    <span>DIAMETER: <span className={classes.cardValue}>{tagInfo.diameter}</span></span>
                                </div>
                                <div className={classes.tagDetailCardRow}>
                                    <span>WEIGHT: <span className={classes.cardValue}>{tagInfo.weight}</span></span>
                                    <span>LENGTH: <span className={classes.cardValue}>{tagInfo.length}</span></span>
                                    <span>DETAILER: <span className={classes.cardValue}>{tagInfo.detailer}</span></span>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                }
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
                {(!loading && tagInfo?.rawMaterials.length !== 0) && 
                    <div>
                        {tagInfo?.rawMaterials.map((tag: RawMaterialsInfo) => (
                            <div style={{marginTop: 16}} key={tag.id}>
                                <Card key={tag.id}>
                                    <CardHeader title={tag.id} action={
                                        <Button variant="contained" color="secondary" onClick={() => {
                                            setSelectedJobTagMaterial(tag)
                                            setOpenEditJobTagMaterialDialog(true)
                                        }}>
                                            Edit
                                            <Edit style={{marginLeft:4}} />
                                        </Button>
                                    }>
                                    </CardHeader>
                                    <CardContent>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>Start Manufacturing: <span className={classes.cardValue}>{formatDateTime(tag.startDate)}</span></span>
                                            <span>Raw Material Tag #: <span className={classes.cardValue}>{tag.rawMaterial}</span></span>
                                        </div>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>Heat No: <span className={classes.cardValue}>{tag.batchHeatNo}</span></span>
                                            <span>Certificate #: <span className={classes.cardValue}>{tag.testCertificateNo}</span></span>
                                        </div>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>Supplier: <span className={classes.cardValue}>{tag.mill}</span></span>
                                        </div>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>End Manufacturing: <span className={classes.cardValue}>{formatDateTime(tagInfo.endManufacturingDate)}</span></span>
                                            <span>Machine: <span className={classes.cardValue}>{tagInfo.machineNo}</span></span>
                                            <span>Operator: <span className={classes.cardValue}>{tagInfo.startManufacturingBy}</span></span>
                                        </div>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>DURATION</span>
                                            <span>PER PIECE</span>
                                        </div>
                                        <div className={classes.tagDetailCardRow}>
                                            <span>Stock code: <span className={classes.cardValue}>{tagInfo.stockCode}</span></span>
                                        </div>
                                    </CardContent>
                                    <CardActions style={{display: "flex", backgroundColor: "#fafafa"}}>
                                        <div style={{flexGrow: 1}}></div>
                                        <Button variant="contained" color="secondary">
                                            View Cert
                                        </Button>
                                    </CardActions>
                                </Card>
                            </div>
                        ))}
                    </div>
                }

                <EditJobTagMaterialDialog
                    openDialog={openEditJobTagMaterialDialog}
                    setOpenDialog={setOpenEditJobTagMaterialDialog}
                    selectedJobTagMaterial={selectedJobTagMaterial}
                    getTagInfo={getTagInfo}
                />
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                {(!loading && tagInfo !== undefined) &&
                        <div style={{marginTop: 16}}>
                            <Card>
                                <CardContent>
                                    <div className={classes.tagDetailCardRow}>
                                        <span>Zone Set: <span className={classes.cardValue}>{formatDateTime(tagInfo.zoneSetAt)}</span></span>
                                        <span>By: <span className={classes.cardValue}>{tagInfo.zoneSetBy}</span></span>
                                        <span>Zone: <span className={classes.cardValue}>{tagInfo.zone}</span></span>
                                    </div>
                                    <div className={classes.tagDetailCardRow}>
                                        <span>Dispatched At: <span className={classes.cardValue}>{formatDateTime(tagInfo.dispatchedAt)}</span></span>
                                        <span>Dispatched By: <span className={classes.cardValue}>{tagInfo.dispatchedBy}</span></span>
                                        <span>Rego: <span className={classes.cardValue}>{tagInfo.dispatchVehicle}</span></span>
                                    </div>
                                    <div className={classes.tagDetailCardRow}>
                                        <span>Required Delivery: <span className={classes.cardValue}>{formatDate(tagInfo.deliveryDate)}</span></span>
                                        <span>Freight Co:</span>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    }
            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                {!loading && 
                    <div style={{marginTop: 16}}>
                        <Card>
                            <CardHeader title="Upload File" />
                            <CardContent>
                                <div>
                                    <section className="container">
                                        <div {...getRootProps({ className: classes.dropzone })}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                        </div>
                                    </section>
                                    {isFileLoading && 
                                        <div style={{marginTop: 16, display: "flex", justifyContent: "center"}}>
                                            <CircularProgress style={{width: 75, height: 75}} color="secondary"/>
                                        </div>
                                    }
                                    {tagInfo?.testCertificateFiles && tagInfo?.testCertificateFiles.map((file: TestCertFileInfo) => (
                                        <Card style={{marginTop: 16}} key={file.s3Url} raised>
                                            <CardContent>
                                                <span>File Name: <span className={classes.cardValue}>{file.s3Filename}</span></span>
                                            </CardContent>
                                        </Card>   
                                    ))}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    
                }
            </TabPanel>

        </div>
    )
}
