
import { loginRequest, graphConfig } from "../msAuthConfig";
import { msalInstance } from "../index";
import { Client } from "@microsoft/microsoft-graph-client";


//Prod eurocorporation.sharepoint.com,3f8c3b7f-fea0-4e1a-9730-8ce0c979bdbe,0f57be9d-b3b4-4b8e-ac25-4637d4ff65dc, testeurocorporation.sharepoint.com,b76b9389-84ed-42ac-90ad-864aca7ac701,6d3807ff-5d61-4050-bb26-cb589d6715f7
// const siteGraphEndpoint = "/sites/eurocorporation.sharepoint.com,3f8c3b7f-fea0-4e1a-9730-8ce0c979bdbe,0f57be9d-b3b4-4b8e-ac25-4637d4ff65dc"
//Test
// const siteGraphEndpoint = "/sites/eurocorporation.sharepoint.com,b76b9389-84ed-42ac-90ad-864aca7ac701,6d3807ff-5d61-4050-bb26-cb589d6715f7"

export async function getAccessToken() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    return response.accessToken
}

export async function callMsGraph() {

    try {
        const accessToken = await getAccessToken()

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        return fetch(graphConfig.graphMeEndpoint, options)
            .then(response => response.json())
            .catch(error => console.log(error));

    } catch (error) {
        throw error;
    }

}


function getAuthenticatedClient(accessToken: string) {

    // Initialize Graph client
    const client = Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done: any) => {
            done(null, accessToken);
        }
    });

    return client;
}

export async function getUserDetails() {
    const accessToken = await getAccessToken()
    const client = getAuthenticatedClient(accessToken);
    //https://graph.microsoft.com/v1.0/sites?search=*
    const user = await client
        .api(graphConfig.siteEndpoint + '/drive/root')
        .select('id, name, webUrl')
        // .select('displayName,userPrincipalName')
        .get();

    console.log(user)

    return user;
}

export async function createFolders(folderName:string, createSubfolder = false) {
    const accessToken = await getAccessToken()
    const client = getAuthenticatedClient(accessToken);

    const driveItem = {
        name: folderName,
        folder: {},
        '@microsoft.graph.conflictBehavior': 'replace'
    };

    const response = await client.api(graphConfig.siteEndpoint + '/drive/root/children')
        .post(driveItem);

    console.log(response)

    //Create Subfolders

    if(createSubfolder){
        //const subFolders = ["Claims", "Contract", "Detailing", "Drawings", "Measure", "Mill Certs", "Placing", "QA", "Shop Drawings", "Tender"]
        const subFolders = ["Tender"]
    //drive/items/01RSJCH5R7EXOQU72YRBGJ6A452FDYSPFE
        for (const folder of subFolders) {
            const driveItem = {
                name: folder,
                folder: {},
                '@microsoft.graph.conflictBehavior': 'replace'
            };

            await client.api(graphConfig.siteEndpoint + '/drive/items/' + response.id + '/children')
                .post(driveItem);
        }

    }
    



    return response.id;
}