import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from "@material-ui/core";

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#d3d3d3"//theme.palette.common.black,
      // color: theme.palette.common.white,
    },
    body: {
      fontSize: 13,
      padding: 0,
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "0px 0px 0px 8px", // <-- arbitrary value

      }
      
    },
  }),
)(TableRow);