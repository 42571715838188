import React from 'react';
import {
    Divider,Typography, List, ListItem, ListItemText, ListItemProps, Container, ListItemIcon, Avatar
} from '@material-ui/core';
import { makeStyles, Theme, createStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { Route, Routes } from "react-router-dom"

import EurolabHome from "./components/EurolabHome"
import EncoreShell from '../components/EncoreShell';
import { Home, Description, Assessment } from "@material-ui/icons"

import EurolabLogo from "./EuroBlue.png"
import StressTestFileDetails from './components/StressTestFileDetails';

import { createMuiTheme } from '@material-ui/core/styles';
import StressTestFiles from './components/StressTestFiles';
import LTQTestResults from './components/LTQTestResults';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffa854',
      main: '#474b56',
      dark: '#b14a00',
      contrastText: '#fff',
    },
    secondary: {
      light: '#757de8',
      main: '#3f51b5',
      dark: '#002984',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiContainer-root': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: 8,
          paddingRight: 8
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(2)
      }
    },
    logo: {
      margin: theme.spacing(1)
    },
    title: {
      margin: theme.spacing(2)
    }
  }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
}

interface Props {
  user: any | null
  globalBranch: string
  setGlobalBranch: (branch: string) => void
}

export default function EurolabApp({ user, globalBranch, setGlobalBranch } : Props) {
  const classes = useStyles();

  const drawer = (
    <div>      
      <div style={{display: "flex", backgroundColor: "#eeeeee"}}>
        <Avatar className={classes.logo} src={EurolabLogo}/>
        <Typography className={classes.title} variant="h6">
          Eurolab
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItemLink href="#/eurolab">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemLink>
        <ListItemLink href="#/eurolab/stressfiles">
          <ListItemIcon>
            <Description />
          </ListItemIcon>
          <ListItemText primary="Stress Files" />
        </ListItemLink>
        <ListItemLink href="#/eurolab/ltq">
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
          <ListItemText primary="LTQ" />
        </ListItemLink>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <EncoreShell drawer={drawer} title="Eurolab" theme={theme} user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch}/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
            <Container maxWidth="xl">
              <Routes>
                <Route path="ltq" element={<LTQTestResults user={user}/>}/>
                <Route path="stressfiles" element={<StressTestFiles/>} />
                <Route path="stressfiles/:id" element={<StressTestFileDetails user={user}/>}/>
                <Route path="/" element={<EurolabHome user={user}/>}/>
              </Routes>
            </Container>
        </main>
      </MuiThemeProvider>
    </div>
  );
}