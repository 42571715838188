import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core";
import { useStyles } from './JobTagDetailTable';


export interface AddNewDetailingScheduleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (scheduleId: string, scheduleName: string) => void;
}
export function AddNewDetailingScheduleScheduleDialog(props: AddNewDetailingScheduleDialogProps) {
    const classes = useStyles();
    const [scheduleId, setScheduleId] = useState('');
    const [scheduleName, setScheduleName] = useState('');

    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue, selectedValue);
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose(selectedValue, selectedValue);
    }

    function handleSubmit(): void {
        if (scheduleName.length === 0 || scheduleId.length === 0) {
            return;
        }
        onClose(scheduleId, scheduleName);
        setScheduleId("");
        setScheduleName("");
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">New Detailing Schedule</DialogTitle>
            <DialogContent>

                <TextField
                    placeholder="Enter Schedule ID"
                    fullWidth
                    variant="filled"
                    required
                    name="scheduleId"
                    value={scheduleId}
                    type="number"
                    onChange={(e) => setScheduleId(e.target.value)} />

                <div style={{ paddingTop: 10 }}>

                    <TextField
                        placeholder="Enter Schedule"
                        fullWidth
                        variant="filled"
                        required
                        name="name"
                        value={scheduleName}
                        onChange={(e) => setScheduleName(e.target.value)} />

                </div>



            </DialogContent>

            <DialogActions>
                {/* {error &&
                <span style={{color: "red"}}>{error}</span>
            } */}
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
