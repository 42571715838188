import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button, CircularProgress, Divider, Grid } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { Close } from "@material-ui/icons";
import { API, Storage } from "aws-amplify"
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 75,
      paddingBottom: 75,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out"
    },
    cancelButton: {
        backgroundColor: "#f44336"
    },
    loadingCard: {
    },
    loadingItem: {
    },
    loadingButton: {
    }
  })
);

interface Props {
  user: any | null
  description: string;
  addDisabled: () => void;
  setLoading: (value: boolean) => void;
  getLoading: () => boolean
  isSecondary: boolean;
  originalTestId?: number;
}

export default function FileUpload({user, description, addDisabled, setLoading, getLoading, isSecondary, originalTestId}: Props) {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false)
  const [isError, setError] = React.useState<boolean>(false)
  const [fileId, setFileId] = React.useState<number>(0)

  const navigate = useNavigate()
  const location = useLocation()


  const handleDrop = (files: any[]) => {
    files.forEach((f)=>{

      setLoading(true)
      setIsLoaded(false)
      var date = new Date();
      var timestamp = date.getTime();
      var objKey =   timestamp + "_" + f.name.replace(" ","-");

      Storage.put(objKey, f)
      .then(() => {
        const params = {
          body:{
            filename: objKey,
            username: user?.attributes.name,
            originalTestId: originalTestId
          }
        };
        API.post("", "/el/stressfiles/processfile",params)
        .then((response: number) => {
            setIsLoaded(true)
            setFileId(response);
        })
        .catch(() => {
            setError(true)
            setLoading(false)
        })
      }) // {key: "test.txt"}
      .catch(() => {
        setError(true)
        setLoading(false)
      });
    });
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleDrop,
    multiple: false,
    accept: ".csv"
  });



  const files = acceptedFiles.map((file: any) => (
    <span key={file.path}>
      {file.path}
    </span>
  ));

  const fileSize = acceptedFiles.map((file: any) => (
    <span key={file.path}>
      {(file.size/Math.pow(10,6)).toFixed(2)} MB
    </span>
  ));

  var currentdate = new Date(); 
  var datetime = currentdate.getHours() + ":"  
                  + (currentdate.getMinutes() < 10 ? `0${currentdate.getMinutes()}` : currentdate.getMinutes()) + " "
                  + currentdate.getDate() + "/"
                  + (currentdate.getMonth()+1)  + "/" 
                  + currentdate.getFullYear();

  const handleView = () => {
    if(isSecondary) {
      navigate(`/eurolab/stressfiles/${fileId}`)
    } else {
      navigate(`/eurolab/stressfiles/${fileId}`)
    }
  }

  return (
  
    <Card>
      <CardContent>
        {!getLoading() &&
          <div>
            <div>
              {isError &&
                <h3>Error: Unable to process file.</h3>
              }
              <p style={{ marginBottom: 16 }}>{description}</p>
              <section className="container">
              <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              </section>
            </div>
            <div style={{display: "flex", marginTop: 16}}>
                <div style={{flexGrow: 1}}></div>
                <Button className={classes.cancelButton} onClick={() => addDisabled()}>
                    <Close />
                    Cancel
                </Button>
            </div>
          </div>
        }
        {getLoading() && 
          <Grid container className={classes.loadingCard} justify="space-evenly" spacing={3}>
            <Grid item className={classes.loadingItem} xs={12} md={2}>
              <h3>File</h3>
              {files}
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item className={classes.loadingItem} xs={12} md={2}>
              <h3>File Size</h3>
              {fileSize}
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item className={classes.loadingItem} xs={12} md={2}>
              <h3>Uploaded By</h3>
              Demo
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item className={classes.loadingItem} xs={12} md={2}>
              <h3>Upload Time</h3>
              {datetime}
            </Grid>
            <Divider orientation="vertical" flexItem/>
            <Grid item className={classes.loadingItem} xs={12} md={2}>
              <h3>Loading</h3>
              {!isLoaded && 
                <CircularProgress />
              }
              {isLoaded && 
                <div>
                  <Button variant="contained" color="primary" className={classes.loadingButton} onClick={handleView}>
                    View
                  </Button>
                </div>
              }
            </Grid>
          </Grid>
        }
      </CardContent>
    </Card>
  );
}
