import React, { useEffect, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, FormControl, Dialog, DialogContent, CircularProgress,
    DialogActions, TextField, FormLabel, Typography
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { API } from "aws-amplify";
import { RawMaterialsInfo } from './TagDetails';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface Props {
    openDialog: boolean
    setOpenDialog: (open: boolean) => void
    selectedJobTagMaterial: RawMaterialsInfo | undefined
    getTagInfo: () => void
}

export default function EditJobTagMaterialDialog({ openDialog, setOpenDialog, selectedJobTagMaterial, getTagInfo }: Props) {

    const classes = useStyles()

    const { handleSubmit, control, errors, clearErrors, reset } = useForm<RawMaterialsInfo>({
        shouldUnregister: false
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>()

    useEffect(() => {
        if(openDialog && selectedJobTagMaterial) {
            clearErrors()
            reset(selectedJobTagMaterial)
        }
    }, [openDialog, selectedJobTagMaterial])

    const onSubmit = (data: RawMaterialsInfo) => {
        if(data) {
            setLoading(true)
            let params = {
                body: {
                    ...data
                }
            }
            API.post("", '/encore/tags/jobTagMaterial/update', params)
            .then(() => {
                setLoading(false)
                setOpenDialog(false)
                getTagInfo()
            })
            .catch((error: any) => {
                setLoading(false)
                setError("Error: updating tag")
                console.log("Error: unable to update tag", error)
            })
        }
    }

    return (
        <Dialog fullWidth maxWidth={'md'} open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
            <DialogContent>
                <div style={{marginBottom: 16}}>
                    <Typography variant="h6">
                        {selectedJobTagMaterial ?
                            <span>{`Edit Job Material Tag: ${selectedJobTagMaterial.id}`}</span>
                        :
                            <span>Edit Job Material Tag</span>
                        }
                    </Typography>
                </div>

                <FormControl
                    error={Boolean(errors.rawMaterial)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Raw Material Tag #</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Raw Material Tag #"
                                fullWidth
                                variant="filled"
                                error={errors.rawMaterial ? true : false}
                                helperText={errors.rawMaterial?.message}/>
                        }
                        name="rawMaterial"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.batchHeatNo)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Heat No</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Heat No"
                                fullWidth
                                variant="filled"
                                error={errors.batchHeatNo ? true : false}
                                helperText={errors.batchHeatNo?.message}/>
                        }
                        name="batchHeatNo"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.testCertificateNo)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Certificate #</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Certificate #"
                                fullWidth
                                variant="filled"
                                error={errors.testCertificateNo ? true : false}
                                helperText={errors.testCertificateNo?.message}/>
                        }
                        name="testCertificateNo"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.mill)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Supplier</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Supplier"
                                fullWidth
                                variant="filled"
                                error={errors.mill ? true : false}
                                helperText={errors.mill?.message}/>
                        }
                        name="mill"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
                
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => setOpenDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
