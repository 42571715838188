import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { 
    Divider, TextField, Grid, IconButton, InputAdornment, LinearProgress,
    Card, CardContent, CardHeader, CardActions, Button, Breadcrumbs, Typography
} from "@material-ui/core"; 
import { Route,  Link, Routes, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Search, ClearAll, Home } from "@material-ui/icons";
import TagDetails from './TagDetails';
import { DataGrid, GridColDef, GridRowsProp, GridRowData, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        searchCardRow: {
            display: "flex",
            justifyContent: "space-between"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        dataGrid: {
            backgroundColor: "white"
        }
    })
);

export interface TagInfo {
    jobNo: number;
    accountName: string;
    jobTitle: string;
    totalTags: number;
    totalDispatched: number;
    totalMade: number;
    totalMaking: number;
    totalInZone: number;
    totalInvoiced: number;
    tagId: number;
}

export default function EncoreTags() {
    const classes = useStyles()
    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [searchInput, setSearchInput] = useState<string>('')
    const [tags, setTags] = useState<Array<TagInfo> | undefined>([])
    const navigate = useNavigate()
    // let { path, url } = useRouteMatch()
    const location = useLocation()

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        setSearchInput(event.target.value)
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
            setTags([])
        }
    }

    useEffect(() => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
          }
          sTimer = setTimeout(() => {
            if (searchInput.length > 0) {
                setIsLoaded(false)
                setTags([])
                fetch(`/api/encore/tags/search/${searchInput}`)
                .then(res => res.json())
                .then((result: Array<TagInfo>) => {
                    if(result) {
                        setTags(result)
                    } else {
                        setTags(undefined)
                    }
                    setIsLoaded(true)
                })
            }
          }, 1500);
    }, [searchInput])

    function handleTagDetail(tagId: number) {
        navigate(`${tagId}`)
    }

    return (
        <div>
                    <Breadcrumbs>
                        <Link to="/encore" className={classes.link}>
                            <Home className={classes.icon} />
                            Home
                        </Link>
                        <Typography color="textPrimary">
                            Tags
                        </Typography>
                    </Breadcrumbs>
                    <div className={classes.header}>
                        <h2 style={{flexGrow: 1}}>
                            Tags
                        </h2>
                        <div className={classes.margin}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                    id="input-with-icon-grid" 
                                    label="Search by Tag Number"
                                    onChange={handleSearch}
                                    value={searchInput}
                                    InputProps={{
                                        className: classes.input,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton color="inherit" onClick={handleClearSearch}>
                                                <ClearAll />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {!isLoaded && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
                    <Divider style={{marginBottom: 16}} />
                    {(isLoaded && tags !== undefined) && 
                        <div>
                            <Grid container spacing={3}>
                                {tags.length !== 0 && tags.map((tag: TagInfo) => (
                                    <Grid item xs={12} key={tag.tagId}>
                                        <Card>
                                            <CardHeader title={`${tag.jobNo} - ${tag.jobTitle}`}>
                                            </CardHeader>
                                            <CardContent>
                                                <div className={classes.searchCardRow}>
                                                    <span>Account: {tag.accountName}</span>
                                                </div>
                                                <div className={classes.searchCardRow}>
                                                    <span>Total Tags: {tag.totalTags}</span>
                                                    <span>Made: {tag.totalMade}</span>
                                                    <span>Dispatched: {tag.totalDispatched}</span>
                                                </div>
                                                <div className={classes.searchCardRow}>
                                                    <span>Total Making: {tag.totalMaking}</span>
                                                    <span>Total In Zone: {tag.totalInZone}</span>
                                                </div>
                                            </CardContent>
                                            <CardActions style={{backgroundColor: "#fafafa"}}>
                                                <div style={{flexGrow: 1}}></div>
                                                <Button variant="contained" color="secondary" onClick={() => handleTagDetail(tag.tagId)}>
                                                    Tag Detail
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>

                        </div>
                    }
                    {(isLoaded && tags === undefined) &&
                        <p>No results found.</p>
                    }
                </div>
    )
}

interface TagsTableProps {
    tags: Array<TagInfo>
}

export function TagsTable({ tags }: TagsTableProps) {

    const classes = useStyles()

    const navigate = useNavigate()

    const gridWrapperRef = useRef<HTMLDivElement>(null);
    
    // useLayoutEffect(() => {
    //     const gridDiv = gridWrapperRef.current;
    //     if (gridDiv){
    //         const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
    //         gridEl.style.height = '';
    //     }
    // });

    const tagsColumns: GridColDef[] = [
        { field: 'jobNo', headerName: "Job No", width: 80 },
        { field: 'jobTitle', headerName: 'Job Title', width: 250 },
        { field: 'accountName', headerName: 'Account Name', width: 400 },
        { field: 'totalTags', headerName: 'Tags', width: 100 },
        { field: 'totalMade', headerName: 'Made', width: 100 },
        { field: 'totalDispatched', headerName: 'Dispatched', width: 110 },
        { field: 'totalMaking', headerName: 'Making', width: 100 },
        { field: 'totalInZone', headerName: 'In Zone', width: 100 },
    ]

    function getTableRows(): GridRowsProp {
        let rows: GridRowData[] = []
        tags?.forEach((job: TagInfo) => {
            let obj: any = {
                id: job.tagId,
                ...job
            }
            rows.push(obj)
        })
        return rows
    }

    return (
        <div ref={gridWrapperRef}>
            <DataGrid  
                className={classes.dataGrid}
                autoHeight
                rows={getTableRows()} 
                columns={tagsColumns} 
                pageSize={25}
                onRowClick={(params: GridRowParams) => {
                    navigate(`/encore/tags/${params.id}`)
                }} />
        </div>

    )
}