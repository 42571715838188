import React from 'react';
import {
    Divider, Typography, List, ListItem, ListItemText, ListItemProps, Container, ListItemIcon, Avatar
} from '@material-ui/core';
import { makeStyles, Theme, createStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {  Route, Routes } from "react-router-dom"

import SwimsHome from "./components/SwimsHome"
import SwimsLoads from './components/SwimsLoads';
import EncoreShell from '../components/EncoreShell';
import { Home, LocalShipping } from '@material-ui/icons';

import SwimsLogo from "./swims_favicon.png"
import LoadDetails from './components/LoadDetails';
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiContainer-root': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: 8,
          paddingRight: 8
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(2)
      }
    },
    drawerItem: {
      marginLeft: 4
    },
    logo: {
      margin: theme.spacing(1)
    },
    title: {
      margin: theme.spacing(2)
    }
  }),
);

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4463a5',
      main: '#003976',
      dark: '#00144a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c',
      contrastText: '#fff',
    },
  },
});

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

interface Props {
  user: any | null
  globalBranch: string
  setGlobalBranch: (branch: string) => void
}

export default function SwimsApp({ user, globalBranch, setGlobalBranch } : Props) {
  const classes = useStyles();

  const drawer = (
    <div>      
      <div style={{display: "flex", backgroundColor: "#eeeeee"}}>
        <Avatar className={classes.logo} src={SwimsLogo}/>
        <Typography className={classes.title} variant="h6">
          Swims
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItemLink href="#/swims">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemLink>
        <ListItemLink href="#/swims/loads">
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="Loads" />
        </ListItemLink>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <EncoreShell drawer={drawer} title="Swims" theme={theme} user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch}/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
              <Container maxWidth={false}>
                <Routes>
                  <Route path="loads" element={<SwimsLoads user={user} globalBranch={globalBranch} />}/>
                  <Route path="loads/:loadNo" element={<LoadDetails user={user} globalBranch={globalBranch} />}/>
                  <Route path="/" element={<SwimsHome/>}/>
                </Routes>
              </Container>
        </main>
      </MuiThemeProvider>
    </div>
  );
}