import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
    Divider,
    LinearProgress,
    Table, TableRow, TableCell, TableHead, TableBody, IconButton,
} from "@material-ui/core";
// import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
// import {StiDesignerOptions} from '../../libs/stimulsoft/stimulsoft.designer'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


let sTimer: NodeJS.Timeout;

declare let StiOptions: any;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: "flex",
        },
        margin: {
            display: "flex",
            margin: theme.spacing(1),
        },
        input: {
            width: 300,
        },
        progress: {
            backgroundColor: "#ff9800",
        },
        progressBackground: {
            backgroundColor: "#ffe0b2",
        },
        dataGrid: {
            backgroundColor: "white",
        },
        table: {
            minWidth: 650,
            backgroundColor: "white",
        }
    })
);

// interface EncoreSearchDto {
//     opportunities: Array<OpportunityInfo>
//     opportunityFiles: Array<OpportunityFileDetail>
//     activeLoads: Array<LoadInfo>
//     dispatchedLoads: Array<LoadInfo>
//     jobs: Array<JobsInfo>
//     tags: Array<TagInfo>
// }

interface Props {
    globalBranch: string;
}

interface ParamTypes {
    reportId: string;
}

export interface ReportInfo {
    reportId: number;
    reportName: string;
    reportDescription: string;
    reportDefinition: string;
    reportFolderName: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}


function ReportsList() {
    const classes = useStyles();
    // const { reportId } = useParams<ParamTypes>();

    const [loading, setLoading] = useState<boolean>(false);
    const [reports, setReports] = useState<Array<ReportInfo>>([])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedReportID, setSelectedReportID] = useState<number>();


    // const [reportFile, setReportFile] = useState<ReportInfo | null>(null);
    // const [searchInput, setSearchInput] = useState<string>('')

    // const [opportunities, setOpportunities] = useState<Array<OpportunityInfo>>([])
    // const [opportunityFiles, setOpportunityFiles] = useState<Array<OpportunityFileDetail>>([])
    // const [activeLoads, setActiveLoads] = useState<Array<LoadInfo>>([])
    // const [dispatchedLoads, setDispatchedLoads] = useState<Array<LoadInfo>>([])
    // const [jobs, setJobs] = useState<Array<JobsInfo>>([])
    // const [tags, setTags] = useState<Array<TagInfo>>([])
    // const [reload, setReload] = useState<boolean>(false)
    // const [isNoResults, setIsNoResults] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            // You can await here
            const response = await API.get("", `/encore/reports`, {})
            setReports(response)
            setLoading(false)
        }
        setLoading(true)
        fetchData();


    }, []);

    const handleClick = (event: React.MouseEvent<HTMLElement>, reportId: number) => {
        setSelectedReportID(reportId)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{ flexGrow: 1 }}>Reports</h2>
                <div className={classes.margin}>
                    {/* <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField 
                            label="Search"
                            value={searchInput}
                            InputProps={{
                                className: classes.input,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton color="inherit" onClick={handleClearSearch}>
                                        <ClearAll />
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        </Grid>
                    </Grid> */}
                </div>
            </div>
            <Divider />
            {loading && (
                <LinearProgress
                    style={{ marginBottom: 16 }}
                    className={classes.progressBackground}
                    classes={{ barColorPrimary: classes.progress }}
                />
            )}

            <div style={{ marginTop: 16 }}>
                {(!loading && reports !== null) &&

                    <Table className={classes.table} size="small" aria-label="reports">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Folder</TableCell>
                                <TableCell>More</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.length !== 0 && reports.map((line: ReportInfo) => (
                                <TableRow key={line.reportId}>
                                    <TableCell component="th" scope="row">
                                        {line.reportId}
                                    </TableCell>
                                    <TableCell>{line.reportName}</TableCell>
                                    <TableCell>{line.reportDescription}</TableCell>
                                    <TableCell>{line.reportFolderName}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" aria-label="more" onClick={(e)=> handleClick(e,line.reportId)}>
                                            <MoreVertIcon />
                                        </IconButton>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                }
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to={`/admin/designer/${selectedReportID}`} onClick={handleClose}>Design</MenuItem>
                {/* <MenuItem onClick={handleClose}>Copy</MenuItem> */}
                <MenuItem component={Link} to={`/report/viewer/${selectedReportID}`} onClick={handleClose}>View</MenuItem>
            </Menu>

        </div>
    );
}

export default ReportsList;
