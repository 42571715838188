import React, { Suspense } from 'react';
import {
    Divider, Typography, List, ListItem, ListItemText, ListItemProps, Container, ListItemIcon, Avatar
} from '@material-ui/core';
import { makeStyles, Theme, createStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {  Route, Routes } from "react-router-dom"
import EncoreSearch from "./components/EncoreSearch"
import EncoreLoads from "./components/loads/EncoreLoads"
import EncoreShell from '../components/EncoreShell';
import EncoreJobs from "./components/jobs/EncoreJobs";
import EncoreCustomers from "./components/customers/EncoreCustomers";
import EncoreTags from "./components/tags/EncoreTags";
import EncoreOpportunities from './components/opportunities/EncoreOpportunities';
import { LocalShipping, Dashboard, AccountBox, LocalOffer, Work, Search, Assessment, Launch } from "@material-ui/icons"

import EncoreLogo from "./encore_favicon.png"
import CustomerDetails from './components/customers/CustomerDetails';
import InvoiceDetails from './components/customers/InvoiceDetails';
import OrderDetails from './components/customers/OrderDetails';
import OpportunityDetails from './components/opportunities/OpportunityDetails';
import JobDetails from './components/jobs/JobDetails';
import TagDetails from './components/tags/TagDetails';
import LoadDetails from './components/loads/LoadDetails';
const ProductionDashboardViewer = React.lazy(() => import('./components/ProductionViewer'));
// import ProductionDashboardViewer from './components/ProductionViewer';
// import ProductionDashboardViewer from './components/ProductionViewer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiContainer-root': {
        [theme.breakpoints.down('lg')]: {
          paddingLeft: 8,
          paddingRight: 8
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 16,
          paddingRight: 16
        }
      },
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(2)
      }
    },
    logo: {
      margin: theme.spacing(1)
    },
    title: {
      margin: theme.spacing(2)
    }
  }),
);

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#4894ac',
      main: '#00667d',
      dark: '#003b51',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f73378',
      main: '#f50057',
      dark: '#ab003c',
      contrastText: '#fff',
    },
  },
});

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
  }

interface Props {
  user: any | null
  globalBranch: string
  setGlobalBranch: (branch: string) => void;
}

export default function EncoreApp({ user, globalBranch, setGlobalBranch } : Props) {
  const classes = useStyles();

  const drawer = (
    <div>      
      <div style={{display: "flex", backgroundColor: "#eeeeee"}}>
        <Avatar className={classes.logo} src={EncoreLogo}/>
        <Typography className={classes.title} variant="h6">
          Encore
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItemLink href="#/encore/search">
          <ListItemIcon>
            <Search />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItemLink>
        <ListItemLink href="https://eurocorporation.sharepoint.com/sites/encorelive" target='_blank'>
          <ListItemIcon>
            <Launch />
          </ListItemIcon>
          <ListItemText primary="Team Site" />
        </ListItemLink>
        <ListItemLink href="#/encore/production">
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
          <ListItemText primary="Production" />
        </ListItemLink>
        <ListItemLink href="#/encore/jobs">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Jobs" />
        </ListItemLink>
        <ListItemLink href="#/encore/tags">
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary="Tags" />
        </ListItemLink>
        <ListItemLink href="#/encore/loads">
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="Loads" />
        </ListItemLink>
        <ListItemLink href="#/encore/customers">
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItemLink>
        <ListItemLink href="#/encore/opportunities">
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary="Opportunities" />
        </ListItemLink>
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <EncoreShell drawer={drawer} title="Encore" theme={theme} user={user} globalBranch={globalBranch} setGlobalBranch={setGlobalBranch} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth={false}>
            <Routes>
              <Route path="opportunities" element={<EncoreOpportunities user={user} globalBranch={globalBranch} />}/>
              <Route path="opportunities/:opportunityNo" element={<OpportunityDetails user={user} />}/>
              <Route path="customers" element={<EncoreCustomers/>}/>
              <Route path="customers/:customerId" element={<CustomerDetails />}/>
              <Route path="customers/:customerId/invoices/:transactionId" element={<InvoiceDetails/>}/>
              <Route path="customers/:customerId/orders/:orderId" element={<OrderDetails/>}/>
              <Route path="jobs" element={<EncoreJobs user={user} />}/>
              <Route path="jobs/:jobId" element={<JobDetails user={user}/>} />
              <Route path="tags" element={<EncoreTags/>}/>
              <Route path="tags/:tagId" element={<TagDetails/>}/>
              <Route path="loads" element={<EncoreLoads user={user} globalBranch={globalBranch} />}/>
              <Route path="loads/:loadNo" element={<LoadDetails user={user} globalBranch={globalBranch} />}/>
              <Route path="production" element={<Suspense fallback={<div>Loading...</div>}><ProductionDashboardViewer user={user}/></Suspense>}/>
              <Route path="search" element={<EncoreSearch globalBranch={globalBranch} />}/>
              <Route path="/" element={<EncoreSearch globalBranch={globalBranch} />}/>
            </Routes>
          </Container>
        </main>
      </MuiThemeProvider>
    </div>
  );
}