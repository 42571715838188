import React, { useState } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Paper, IconButton, TableContainer, Table, TableHead, TableRow, 
    TableBody, Collapse, Box, TableCell
} from "@material-ui/core";
import {
    KeyboardArrowDown, KeyboardArrowUp
} from "@material-ui/icons";
import { 
    JobLabourTableData, JobLabourInfo
} from '../../types/JobTypes';
import { useRowStyles } from './JobDetails';
import { 
    StyledTableCell, StyledTableRow 
} from '../../../components/StyledTableComponents';
import { formatDateTime } from '../../../utils/utils';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        }
    })
);

interface JobLabourTableProps {
    labourData: Array<JobLabourTableData>
}

export function JobLabourTable({ labourData }: JobLabourTableProps) {

    const classes = useStyles()

    return (
        <>
            <TableContainer component={Paper} style={{marginTop: 16}}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Activity Code</TableCell>
                        <TableCell>Activity Name</TableCell>
                        <TableCell>Total Hours</TableCell>
                        <TableCell>No. Employees</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {labourData.map((group: JobLabourTableData, idx: number) => (
                        <LabourTableRow key={idx} group={group} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

interface LabourTableRowProps {
    group: JobLabourTableData
}



function LabourTableRow({ group }: LabourTableRowProps) {

    const classes = useRowStyles()
    const [open, setOpen] = useState<boolean>(false);
    
    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{group.activityCode}</strong>
                </TableCell>
                <TableCell><strong>{group.activityName}</strong></TableCell>
                <TableCell><strong>{group.totalHours}</strong></TableCell>
                <TableCell><strong>{group.numEmployees}</strong></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Employee Name</StyledTableCell>
                                        <StyledTableCell>Employee Code</StyledTableCell>
                                        <StyledTableCell>Start Time</StyledTableCell>
                                        <StyledTableCell>End Time</StyledTableCell>
                                        <StyledTableCell>Quantity</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {group.labour.map((labour: JobLabourInfo) => (
                                        <StyledTableRow key={labour.seqNo}>
                                            <StyledTableCell>{labour.employeeName}</StyledTableCell>
                                            <StyledTableCell>{labour.employeeCode}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(labour.startTime)}</StyledTableCell>
                                            <StyledTableCell>{formatDateTime(labour.endTime)}</StyledTableCell>
                                            <StyledTableCell>{labour.quantity}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}
