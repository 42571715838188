import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { 
    Divider, TextField, Grid, IconButton, InputAdornment, LinearProgress,
    Card, CardContent, CardHeader, CardActions, Button, Breadcrumbs, Typography
} from "@material-ui/core"; 
import {  Route, Link, Routes, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Search, ClearAll } from "@material-ui/icons";
import CustomerDetails from './CustomerDetails';
import { Home } from "@material-ui/icons";

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        customerSearchCardRow: {
            display: "flex",
            justifyContent: "space-between"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        dataGrid: {
            backgroundColor: "white"
        }
    })
);

export interface CustomerInfo {
    id: number
    name: string;
    address1: string
    address2: string
    address3: string
    phone: string
    email: string
}

export default function EncoreCustomers() {
    const classes = useStyles()
    const [isLoaded, setIsLoaded] = useState<boolean>(true)
    const [searchInput, setSearchInput] = useState<string>('')
    const [customers, setCustomers] = useState<Array<CustomerInfo>>([])

    const navigate = useNavigate()
    // let { path, url } = useRouteMatch()
    const location = useLocation()

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        setSearchInput(event.target.value)
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
        }
    }

    useEffect(() => {
        if (sTimer !== null) {
            clearTimeout(sTimer);
          }
          sTimer = setTimeout(() => {
            if (searchInput.length > 0) {
                setIsLoaded(false)
                fetch(`/api/encore/customers/search/${searchInput}`)
                .then(res => res.json())
                .then((result) => {
                    if(result) {
                        setCustomers(result)
                    } else {
                        setCustomers([])
                    }
                    setIsLoaded(true)
                })
            }
          }, 1500);
    }, [searchInput])

    function handleCustomerDetail(customerId: number) {
        navigate(`${customerId}`)
    }

    return (
       <div>
                    <Breadcrumbs>
                        <Link to="/encore" className={classes.link}>
                            <Home className={classes.icon} />
                            Home
                        </Link>
                        <Typography color="textPrimary">Customers</Typography>
                    </Breadcrumbs>
                    <div className={classes.header}>
                        <h2 style={{flexGrow: 1}}>
                            Customers
                        </h2>
                        <div className={classes.margin}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search />
                                </Grid>
                                <Grid item>
                                    <TextField 
                                    id="input-with-icon-grid" 
                                    label="Search by Name or Acc No"
                                    onChange={handleSearch}
                                    value={searchInput}
                                    InputProps={{
                                        className: classes.input,
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton color="inherit" onClick={handleClearSearch}>
                                                <ClearAll />
                                            </IconButton>
                                        </InputAdornment>
                                    }} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {!isLoaded && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
                    <Divider style={{marginBottom: 16}} />
                    {isLoaded && 
                        <div>
                            <Grid container spacing={3}>
                                {customers.length !== 0 && customers.map((customer: CustomerInfo) => (
                                    <Grid item xs={12} key={customer.id}>
                                        <Card>
                                            <CardHeader title={customer.name}>
                                            </CardHeader>
                                            <CardContent>
                                                <div>
                                                    <span>Phone: {customer.phone}</span>
                                                </div>
                                                <div>
                                                    <span>Email: {customer.email}</span>
                                                </div>
                                                <div>
                                                    <span>Address: {customer.address1} {customer.address2} {customer.address3}</span>
                                                </div>
                                            </CardContent>
                                            <CardActions style={{backgroundColor: "#fafafa"}}>
                                                <div style={{flexGrow: 1}}></div>
                                                <Button variant="contained" color="secondary" onClick={() => handleCustomerDetail(customer.id)}>
                                                    Detail
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                                {customers.length === 0 && 
                                    <Grid item xs={12}>
                                        <Card>
                                            <p style={{marginLeft: 8}}>No search results found.</p>
                                        </Card>
                                    </Grid>
                                }
                            </Grid>

                        </div>
                    }
                </div>
    )
}

// interface CustomersTableProps {
//     customers: Array<CustomerInfo>
// }

// export function CustomersTable({ customers }: CustomersTableProps) {

//     const classes = useStyles()

//     const navigate = useNavigate()

//     const gridWrapperRef = useRef<HTMLDivElement>(null);
    
//     // useLayoutEffect(() => {
//     //     const gridDiv = gridWrapperRef.current;
//     //     if (gridDiv){
//     //         const gridEl: HTMLDivElement = gridDiv.querySelector('div')!;
//     //         gridEl.style.height = '';
//     //     }
//     // });

//     const customerColumns: GridColDef[] = [
//         { field: 'id', hide: true },
//         { field: 'name', headerName: 'Name', width: 350 },
//         { field: 'address1', headerName: 'Address', width: 400, valueFormatter: ((params: GridValueFormatterParams) => 
//             `${params.value} ${params.getValue(params.id,'address2')} ${params.getValue(params.id, 'address3')}`
//         )},
//         { field: 'phone', headerName: 'Phone', width: 130 },
//         { field: 'email', headerName: 'Email', width: 250 },
//     ]

//     function getTableRows(): GridRowsProp {
//         let rows: GridRowData[] = []
//         customers?.forEach((cust: CustomerInfo) => {
//             let obj: any = {
//                 ...cust
//             }
//             rows.push(obj)
//         })
//         return rows
//     }

//     return (
//         <div ref={gridWrapperRef}>
//             <DataGrid  
//                 className={classes.dataGrid}
//                 autoHeight
//                 rows={getTableRows()} 
//                 columns={customerColumns} 
//                 pageSize={25}
//                 onRowClick={(params: GridRowParams) => {
//                     navigate(`/encore/customers/${params.id}`)
//                 }} />
//         </div>

//     )
// }
