import React, { useState, useEffect } from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, TextField, LinearProgress
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { API } from "aws-amplify";
import LTQAnalysis from './LTQAnalysis';
import useFetch from "../../encore/hooks/api/useFetch";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        header: {
            display: "flex"
        },
        autocomplete: {
            marginTop: 16, 
            marginBottom: 8,
            width: 400
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
    })
);

interface Props {
    user?: any | null;
}

export default function LTQTestResults({ user }: Props) {
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(true)
    const [selectedProduct, setSelectedProduct] = useState<string | null>()
    const [active, setActive] = useState<boolean>(false)

    const products = ['SE620', 'SE720', 'SE820', 'SE920']

    const { data,  error:fetchError, reFetch } = useFetch<Array<string>>(`/el/products`)

    
    if(data) {
        //Add data to products
        const newProducts = data.filter((x) => !x.startsWith("SE")  && !x.startsWith(" "))
        products.push(...newProducts)
        //Sort Products
        products.sort()

    }
    

    useEffect(() => {
        let productName = localStorage.getItem('eurocorpProductName')
        if(productName) {
            setSelectedProduct(productName)
            setActive(true)
        }
    }, [])

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    LTQ Test Results
                </h2>
                <Autocomplete
                    className={classes.autocomplete}
                    options={products}
                    getOptionSelected={(option, value) => {
                        return option === value
                    }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="Search Product Name"/>
                    )}
                    getOptionLabel={(option) => option}
                    onChange={(_, newValue) => {
                        if(newValue) {
                            setSelectedProduct(newValue)
                            localStorage.setItem('eurocorpProductName', newValue)
                            localStorage.setItem('eurocorpSupplier', '')
                            localStorage.setItem('eurocorpManufacturer', '')
                            setActive(true)
                        }
                    }}/>
            </div>
            {loading &&  <LinearProgress  className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
            <Divider style={{marginBottom: 16}}/>

            <LTQAnalysis productName={selectedProduct} active={active} setLoading={setLoading} />

        </div>
    )
}
