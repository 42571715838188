import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormLabel,
  DialogTitle,
  DialogContentText,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  FilledInput,
  Checkbox,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import {
  JobMeasureQuoteLineInfo,
  JobVariationSubSchedules,
} from "../../types/JobTypes";
import NumberFormat from "react-number-format";
import { API } from "aws-amplify";
import VariationTree from "./VariationTree";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
  })
);

interface QuoteDialogProps {
  openDialog: boolean;
  handleCloseDialog: (open: boolean) => void;
  jobMeasureQuoteInfo?: JobMeasureQuoteLineInfo;
  isNewItem: boolean;
  similarQuotes?: Array<JobMeasureQuoteLineInfo>;
  isUpdateMode?: boolean;
}

export default function AddToScheduleDialog({
  openDialog,
  handleCloseDialog,
  jobMeasureQuoteInfo,
  isNewItem,
  similarQuotes,
  isUpdateMode,
}: QuoteDialogProps) {
  const [complexityTypes, setComplexityTypes] = useState<string[]>([
    "",
    "Cut/Bend",
    "Other",
    "Cut/CBend",
    "Blockwork",
    "Epoxied",
    "Not Tied",
    "Spacers",
    "Spiral",
    "Stirrup",
    "Stock Bar",
    "Straight",
  ]);

  const classes = useStyles();

  const { handleSubmit, control, errors, reset } =
    useForm<JobMeasureQuoteLineInfo>();
  const [error, setError] = useState<string>();
  const [open, setOpen] = React.useState(false);
  const [subSchedules, setSubSchedules] = React.useState(
    Array<JobVariationSubSchedules>()
  );

  useEffect(() => {
    // console.log(jobMeasureQuoteInfo);
    //Add type to complexityTypes if it is not there with normal casing
    if (
      jobMeasureQuoteInfo?.type &&
      !complexityTypes.includes(jobMeasureQuoteInfo?.type)
    ) {
      setComplexityTypes((prevTypes) => [
        ...prevTypes,
        jobMeasureQuoteInfo.type,
      ]);
    }

    reset(jobMeasureQuoteInfo);
    if (jobMeasureQuoteInfo?.jobSeqNo) {
      fetchSubschedules();
    }
  }, [jobMeasureQuoteInfo, reset]);

  async function onSubmit(data: JobMeasureQuoteLineInfo) {
    data.jobSeqNo = jobMeasureQuoteInfo?.jobSeqNo;
    data.kg = parseInt(data.kg.toString());
    data.placeRate = parseFloat(data.placeRate.toString());
    data.supplyRate = parseFloat(data.supplyRate.toString());
    data.schedule = parseFloat(data.schedule.toString());
    data.id = jobMeasureQuoteInfo?.id!;

    let params = {
      body: {
        ...data,
      },
    };
    //Post
    try {
      await API.post(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/measure/add`,
        params
      );
      handleCloseDialog(false);
    } catch (error) {
      console.log(error);
      setError("Error: Unable to post record.");
    }
  }

  async function onSubmitUpdate(data: JobMeasureQuoteLineInfo) {
    data.jobSeqNo = jobMeasureQuoteInfo?.jobSeqNo;
    data.kg = parseInt(data.kg.toString());
    data.placeRate = parseFloat(data.placeRate.toString());
    data.supplyRate = parseFloat(data.supplyRate.toString());
    data.schedule = parseFloat(data.schedule.toString());
    data.id = jobMeasureQuoteInfo?.id!;

    let params = {
      body: {
        ...data,
      },
    };
    //Post
    try {
      await API.put(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/measure/${data.id}`,
        params
      );
      handleCloseDialog(false);
    } catch (error) {
      console.log(error);
      setError("Error: Unable to post record.");
    }
  }

  const handleDialogClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = async (value: string) => {
    // setSelectedValue(value);
    let params = {
      body: {
        jobSeqNo: jobMeasureQuoteInfo?.jobSeqNo,
        variationSubScheduleName: value,
      },
    };
    //Post
    try {
      await API.post(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/subschedules/add`,
        params
      );
    } catch (error) {
      console.log(error);
      setError("Error: Unable to post record.");
    } finally {
      fetchSubschedules();
      setOpen(false);
    }
  };

  const fetchSubschedules = async () => {
    try {
      const response = await API.get(
        "",
        `/encore/jobs/${jobMeasureQuoteInfo?.jobSeqNo}/subschedules`,
        {}
      );
      //   console.log(response);
      setSubSchedules(response);
    } catch (error) {
      console.log(error);
      setError("Error: Fetching Subschedules for job.");
    } finally {
      setOpen(false);
    }
  };
  //default complexityTypes

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={openDialog}
      onClose={() => handleCloseDialog(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>Add To Schedule</DialogTitle>
      <DialogContent>
        {similarQuotes && similarQuotes?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table style={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Schedule</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Supply Rate</TableCell>
                  <TableCell align="right">Place Rate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {similarQuotes?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {`${row.schedule} - ${row.area}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.complexityType}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.product}
                    </TableCell>
                    <TableCell align="right">{row.supplyRate}</TableCell>
                    <TableCell align="right">{row.placeRate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>{!isUpdateMode && <>No Similar Products</>}</>
        )}

        {!isUpdateMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl className={classes.formInput}>
                <Controller
                  render={(props) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                            // clearErrors("endDate")
                          }}
                          checked={props.value}
                        />
                      }
                      label="Mark as Top Level Variation Schedule"
                    />
                  )}
                  name="isVariationSchedule"
                  control={control}
                  defaultValue={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.schedule)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>Schedule</FormLabel>

              <Controller
                as={
                  <TextField
                    placeholder="Enter Schedule"
                    fullWidth
                    variant="filled"
                    error={errors.schedule ? true : false}
                    helperText={errors.schedule?.message}
                    disabled={!isNewItem}
                  />
                }
                name="schedule"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.area)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>Area</FormLabel>

              <Controller
                as={
                  <TextField
                    placeholder="Enter area"
                    fullWidth
                    variant="filled"
                    error={errors.area ? true : false}
                    helperText={errors.area?.message}
                    disabled={!isNewItem}
                  />
                }
                name="area"
                control={control}
                defaultValue=""
                rules={
                  {
                    // required: "This is required",
                  }
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl
          error={Boolean(errors.product)}
          className={classes.formInput}
        >
          <FormLabel className={classes.label}>Product</FormLabel>

          <Controller
            as={
              <TextField
                placeholder="Enter Product"
                fullWidth
                variant="filled"
                error={errors.product ? true : false}
                helperText={errors.product?.message}
                disabled={!isNewItem}
              />
            }
            name="product"
            control={control}
            defaultValue=""
            variant="filled"
            placeholder="Enter product"
            rules={{
              required: "This is required",
            }}
          />
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.kg)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>
                Estimated Quantity
              </FormLabel>

              <Controller
                as={
                  <TextField
                    placeholder="Enter Quantity"
                    fullWidth
                    variant="filled"
                    error={errors.kg ? true : false}
                    helperText={errors.kg?.message}
                    type="number"
                    disabled={!isNewItem || isUpdateMode}
                  />
                }
                name="kg"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.uom)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>Unit</FormLabel>

              {isNewItem ? (
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      variant="filled"
                      value={value}
                      onChange={(e) => {
                        let val = e.target.value as string;
                        onChange(val);
                        // methods.clearErrors()
                        // claimSummary.type = val
                        // methods.reset(claimSummary)
                      }}
                    >
                      <MenuItem value={"Each"}>Each</MenuItem>
                      <MenuItem value={"Kg"}>Kg</MenuItem>
                    </Select>
                  )}
                  name="uom"
                  control={control}
                  defaultValue=""
                />
              ) : (
                <Controller
                  as={
                    <TextField
                      placeholder="Enter Unit"
                      fullWidth
                      variant="filled"
                      error={errors.uom ? true : false}
                      helperText={errors.uom?.message}
                      disabled
                    />
                  }
                  name="uom"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>

        <FormControl className={classes.formInput}>
          <FormLabel className={classes.label}>Type</FormLabel>

          {isUpdateMode ? (
            <Controller
              as={
                <TextField
                  placeholder="Type"
                  fullWidth
                  variant="filled"
                  error={errors.type ? true : false}
                  helperText={errors.type?.message}
                  disabled
                />
              }
              name="type"
              control={control}
              defaultValue=""
              rules={{
                required: "This is required",
              }}
            />
          ) : (
            <Controller
              render={({ onChange, value }) => (
                <Select
                  variant="filled"
                  value={value}
                  onChange={(e) => {
                    let val = e.target.value as string;
                    onChange(val);
                    // methods.clearErrors()
                    // claimSummary.type = val
                    // methods.reset(claimSummary)
                  }}
                >
                  {complexityTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
              name="type"
              control={control}
              defaultValue=""
            />
          )}
        </FormControl>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.supplyRate)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>Supply Rate ($)</FormLabel>

              <Controller
                as={
                  <TextField
                    placeholder="Enter supply Rate"
                    fullWidth
                    variant="filled"
                    error={errors.supplyRate ? true : false}
                    helperText={errors.supplyRate?.message}
                    type="number"
                  />
                }
                name="supplyRate"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={Boolean(errors.placeRate)}
              className={classes.formInput}
            >
              <FormLabel className={classes.label}>Place Rate ($)</FormLabel>

              <Controller
                as={
                  <TextField
                    placeholder="Enter place Rate"
                    fullWidth
                    variant="filled"
                    error={errors.placeRate ? true : false}
                    helperText={errors.placeRate?.message}
                    type="number"
                  />
                }
                name="placeRate"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {error && <span style={{ color: "red" }}>{error}</span>}
        <Button color="secondary" onClick={() => handleCloseDialog(false)}>
          Cancel
        </Button>
        {isUpdateMode ? (
          <Button
            onClick={handleSubmit(onSubmitUpdate)}
            variant="contained"
            color="secondary"
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
          >
            Add
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export interface VariationDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function VariationScheduleDialog(props: VariationDialogProps) {
  const classes = useStyles();
  const [name, setName] = useState("");

  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  function handleCloseDialog(arg0: boolean): void {
    onClose(selectedValue);
  }

  function handleSubmit(): void {
    if (name.length === 0) {
      return;
    }
    onClose(name);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">New Variation Schedule</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Enter Schedule"
          fullWidth
          variant="filled"
          required
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
        <Button color="secondary" onClick={() => handleCloseDialog(false)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="secondary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
