import React from "react"

function SwimsHome() {
    return (
        <div>
            <h1>
                Swims Home
            </h1>
        </div>
    )
}

export default SwimsHome