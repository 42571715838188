import React, { useState } from 'react'
import {
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import {
    Paper, IconButton, TableContainer, Table, TableHead, TableRow,
    TableBody, Collapse, Box, TableCell, Button, Dialog, DialogTitle,
    DialogContent, DialogActions, CircularProgress, Chip, Menu, MenuItem, Toolbar, Tooltip, Typography, Card, CardActions, CardContent
} from "@material-ui/core";
import {
    KeyboardArrowDown, KeyboardArrowUp, Assessment, Edit
} from "@material-ui/icons";
import {
    JobClaimsData, JobScheduleClaims
} from '../../types/JobTypes';
import {
    StyledTableCell, StyledTableRow
} from '../../../components/StyledTableComponents';
import { API } from "aws-amplify";
import { Link, NavLink } from 'react-router-dom';
import { ReportInfo } from '../../../components/ReportViewer';
import { ClaimDescriptionDialog } from './ClaimDescriptionDialog';
import { callMsGraph } from '../../../utils/MsGraphApi';
import { ClaimContactForm } from '../../types/OpportunityTypes';
import JobDetails from './JobDetails';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        table: {
            minWidth: 650,
        },
        green: {
            color: "green"
        },
        red: {
            color: "red"
        },
        dollarAmountCell: {
            textAlign: "right"
        },
        link: {
            color: "red",

        },
        title: {
            flex: '1 1 100%',
        },
    })
);

interface JobClaimTableProps {
    claimData: Array<JobClaimsData>
    user?: any | null
    refreshData: () => void;
    setClaimEditMode: (editeMode: boolean) => void;
    setSelectedClaimID: (claim: number) => void;
    claimReports: Array<ReportInfo>;
    jobNo: string | undefined;
    canEdit: boolean;

}

export function JobClaimTable({ claimData, user, refreshData, setClaimEditMode, setSelectedClaimID, claimReports, jobNo, canEdit }: JobClaimTableProps) {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [claimSeqNo, setClaimSeqNo] = useState<number>()
    // const [reportId, setReportId] = useState<number>()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, claimId: number) => {
        setClaimSeqNo(claimId)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <TableContainer component={Paper} style={{ marginTop: 16 }}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>

                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Actual Place</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Actual Supply</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Actual Total</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Claim Place</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Claim Supply</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Claim Total</TableCell>
                            <TableCell className={classes.dollarAmountCell}>Adjustment Total</TableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {claimData.map((claim: JobClaimsData, idx: number) => (
                            <ClaimTableRow key={idx} claim={claim} user={user} refreshData={refreshData} setClaimEditMode={setClaimEditMode} setSelectedClaimID={setSelectedClaimID} handleMenuClick={handleClick} canEdit={canEdit} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {claimReports.length > 0 &&
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >

                    {claimReports.map((report) => (
                        // <Link to={`/report/viewer/${report.reportId}?claimNo=${claimSeqNo}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
                        //     <MenuItem  onClick={handleClose}>{report.reportName}</MenuItem>
                        // </Link>
                        <MenuItem
                            href={`/#/report/viewer/${report.reportId}?claimNo=${claimSeqNo}&jobID=${jobNo?.replaceAll("EC", "")}`}
                            target="_blank"
                            component="a"
                            onClick={handleClose}
                        > {report.reportName} </MenuItem>
                    ))}
                </Menu>
            }
        </>
    )
}

interface ClaimTableRowProps {
    claim: JobClaimsData
    user?: any | null
    refreshData: () => void;
    setClaimEditMode: (editeMode: boolean) => void;
    setSelectedClaimID: (claim: number) => void;
    handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>, claimId: number) => void;
    canEdit: boolean;
}

function ClaimTableRow({ claim, user, refreshData, setClaimEditMode, setSelectedClaimID, handleMenuClick, canEdit }: ClaimTableRowProps) {

    const classes = useStyles()

    const [open, setOpen] = useState<boolean>(false);
    const [showClaimDescriptionDialog, setShowClaimDescriptionDialog] = useState<boolean>(false);
    const [openAcceptClaimDialog, setOpenAcceptClaimDialog] = useState<boolean>(false);
    const [openInvoiceClaimDialog, setOpenInvoiceClaimDialog] = useState<boolean>(false);

    const calculateAdjustment = (actual: number, claim: number): string => {
        let adjustment = claim - actual
        return adjustment.toFixed(2)
    }

    const getAdjustmentClass = (actual: number, claim: number) => {
        if (claim > actual) {
            return classes.green
        } else if (claim < actual) {
            return classes.red
        }
    }

    const getStatusBackgroundColor = (status: string): string => {
        if (status === "ACCEPTED") {
            return "#e8f5e9"
        } else if (status === "INVOICED") {
            return "#ffebee"
        } else {
            return "#e3f2fd"
        }
    }

    const getStatusColor = (status: string): string => {
        if (status === "ACCEPTED") {
            return "#388e3c"
        } else if (status === "INVOICED") {
            return "#d32f2f"
        } else {
            return "#1976d2"
        }
    }

    const showDetailedReport = (claimNo: number) => {
        console.log(claimNo)
        window.open("localhost");
    }

    const onShowClaimDescriptionDialogClick =() =>{
        setShowClaimDescriptionDialog(true)

    }
    const onCloseClaimDescriptionDialogClick =() =>{
        setShowClaimDescriptionDialog(false)

    }
    const onSubmitClaimDescriptionDialogClick =(contactDetails: ClaimContactForm) =>{
        console.log(contactDetails)
        

        let params = {
            body: {
                ...contactDetails
            }
        }

        API.put("", `/encore/jobs/${claim.jobSeqNo}/claim/${claim.seqNo}`, params)
            .then(() => {
                setShowClaimDescriptionDialog(false)
                setClaimEditMode(false)
                
            })
            .catch((error: any) => {
                console.log("Error: unable to submit Contact Details", error)
            }).finally(()=>{
                refreshData()
            })

    }

    return (
        <>
            <TableRow >
                <TableCell colSpan={16}>
                    <Toolbar>
                        <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                            Contact: {claim.contactName}
                        </Typography>
                        <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                            Email: {claim.email}
                        </Typography>
                        <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                            Phone: {claim.phone}
                        </Typography>
                        <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                            Ref#: {claim.referenceNo}
                        </Typography>

                        <Tooltip title="Edit Claim details">
                            <IconButton aria-label="Edit Claim details" onClick={onShowClaimDescriptionDialogClick}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <Typography className={classes.title} variant="body2" id="tableTitle" component="div">
                        {claim.description && claim.description.length > 0 ? claim.description : "No Description"}
                    </Typography>


                </TableCell>

            </TableRow>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <strong>{claim.seqNo}</strong>
                </TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.actualPlace.toFixed(2)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.actualSupply.toFixed(2)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.actualAmount.toFixed(2)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.claimPlace.toFixed(2)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.claimSupply.toFixed(2)}</strong></TableCell>
                <TableCell className={classes.dollarAmountCell}><strong>${claim.claimAmount.toFixed(2)}</strong></TableCell>
                <TableCell className={`${getAdjustmentClass(claim.actualAmount, claim.claimAmount)} ${classes.dollarAmountCell}`}><strong>${calculateAdjustment(claim.actualAmount, claim.claimAmount)}</strong></TableCell>
                <TableCell>
                    <Chip label={claim.claimStatus} size="small" style={{ backgroundColor: getStatusBackgroundColor(claim.claimStatus), color: getStatusColor(claim.claimStatus), fontWeight: "bold" }} />
                </TableCell>
                <TableCell style={{ display: "flex" }}>
                    <>
                        {claim.claimStatus === "ISSUED" &&
                            <>
                                <Button variant="contained" color="secondary" onClick={() => setOpenAcceptClaimDialog(true)}>
                                    Accept
                                </Button>
                                <Button style={{ marginLeft: 8 }} variant="contained" color="secondary" disabled={!canEdit} onClick={() => {
                                    setSelectedClaimID(claim.seqNo)
                                    setClaimEditMode(true)
                                }}>
                                    Edit
                                </Button>



                            </>
                        }
                        {claim.claimStatus === "ACCEPTED" &&
                            <Button variant="contained" color="primary" onClick={() => setOpenInvoiceClaimDialog(true)}>
                                Invoice
                            </Button>
                        }
                        {/* <Button style={{ marginLeft: 8 }} variant="contained" color="secondary" href={`/#/report/viewer/102?claimNo=${claim.seqNo}`} target="_blank">
                            <Assessment />
                        </Button> */}
                        <Button style={{ marginLeft: 8 }} variant="contained" color="secondary" onClick={(event) => handleMenuClick(event, claim.seqNo)}>
                            <Assessment />
                        </Button>
                    </>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>


                            <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1 }}>
                                    {claim.adjustmentType !== "none" &&
                                        <>
                                            <div>
                                                <strong>Adjustment Type:</strong> {claim.adjustmentType}
                                            </div>
                                            <div>
                                                <strong>Adjustment Comment:</strong> {claim.adjustmentComment}
                                            </div>
                                        </>
                                    }
                                </div>
                                {claim.claims.filter((j: JobScheduleClaims) => j.schedule === "Labour").map((v: JobScheduleClaims, i: number) => (
                                    <div key={i}>
                                        <div>
                                            <strong>Labour Total:</strong> ${v.actualAmount}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Schedule</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Actual Place</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Actual Supply</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Actual Amount</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Claim Place</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Claim Supply</StyledTableCell>
                                        <StyledTableCell className={classes.dollarAmountCell}>Claim Amount</StyledTableCell>
                                        {claim.adjustmentType !== "none" &&
                                            <>
                                                {claim.adjustmentType === "percentage" &&
                                                    <StyledTableCell>Adjustment Percentage</StyledTableCell>
                                                }
                                                <StyledTableCell>Adjustment Amount</StyledTableCell>
                                            </>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {claim.claims.map((c: JobScheduleClaims) => (
                                        <React.Fragment key={c.id}>
                                            {c.schedule !== "Labour" &&
                                                <StyledTableRow key={c.id}>
                                                    <StyledTableCell>{c.schedule}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.actualPlace}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.actualSupply}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.actualAmount}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.claimPlace}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.claimSupply}</StyledTableCell>
                                                    <StyledTableCell className={classes.dollarAmountCell}>${c.claimAmount}</StyledTableCell>
                                                    {claim.adjustmentType !== "none" &&
                                                        <>
                                                            {claim.adjustmentType === "percentage" &&
                                                                <StyledTableCell>{c.adjustmentPercentage}%</StyledTableCell>
                                                            }
                                                            <StyledTableCell className={getAdjustmentClass(c.actualAmount, c.claimAmount)}><strong>${c.adjustmentTotal}</strong></StyledTableCell>
                                                        </>
                                                    }
                                                </StyledTableRow>
                                            }
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <AcceptClaimDialog
                open={openAcceptClaimDialog}
                handleDialog={setOpenAcceptClaimDialog}
                claim={claim}
                user={user}
                refreshData={refreshData}
            />

            <InvoiceClaimDialog
                open={openInvoiceClaimDialog}
                handleDialog={setOpenInvoiceClaimDialog}

            />

            <ClaimDescriptionDialog open={showClaimDescriptionDialog} claimContact={{description: claim.description, contactName: claim.contactName, email: claim.email, phone: claim.phone, referenceNo: claim.referenceNo}} onClose={onCloseClaimDescriptionDialogClick } onSubmitClose={onSubmitClaimDescriptionDialogClick}></ClaimDescriptionDialog>
        </>
    )
}

interface AcceptClaimDialogProps {
    open: boolean
    handleDialog: (open: boolean) => void;
    claim: JobClaimsData
    user?: any | null
    refreshData: () => void;
}

function AcceptClaimDialog({ open, handleDialog, claim, user, refreshData }: AcceptClaimDialogProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const onSubmit = () => {
        if (claim && user) {
            setLoading(true)
            let params = {
                body: {
                    seqNo: claim.seqNo,
                    updatedBy: user.attributes.name
                }
            }
            API.post("", "/encore/jobs/acceptClaim", params)
                .then(() => {
                    setLoading(false)
                    handleDialog(false)
                    refreshData()
                })
                .catch((error: any) => {
                    setLoading(false)
                    setError("Error: unable to process request")
                    console.log("Error: accepting claim", error)
                })
        } else {
            setError("Error: unable to process request")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Accept Claim
            </DialogTitle>
            <DialogContent>
                Are you sure you want to accept this claim?
            </DialogContent>
            <DialogActions style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                {error &&
                    <span style={{ color: "red" }}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)} color="secondary" style={{ width: 60 }}>
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary" style={{ width: 60 }}>
                        <CircularProgress size={25} style={{ color: "white" }} />
                    </Button>
                    :
                    <Button onClick={() => onSubmit()} variant="contained" color="secondary" style={{ width: 60 }}>
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

interface InvoiceClaimDialogProps {
    open: boolean
    handleDialog: (open: boolean) => void;
}

function InvoiceClaimDialog({ open, handleDialog }: InvoiceClaimDialogProps) {

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Invoice Claim
            </DialogTitle>
            <DialogContent>
                Are you sure you want to invoice this claim?
            </DialogContent>
            <DialogActions style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}></div>
                <Button onClick={() => handleDialog(false)} color="secondary" style={{ width: 60 }}>
                    No
                </Button>
                <Button variant="contained" color="secondary" style={{ width: 60 }}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
