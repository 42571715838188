import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "bc4a3f9b-76da-440b-90f0-627dd95a8f37",
        authority: "https://login.microsoftonline.com/9896f1c6-9743-4c61-9c8e-6d6ba0bd4aab",
        redirectUri: `${window.location.protocol}//${window.location.host}`,
        postLogoutRedirectUri: "/"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [
        'user.read',
        // 'mailboxsettings.read',
        // 'calendars.readwrite',
        'files.readwrite',
        'sites.fullcontrol.all',
        'sites.readwrite.all',
        'sites.read.all'
      ]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
    //Prod
    siteEndpoint: "https://graph.microsoft.com/v1.0/sites/eurocorporation.sharepoint.com,3f8c3b7f-fea0-4e1a-9730-8ce0c979bdbe,0f57be9d-b3b4-4b8e-ac25-4637d4ff65dc"
    //Test
    // siteEndpoint: "https://graph.microsoft.com/v1.0/sites/eurocorporation.sharepoint.com,b76b9389-84ed-42ac-90ad-864aca7ac701,6d3807ff-5d61-4050-bb26-cb589d6715f7"
};