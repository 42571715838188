import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Typography, Button, FormControl, Dialog, DialogContent, 
    Step, TextField, FormLabel, Stepper, StepLabel, FormHelperText
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab"
import { Controller, useForm } from "react-hook-form";
import { API } from "aws-amplify";
import { 
    NewCustomerForm, CustomerContactForm, CreateNewCustomerForm 
} from '../../../types/OpportunityTypes';
import PlacesAutocomplete from "react-places-autocomplete";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        stepper: {
            '&.MuiStepper-root': {
                padding: 16
            }
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        }
    })
);


interface NewCustomerDialogProps {
    openDialog: boolean;
    handleAddDialog: (open: boolean) => void;
    handleCreateDialog: (open: boolean) => void;
    // setOpenGenQuoteDialog: (open: boolean) => void;
    setNewCustomerForm: (data: CreateNewCustomerForm) => void;
    user?: any | null;
    oppNo?: string;
    getCustomers: () => void;
}

export default function CreateCustomerDialog({ openDialog, handleAddDialog, handleCreateDialog, setNewCustomerForm, user, oppNo, getCustomers }: NewCustomerDialogProps) {
    
    const classes = useStyles()
    const [roleOptions, setRoleOptions] = useState<Array<string>>([])
    const [rolesLoading, setRolesLoading] = useState<boolean>(false)

    const [activeStep, setActiveStep] = useState<number>(0)
    const steps =  ["Customer details", "Contact details"]

    useEffect(() => {
        if(openDialog) {
            setRolesLoading(true)
            API.get("", '/encore/opportunities/customers/roles', {})
            .then((response: Array<string>) => {
                if(response) {
                    setRoleOptions(response)
                }
                setRolesLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching customer contact roles", error)
                setRolesLoading(false)
            })
        }
    }, [openDialog])

    const getStepContent = (activeStep: number) => {
        switch(activeStep) {
            case 0:
                return (
                    <CreateCustomerForm 
                        handleCreateDialog={handleCreateDialog}
                        handleAddDialog={handleAddDialog}
                        incrementActiveStep={incrementActiveStep}
                    /> 
                )
            case 1:
                return (
                    <CreateContactForm
                        handleCreateDialog={handleCreateDialog}
                        handleAddDialog={handleAddDialog}
                        decrementActiveStep={decrementActiveStep}
                        roleOptions={roleOptions}
                        rolesLoading={rolesLoading}
                        setNewCustomerForm={setNewCustomerForm}
                        user= {user}
                        oppNo={oppNo}
                        getCustomers={getCustomers}
                        // setOpenGenQuoteDialog={setOpenGenQuoteDialog}
                    />
                )

            default:
                return <div></div>
        }
    }

    const decrementActiveStep = () => {
        setActiveStep(step => step - 1)
    }

    const incrementActiveStep = () => {
        setActiveStep(step => step + 1)
    }
 
    return (
        <Dialog 
            fullWidth 
            maxWidth={'sm'} 
            open={openDialog} 
            onClose={() => {
                handleCreateDialog(false)
                handleAddDialog(true)
            }} 
            aria-labelledby="form-dialog-title">

            <DialogContent>
                <div style={{marginBottom: 16}}>
                    <Typography variant="h6">
                        Create Customer
                    </Typography>
                </div>

                <Stepper className={classes.stepper} activeStep={activeStep}>
                    {steps.map((label: string, i: number) => (
                        <Step key={i}>
                            <StepLabel>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div style={{padding: 16}}>
                    {getStepContent(activeStep)}
                </div>

            </DialogContent>
        </Dialog>
    )
}

interface CreateCustomerFormProps {
    handleCreateDialog: (open: boolean) => void
    handleAddDialog: (open: boolean) => void
    incrementActiveStep: () => void;
}


function CreateCustomerForm({ handleCreateDialog, handleAddDialog, incrementActiveStep }: CreateCustomerFormProps) {

    const classes = useStyles()
    const { handleSubmit, control, errors, clearErrors, setError, setValue, reset } = useForm<NewCustomerForm>({
        shouldUnregister: false
    });

    const [addressInput, setAddressInput] = useState<string>('')
    const [addressValue, setAddressValue] = useState<string>()

    useEffect(() => {
        let createCustomerForm = localStorage.getItem("createCustomerForm")
        if(createCustomerForm) {
            let form: NewCustomerForm = JSON.parse(createCustomerForm)
            setAddressInput(form.address)
            setAddressValue(form.address)
            reset(form)
        }
    }, [])

    function onSubmit(data: NewCustomerForm) {
        if(!addressValue) {
            setError("address", { message: "This is required"})
        } else {
            data.address = addressValue
            localStorage.setItem("createCustomerForm", JSON.stringify(data))
            incrementActiveStep()
        }
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.clientName)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Client Name</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter Client Name"
                            fullWidth
                            variant="filled"
                            error={errors.clientName ? true : false}
                            helperText={errors.clientName?.message}/>
                    }
                    name="clientName"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
            </FormControl>

            <FormControl
                error={Boolean(errors.address)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Office Address</FormLabel>

                <PlacesAutocomplete 
                    value={addressInput}
                    onChange={(address: string) => {
                        setAddressInput(address)
                        clearErrors("address")
                        setAddressValue(undefined)
                    }}
                    onSelect={(_, placeID: string) => {
                        var request = {
                            placeId: placeID
                        };
                
                        var placeRequest: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
                            new google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                                if (status === google.maps.places.PlacesServiceStatus.OK) {
                                    if (place != null) {
                                        resolve(place);
                                    }
                                } else { reject() };
                            });
                        })
                        placeRequest.then((result: google.maps.places.PlaceResult) => {
                            if(result && result?.formatted_address && result?.address_components) {
                                setAddressInput(result.formatted_address)
                                setAddressValue(result.formatted_address)

                                result.address_components.forEach((c) => {
                                    if(c.types.includes("street_number")) {
                                        setValue("streetNumber", c.long_name)

                                    } else if(c.types.includes("route")) {
                                        setValue("street", c.long_name)

                                    } else if(c.types.includes("sublocality_level_1")) {
                                        setValue("suburb", c.long_name)

                                    } else if(c.types.includes("locality")) {
                                        setValue("region", c.long_name)
                                    
                                    } else if(c.types.includes("postal_code")) {
                                        setValue("postcode", c.long_name)

                                    }
                                })
                            }
                        })
                        .catch((error: any) => {
                            console.log("Error: ", error)
                        })
                    }}
                    searchOptions={{
                        componentRestrictions: { country: ['nz'] },
                        types: ['address']
                    }}
                    onError={(_, clearSuggestions) => {
                        clearSuggestions()
                    }}>

                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField
                                fullWidth
                                variant="filled"

                                {...getInputProps({
                                    placeholder: 'Enter address',
                                })}
                            />
                            <div className={classes.addressOptions}>
                            {loading && 
                                <div style={{backgroundColor: '#ffffff', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa"}}>
                                    <Typography variant="body1">
                                        Loading ...
                                    </Typography>
                                </div>
                            }

                            {suggestions.map((suggestion: any, i: number) => {
                                let style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" }
                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" };

                                if((i === suggestions.length - 1) && !suggestion.active) {
                                    style = { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "2px solid #fafafa" }
                                }

                                return (
                                    <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                        <Typography variant="body1">{suggestion.description}</Typography>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <FormHelperText>
                    {errors.address && "Invalid address"}
                </FormHelperText>
            </FormControl>

            <FormControl
                error={Boolean(errors.accountsEmail)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Accounts Email</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter Accounts Email"
                            fullWidth
                            variant="filled"
                            error={errors.accountsEmail ? true : false}
                            helperText={errors.accountsEmail?.message}/>
                    }
                    name="accountsEmail"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
            </FormControl>

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}></div>
                <div>
                    <Button color="secondary" onClick={() => { 
                        handleCreateDialog(false)
                        handleAddDialog(true)}}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface CreateContactFormProps {
    decrementActiveStep: () => void;
    handleCreateDialog: (open: boolean) => void
    handleAddDialog: (open: boolean) => void
    roleOptions: Array<string>;
    rolesLoading: boolean
    setNewCustomerForm: (data: CreateNewCustomerForm) => void;
    user: any;
    oppNo?: string;
    getCustomers: () => void;
    // setOpenGenQuoteDialog: (open: boolean) => void;

}

function CreateContactForm({ decrementActiveStep, handleCreateDialog, handleAddDialog, roleOptions, rolesLoading, setNewCustomerForm, user, oppNo, getCustomers }: CreateContactFormProps) {

    const classes = useStyles()
    const { handleSubmit, control, errors, clearErrors, setValue, reset, getValues } = useForm<CustomerContactForm>();

    const [error, setError] = useState<string>()

    useEffect(() => {
        let createContactForm = localStorage.getItem("createContactForm")
        if(createContactForm) {
            let form: CustomerContactForm = JSON.parse(createContactForm)
            reset(form)
        }
    }, [])

    const onBack = () => {
        localStorage.setItem("createContactForm", JSON.stringify(getValues()))
        decrementActiveStep()
    }

    function onSubmit(data: CustomerContactForm) {
        let jsonForm = localStorage.getItem("createCustomerForm")
        if(jsonForm) {
            let customerDetailsForm: NewCustomerForm = JSON.parse(jsonForm)

            let newCustomerForm: CreateNewCustomerForm = {
                ...data,
                ...customerDetailsForm
            }


            var params = {
                body: {
                    opportunityNo: oppNo,
                    username:user.attributes.name,
                    genQuote: false,
                    ...newCustomerForm
                }
            }
            API.post("", `/encore/opportunities/createCustomer`, params)
                .then(() => {
                    // setNewCustomerForm(newCustomerForm)
                    handleCreateDialog(false)
                    handleAddDialog(false)
                    getCustomers()
                })
                .catch((error: any) => {
                    console.log("Error: creating opportunity customer with quote", error)
                    // setLoading(false)
                    setError("Error: unable to create customer.")
                })
            
            // setOpenGenQuoteDialog(true)
        } else {
            setError("Error: unable to create customer")
        }     
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.contactName)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Contact Name</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter Contact Name"
                            fullWidth
                            variant="filled"
                            error={errors.contactName ? true : false}
                            helperText={errors.contactName?.message}/>
                    }
                    name="contactName"
                    control={control}
                    defaultValue=""
                    rules={{
                        // required: "This is required"
                    }}
                />
            </FormControl>

            <FormControl
            error={Boolean(errors.role)} 
            className={classes.formInput}>

            <FormLabel className={classes.label}>Role</FormLabel>

            <Controller
                render={(props) => (
                    <Autocomplete
                        {...props}
                        freeSolo
                        loading={rolesLoading}
                        options={roleOptions}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                variant="filled" 
                                fullWidth
                                placeholder="Select a role"
                                error={errors.role ? true : false}
                                helperText={errors.role?.message} />
                        )}
                        onInputChange={(_, newInputValue) => {
                            if(newInputValue.length !== 0) {
                                setValue("role", newInputValue)
                                clearErrors("role")
                            }
                        }}
                        onChange={(_, data) => props.onChange(data)}/>
                )}
                name="role"
                control={control}
                rules={{
                    // required: "This is required"
                }}
                defaultValue={null}
            />
            </FormControl>

            <FormControl
                error={Boolean(errors.email)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Email</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter Email"
                            fullWidth
                            variant="filled"
                            error={errors.email ? true : false}
                            helperText={errors.email?.message}/>
                    }
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
            </FormControl>

            <FormControl
                error={Boolean(errors.phone)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Phone</FormLabel>

                <Controller
                    as={
                        <TextField
                            placeholder="Enter Phone"
                            fullWidth
                            variant="filled"
                            error={errors.phone ? true : false}
                            helperText={errors.phone?.message}/>
                    }
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                        // required: "This is required"
                    }}
                />
            </FormControl>   

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}></div>
                <div>
                    {error &&
                        <span style={{color: "red", marginRight: 4}}>{error}</span>
                    }
                    <Button color="secondary" onClick={() => onBack()}>
                        Back
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Create
                    </Button>
                </div>
            </div>          
        </div>
    )
}