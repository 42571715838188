import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, FormControl, FormLabel, makeStyles, createStyles, Theme } from "@material-ui/core";

import { Controller, useForm } from 'react-hook-form';
import { ClaimContactForm } from '../../types/OpportunityTypes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);


export interface ClaimDescriptionDialogProps {
    open: boolean;
    claimContact: ClaimContactForm;
    onClose: () => void;
    onSubmitClose: (details: ClaimContactForm) => void;
}
export function ClaimDescriptionDialog(props: ClaimDescriptionDialogProps) {
    const classes = useStyles();
    const { handleSubmit, control, errors, clearErrors, setValue, reset } = useForm<ClaimContactForm>();

    const { onClose, open, claimContact, onSubmitClose } = props;

    useEffect(() => {
        // console.log(claimContact)
        reset(claimContact)
    }, [claimContact, reset]);
    // reset(claimContact)
    

    const handleClose = () => {
        onClose();
    };


    function handleCloseDialog(arg0: boolean): void {
        onClose();
    }

    function onSubmit(data: ClaimContactForm): void {
        onSubmitClose(data);
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Claim Description</DialogTitle>
            <DialogContent>

                <FormControl
                    error={Boolean(errors.contactName)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Contact Name</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Contact Name"
                                fullWidth
                                variant="filled"
                                error={errors.contactName ? true : false}
                                helperText={errors.contactName?.message} />
                        }
                        name="contactName"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.email)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Contact Email</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Contact Email"
                                fullWidth
                                type="email"
                                variant="filled"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message} />
                        }
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required",
                            pattern:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.phone)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Contact Phone</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Contact Phone"
                                fullWidth
                                variant="filled"
                                error={errors.phone ? true : false}
                                helperText={errors.phone?.message} />
                        }
                        name="phone"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.referenceNo)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Reference No</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Reference No"
                                fullWidth
                                variant="filled"
                                error={errors.referenceNo ? true : false}
                                helperText={errors.referenceNo?.message} />
                        }
                        name="referenceNo"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.description)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>Description</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Description"
                                fullWidth
                                multiline
                                rows={3}
                                variant="filled"
                                error={errors.description ? true : false}
                                helperText={errors.description?.message} />
                        }
                        name="description"
                        control={control}
                        defaultValue=""
                        rules={{
                            // required: "This is required"
                        }}
                    />
                </FormControl>



            </DialogContent>

            <DialogActions>
                {/* {error &&
                <span style={{color: "red"}}>{error}</span>
            } */}
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    color="secondary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
