import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Select, InputLabel, MenuItem, FormControl, Divider, TextField, Grid, IconButton,
    InputAdornment, Breadcrumbs, Typography
} from "@material-ui/core";
import { Search, Refresh, ClearAll, Home } from "@material-ui/icons";
import { format, parseISO } from "date-fns";
import { Route, Link, useNavigate, Routes, useLocation } from "react-router-dom"
import LoadDetails from './LoadDetails';
import { DataGrid, GridColDef, GridRowsProp, GridRowData, GridValueFormatterParams, GridRowParams } from '@mui/x-data-grid';

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        },
        dataGrid: {
            backgroundColor: "white"
        }
    })
);

export interface LoadInfo {
    loadNo: number
    vehicleNo: string
    loadStartedAt: Time
    loadStartedBy: string
    loadCompletedBy: string
    loadCompletedAt: Time
    dispatchedAt: Time
    dispatchedBy: string
    loadType: string
    loadStatus: string
    totalLoadedWeight: number
    totalLoadedTags: number
    numJobs: number
    location: String
    freightCo: String
}

export interface Time {
    Time: string;
    Valid: boolean;
}

interface Props {
    user?: any | null;
    globalBranch: string
}

export default function SwimsLoads({ user, globalBranch }: Props) {
    const classes = useStyles();

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loads, setLoads] = useState<Array<LoadInfo> | undefined>(undefined);
    const [type, setType] = useState('active');
    const [searchInput, setSearchInput] = useState<string>('');

    // let { path, url } = useRouteMatch()
    const navigate = useNavigate()
    const location = useLocation()

    const activeLoadsColumns: GridColDef[] = [
        { field: 'loadNo', hide: true },
        { field: 'vehicleNo', headerName: 'Vehicle No', width: 125 },
        { field: 'loadStartedBy', headerName: 'Load Started By', width: 165 },
        {
            field: 'loadStartedAt', headerName: 'Load Started At', width: 170, type: 'dateTime',
            valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as Date))
        },
        { field: 'totalLoadedWeight', headerName: 'Total Weight', width: 155 },
        { field: 'numJobs', headerName: '# Jobs', width: 100 },
        { field: 'location', headerName: 'Location', width: 150 },
        { field: 'freightCo', headerName: 'Freight Co', width: 350 },
    ]

    const dispatchedLoadsColumns: GridColDef[] = [
        { field: 'loadNo', hide: true },
        { field: 'vehicleNo', headerName: 'Vehicle No', width: 125 },
        { field: 'loadStartedBy', headerName: 'Started By', width: 165 },
        {
            field: 'loadStartedAt', headerName: 'Started At', width: 170, type: 'dateTime',
            valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as Date))
        },
        { field: 'loadCompletedBy', headerName: 'Completed By', width: 155 },
        {
            field: 'loadCompletedAt', headerName: 'Completed At', width: 170, type: 'dateTime',
            valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as Date))
        },
        { field: 'dispatchedBy', headerName: 'Dispatched By', width: 155 },
        {
            field: 'dispatchedAt', headerName: 'Dispatched At', width: 170, type: 'dateTime',
            valueFormatter: ((params: GridValueFormatterParams) => formatDateString(params.value as Date))
        },
        { field: 'totalLoadedWeight', headerName: 'Total Weight', width: 150 },
        { field: 'numJobs', headerName: '# Jobs', width: 100 },
        { field: 'location', headerName: 'Location', width: 150 },
        { field: 'freightCo', headerName: 'Freight Co', width: 350 },
    ]

    useEffect(() => {
        let isMounted = true;

        setIsLoaded(false)
        setLoads(undefined)
        fetch(`/api/loads/${type}/type/salesorder`)
            .then(res => res.json())
            .then((result: Array<LoadInfo>) => {
                if (isMounted) {
                    if (result) {
                        setLoads(result)
                    } else {
                        setLoads(undefined)
                    }
                    setIsLoaded(true)
                }
            })
            .catch((error: any) => {
                console.log("Error fetching loads", error)
                setIsLoaded(true)
            })

        return () => {
            isMounted = false;
        }
    }, [])

    const handleType = (event: React.ChangeEvent<{ value: unknown }>) => {
        onLoadTypeChanged(event.target.value as string);
    };

    const handleSearch = (event: React.ChangeEvent<{ value: string }>) => {
        onSearchInput(event.target.value)
    }

    const handleClearSearch = () => {
        if (searchInput.length !== 0) {
            setSearchInput('')
            getLoads(type)
        }
    }

    function getLoads(type: string) {
        setIsLoaded(false)
        setLoads(undefined)
        fetch(`/api/loads/${type}/type/salesorder`)
            .then(res => res.json())
            .then((result: Array<LoadInfo>) => {
                if (result) {
                    setLoads(result)
                } else {
                    setLoads(undefined)
                }
                setIsLoaded(true)
            })
    }

    const handleRefresh = () => {
        getLoads(type)
    }

    function onLoadTypeChanged(type: string) {
        setType(type);
        if (searchInput.length === 0) {
            getLoads(type)
        } else {
            setIsLoaded(false)
            setLoads(undefined)
            fetch(`/api/loads/status/${type}/type/salesorder/search/${searchInput}`)
                .then(res => res.json())
                .then((result: Array<LoadInfo>) => {
                    if (result) {
                        setLoads(result)
                    } else {
                        setLoads(undefined)
                    }
                    setIsLoaded(true)
                })
        }
    }


    function onSearchInput(input: string) {
        setSearchInput(input)
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if (input.length > 0) {
                setIsLoaded(false)
                setLoads(undefined)
                fetch(`/api/loads/status/${type}/type/salesorder/search/${input}`)
                    .then(res => res.json())
                    .then((result: Array<LoadInfo>) => {
                        if (result) {
                            setLoads(result)
                        } else {
                            setLoads(undefined)
                        }
                        setIsLoaded(true)
                    })
            }
        }, 1500);
    }

    function formatDate(date: Time): Date | string {
        return date.Valid ? parseISO(date.Time) : "";
    }

    const formatDateString = (date: Date | string) => {
        if (typeof (date) === 'string') {
            return ""
        }

        return format(date, "dd/MM/yyyy HH:mm")
    }

    function getTableRows(): GridRowsProp {
        let rows: GridRowData[] = []
        let filteredList = loads?.filter((load: LoadInfo) => globalBranch === "" ? true :  globalBranch.toLowerCase().includes(load.location.toLowerCase(),0))
        if (filteredList) {
            filteredList.forEach((load: LoadInfo) => {

                let obj: any = {
                    id: load.loadNo,
                    ...load,
                    loadStartedAt: formatDate(load.loadStartedAt),
                    loadCompletedAt: formatDate(load.loadCompletedAt),
                    dispatchedAt: formatDate(load.dispatchedAt)
                }
                rows.push(obj)
            })
        }
        return rows
    }

    return (
        <div>
            <Breadcrumbs>
                <Link to="/swims" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Typography color="textPrimary">Loads</Typography>
            </Breadcrumbs>
            <div className={classes.header}>
                <h2 style={{ flexGrow: 1 }}>
                    Loads
                </h2>
                <div className={classes.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="input-with-icon-grid"
                                label="Search by Vehicle or Order No"
                                onChange={handleSearch}
                                value={searchInput}
                                InputProps={{
                                    className: classes.input,
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton color="inherit" onClick={handleClearSearch}>
                                            <ClearAll />
                                        </IconButton>
                                    </InputAdornment>
                                }} />
                        </Grid>
                    </Grid>
                </div>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={type}
                        onChange={handleType}>
                        <MenuItem value={'active'}>Active</MenuItem>
                        <MenuItem value={'dispatched'}>Dispatched</MenuItem>
                    </Select>
                </FormControl>
                <IconButton color="inherit" onClick={handleRefresh}>
                    <Refresh />
                </IconButton>
            </div>
            {/* {!isLoaded && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>} */}
            <Divider style={{ marginBottom: 16 }} />
            <div style={{ height: 800, width: '100%' }}>
                {type === "active" ?
                    <DataGrid
                        className={classes.dataGrid}
                        autoHeight
                        loading={!isLoaded}
                        rows={getTableRows()}
                        columns={activeLoadsColumns}
                        pageSize={25}
                        onRowClick={(params: GridRowParams) => {
                            navigate(`${params.id}`, { state: { type: type, selectedLoadIdx: params.row.rowIndex, loads: loads, searchInput: searchInput } })
                        }} />
                    :
                    <DataGrid
                        className={classes.dataGrid}
                        autoHeight
                        loading={!isLoaded}
                        rows={getTableRows()}
                        columns={dispatchedLoadsColumns}
                        pageSize={25}
                        onRowClick={(params: GridRowParams) => {
                            navigate(`${params.id}`, { state: { type: type, selectedLoadIdx: params.row.rowIndex, loads: loads, searchInput: searchInput } })
                        }} />
                }
            </div>
        </div>

    )
}
