import React, { useState, useEffect } from "react"
import clsx from 'clsx';
import {
    Divider, IconButton, ListItemText, Menu, MenuItem, Toolbar,
    Typography, CssBaseline, AppBar, Hidden, Drawer, ListItemAvatar, Avatar,
    ClickAwayListener, Paper, Button, FormControl, InputLabel, OutlinedInput, Select, Input, useTheme
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu"
import { makeStyles, createStyles, Theme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from "react-router-dom"
import { Apps, AccountCircle, HighlightOff, KeyboardArrowDown } from "@material-ui/icons"

import SwimsLogo from "../swims/swims_favicon.png"
import EncoreLogo from "../encore/encore_favicon.png"
import EurolabLogo from "../eurolab/EuroBlue.png"

import { useMsal, useAccount } from "@azure/msal-react";

import { Auth } from "aws-amplify";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        '& .MuiContainer-root': {
          [theme.breakpoints.down('lg')]: {
            paddingLeft: 8,
            paddingRight: 8
          },
          [theme.breakpoints.up('xl')]: {
            paddingLeft: 16,
            paddingRight: 16
          }
        },
        display: 'flex',
      },
      drawer: {
        [theme.breakpoints.up('lg')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
      },
      drawerButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
          display: 'none',
        },
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        [theme.breakpoints.down('lg')]: {
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1)
        },
        [theme.breakpoints.up('xl')]: {
          padding: theme.spacing(2)
        }
      },
      title: {
        flexGrow: 1
      },
      drawerItem: {
        marginLeft: 4
      },
      userDetails: {
        position: "absolute",
        right: 80,
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
        marginTop: -10,
        marginRight: 10,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
      noLabel: {
        marginTop: theme.spacing(3),
      },
      icon: {
        fill: "white"
      }
  }),
);

interface Props {
    window?: () => Window;
    drawer: JSX.Element;
    title: String;
    theme: Theme;
    user: any | null;
    globalBranch: string;
    setGlobalBranch: (branch: string) => void;
  }

function EncoreShell(props: Props) {
    const { window } = props;
    const classes = useStyles();
    

    const [mobileOpen, setMobileOpen] = useState(true);
    const [branchActionsMenu, setBranchActionsMenu] = useState<null | HTMLElement>(null)

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openUserDetails, setOpenUserDetails] = useState<boolean>(false);

    const handleUserDetailsClick = () => {
      setOpenUserDetails(!openUserDetails);
    }

    const handleUserDetailsClickAway = () => {
      setOpenUserDetails(false);
    }

    const handleBranch = (branch: string) => {
      props.setGlobalBranch(branch)
      localStorage.setItem("eurocorp-branch", branch)
    }

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    useEffect(() => {
        if (account && account.name) {
            setName(account.name);
        }
    }, [account]);
    
    const handleLogout = async (logoutType: string) => {
      setAnchorEl(null);
      if (logoutType === "popup") {
        instance.logoutPopup({
            mainWindowRedirectUri: "/"
        });
    } else if (logoutType === "redirect") {
        instance.logoutRedirect();
    }
      // try {
      //   await Auth.signOut({ global: true });
      // } catch (error) {
      //     console.log('error signing out: ', error);
      // }
      
  }

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  const branchNames = [
    'Auckland', 'Christchurch','Cromwell', 'Dunedin','Mt Maunganui','Nelson','Wellington'
  ];
  
  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
 
  const [branchName, setBranchName] = React.useState<string[]>(props.globalBranch.split("|"));

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBranchName(event.target.value as string[]);
    handleBranch((event.target.value as string[]).join("|"))
  };

  const theme = useTheme();

    return (
      <div>
        <MuiThemeProvider theme={props.theme}>
          <CssBaseline />
          <AppBar position="fixed" 
            className={clsx(classes.appBar, {
            [classes.appBarShift]: mobileOpen,
            })}>
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                {props.title}
              </Typography>
              <div>
                

                {/* <Button 
                  style={{color: "#ffffff"}} 
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setBranchActionsMenu(event.currentTarget)
                }}>
                  {props.globalBranch}
                  <KeyboardArrowDown />
                </Button> */}
                <FormControl variant="filled" className={classes.formControl} >
                  <InputLabel id="demo-mutiple-name-label" style={{color:"white"}}>Branch</InputLabel>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={branchName}
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                    inputProps={{classes:{icon:classes.icon}}}
                    style={{color:"white"}}
                  >
                    {branchNames.map((name) => (
                      <MenuItem key={name} value={name} style={getStyles(name, branchName, theme)}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Hidden xsDown>
                  
                    <span style={{fontWeight: "bold"}}>{`${name}`}</span> 
                  
                </Hidden>
                <IconButton color="inherit" onClick={handleUserDetailsClick}>
                  <AccountCircle />
                </IconButton>
                {openUserDetails && 
                  <ClickAwayListener onClickAway={handleUserDetailsClickAway}>
                    <Paper className={classes.userDetails}>
                      <Button style={{width: "100%"}} onClick={() => handleLogout("redirect")}>
                        Sign Out
                        <HighlightOff style={{marginLeft: 4}}/>
                      </Button>
                    </Paper>
                  </ClickAwayListener>
                }

                {/* <Menu
                  open={Boolean(branchActionsMenu)} 
                  anchorEl={branchActionsMenu}
                  onClose={() => setBranchActionsMenu(null)}>

                  <MenuItem selected={props.globalBranch === "All"} onClick={() => {
                    handleBranch('All')
                    setBranchActionsMenu(null)
                  }}>
                    All
                  </MenuItem>
                  <MenuItem selected={props.globalBranch === "Auckland"} onClick={() => {
                    handleBranch('Auckland')
                    setBranchActionsMenu(null)
                  }}>
                    Auckland
                  </MenuItem>
                  <MenuItem selected={props.globalBranch === "Christchurch"} onClick={() => {
                    handleBranch('Christchurch')
                    setBranchActionsMenu(null)
                  }}>
                    Christchurch
                  </MenuItem>
                  <MenuItem selected={props.globalBranch === "Cromwell"} onClick={() => {
                    handleBranch('Cromwell')
                    setBranchActionsMenu(null)
                  }}>
                    Cromwell
                  </MenuItem>
                  <MenuItem selected={props.globalBranch === "Dunedin"} onClick={() => {
                    handleBranch('Dunedin')
                    setBranchActionsMenu(null)
                  }}>
                    Dunedin
                  </MenuItem>

                  <MenuItem selected={props.globalBranch === "Mt Maunganui"} onClick={() => {
                    handleBranch('Mt Maunganui')
                    setBranchActionsMenu(null)
                  }}>
                    Mt Maunganui
                  </MenuItem>

                  <MenuItem selected={props.globalBranch === "Nelson"} onClick={() => {
                    handleBranch('Nelson')
                    setBranchActionsMenu(null)
                  }}>
                    Nelson
                  </MenuItem>

                  <MenuItem selected={props.globalBranch === "Wellington"} onClick={() => {
                    handleBranch('Wellington')
                    setBranchActionsMenu(null)
                  }}>
                    Wellington
                  </MenuItem>
                  
                  
                </Menu> */}

                <IconButton color="inherit" onClick={handleClick}>
                  <Apps/>
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                    marginLeft: 15,
                    marginTop: 48,
                    width: 175,
                    backgroundColor: "#eeeeee"
                  }
                }}>
                  <MenuItem component={Link} to="/encore">
                    <ListItemAvatar>
                      <Avatar src={EncoreLogo}/>
                    </ListItemAvatar>
                    <ListItemText primary="Encore" secondary="encore app"/>
                  </MenuItem>
                  <Divider />
                  <MenuItem component={Link} to="/swims">
                    <ListItemAvatar>
                    <Avatar src={SwimsLogo}/>
                    </ListItemAvatar>
                    <ListItemText primary="Swims" secondary="swims app" />
                  </MenuItem>
                  <Divider />
                  <MenuItem component={Link} to="/eurolab">
                    <ListItemAvatar>
                    <Avatar src={EurolabLogo}/>
                    </ListItemAvatar>
                    <ListItemText primary="Eurolab" secondary="eurolab app"/>
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: mobileOpen,
              [classes.drawerClose]: !mobileOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen,
              }),
            }}>
            {props.drawer}
          </Drawer>
        </MuiThemeProvider>
      </div>
  )
}

export default EncoreShell