import React, { useState, useEffect } from "react"
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Table, TableContainer, TableHead, TableBody, TableRow, 
    TableCell, Paper, Chip, Breadcrumbs, Typography, Grid, TextField, InputAdornment, 
    IconButton
} from "@material-ui/core";
import moment from "moment";
import { Link } from 'react-router-dom';
import { 
    CheckCircleOutline, ErrorOutline, InfoOutlined, ReportProblemOutlined, 
    Home, Search, ClearAll 
} from "@material-ui/icons";
import { StyledTableCell, StyledTableRow } from "../../components/StyledTableComponents";

let sTimer: NodeJS.Timeout;

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        table: {
            minWidth: 650,
        },
        header: {
            display: "flex"
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 300
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        link: {
            display: "flex"
        }
    })
);

// const StyledTableCell = withStyles((theme: Theme) =>
//   createStyles({
//     body: {
//       fontSize: 14,
//     },
//   }),
// )(TableCell);

// const StyledTableRow = withStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//       },
//     },
//   }),
// )(TableRow);

export interface StressFileInfo {
    seqNo: number;
    loadedAt: string;
    loadedBy: string;
    fileUrl: string;
    testName: string;
    testMode: string;
    testType: string;
    title1: string;
    comment1: string;
    supplier: string;
    manufacturer: string;
    productName: string;
    castHeatNumber: string;
    batchNumber: string;
    reportDate: string;
    testNumber: number;
    operator: string;
    temp: number;
    manufacturingDate: string;
    batchQty: number;
    speed: string;
    shape: string;
    numberBatched: number;
    qtyBatch: number;
    diammeterMM: number;
    guageLengthMM: number;
    status: string;
    isDowngrade: boolean;
    isQuarantine: boolean;
    lastModifiedBy: string;
    lastModifiedAt: string;  
    machineName: string;
    machineDesc: string;
}

export default function StressTestFiles() {
    const classes = useStyles()
    const [isLoading, setLoading] = useState<boolean>(true);
    const [stressFiles, setStressFiles] = useState<Array<StressFileInfo> | undefined>(undefined)
    const [searchInput, setSearchInput] = useState<string>('')

    function getFiles() {
        setLoading(true)
        fetch(`/api/el/stressfiles`)
        .then(res => res.json())
        .then((result: Array<StressFileInfo>) => {
            setStressFiles(result)
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("ERROR: loading stress files", error)
            setLoading(false)
        })
    }

    useEffect(() => {
        getFiles()
    }, []);

    function onSearchInput(input: string) {
        setSearchInput(input)
        if (sTimer !== null) {
            clearTimeout(sTimer);
          }
          sTimer = setTimeout(() => {
            if (searchInput.length > 0) {
                setLoading(true)
                fetch(`/api/el/stressfiles/search/${input}`)
                .then(res => res.json())
                .then((result: Array<StressFileInfo>) => {
                    if(result) {
                        setStressFiles(result)
                    } else {
                        setStressFiles([])
                    }
                    setLoading(false)
                })
            } else {
                getFiles()
            }
          }, 1500);
    }

    const handleSearch = (event: React.ChangeEvent<{value: string}>) => {
        onSearchInput(event.target.value);
    }

    const handleClearSearch = () => {
        if(searchInput.length !== 0) {
            setSearchInput('')
            getFiles()
        }
    }

    function formatDate(date: string) : moment.Moment | string {
        if (date === "0000-00-00"){
            return "";
        }
        return date ? moment.parseZone(date).format('DD/MM/YY HH:mm'): "";
    }

    function formatBool(s: boolean) : string {
        return s ? "Yes" : "No";
    }

    function getStatusBackgroundColor(status: string) : string {
        if(status === "Pass") {
            return "#e8f5e9"
        } else if(status === "Fail") {
            return "#ffebee"
        } else {
            return "#e3f2fd"
        }
    }

    function getStatusColor(status: string) : string {
        if(status === "Pass") {
            return "#388e3c"
        } else if (status === "Fail") {
            return "#d32f2f"
        } else {
            return "#1976d2" 
        }
    }

    function getStatusIcon(status: string) : JSX.Element {
        if(status === "Pass") {
            return <CheckCircleOutline style={{color: "#388e3c"}}/>
        } else if (status === "Fail") {
           return <ErrorOutline style={{color: "#d32f2f"}}/>
        } else {
           return <InfoOutlined style={{color: "#1976d2"}}/>
        }
    }

    function getTypeBackgroundColor(type: boolean) : string {
        return type ? "#fff3e0" : "#e3f2fd";
    }

    function getTypeColor(type: boolean) : string {
        return type ? "#f57c00" : "#1976d2";
    }

    function getTypeIcon(type: boolean) : JSX.Element {
        return type ? <ReportProblemOutlined style={{color: "#f57c00"}}/> : <InfoOutlined style={{color: "#1976d2"}}/>
    }

    return (
        <div>
            <Breadcrumbs>
                <Link to="/eurolab" className={classes.link}>
                    <Home className={classes.icon} />
                    Home
                </Link>
                <Typography color="textPrimary">Stress Files</Typography>
            </Breadcrumbs>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Stress Test Files
                </h2>
                <div className={classes.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <Search />
                        </Grid>
                        <Grid item>
                            <TextField 
                            id="input-with-icon-grid" 
                            label="Search by Product or Batch No."
                            onChange={handleSearch}
                            value={searchInput}
                            InputProps={{
                                className: classes.input,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton color="inherit" onClick={handleClearSearch}>
                                        <ClearAll />
                                    </IconButton>
                                </InputAdornment>
                            }} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            {isLoading && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}}/>}
            <Divider style={{marginBottom: 16}} />
            {stressFiles &&
                <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Test Name</StyledTableCell>
                        <StyledTableCell>Product Name</StyledTableCell>
                        <StyledTableCell>Batch Number</StyledTableCell>
                        <StyledTableCell>Supplier</StyledTableCell>
                        <StyledTableCell>Machine</StyledTableCell>
                        <StyledTableCell>Manufacturer</StyledTableCell>
                        <StyledTableCell>Loaded By</StyledTableCell>
                        <StyledTableCell>Loaded At</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Quarantine</StyledTableCell>
                        <StyledTableCell>Downgrade</StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {stressFiles.length !== 0 && stressFiles.map((file: StressFileInfo) => (
                        <StyledTableRow key={file.seqNo}>
                            <StyledTableCell component="th" scope="row">
                                <Link to={`/eurolab/stressfiles/${file.seqNo}`} state={{tab:0}}>
                                    {file.seqNo}
                                </Link>
                            </StyledTableCell>
                            <StyledTableCell>{file.testName}</StyledTableCell>
                            <StyledTableCell>{file.productName}</StyledTableCell>
                            <StyledTableCell>{file.batchNumber}</StyledTableCell>
                            <StyledTableCell>{file.supplier}</StyledTableCell>
                            <StyledTableCell>{file.machineName}</StyledTableCell>
                            <StyledTableCell>{file.manufacturer}</StyledTableCell>
                            <StyledTableCell>{file.loadedBy}</StyledTableCell>
                            <StyledTableCell>{formatDate(file.loadedAt)}</StyledTableCell>
                            <StyledTableCell>
                                <Chip 
                                    label={file.status}
                                    icon={getStatusIcon(file.status)}
                                    style={{backgroundColor: getStatusBackgroundColor(file.status), color: getStatusColor(file.status), fontWeight: "bold"}}
                                    size="small"/>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Chip 
                                    label={formatBool(file.isQuarantine)}
                                    icon={getTypeIcon(file.isQuarantine)}
                                    style={{backgroundColor: getTypeBackgroundColor(file.isQuarantine), color: getTypeColor(file.isQuarantine), fontWeight: "bold"}}
                                    size="small"/>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Chip 
                                    label={formatBool(file.isDowngrade)}
                                    icon={getTypeIcon(file.isDowngrade)}
                                    style={{backgroundColor: getTypeBackgroundColor(file.isDowngrade), color: getTypeColor(file.isDowngrade), fontWeight: "bold"}}
                                    size="small"/>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
            }
        </div>
    )
}
