import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid } from "@material-ui/core";
import { API } from 'aws-amplify';
import { OpportunityCustomerQuoteDetail } from '../../../types/OpportunityTypes';
// import { useStyles } from './JobTagDetailTable';


export interface CustomerQuoteEditDetailDialogProps {
    open: boolean;
    // quoteDrawing?: string;
    // quoteNotes?: string;
    // opportunityNo?: number;
    // customerSeqNo?: number;
    // measureNo?: number;
    customerQuote?: OpportunityCustomerQuoteDetail
    
    onClose: () => void;
}
export function CustomerQuoteEditDetailDialog(props: CustomerQuoteEditDetailDialogProps) {
    const { onClose, open, customerQuote } = props;
    // const classes = useStyles();
    const [drawing, setDrawing] = useState(customerQuote?.drawingDetails);
    const [notes, setNotes] = useState(customerQuote?.notes);

    useEffect(() => {
        setDrawing(customerQuote?.drawingDetails)
        setNotes(customerQuote?.notes)
    }, [customerQuote])
    


    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setValues({
    //         ...values,
    //         [name]: value,
    //     });
    // };




    const handleClose = () => {
        onClose();
    };


    function handleCloseDialog(): void {
        onClose();
    }

    const handleSubmit = async () => {
        //Post to API
        let params = {
            body: {...customerQuote,
                drawingDetails: drawing,
                notes: notes
            }
        }
        try {
            await API.put("", `/encore/opportunities/${customerQuote?.opportunitySeqNo}/customers/${customerQuote?.custSeqNo}/measure/${customerQuote?.measureId}`, params)
            onClose();
        } catch (error) {
            console.log(error);
        }
        
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth='md'>
            <DialogTitle id="simple-dialog-title">Customer Quote Information</DialogTitle>
            
                <DialogContent>

                <Grid container direction='column' spacing={1}>
                    <Grid item  >

                    <TextField
                        placeholder="Enter Drawing Detail"
                        fullWidth
                        variant="filled"
                        required
                        name="drawing"
                        value={drawing}
                        multiline={false}
                        rows={1}
                        onChange={(e) => setDrawing(e.target.value)} />
                </Grid>
                <Grid item  >
                    <TextField
                        placeholder="Enter Notes"
                        fullWidth
                        variant="filled"
                        required
                        name="notes"
                        value={notes}
                        multiline={true}
                        rows={10}
                        onChange={(e) => setNotes(e.target.value)} />
                </Grid>
                </Grid>

                </DialogContent>

                <DialogActions>
                    {/* {error &&
                    <span style={{color: "red"}}>{error}</span>
                } */}
                    <Button color="secondary" onClick={() => handleCloseDialog()}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="secondary"
                    >
                        Add
                    </Button>
                </DialogActions>
           
        </Dialog>
    );
}
