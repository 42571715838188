import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@material-ui/core";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useStyles } from './JobTagDetailTable';


export interface EditLabourRatesDialogProps {
    openDialog: boolean;
    // selectedValue: string;
    onCloseDialog: () => void;
}
export function EditLabourRatesDialog(props: EditLabourRatesDialogProps) {
    const classes = useStyles();
    const [labourRate, setLabourRate] = useState(0);
    // const [scheduleName, setScheduleName] = useState('');

    const { onCloseDialog, openDialog } = props;

    const handleClose = () => {
        onCloseDialog();
    };


    function handleCloseDialog(arg0: boolean): void {
        onCloseDialog();
    }

    function handleSubmit(): void {
        if  (!labourRate) {
            return;
        }
        onCloseDialog();
        // setLabourRate("");
        // setScheduleName("");
    }

    // const columns: GridColDef[] = [
    //     { field: "id", headerName: "ID", width: 90 },
    //     {
    //         field: "productType",
    //         headerName: "Type",
    //         width: 150,
    //         editable: false,
    //     },
    //     {
    //         field: "rate",
    //         headerName: "Place Rate",
    //         width: 150,
    //         editable: true,
    //     },

    // ];

    // const rows = [
    //     { id: 1, rate: 1.21, productType: "Cut/Bend - D16" },
    //     { id: 2, rate: 1.21, productType: "Cut/Bend - D20" },
    //     { id: 3, rate: 1.21, productType: "Cut/Bend - D25" },
    //     { id: 4, rate: 1.21, productType: "Cut/Bend - D32" },
    //     { id: 5, rate: 1.21, productType: "Cut/Bend - D40" },
    //     { id: 6, rate: 1.21, productType: "Cut/Bend - D50" },
    //     { id: 7, rate: 1.21, productType: "Cut/Bend - D63" },
    //     { id: 8, rate: 1.21, productType: "Cut/Bend - D75" },

        
    // ];


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openDialog}>
            <DialogTitle id="simple-dialog-title">Edit Labour Rates</DialogTitle>
            <DialogContent>
            <TextField
                    placeholder="Labour Rate"
                    fullWidth
                    variant="filled"
                    required
                    name="scheduleId"
                    value={labourRate}
                    type="number"
                    onChange={(e) => setLabourRate(e.target.value as unknown as number)} />



            </DialogContent>

            <DialogActions>
                {/* {error &&
                <span style={{color: "red"}}>{error}</span>
            } */}
                <Button color="secondary" onClick={() => handleCloseDialog(false)}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
